export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Ajustée du fait de la devise ou des jours fériés)',
  eba8e32b871086c08ac4b90ef5ede94b:
    'Les dates de frais et de paiement sont indicatives et ne sont pas garanties.',
  fa090ada546b5deb33cdead006822e26:
    '*Les dates de frais et de paiement sont indicatives et ne sont pas garanties.',
  '52fce318164dc60017ca67121172aca9': 'Accepté',
  '615a093396ed9c6f52cd7ece7217608e': 'Ajustement de compte',
  '79dd431d35b370210a3dbeb1bb653d43': 'Nom de compte',
  '8c66a974a95769447407c061a36d0824': 'Numéro de compte',
  '6a810f9427275516cc7ac687ea182f27': 'Intérêts courus sur la dette',
  '21df2d8134802290caddc9a8070e9d94': 'Frais de retard courus',
  '7377dcc6989501c8299b7ccf697b731e': 'Intérêts courus sur la dette',
  dab81136f1c6e799c5626826349a8dcf: 'Frais de retard courus',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Tout',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Tous les acheteurs',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Toutes le devises',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Toutes les factures',
  d7db3397d1ee87530b253bc69ebca041: 'Montant',
  c7586d1e153c74713afd948b3048d03d:
    'Montant qui vous est versé après déduction des intérêts et des éventuels remboursements échus sur le montant financé.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    "Montant récupéré sur les remboursements échus d'autre(s) facture(s).",
  '57e646b300b4b551d662b0cd536a6ad5': 'Montant à verser',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Tout fonds excédentaire vous sera transmis.',
  de9800ff635c316938aece381c98b094:
    "Tous les paiements incomplets et intérêts impayés peuvent être soustraits du prix d'achat à payer ou d'autres encaissements. Si nous ne sommes pas en mesure d'encaisser de cette manière, nous pouvons vous demander de payer le montant directement à l'acheteur.",
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Approuvé',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Poser une question',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Limite de crédit disponible pour les nouveaux financements.',
  '85a6c2f454268a7b3c4156c070abc517': 'En attente d’approbation',
  '99fb0198659b8076326d69a42670433b':
    'Les soldes sont généralement mis à jour le jour ouvrable suivant.',
  '0448c27d5574d95fc844e50416fc13a3': 'Ensemble',
  '84b730c6e665f3a83a96a8511b4f63db': 'Informations de bundle',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Branche de l’acheteur',
  '2be86945c7f60b651a0cf58d8e73dabf': "Facture rejetée par l'acheteur",
  cdb5b8f6940144b32dc7f27b7700719a: "Règlement de l'acheteur",
  '2e5e060fbc0b1324e5e2059482c65be6': 'Marqué payé par l’acheteur',
  e7094a08ea031332f2a7e7f94b214174: "Règlement de l'acheteur",
  b8b64034058f45fe990f8377c7990c6b: "Compte de règlement de l'acheteur",
  b58bdf039e33f8abf293173c9e0c1ee6: 'Acheteur :',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Les acheteurs paient ici leurs factures. Vous payez ici les paiements incomplets',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Par défaut, le montant du remboursement échu est déduit de la ou des prochaines factures financées.',
  cb1a025651df74878d8d5af1b99991df: 'Annulé',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Informations sur le programme Cash',
  '6fd54168f061cd9f269dbcbb29415486': 'Montant des frais',
  '252982cd07a2c1e75a9cadb781c4428f': 'Restitution',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Cliquez pour copier',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Fermer',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Fermé',
  a150472168406890d84a6a5c3c8a894c: 'Collecté',
  '0743742edda54d26e47f9959ca4be4f0': 'Intérêts de dette recouvrés',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Frais de retard recouvrés',
  '0b2194f7621b8a036308dce1647b982d': 'Montant collecté',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Intérêts de dette recouvrés',
  b1a3b2482cd668f631d724aac2dfc685: 'Frais de retard recouvrés',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Encaissement',
  ba18fa9a0a1da454462f71c6e36139ff: 'Soldes de recouvrement',
  '1103815eae13527faa8d51c83c20d17e': 'Informations sur la collecte',
  '898e15d1fa445c9c37ca795f79029f6d': 'Dénomination sociale de l’entreprise',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Continuer',
  c05df0411bb18c405aaa68153432bf10:
    'Limite de crédit et de facture mise à jour à',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Crédit disponible',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Limite de crédit',
  d23014cf5a5e7f8c6440b96692831aa8: 'Avoir',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Crédit utilisé',
  '207df9f33356e378a1ff2ef7b62becc7': 'Crédit disponible',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Limite de crédit',
  '208d5141343637870387b27db87ccf05': 'La limite de crédit a été dépassée',
  '0e27340c6eb226df1076c4f8930d2289': 'Crédit utilisé',
  e15a66f111bc879c77f24943a94d19e7: "Cette devise n'est pas prise en charge",
  b1e5986f972b7599559dea22cfeae8fb: 'Devise :',
  '90322bb69cffbb5d303e93c3a98cc595': 'Réduction quotidienne',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Taux de réduction quotidien',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Taux de retard journalier',
  '9e268b45f182ad1851f03539be6ab29c': 'Taux de réduction quotidien',
  c2191b6160c46ed7a4b05cc0279158f3: 'Taux de retard journalier',
  e2d9ef555d350e16239f4dfc92842fdc: 'Tableau de bord',
  '2eca727eade526acc715c2e5605ebc7e':
    'Date à laquelle le montant financé a été réglé intégralement.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Date à laquelle le délai de grâce de 30 jours prend fin.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Date à laquelle la facture a été rejetée par votre acheteur.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Jours en retard',
  '1e1e68c46e0d719364bd445b1d605144': 'Jours en retard',
  bd50c01a22cae65be4d588abcae65a90: 'Déduction',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Déductions',
  a39bf2f30994908fe95498524b423d43: 'Détails',
  '193d60da437b58b24ed69a5683c1ffcf': 'Dilué',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Montant de dilution',
  d07e29091188f3b0871dc7712eee7255: 'Type de dilution',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Règlement direct du vendeur',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Règlement direct du vendeur',
  afc1c7dfc9315e856f8da52e2450182b: 'Réduction',
  '26e7eb298420701d5a03c53fe096cc0b': 'Contesté',
  '43cca9c1b76ebf869c7bf22fff271689': 'Filtres de statut de document',
  ce73b429141cda25571c88d9b0c78224: 'Statut du document',
  af13cc2ad0a7337c45420f75b7d5a668: 'Télécharger',
  '8475be8bb8249000118af931ae75c373': 'Télécharger le rapport',
  '90567e36a540e51806d7790eb195b1d5': 'Téléchargement de toutes les factures',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Brouillon',
  '72015a772310560b755bd03132a543b2': "Date d'échéance",
  f12ae1ac50389e2b7470ae1f3d1a0795: "Date d'échéance",
  aebf0bb5575e8a95e1eaa6f0de3d0416: "Date d'échéance trop tôt",
  '6def6918bd02ae5117332f4262cecb52': 'Éligible',
  '6426c30d14da5d40d03fc7e85d0c9677': "Statut de l'inscription",
  cb339c5c02cfb087fc8d393f917379df:
    'Erreur lors du chargement du tableau de bord.',
  e9994b26424cb4bce5059d05e094df8d: 'Frais estimé',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Pays exclu',
  b5cb31ac9c7d1871e9075efc1f271536: 'FRAIS',
  fd9d568c402dd04e0715e877d76f7da5: 'Valeur faciale de la facture.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Échec',
  '817f1fe84c2d87894bc374681814abbf': 'Échec du chargement de {title} –',
  '7932079ce6ea928bfa77efd91578312d': 'Frais',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filtrez par devise pour afficher les totaux résumés.',
  '110df72171aeef47da3fd84ea2a1a10f': 'Date de paiement prévue trop longue',
  e5af505b37031920b08edb84df19b5ec:
    'Date de recouvrement du montant total financé',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Date de recouvrement du montant total financé',
  '6dd341953c8c405c70878a457622cf55': 'Fondé',
  '42e20c6bb1dc82fbdbd889f769850320': 'Montant financé recouvré',
  cab921680af6ff282ce37398b0b231d4: 'Montant financé',
  fc0347ceea208298049efd79021475a2: 'Montant financé recouvré',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Date du financement',
  '55d900eb37f97b684c0bd5821f161216': 'Factures financées',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Financement',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Exception détectée',
  '5ca89ce217f3bb07293db00a42c10200':
    "Raison(s) d'inadmissibilité au financement",
  eac067c62df8f46d6bbdbfc4f9e69803: "Vue d'ensemble du financement",
  '08109e77ecbcb34d6e7391b293d8ba20': 'Succès de la demande de financement',
  d5ec85821723434aba05986044ebdcdd: 'Statut du financement',
  '3447158e3ace443dbe27556e07c88639': 'Filtres de statut de financement',
  '022848540bf0e01453be6cf79843aa67': 'Durée du financement',
  b4a59826d5bfc276fa88bd00abac615a: 'Détails du financement',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Durée du financement',
  e74f45b44aadcdd084b5cbdd3560f515: 'Date de fin de grâce',
  '5434cf75f987ef76dbdc544fa1e32096': 'Comment payer',
  cb9f9412bd12122bb088124afccf738b: 'Comment rembourser les paiements courts ?',
  '9ad57f0b683fecb097c09bedebf68a45': 'Comment régler le remboursement dû :',
  '7428e52e7eecc9d7211ad241a469cd65':
    "Si vous pensez qu'il s'agit d'une erreur, veuillez contacter <a href=",
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Informations importantes',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'En cours',
  '313b15a4fdfa2368015e2eb67416af2a': 'Programme inactif',
  '7b6a003ceca7fe7fec85daae919e800b': 'Inéligible',
  '6cf0784c1ac918c22cd402e0cb558749': 'Raison inadmissible',
  '65cb9c651d2d9fb8941050a08add03c3': 'Inéligible aux paiements anticipés',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Factures non admissibles',
  c555faa7b0b745638bb3ea785ee10137:
    'Les informations affichées ont été mises à jour pour la dernière fois le',
  b7311d63c44566a8562d92720cffed2e:
    'Ratios encaissements/factures insuffisants',
  fb07cb1753537f6969513f2d5fdb7993: 'Montant des intérêts',
  '1938e208f65e66474dc4148c3fa7da08': 'Intérêts déduits',
  '42094e40339ac5c0957a980062c47659': 'Intérêts courus sur paiement incomplet',
  d9580a19710f07e8c72c215b1270ca57:
    'Intérêts facturés par HSBC sur le montant financé.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Intérêts déduits',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Intérêts courus sur facture échue',
  '47a565198d287d0864339c077819a361':
    "Des intérêts continueront à courir sur la dette en souffrance jusqu'à ce qu'ils soient déduits du prochain paiement qui vous sera versé ou que nous recevions un règlement direct.",
  '3c4be400f728da4526de626653d56ccc': 'Facture',
  '70546e9227f592c24d3c491756cb901d': 'N° de facture',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Montant de la facture',
  '6b9515d101794c31e38ae60d092fdc03': 'Date de la facture',
  '2548c6a52ce9e1bffef7aad14530f490': 'Facture financée',
  '42bb3ba3a1cd691726744d3a1a8da732':
    "Vue d'ensemble du financement des factures",
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Limite de facture',
  '734ed127b3d7226c75e8553c0711367a': 'Limite de facture utilisée',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Limite totale de facture',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Numéro de facture',
  da2db17c9b22f995d6673a754d998fc8: 'Facture échue',
  d729bd1bdced62265cd4ecd7131ef94e: 'Facture rejetée',
  e14208d4378a3378be8b27eb15984fc6: 'Facture réglée',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Statut de la facture',
  '54d8df173250a197fab1644a0d0a772a': 'Montant de la facture',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    "La facture ne peut pas être financée pour le moment. Voir la rubrique Raisons d'inadmissibilité au financement pour plus d'informations.",
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'La devise de facturation ne correspond pas au pays',
  d0da9e977c8b309f93ee378de34fb251: 'Date de facturation',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Date de facturation avant la première date éligible',
  ad0ab15468b719694f3193d0649ad9eb:
    "La date de facturation est postérieure à la date d'annulation de l'établissement.",
  fc9e80713aebb3d820d888f0755fedde: "La facture n'a pas d'acheteur abonné",
  e17cbec04e7786361eff9da629f09bb7:
    "La facture contient une date d'émission future.",
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'La facture a été annulée.',
  '89f842841b4ae213259b94a5411453f2': 'La facture a été diluée.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'La facture a été financée dans le cadre du programme Cash.',
  ab5303505d045929abb1256607ca0d4d:
    "La facture a été entièrement payée. Aucun autre paiement n'est requis.",
  '3e159746cbdfda07b2f45750941e26f6':
    'Un financement a été demandé pour la facture.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'La facture a le statut de document inéligible.',
  '8204c52c01b05791dc3e4c59ac352978':
    "La facture n'a pas été entièrement réglée et a dépassé la fin du délai de grâce",
  d87ca92704bcfb34fa4ac08a4d24147a:
    "La facture n'est actuellement pas éligible.",
  '2bfdce2f3dd38d4d167510eb5c9434de':
    "La facture n'a pas de date de fin ou de date de fin finale",
  '9ab3b9c101b4053a3818c764fcfcde35':
    'La facture est trop proche de la date de paiement prévue.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'La facture est inhabituelle',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Facture marquée comme déjà payée.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'La facture pourrait être un doublon.',
  b1f771da19efea2f0fa6a542823231b9: 'Facture partiellement éligible',
  '4217948ca113ee4158d5dd4c07d71624': 'Facture réglée à brève échéance',
  b6d4f7035794b3bc3a0be36237fedd2b:
    "La teneur de la facture n'est pas dans la plage acceptable",
  '7c3fa246e8cca51273ddf5062ff2d593': 'Teneur de la facture trop long',
  '90d9ac20dda384e9c07554d29b143bef':
    'La valeur de la facture dépasse la limite',
  '4b36f0be327fdca8d54126cd57095a13': 'Numéro de facture/RA',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Factures',
  '8089283aad9294aadc2bde165ccc78c1': "Date d'émission",
  '3a4123f9c604369ab18781f7492f9293': "Date d''émission",
  f2cc23904a2b3bf60e1c059712a12cd7: 'Dernière date de paiement',
  a94bc4d4df1d56c16e25493e22684412: 'Limite disponible',
  '92f97c843d499a1559828ba0a9134e24': 'Limite utilisée',
  b5dd8e130d88698834ee00464f01cea4: 'Limite disponible',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Limite utilisée',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Chargement en cours...',
  '24cc5627dea65e22f9279be9522a06e2': 'Montant du prêt',
  bd24bcedd066a27749016a07cb729a32: 'Marqué réglé',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: "Date d'échéance",
  '41dcb6cef39f12b7e13ec8e3e504b3b3': "Date du début de l'adhésion",
  d26429adfb846303cbd889879b23e1ba:
    'Sommes perçues sur les paiements des acheteurs, les remises des vendeurs et/ou les déductions avant et/ou après le délai de grâce.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Montant net payé',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Montant net payé',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nouveau montant',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Jour ouvrable suivant',
  '325c48f099844279c0cf48584de42e64': "Pas d'activité récente",
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Aucun',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Pas activement enregistré comme Espèces',
  '97d4ed503f1a63577fc5befd1c7a2663': "Pas assez d'historique des factures",
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Pas assez de factures les mois précédents',
  c9e2ce8da559465a8596317c35e8f3a4: 'Pas assez de factures payées',
  e6f890deafa579eb0c24603c3101c1c7:
    "Notez que les soldes sont généralement mis à jour le jour ouvrable suivant. Ouvrez un ticket d'assistance si vous avez besoin de connaître votre position actuelle.",
  '83070a059f10db1515ac922bf3433158':
    'Nombre de jours après la date de fin de grâce.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Un rapport disponible en téléchargement',
  '2778717f6acbeb1edaac6a2656b478f3': 'Ouvrir',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Autres frais',
  fad92e97e692758c0d0a9b5730663894: 'Intérêts de la dette impayés',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Montant financé impayé',
  '9eab1baddf2537312408088fc54f21d5': 'Frais de retard impayés',
  bfba12a2c234532c0af24a4ad6831ab6: 'Montant total impayé',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Intérêts de la dette impayés',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Montant financé impayé',
  '2f9a3de6940f818fe0376e4aece59512': 'Frais de retard impayés',
  '6f79eea38bff17917740a69116270074': 'Échu',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Soldes en souffrance',
  '848ac8c39b11a751d697a383303e6109': 'Dette en souffrance',
  '8d1e868665fc24a46f0cf55187f72218':
    'Les dettes en souffrance seront signalées conformément aux règlements de RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Présentation',
  '5b885084a5067d7fb4372cef38d8a82d': 'Payé',
  '830b95d305cdc61a66cdf75ebff1d737': "Payé par l'acheteur",
  '6444796646c95dbc2cd1cb646e41293d':
    'Partie de la valeur faciale de la facture qui a été financée selon le pourcentage de paiement anticipé approuvé par HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': "Détails de l'acheteur participant",
  a39314e5897b8baaa51ca296610ec30c: 'Conditions des partenaires',
  a6acd5f03fda9c8dc607e59d8be08320: "Délai d'approbation du paiement",
  e6249829dadf637c4092e4a900df529e: "Payable a été contesté par l'acheteur",
  '07ed468d28d8e8437d8ec64036b96c03': 'Le paiement a été rejeté',
  '042b8dfb88f7045ca9145bbcccf79200': 'Payable est dans le futur',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Le paiement est trop ancien',
  '8755bbc212b541efb6576f39f3f0f51f': 'Paiement alloué',
  '8744ee10540166dfc39db2a6859a9ce1': 'Montant du paiement',
  '56133aa371eb0132334e4867479be942': 'Informations de paiement',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Retard de paiement',
  '2dd7164df606f9b42beefaba031b06f7': 'Paiement reçu',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Paiement confirmé par le vendeur',
  '661711004f06b0d9601db365679be130': 'Montant du paiement',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Date de paiement',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Intérêt de pénalité',
  '0fd036622716f275c3e14c5a14faa65a': 'En attente',
  '5d6bc30e401a38c04005a5d1962b46bc': 'En attente de dilution',
  '8cf3479829ba47a8a4123895d0088eeb': 'Dilutions en attente',
  '270b56fd05f72407ca0607b6162d0b10': 'Connexion en attente',
  '4a78218416ca45d97b5633b37ae3f638':
    'Taux en pourcentage auquel les frais quotidiens seront calculés pour la maturité totale du financement.',
  b586a5468c7070bf1717593ea84fa607:
    'Taux en pourcentage auquel vous serez facturé quotidiennement sur votre montant financé impayé pour les factures échues ou financées et rejetées.',
  f8c94740abd5c69fb813bd0f4827de0e:
    "Veuillez ouvrir un ticket d'assistance si vous avez besoin de connaître les soldes actuels.",
  '34645fa620cae4187f27d9f96c172f26': 'Veuillez remettre le montant',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Veuillez verser le montant suivant sur le compte bancaire suivant :',
  '01734a7316427fdcdcd4f937cda3802b': 'Prépaiement',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Montant précédent',
  '500fc4731dab800a878b33906d9332fb': "Programmer un compte d'encaissement",
  '7327113634d1f5f4f68632dceebfbc6f': "Vue d'ensemble du programme",
  d95ebfb51a485293a134b1038d332a10: 'Programme activé le',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Informations de programme non disponible pour le tableau de bord',
  '98feb2c86a81b8caeeaa30966ba34636': 'Informations sur le programme',
  d202f03687187e744a1f4b11a1e5f8e4:
    "Les paiements des facture d'achat qui ne sont pas encaissés intégralement à la date d'échéance prévue génèrent un paiement incomplet. Les paiements incomplets font courir des intérêts de 1,5 fois le taux d'escompte quotidien : il est de votre responsabilité de rembourser les deux.",
  '521689b600b8da5b0a111b89dea72126': 'Raison',
  fe7bd622a1efcec03eb86dabb9395936: 'Reçu',
  e84203a8833e4d0d92faf89f4107bf11: 'Remboursement des recours',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Montant du remboursement',
  b0be744ae1f6312f16990bbd92a87aea: "Date d'émission du remboursement",
  '39591f42bcdde6d7ce9409e167aa7e80': 'Maturité du remboursement',
  '654d6dcc141079bc84500fd14ca5087d': 'Montant du remboursement',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Détails du remboursement',
  '5b2508eaa0c48b46fe2034cdf1916867': "Date d'émission du remboursement",
  c0a6a91e7ca80af9de47097b06dc7618: 'Maturité du remboursement',
  '4a2875439ab289a068abb9ea3b567bcb': 'Rejeté',
  '592b34cdfa2e51826b32a0783e0edce3': "Rejeté par l'acheteur",
  '807d35f105b65ab2795c4f8e51795f3c': 'Rejeté par le vendeur',
  aea4dd208e871d7ca0051b0ec38c3068: 'Date du rejet',
  d64ed4934fbf68a8f716467b0adf89ba: 'Date du rejet',
  '5a77de3b287a92494be077c197f37581':
    'Montant financé restant à recouvrir ou à rembourser.',
  e442b864bf745a75477ea0ee88bc15c4: 'Remboursement échu',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Montant du remboursement à la date de fin de grâce. Cela ne change pas pour refléter les remboursements effectués après cette date, ni la dette en souffrance.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Remboursement échu',
  dc20cbfb76d220c108241a451bc3faf4:
    'Informations sur les instructions de remboursement',
  c8c021e2a7c9064a0b97db4aa96f6623:
    "Les remboursements dus correspondent au montant total restant à recouvrer, résultant soit du fait que le montant payé par l'acheteur est inférieur à la valeur nominale de la facture ; et/ou l'acheteur rejetant la facture après qu'elle ait été approvisionnée.",
  '45b60e22719c12291932bf09db0df5cd':
    'Les remboursements échus correspondent au montant total impayé qui doit être recouvré, résultant soit de :',
  ba093b1421452b3afdebbebedaeae552: 'Demander un financement',
  '34f8f9968ec0d7f91e558e906a7de982': 'Demandé',
  dc1650cfbadb05f8fb6357692e48ee71: 'Demandé pour financement',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Retenu',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Montant retenu',
  a476316a89541d1aa71d5b5366874e13: 'Montant retenu',
  '328f6585a0e78a96deefc0661474248f': 'Réessayer',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Ventes',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Rechercher par numéro de facture',
  '3215999493db188b35242dc4f4fd8fb5':
    'Voir les informations sur le programme pour plus de détails sur les remboursements.',
  '69f43575c3a51e94605eb75b75f7b563': 'Sélectionné',
  '56a1c7c4a02e9985745969563a80799f': 'Compte de remboursement du vendeur',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Envoi',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Envoyé',
  '95d54926719f1071b2fa640f1ba23d3e': 'Réglé',
  e243f36392a7f264d830f04f21aa8972: 'Factures réglées',
  b548572d4123e3a569d27e4676664dad: 'Paiement court',
  '6e09ada7e33833d259370b0cc900f082': 'Montant du paiement court',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Déductions pour paiement incomplet',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Paiements partiels',
  '46bbc145b1f85c082950c768ade012f6': 'Montant du paiement court',
  e66e6d9768e8f489c05951ee70ede350:
    "Une erreur s'est produite Toutes les fonctionnalités du tableau de bord peuvent ne pas être disponibles. Veuillez réessayer ou contacter l'assistance pour obtenir de l'aide.",
  cd969f183dc619b5716067cfda39c055: 'Code guichet',
  '17939071126e9e41921d79b837f63297': 'Nom du fournisseur',
  '750d4460b260343039f9bc71b66a231c': 'Le fournisseur a été jugé insolvable',
  '7f6562457845bcf84c0cfece3d1eaa51':
    "Ce fournisseur n'est pas encore configuré dans le programme",
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Conditions acceptées par',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Conditions du service',
  '1294cf929ec55581954a79948592dda9':
    'La date à laquelle le financement du programme Cash a été activé.',
  f51144e4468390a486d41b4db66fc243: 'Le fichier n’a pas pu être téléchargé.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    "Le nombre maximal de jours pendant lesquels le paiement de votre acheteur est attendu, tel qu'approuvé par HSBC.",
  d438da584c455e727642a303917e690d:
    "Le montant du crédit restant qui peut encore être utilisé. C'est la différence entre Limite de crédit et Crédit utilisé.",
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Le montant total des factures actuellement financées par ce programme. Ce montant est calculé en fonction de la limite de crédit et du montant actuel des factures financées.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Le montant total des factures pouvant être financées par ce programme. Ce montant est calculé en fonction de la limite de crédit et du montant actuel des factures financées.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Le montant total retenu avant le règlement de la facture.',
  '13f271ac486faca89c1944226ba0efff':
    'La valeur faciale totale de toutes les factures importées.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    "La valeur faciale totale des factures qui sont passées à l'état Réglé parce qu'elles ont été entièrement recouvrées.",
  '8cbe218cd17eada461e5da93d4cbd541':
    'La valeur faciale totale des factures financées.',
  '565c255165bed2ff2640d0e42369c62c':
    'La valeur totale des factures non éligibles au financement.',
  '3463c9dcfdb9aa161c07a692207786bf':
    "Ce compte n'est pas activement enregistré comme Espèces.",
  '1fa3524cbb92859434dcb01b98f71c7d':
    "Ces montants sont généralement mis à jour le jour ouvrable suivant. Veuillez créer un ticket d'assistance si vous avez besoin de connaître les soldes actuels.",
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Cette valeur reflète votre solde de paiement incomplet le plus récent, y compris les intérêts.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Ensemble avec',
  '603e4b3b3ac7b888a06548d14435bc25': 'Trop de dilutions',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Montant total recouvré',
  f786c8a1095bdf155841d510bb53142a:
    'Intérêts totaux sur la dette en souffrance.',
  '4f25d1096076868bc590da9f8a26cf55': 'Limite totale',
  '230bd56af0acab75194f58b46a763535':
    "Montant total recouvré sur le(s) paiement(s) de l'acheteur.",
  '8f78461d739b4d6d46f961f352000cec': 'Montant total recouvré par vous.',
  b62dd565f60dc6172605d8db223510b9:
    "Montant total recouvré via le règlement de l'acheteur, le règlement du vendeur et/ou la restitution, y compris tout intérêt sur la dette en souffrance.",
  '8dfcacc58acbafda8eca3d58c4d8a447': 'Montant total financé sur vos ventes.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Montant total non financé, selon le pourcentage de paiement anticipé approuvé par HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Montant total des factures financées recouvrées en totalité.',
  a069138e83a8b4cb7e81145689155826:
    'Montant total des intérêts sur les dettes en souffrance restant à payer ou impayés.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Montant total des frais de retard dus. Les frais de pénalité courent quotidiennement.',
  bc37640f0d55a99b3f0b8bce05a2f0d1: 'Montant total des frais de retard payés.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Montant total des frais de retard restant à payer ou impayés.',
  d649fb9a0971deaa127067341f59be91:
    'Montant total récupéré sur les autres déductions sur facture.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Montant total restant à recouvrer ou à rembourser.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Montant total du recouvrement',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Valeur faciale totale des factures non éligibles au financement.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Valeur faciale totale des factures envoyées au(x) acheteur(s).',
  f0164c47e6c3d2497856505cb08e6e4f:
    "Montant total financé recouvré via le règlement de l'acheteur, le règlement du vendeur et/ou la restitution.",
  d4867ee77e79ded226c07918b9393715:
    'Total des intérêts payés sur les dettes en souffrance.',
  de4c28160afe1ab48bcf83be49bbb196: 'Limite totale',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Montant total impayé',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    "Montant total impayé et tout intérêt sur la dette en souffrance à rembourser par vous. Cela peut être dû au fait qu'une ou plusieurs factures financées sont échues ou que votre acheteur a rejeté une ou plusieurs de vos factures après leur financement.",
  fecdb72e34cebfadf037b45b596d90e5: 'FAQ du programme de trésorerie Tradeshift',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Informations sur la transaction',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Impossible de télécharger le fichier csv de facture Contactez l’assistance technique.',
  c8415d81378085ba3933015b8f5fc768:
    'Impossible de récupérer le contrat Contactez l’assistance technique.',
  '64580a198c861aa0226c91b28f8418aa': 'Indisponible à la vente',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Les factures impayées après cette date sont considérées comme "échues" et supporteront ensuite des intérêts quotidiens.',
  '9d632e72031eea3767881d6c3adcfe54': 'Limite de crédit utilisée.',
  e871a6882109bd7ef07827882236c8db: 'Afficher le contrat',
  '2ac548c28faaa41283f9d6b7606a4844': 'Annulé',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Bienvenue dans Cash !',
  '78eb58937961a18929a623397b544a01':
    'Vous pouvez solder le remboursement échu en effectuant un règlement direct sur le compte de remboursement (répertorié ci-dessus).',
  f347abf54f65274a614a9ba7515dfa98:
    "Vous pouvez suivre tous vos paiements anticipés en un seul endroit avec l'application Cash",
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Votre programme Cash est actif !',
  '7807cbebc0a0ffe93df0d4b4f626eed5':
    'Votre programme de trésorerie est inactif',
  '6e6e06469dd953a124d718cd2307e32c':
    "Votre participation au programme Cash n'est plus active depuis le {date}. Veuillez contacter <a href=",
  '626afbd7141286d15df45bccdb695a66':
    "une facture financée étant rejetée par l'acheteur avant la fin du délai de grâce (intérêts courus quotidiennement après le jour du rejet)",
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'une facture approvisionnée étant impayée à la fin du délai de grâce (intérêts courus quotidiennement par la suite) ; et/ou,',
  '33e67e73d7f54aaee31e6040f873be79': 'appliqué aux déductions',
  a9798a3e063111de4d4768c363bdc267: 'appliqué aux factures',
  a5be13d3bfec5fc92521a841009db3c7: 'numéro de compte bancaire',
  '736c1e36ac779a73ff4fddd19d82008c': "taux d'intérêt journalier",
  ab53acde68c117f8fbfff6a8901de083: "taux d'intérêt journalier échu",
  '4c584eabd4260d69914ac993cc4db5d2': 'jours',
  '047dbee224da5fb4ecfc21268ffd3115': "date d'échéance",
  dd051262964c80130a2e9023ff933d89: 'paiement anticipé',
  bc55e8a10310a96eeda133ddf159303d: "date d'échéance attendue",
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'pour les paiements anticipés',
  '932d9bd46daba4d09f361717c0ec03ab': 'montant financé',
  '5e82566144af4903b40303fc3d1c799e': 'date de financement',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'délai de grâce',
  '0a6949f0e73bc3c42b0e93fe10e63fff': "paiement à l'échéance",
  '2db524546615d120af0adf844a6e1bd7': 'sur',
  '99c2f4783bb6821d84ad17a22f556813': 'de financé',
  '7555b72d3b4ffe69558b68b3cb86b954': 'le',
  c138be463a457afe89e5ecfefc6a3dc9: 'avoirs en attente',
  '37f59e13afd5455cf130060ed55f4976': 'numéro de routage',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'au compte bancaire suivant',
  cf4edd5f38054a752994e3242a1f3807: 'montant total',
  '3c9f634310f5e3de42b79ee83d20923c': 'total des frais',
  '573b1b7930d8030d1669945f2c34992a': 'transféré à votre banque',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'sera déduit des paiements à venir',
};
