import * as cs from './messages_cs';
import * as da from './messages_da';
import * as de from './messages_de';
import * as en from './messages_en';
import * as en_GB from './messages_en_GB';
import * as es from './messages_es';
import * as fi from './messages_fi';
import * as fr from './messages_fr';
import * as hu from './messages_hu';
import * as id from './messages_id';
import * as it from './messages_it';
import * as ja from './messages_ja';
import * as ms from './messages_ms';
import * as nb from './messages_nb';
import * as nl from './messages_nl';
import * as pl from './messages_pl';
import * as pt from './messages_pt';
import * as pt_PT from './messages_pt_PT';
import * as ro from './messages_ro';
import * as sk from './messages_sk';
import * as sv from './messages_sv';
import * as zh from './messages_zh';
import * as zh_TW from './messages_zh_TW';

export const ALL_TRANSLATIONS = {
	cs,
	da,
	de,
	en,
	'en-us': { ...en },
	'en-gb': { ...en_GB },
	es,
	fi,
	fr,
	hu,
	id,
	it,
	ja,
	ms,
	nb,
	'nb-no': { ...nb },
	nl,
	pl,
	pt,
	'pt-br': { ...pt },
	'pt-pt': { ...pt_PT },
	ro,
	sk,
	sv,
	'sv-se': { ...sv },
	zh,
	'zh-cn': { ...zh },
	'zh-tw': { ...zh_TW }
};
