export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Justerad på grund av valuta eller helgdagar)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '*Avgifter och betalningsdatum är vägledande och går inte att garantera',
  fa090ada546b5deb33cdead006822e26:
    '*Avgifter och betalningsdatum är vägledande och går inte att garantera.',
  '52fce318164dc60017ca67121172aca9': 'Godkänd',
  '615a093396ed9c6f52cd7ece7217608e': 'Kontojustering',
  '79dd431d35b370210a3dbeb1bb653d43': 'Kontonamn',
  '8c66a974a95769447407c061a36d0824': 'Kontonummer',
  '6a810f9427275516cc7ac687ea182f27': 'Upplupen skuldränta',
  '21df2d8134802290caddc9a8070e9d94': 'Upplupna förseningsavgifter',
  '7377dcc6989501c8299b7ccf697b731e': 'Upplupen skuldränta',
  dab81136f1c6e799c5626826349a8dcf: 'Upplupna förseningsavgifter',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Alla',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Alla köpare',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Alla valutor',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Alla fakturor',
  d7db3397d1ee87530b253bc69ebca041: 'Summa',
  c7586d1e153c74713afd948b3048d03d:
    'Belopp betalat till dig efter ränteavdrag och eventuella utestående återbetalningar av det finansierade beloppet.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Återbetalt belopp mot utestående återbetalningar på andra fakturor.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Belopp att remittera',
  d35a870d0219f3f9cbde80ed0eb11aa1: 'Eventuell överskott skickas till dig.',
  de9800ff635c316938aece381c98b094:
    'Eventuella utestående delbetalningar och räntor går att dra från utgående inköpspris eller annan inkasso. Om det inte går att inkassera på dessa sätt, kan vi be dig att betala beloppet direkt till köparen.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Godkänd',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Ställ fråga',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Tillgänglig kreditgräns för ny finansiering.',
  '85a6c2f454268a7b3c4156c070abc517': 'Väntar på godkännande',
  '99fb0198659b8076326d69a42670433b':
    'Saldin uppdateras vanligen nästa arbetsdag.',
  '0448c27d5574d95fc844e50416fc13a3': 'Bunt',
  '84b730c6e665f3a83a96a8511b4f63db': 'Buntinformation',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Köparens affärsenhet',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Faktura avvisad av köparen',
  cdb5b8f6940144b32dc7f27b7700719a: 'Köparens kundremissa',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Köparen markerade som betald',
  e7094a08ea031332f2a7e7f94b214174: 'Köparens kundremissa',
  b8b64034058f45fe990f8377c7990c6b: 'Köparens remitteringskonto',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Köpare:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Köpare betalar för fakturor här. Du betalar för delbetalningar här',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Som standard dras det förfallna återbetalningsbeloppet av från nästa finansierade faktura/fakturor.',
  cb1a025651df74878d8d5af1b99991df: 'Avbruten',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Information om kontantprogram',
  '6fd54168f061cd9f269dbcbb29415486': 'Avgiftsbelopp',
  '252982cd07a2c1e75a9cadb781c4428f': 'Återkrav',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Klicka för att kopiera',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Stäng',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Stängt',
  a150472168406890d84a6a5c3c8a894c: 'Inkasserad',
  '0743742edda54d26e47f9959ca4be4f0': 'Inkasserad skuldränta',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Inkasserade förseningsavgifter',
  '0b2194f7621b8a036308dce1647b982d': 'Insamlat belopp',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Inkasserad skuldränta',
  b1a3b2482cd668f631d724aac2dfc685: 'Inkasserade förseningsavgifter',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Insamling',
  ba18fa9a0a1da454462f71c6e36139ff: 'Inkasserade saldon',
  '1103815eae13527faa8d51c83c20d17e': 'Samlingsinformation',
  '898e15d1fa445c9c37ca795f79029f6d': 'Företagets registrerade namn',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Fortsätt',
  c05df0411bb18c405aaa68153432bf10: 'Kredit- och fakturagräns uppdaterad vid',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Kredit tillgänglig',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Kreditgräns',
  d23014cf5a5e7f8c6440b96692831aa8: 'Kreditfaktura',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Kredit använd',
  '207df9f33356e378a1ff2ef7b62becc7': 'Kredit tillgänglig',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Kreditgräns',
  '208d5141343637870387b27db87ccf05': 'Kreditgränsen har överskridits',
  '0e27340c6eb226df1076c4f8930d2289': 'Kredit använd',
  e15a66f111bc879c77f24943a94d19e7: 'Valutan stöds inte',
  b1e5986f972b7599559dea22cfeae8fb: 'Valuta:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Daglig rabatt',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Daglig rabattariff',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Daglig dröjsmålsränta',
  '9e268b45f182ad1851f03539be6ab29c': 'Daglig rabattsats',
  c2191b6160c46ed7a4b05cc0279158f3: 'Daglig dröjsmålsränta',
  e2d9ef555d350e16239f4dfc92842fdc: 'Instrumentpanel',
  '106202929cf48a3de3f20b3255365915': 'Datum',
  '2eca727eade526acc715c2e5605ebc7e':
    'Datum då det finansierade beloppet betalades fullt.',
  d8b0ba98f5a8b8a8e0dfe049222009ad: 'Datum då 30 dagars anstånd upphör.',
  b914f3ab262190a5dd60478fa6b7b828: 'Datum när köparen avvisade fakturan.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Dagar försenad',
  '1e1e68c46e0d719364bd445b1d605144': 'Dagar försenad',
  bd50c01a22cae65be4d588abcae65a90: 'Avdrag',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Avdrag',
  d706035ec5f053ca33b234ddec228ab2: 'Beskrivning',
  a39bf2f30994908fe95498524b423d43: 'Information',
  '193d60da437b58b24ed69a5683c1ffcf': 'Nedskriven',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Utspädningsbelopp',
  d07e29091188f3b0871dc7712eee7255: 'Utspädningstyp',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Direkt säljarremissa',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Direkt säljarremissa',
  afc1c7dfc9315e856f8da52e2450182b: 'Rabatt',
  '26e7eb298420701d5a03c53fe096cc0b': 'Överklagad',
  '43cca9c1b76ebf869c7bf22fff271689': 'Dokumentstatusfilter',
  ce73b429141cda25571c88d9b0c78224: 'Dokumentstatus',
  af13cc2ad0a7337c45420f75b7d5a668: 'Ladda ner',
  '8475be8bb8249000118af931ae75c373': 'Ladda ned rapport',
  '90567e36a540e51806d7790eb195b1d5': 'Ladda ned alla fakturor',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Utkast',
  '72015a772310560b755bd03132a543b2': 'Förfallodatum',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Förfallodag',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Förfallodatum för nära i tiden',
  '6def6918bd02ae5117332f4262cecb52': 'Berättigad',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Status för registrering',
  cb339c5c02cfb087fc8d393f917379df:
    'Det gick inte att läsa in instrumentpanel.',
  e9994b26424cb4bce5059d05e094df8d: 'Uppskattad avgift',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Exkluderat land',
  b5cb31ac9c7d1871e9075efc1f271536: 'AVGIFT',
  fd9d568c402dd04e0715e877d76f7da5: 'Nominellt fakturavärde.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Misslyckad',
  '817f1fe84c2d87894bc374681814abbf': 'Det gick inte att läsa in {title} –',
  '7932079ce6ea928bfa77efd91578312d': 'Avgifter',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filtrera efter valuta för att visa sammanfattade totalbelopp.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'Prognos för betalningsdatum för lång fram',
  e5af505b37031920b08edb84df19b5ec:
    'Inkasseringsdatum för fullt finansierat belopp',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Inkasseringsdatum för fullt finansierat belopp',
  '6dd341953c8c405c70878a457622cf55': 'Finansierad',
  '42e20c6bb1dc82fbdbd889f769850320': 'Finansierat belopp inkasserat',
  cab921680af6ff282ce37398b0b231d4: 'Finansierat belopp',
  fc0347ceea208298049efd79021475a2: 'Finansierat belopp inkasserat',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Finansieringsdatum',
  '55d900eb37f97b684c0bd5821f161216': 'Finansierade fakturor',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Finansiering',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Finansieringsundantag',
  '5ca89ce217f3bb07293db00a42c10200':
    'Orsak(er) till ej berättigad finansiering',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Finansieringsöversikt',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Finansieringsbegäran godkänd',
  d5ec85821723434aba05986044ebdcdd: 'Finansieringsstatus',
  '3447158e3ace443dbe27556e07c88639': 'Finansieringsstatusfilter',
  '022848540bf0e01453be6cf79843aa67': 'Finansieringslöptid',
  b4a59826d5bfc276fa88bd00abac615a: 'Finansieringsinformation',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Finansieringslöptid',
  e74f45b44aadcdd084b5cbdd3560f515: 'Anståndets slutdatum',
  '5434cf75f987ef76dbdc544fa1e32096': 'Hur du betalar',
  cb9f9412bd12122bb088124afccf738b: 'Hur återbetalar jag delbetalningar?',
  '9ad57f0b683fecb097c09bedebf68a45': 'Lös förfallen återbetalning:',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Om du anser att det är fel, kontakta <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Viktig information',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'Pågår',
  '313b15a4fdfa2368015e2eb67416af2a': 'Inaktivt program',
  '7b6a003ceca7fe7fec85daae919e800b': 'Ej berättigad',
  '6cf0784c1ac918c22cd402e0cb558749': 'Ogiltig orsak',
  '65cb9c651d2d9fb8941050a08add03c3': 'Ej berättigad för tidiga betalningar',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Ej berättigade fakturor',
  c555faa7b0b745638bb3ea785ee10137:
    'Information som visas uppdaterades senast den',
  b7311d63c44566a8562d92720cffed2e: 'Otillräcklig inkasso för att fakturera',
  fb07cb1753537f6969513f2d5fdb7993: 'Räntebelopp',
  '1938e208f65e66474dc4148c3fa7da08': 'Ränta avdragen',
  '42094e40339ac5c0957a980062c47659': 'Upplupen ränta på delbetalning',
  d9580a19710f07e8c72c215b1270ca57:
    'HSBC debiterar ränta på det finansierade beloppet.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Ränta avdragen',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Ränta på förfallna fakturor',
  '47a565198d287d0864339c077819a361':
    'Ränta fortsätter tillfalla den förfallna skulden tills den antingen dras av från nästa betalning till dig eller så får vi en direkt överföring.',
  '3c4be400f728da4526de626653d56ccc': 'Faktura',
  '70546e9227f592c24d3c491756cb901d': 'Fakturanr.',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Fakturabelopp',
  '6b9515d101794c31e38ae60d092fdc03': 'Fakturadatum',
  '2548c6a52ce9e1bffef7aad14530f490': 'Faktura återbetalad',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Översikt, fakturafinansiering',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Fakturagräns',
  '734ed127b3d7226c75e8553c0711367a': 'Använd fakturagräns',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Summa fakturagräns',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Fakturanummer',
  da2db17c9b22f995d6673a754d998fc8: 'Faktura förfallen',
  d729bd1bdced62265cd4ecd7131ef94e: 'Faktura avvisad',
  e14208d4378a3378be8b27eb15984fc6: 'Faktura kvittad',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Fakturastatus',
  '54d8df173250a197fab1644a0d0a772a': 'Fakturabelopp',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Det går för tillfället inte att finansiera fakturan. Se Orsak(er) till ej berättigad finansiering för mer information.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'Fakturans valuta överensstämmer inte med landet',
  d0da9e977c8b309f93ee378de34fb251: 'Utfärdandedatum',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Faktureringsdatum före tidigaste giltighetsdatum',
  ad0ab15468b719694f3193d0649ad9eb:
    'Fakturadatum ligger efter facilitetens annuleringsdatum.',
  fc9e80713aebb3d820d888f0755fedde: 'Fakturan saknar prenumererande köpare',
  e17cbec04e7786361eff9da629f09bb7: 'Fakturan har utfärdandedatum i framtiden.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Faktura har annulerats.',
  '89f842841b4ae213259b94a5411453f2': 'Fakturan har spätts ut.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'Fakturan har finansierats genom kontantprogrammet.',
  ab5303505d045929abb1256607ca0d4d:
    'Fakturan har betalats till fullo. Inga ytterligare betalningar krävs.',
  '3e159746cbdfda07b2f45750941e26f6': 'Faktura begärd för finansiering.',
  e8a7d355e6efb25006f6e4c50fde715a: 'Faktura har ej berättigad dokumentstatus.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Faktura har inte kvittats till fullo och anståndsperioden har upphört',
  d87ca92704bcfb34fa4ac08a4d24147a: 'Faktura är för närvarande ej berättigad.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Fakturan saknar slutdatum eller slutligt slutdatum',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Fakturan är för nära beräknat betalningsdatum.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Fakturan är ovanlig',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Faktura markerad som redan betald.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Fakturan kan vara en dubblett.',
  b1f771da19efea2f0fa6a542823231b9: 'Faktura endast delvis berättigad',
  '4217948ca113ee4158d5dd4c07d71624': 'Faktura kvittad med delbetalning',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Fakturans löptid ligger inte inom gördkänt område',
  '7c3fa246e8cca51273ddf5062ff2d593': 'För långt fakturainnehåll',
  '90d9ac20dda384e9c07554d29b143bef': 'Fakturans värde överskrider gränsen',
  '4b36f0be327fdca8d54126cd57095a13': 'Faktura/RA-nummer',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Fakturor',
  '8089283aad9294aadc2bde165ccc78c1': 'Utfärdad datum',
  '3a4123f9c604369ab18781f7492f9293': 'Utfärdad den',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Senaste utbetalningsdatum',
  a94bc4d4df1d56c16e25493e22684412: 'Gräns tillgänglig',
  '92f97c843d499a1559828ba0a9134e24': 'Använd gräns',
  b5dd8e130d88698834ee00464f01cea4: 'Tillgänglig gräns',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Använd gräns',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Läser in…',
  '24cc5627dea65e22f9279be9522a06e2': 'Lånebelopp',
  bd24bcedd066a27749016a07cb729a32: 'Markerad kvittad',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Förfallodag',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Startdatum för medlemskap',
  d26429adfb846303cbd889879b23e1ba:
    'Pengar insamlade in från antingen köpares betalningar, säljarens överföringar gjorda före och/eller efter anståndsperioden.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Betalat nettobelopp',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Betalat nettobelopp',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nytt belopp',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Nästa arbetsdag',
  '325c48f099844279c0cf48584de42e64': 'Ingen senaste aktivitet',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Inga',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Inte aktivt registrerad på Cash',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Inte tillräcklig fakturahistorik',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Inte tillräckligt antal fakturor under föregående månader',
  c9e2ce8da559465a8596317c35e8f3a4: 'Inte tillräckligt antal betalda fakturor',
  e6f890deafa579eb0c24603c3101c1c7:
    'Observera att saldon vanligen uppdateras nästa arbetsdag. Skicka in ett supportärende om du vill veta den nuvarande positionen.',
  '83070a059f10db1515ac922bf3433158':
    'Antal dagar efter anståndsperiodens slutdatum.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'En rapport tillgänglig för nedladdning',
  '2778717f6acbeb1edaac6a2656b478f3': 'Öppna',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Annan avgift',
  fad92e97e692758c0d0a9b5730663894: 'Utestående skuldränta',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Utestående finansierat belopp',
  '9eab1baddf2537312408088fc54f21d5': 'Utestående förseningsavgifter',
  bfba12a2c234532c0af24a4ad6831ab6: 'Utestående totalbelopp',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Utestående skuldränta',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Utestående finansierat belopp',
  '2f9a3de6940f818fe0376e4aece59512': 'Utestående förseningsavgifter',
  '6f79eea38bff17917740a69116270074': 'Förfallen',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Förfallna saldon',
  '848ac8c39b11a751d697a383303e6109': 'Förfallen skuld',
  '8d1e868665fc24a46f0cf55187f72218':
    'Förfallen skuld redovisas enligt RBI:s regler.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Översikt',
  '5b885084a5067d7fb4372cef38d8a82d': 'Betald',
  '830b95d305cdc61a66cdf75ebff1d737': 'Betalas av köpare',
  '6444796646c95dbc2cd1cb646e41293d':
    'Del av fakturans nominella värde som har finansierats enligt förskottsprocentsats godkänd av HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Deltagande köpares affärsinformation',
  a39314e5897b8baaa51ca296610ec30c: 'Villkor för partner',
  a6acd5f03fda9c8dc607e59d8be08320: 'Fördröjning för betalningsgodkännande',
  e6249829dadf637c4092e4a900df529e: 'Betalning bestriden av köparen',
  '07ed468d28d8e8437d8ec64036b96c03': 'Betalning har avvisats',
  '042b8dfb88f7045ca9145bbcccf79200': 'Betalning i framtiden',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Betalningen är för gammal',
  '8755bbc212b541efb6576f39f3f0f51f': 'Betalning allokerad',
  '8744ee10540166dfc39db2a6859a9ce1': 'Betalningsbelopp',
  '56133aa371eb0132334e4867479be942': 'Betalningsinformation',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Försenad betalning',
  '2dd7164df606f9b42beefaba031b06f7': 'Betalning mottagen',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Betalning bekräftad av säljare',
  '661711004f06b0d9601db365679be130': 'Betalningsbelopp',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Utbetalningsdatum',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Dröjsmålsränta',
  '0fd036622716f275c3e14c5a14faa65a': 'Väntande',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Väntar på utspädning',
  '8cf3479829ba47a8a4123895d0088eeb': 'Väntande utspädningar',
  '270b56fd05f72407ca0607b6162d0b10': 'Väntande kontakt',
  '4a78218416ca45d97b5633b37ae3f638':
    'Procentsats med vilken dagliga avgifter beräknas för den fullständiga finansieringens löptid.',
  b586a5468c7070bf1717593ea84fa607:
    'Procent skattesats som du debiteras dagligen på det utestående finansierade beloppet för förfallna eller finansierade och avvisade fakturor.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Skicka in ett supportärende om du måste veta aktuella saldon.',
  '34645fa620cae4187f27d9f96c172f26': 'Remittera beloppet',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Skicka följande belopp till följande bankkonto:',
  '01734a7316427fdcdcd4f937cda3802b': 'Förskottsbetalning',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Föregående belopp',
  '500fc4731dab800a878b33906d9332fb': 'Programindrivningskonto',
  '7327113634d1f5f4f68632dceebfbc6f': 'Programöversikt',
  d95ebfb51a485293a134b1038d332a10: 'Program aktiverat vid',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Programinformation inte tillgänglig för instrumentpanel',
  '98feb2c86a81b8caeeaa30966ba34636': 'Programinformation',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Betalning av inköpsfakturor som inte inkasseras i sin helhet på förväntat förfallodatum genererar en delbetalning. Delbetalningarnas ränta är 1,5 ggr den dagliga räntan och det är ditt ansvar att betala tillbaka båda.',
  '521689b600b8da5b0a111b89dea72126': 'Orsak',
  fe7bd622a1efcec03eb86dabb9395936: 'Mottagen',
  e84203a8833e4d0d92faf89f4107bf11: 'Regressåterbetalning',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Återbetalningsbelopp',
  b0be744ae1f6312f16990bbd92a87aea: 'Återbetalningens utfärdandedatum',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Återbetalningens löptid',
  '654d6dcc141079bc84500fd14ca5087d': 'Återbetalningsbelopp',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Återbetalningsinformation',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Återbetalningens utfärdandedatum',
  c0a6a91e7ca80af9de47097b06dc7618: 'Återbetalningens löptid',
  '4a2875439ab289a068abb9ea3b567bcb': 'Avvisade',
  '592b34cdfa2e51826b32a0783e0edce3': 'Avvisad av köpare',
  '807d35f105b65ab2795c4f8e51795f3c': 'Avvisad av säljare',
  aea4dd208e871d7ca0051b0ec38c3068: 'Avvisningsdatum',
  d64ed4934fbf68a8f716467b0adf89ba: 'Avvisningsdatum',
  '5a77de3b287a92494be077c197f37581':
    'Ännu inte inkasserat eller återbetalat återstående belopp.',
  e442b864bf745a75477ea0ee88bc15c4: 'Återbetalning förfaller',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Återbetalningsbelopp per anståndets slutdatum. Det här ändras inte för att återspegla eventuella återbetalningar gjorda efter det datumet och inkluderar inte heller förfallen skuld.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Återbetalning förfaller',
  dc20cbfb76d220c108241a451bc3faf4: 'Information om återbetalningsanvisningar',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Återbetalningar som förfaller är det totala utestående beloppet som måste återkrävas, antingen från beloppet köparen betalade som är lägre än fakturans nominella värde och/eller att köparen avvisar fakturan när den har finansierats.',
  '45b60e22719c12291932bf09db0df5cd':
    'Återbetalningar som förfaller är det sammanlagda utestående beloppet som måste återkrävas, vilket beror på antingen:',
  ba093b1421452b3afdebbebedaeae552: 'Begär finansiering',
  '34f8f9968ec0d7f91e558e906a7de982': 'Begärt',
  dc1650cfbadb05f8fb6357692e48ee71: 'Begärd till finansiering',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Kvarhållet',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Kvarhållet belopp',
  a476316a89541d1aa71d5b5366874e13: 'Kvarhållet belopp',
  '328f6585a0e78a96deefc0661474248f': 'Försök igen',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Försäljningar',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Sök med fakturanummer',
  '3215999493db188b35242dc4f4fd8fb5':
    'Se Programinformation för mer information om återbetalningar.',
  '69f43575c3a51e94605eb75b75f7b563': 'Markerad',
  '56a1c7c4a02e9985745969563a80799f': 'Säljarens återbetalningskonto',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Skickar',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Skickat',
  '95d54926719f1071b2fa640f1ba23d3e': 'Kvittad',
  e243f36392a7f264d830f04f21aa8972: 'Kvittade fakturor',
  b548572d4123e3a569d27e4676664dad: 'Kort betalning',
  '6e09ada7e33833d259370b0cc900f082': 'Kort betalningsbelopp',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Delbetalningsavdrag',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Delbetalningar',
  '46bbc145b1f85c082950c768ade012f6': 'Kort betalningsbelopp',
  e66e6d9768e8f489c05951ee70ede350:
    'Ett fel inträffade och alla funktioner i instrumentpanelen är kanske inte tillgängliga. Försök igen eller kontakta support för hjälp.',
  cd969f183dc619b5716067cfda39c055: 'Clearingnummer',
  '17939071126e9e41921d79b837f63297': 'Leverantörens namn',
  '750d4460b260343039f9bc71b66a231c': 'Leverantören anses vara insolvent',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Leverantören är ännu inte konfigurerad för programmet',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Villkor godkända av',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Användarvillkor',
  '1294cf929ec55581954a79948592dda9':
    'Datumet då kontantprogrammets finansiering aktiverades.',
  f51144e4468390a486d41b4db66fc243: 'Det gick inte att ladda ned filen.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Högsta antal dagar då betalning förväntas från köparen, enligt godkännande från HSBC.',
  d438da584c455e727642a303917e690d:
    'Resterande kreditbelopp som går att utnyttja. Det här är skillnaden mellan kreditgränsen och den använda krediten.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Totalt antal fakturor som för närvarande finansieras för det här programmet. Det här beloppet beräknas grundat på kreditgränsen och aktuellt antal finansierade fakturor.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Totalt antal fakturor som går att finansiera för det här programmet. Det här beloppet beräknas grundat på kreditgränsen och aktuellt antal finansierade fakturor.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Den totala summan pengar kvarhållen före betalning av faktura.',
  '13f271ac486faca89c1944226ba0efff':
    'Alla importerade fakturors sammanlagda nominella värde.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Det sammanlagda nominella värdet på fakturor som flyttades till kvittad status eftersom de är fullständigt inkasserade.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'De finansierade fakturornas sammanlagda nominella värde.',
  '565c255165bed2ff2640d0e42369c62c':
    'Det totala fakturavärdet som inte går att finansiera.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Det här kontot är inte aktivt registrat i Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Det här uppdateras vanligen nästa arbetsdag. Skicka in ett supportärende om du måste känna till aktuella saldon.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Det här värdet speglar det senaste delbetalningssaldot inklusive eventuell ränta.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Tillsammans med',
  '603e4b3b3ac7b888a06548d14435bc25': 'För många utspädningar',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Totalt',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Sammanlagt inkasserat belopp',
  f786c8a1095bdf155841d510bb53142a: 'Sammanlagd ränta på förfallen skuld.',
  '4f25d1096076868bc590da9f8a26cf55': 'Sammanlagd gräns',
  '230bd56af0acab75194f58b46a763535':
    'Totalt belopp inkasserat från köparens betalning(ar).',
  '8f78461d739b4d6d46f961f352000cec': 'Totalt belopp inkasserat från dig.',
  b62dd565f60dc6172605d8db223510b9:
    'Totalt belopp inkasserat genom köparöverföring, säljaröverföring och/eller återkrav, inklusive eventuell ränta på förfallna skulder.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Totalt belopp finansierat mot din försäljning.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Totalt ej finansierat belopp, enligt förskottsprocentsats godkänd av HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Totalt antal finansierade fakturor inkasserade in i sin helhet.',
  a069138e83a8b4cb7e81145689155826:
    'Totalt räntebelopp på förfallna skulder som återstår att betala eller är utestående.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Totalt belopp för förfallna förseningsavgifter. Straffavgifter ackumuleras dagligen.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Totalt belopp betalade förseningsavgifter.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Totalt belopp förseningsavgifter som återstår att betala eller är utestående.',
  d649fb9a0971deaa127067341f59be91:
    'Totalt belopp återkrävt från andra fakturaavdrag.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Totalt återstående ännu inte inkasserat eller återbetalat belopp.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Totalt inkasserat belopp',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Totalt nominellt värde på fakturor ej berättigade till finansiering.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Totalt nominellt värde på fakturor skickade till köpare(n).',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Totalt finansierat belopp inkasseat genom kundöverföring, säljaröverföring och/eller återkrav.',
  d4867ee77e79ded226c07918b9393715:
    'Sammanlagd ränta på inbetalad förfallen skuld.',
  de4c28160afe1ab48bcf83be49bbb196: 'Sammanlagd gräns',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Totalt utestående belopp',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Totalt utestående finansierat belopp och eventuell ränta på förfallna skulder som återbetalas av dig. Det här kan bero på att en eller flera finansierade fakturor har förfallit eller att köparen avvisar en eller flera fakturor efter att de(n) finansierades.',
  fecdb72e34cebfadf037b45b596d90e5: 'Tradeshift kontantprogram F.A.Q.',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Transaktionsinformation',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Det går inte att ladda ned fakturans csv-fil. Kontakta support',
  c8415d81378085ba3933015b8f5fc768:
    'Det går inte att hämta avtal. Kontakta support',
  '64580a198c861aa0226c91b28f8418aa': 'Ej tillgänglig att sälja',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Obetalda fakturor efter det här datumet anses förfallna och ackumulerar daglig dröjsmålsränta därefter.',
  '9d632e72031eea3767881d6c3adcfe54': 'Använd kreditgräns.',
  e871a6882109bd7ef07827882236c8db: 'Visa avtal',
  '2ac548c28faaa41283f9d6b7606a4844': 'Annullerad',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Välkommen till Cash!',
  '78eb58937961a18929a623397b544a01':
    'Det går att kvitta förfallet återbetalningssaldo genom att göra en direkt överföring till återbetalningskontot (visat ovan).',
  f347abf54f65274a614a9ba7515dfa98:
    'Appen Cash gör det möjligt att spåra alla tidiga betalningar på ett ställe',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Ditt Cash-program är aktivt!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Ditt kontantprogram är inaktivt',
  '6e6e06469dd953a124d718cd2307e32c':
    'Ditt deltagande i Cash-programmet har inte varit aktivt sedan den {date}. Kontakta <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'en finansierad faktura som avvisas av köparen före anståndsperiodens slut (ackumulerar ränta dagligen efter avvisandedagen)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'en finansierad faktura som är obetald efter anståndsperiodens slut (ackumulerar ränta dagligen därefter), och/eller',
  '33e67e73d7f54aaee31e6040f873be79': 'tillämpas på avdrag',
  a9798a3e063111de4d4768c363bdc267: 'Används på fakturor',
  a5be13d3bfec5fc92521a841009db3c7: 'bankkontonummer',
  '736c1e36ac779a73ff4fddd19d82008c': 'daglig ränta',
  ab53acde68c117f8fbfff6a8901de083: 'daglig förfalloavgift',
  '4c584eabd4260d69914ac993cc4db5d2': 'dagar',
  '047dbee224da5fb4ecfc21268ffd3115': 'förfallodatum',
  dd051262964c80130a2e9023ff933d89: 'tidig betalning',
  bc55e8a10310a96eeda133ddf159303d: 'förväntat förfallodatum',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'för tidiga betalningar',
  '932d9bd46daba4d09f361717c0ec03ab': 'finansierat belopp',
  '5e82566144af4903b40303fc3d1c799e': 'finansieringsdatum',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'Anståndsperiod',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'slutlig betalning',
  '2db524546615d120af0adf844a6e1bd7': 'av',
  '99c2f4783bb6821d84ad17a22f556813': 'av finansierad',
  c138be463a457afe89e5ecfefc6a3dc9: 'utestående kreditfakturor',
  '37f59e13afd5455cf130060ed55f4976': 'clearingnummer',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'löptid',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'till följande bankkonto:',
  cf4edd5f38054a752994e3242a1f3807: 'totalt belopp',
  '3c9f634310f5e3de42b79ee83d20923c': 'total avgift',
  '573b1b7930d8030d1669945f2c34992a': 'överfört till din bank',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'dras av från kommande betalningar',
};
