export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Justert på grunn av valuta eller helligdager)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '*Dato for gebyrer og betalinger er veiledende og kan ikke garanteres.',
  fa090ada546b5deb33cdead006822e26:
    '*Avgifter og betalingsdatoer er indikative og kan ikke garanteres',
  a60c743772480aaf26a3ee6fba11ba50: 'Just. Kont.',
  '52fce318164dc60017ca67121172aca9': 'Akseptert',
  '615a093396ed9c6f52cd7ece7217608e': 'Kontojustering',
  '79dd431d35b370210a3dbeb1bb653d43': 'Kontonavn',
  '8c66a974a95769447407c061a36d0824': 'Kontonummer',
  '6a810f9427275516cc7ac687ea182f27': 'Påløpte gjeldsrenter',
  '21df2d8134802290caddc9a8070e9d94': 'Påløpte forfalte kostnader',
  '7377dcc6989501c8299b7ccf697b731e': 'Påløpte gjeldsrenter',
  dab81136f1c6e799c5626826349a8dcf: 'Påløpte forfalte kostnader',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Alle',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Alle kjøpere',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Alle valutaer',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Alle fakturaer',
  d7db3397d1ee87530b253bc69ebca041: 'Beløp',
  c7586d1e153c74713afd948b3048d03d:
    'Beløp som blir betalt til deg, etter fradrag av renter og eventuelle tilbakebetalinger, grunnet det finansierte beløpet.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Etterbetaling mot tilbakebetalinger forfalt på andre fatura(er).',
  '57e646b300b4b551d662b0cd536a6ad5': 'Beløp som skal remitteres',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Ethvert overskuddsbeløp vil bli overført til deg.',
  de9800ff635c316938aece381c98b094:
    'Alle utestående delbetalinger og renter kan trekkes fra kjøpsprisen som skal betales, eller andre innkrevinger. Hvis vi ikke kan kreve inn på denne måten, kan vi be deg om å betale beløpet direkte til kjøperen.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Godkjent',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Still et spørsmål',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Tilgjengelig kredittgrense for ny finansiering.',
  '85a6c2f454268a7b3c4156c070abc517': 'Venter på godkjenning',
  '99fb0198659b8076326d69a42670433b':
    'Vanligvis oppdateres Saldoer neste arbeidsdag.',
  '0448c27d5574d95fc844e50416fc13a3': 'Bunt',
  '84b730c6e665f3a83a96a8511b4f63db': 'Buntdetaljer',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Kjøperfilial',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Kjøper avviste faktura',
  cdb5b8f6940144b32dc7f27b7700719a: 'Kjøper-remittering',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Kjøper markert som betalt',
  e7094a08ea031332f2a7e7f94b214174: 'Kjøper-remittering',
  b8b64034058f45fe990f8377c7990c6b: 'Remitteringskonto for kjøper',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Kjøper:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Kjøpere betaler her for fakturaer. Du betaler her for delbetalinger',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Som standard vil tilbakebetalingsbeløpet trekkes fra de neste finansierte fakturaen(e) ved forfall.',
  cb1a025651df74878d8d5af1b99991df: 'Avbrutt',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Informasjon om kontantprogram',
  '6fd54168f061cd9f269dbcbb29415486': 'Belastningsbeløp',
  '252982cd07a2c1e75a9cadb781c4428f': 'Tilbakekreving',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Klikk for å kopiere',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Lukk',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Lukket',
  a150472168406890d84a6a5c3c8a894c: 'Innsamlet',
  '0743742edda54d26e47f9959ca4be4f0': 'Samlede gjeldsrenter',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Påløpte forfalte kostnader',
  '0b2194f7621b8a036308dce1647b982d': 'Samlet beløp',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Samlede gjeldsrenter',
  b1a3b2482cd668f631d724aac2dfc685: 'Påløpte forfalte kostnader',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Inndrivelse',
  ba18fa9a0a1da454462f71c6e36139ff: 'Saldo for inndrivelse',
  '1103815eae13527faa8d51c83c20d17e': 'Samlingsinformasjon',
  '898e15d1fa445c9c37ca795f79029f6d': 'Juridisk navn for firma',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Fortsett',
  c05df0411bb18c405aaa68153432bf10: 'Kreditt- og fakturagrense oppdatert på',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Kreditt tilgjengelig',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Kredittgrensen',
  d23014cf5a5e7f8c6440b96692831aa8: 'Kreditnota',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Brukt kreditt',
  '207df9f33356e378a1ff2ef7b62becc7': 'Kreditt tilgjengelig',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Kredittgrense',
  '208d5141343637870387b27db87ccf05': 'Kredittgrensen er oversteget',
  '0e27340c6eb226df1076c4f8930d2289': 'Brukt kreditt',
  e15a66f111bc879c77f24943a94d19e7: 'Valuta støttes ikke',
  b1e5986f972b7599559dea22cfeae8fb: 'Valuta:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Daglig rabatt',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Daglig rabattsats',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Dagmulktsrate',
  '9e268b45f182ad1851f03539be6ab29c': 'Daglig rabattsats',
  c2191b6160c46ed7a4b05cc0279158f3: 'Dagmulktsrate',
  '106202929cf48a3de3f20b3255365915': 'Dato',
  '2eca727eade526acc715c2e5605ebc7e':
    'Datoen der det finansierte beløpet ble gjort opp fullt ut.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Datoen for når 30 dagers respittperiode utløper.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Dato for da fakturaen ble avvist av kjøperen din.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Dager forsinket',
  '1e1e68c46e0d719364bd445b1d605144': 'Dager forsinket',
  bd50c01a22cae65be4d588abcae65a90: 'Fradrag',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Fradrag',
  d706035ec5f053ca33b234ddec228ab2: 'Beskrivelse',
  a39bf2f30994908fe95498524b423d43: 'Detaljer',
  '193d60da437b58b24ed69a5683c1ffcf': 'Oppløst',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Utraderingsbeløp',
  d07e29091188f3b0871dc7712eee7255: 'Utraderingstype',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Remittering for direktesalg',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Remittering for direktesalg',
  afc1c7dfc9315e856f8da52e2450182b: 'Rabatt',
  '26e7eb298420701d5a03c53fe096cc0b': 'Omstridt',
  '43cca9c1b76ebf869c7bf22fff271689': 'Dokumentstatusfiltre',
  ce73b429141cda25571c88d9b0c78224: 'Dokumentstatus',
  af13cc2ad0a7337c45420f75b7d5a668: 'Last ned',
  '8475be8bb8249000118af931ae75c373': 'Last ned rapport',
  '90567e36a540e51806d7790eb195b1d5': 'Last ned alle fakturaer',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Utkast',
  '72015a772310560b755bd03132a543b2': 'Forfallsdato',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Forfallsdato',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Forfallsdato er for tidlig',
  '6def6918bd02ae5117332f4262cecb52': 'Kvalifisert',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Registreringstatus',
  cb339c5c02cfb087fc8d393f917379df: 'Feil under lasting av dashbord.',
  e9994b26424cb4bce5059d05e094df8d: 'Estimert avgift',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Ekskludert land',
  b5cb31ac9c7d1871e9075efc1f271536: 'AVGIFT',
  fd9d568c402dd04e0715e877d76f7da5: 'Nominell fakturaverdi.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Mislyktes',
  '817f1fe84c2d87894bc374681814abbf': 'Lasting av {title} mislyktes -',
  '7932079ce6ea928bfa77efd91578312d': 'Avgifter',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filtrer etter valuta for å vise oppsummerte sluttsummer.',
  '110df72171aeef47da3fd84ea2a1a10f': 'Forespeilet betalingsdato er for lang',
  e5af505b37031920b08edb84df19b5ec: 'Doto for betaling av totalbeløp',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Doto for betaling av totalbeløpet som var finansiert',
  '6dd341953c8c405c70878a457622cf55': 'Finansiert',
  '42e20c6bb1dc82fbdbd889f769850320': 'Finansiert beløp innbetalt',
  cab921680af6ff282ce37398b0b231d4: 'Finansiert beløp',
  fc0347ceea208298049efd79021475a2: 'Finansiert beløp innbetalt',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Finansieringsdato',
  '55d900eb37f97b684c0bd5821f161216': 'Finansierte fakturaer',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Finansiering',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Finansieringsunntak',
  '5ca89ce217f3bb07293db00a42c10200':
    'Årsak(er) for å ikke kvalifiseres for finansiering',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Finansieringsoversikt',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Vellykket finansieringsforespørsel',
  d5ec85821723434aba05986044ebdcdd: 'Finansieringsstatus',
  '3447158e3ace443dbe27556e07c88639': 'Finansieringstatusfiltre',
  '022848540bf0e01453be6cf79843aa67': 'Betalingstid for finansiering',
  b4a59826d5bfc276fa88bd00abac615a: 'Finansieringsdetaljer',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Betalingstid for finansiering',
  e74f45b44aadcdd084b5cbdd3560f515: 'Sluttdato for løpetid',
  '5434cf75f987ef76dbdc544fa1e32096': 'Hvordan betale',
  cb9f9412bd12122bb088124afccf738b: 'Hvordan tilbakebetaler man delbetalinger?',
  '9ad57f0b683fecb097c09bedebf68a45':
    'Hvordan løse tilbakebetaling som ligger til forfall:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'INV (FAK- faktura)',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Hvis du mener dette er en feil, vennligst kontakt <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Viktig informasjon',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'Pågår',
  '313b15a4fdfa2368015e2eb67416af2a': 'Inaktivt program',
  '7b6a003ceca7fe7fec85daae919e800b': 'Ikke kvalifisert',
  '6cf0784c1ac918c22cd402e0cb558749': 'Ikke-akseptert årsak',
  '65cb9c651d2d9fb8941050a08add03c3': 'Ikke kvalifisert for Tidlig betaling',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Ikke-kvalifiserte fakturaer',
  c555faa7b0b745638bb3ea785ee10137:
    'Informasjonen som vises ble sist oppdatert den',
  b7311d63c44566a8562d92720cffed2e:
    'For lite inkassobeløp for å kunne faktureres',
  fb07cb1753537f6969513f2d5fdb7993: 'Rentebeløp',
  '1938e208f65e66474dc4148c3fa7da08': 'Renter fratrukket',
  '42094e40339ac5c0957a980062c47659': 'Renter påløpt ved delbetaling',
  d9580a19710f07e8c72c215b1270ca57:
    'Renter belastet av HSBC på finansiert beløp.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Renter fratrukket',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Renter påløpt på forfalt faktura',
  '47a565198d287d0864339c077819a361':
    'Renter vil fortsette å påløpe ved forfalt gjeldsbetaling, frem til begge deler trekkes fra neste betaling til deg, eller til vi mottar direkte remittering.',
  '3c4be400f728da4526de626653d56ccc': 'Faktura',
  '70546e9227f592c24d3c491756cb901d': 'Faktura #',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Fakturabeløp',
  '6b9515d101794c31e38ae60d092fdc03': 'Fakturadato',
  '2548c6a52ce9e1bffef7aad14530f490': 'Faktura finansiert',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Oversikt over fakturafinansiering',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Fakturagrense',
  '734ed127b3d7226c75e8553c0711367a': 'Fakturagrense i bruk',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Fakturagrense totalt',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Fakturanummer',
  da2db17c9b22f995d6673a754d998fc8: 'Faktura forfalt',
  d729bd1bdced62265cd4ecd7131ef94e: 'Faktura avvist',
  e14208d4378a3378be8b27eb15984fc6: 'Faktura oppgjort',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Fakturastatus',
  '54d8df173250a197fab1644a0d0a772a': 'Fakturabeløp',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Faktura kan ikke finansieres på dette tidspunktet. Se kvalifiseringsårsaker for finansiering for mer informasjon.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'Valuta på faktura samsvarer ikke med land',
  d0da9e977c8b309f93ee378de34fb251: 'Fakturadato',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Fakturadato før tidligste kvalifiserte dato',
  ad0ab15468b719694f3193d0649ad9eb:
    'Fakturadato er etter avbestillingsdato for fasilitet',
  fc9e80713aebb3d820d888f0755fedde: 'Fakturaen har ikke kjøper som abonnerer',
  e17cbec04e7786361eff9da629f09bb7: 'Fakturaen har en senere utstedelsesdato.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Fakturaen ble kansellert.',
  '89f842841b4ae213259b94a5411453f2': 'Fakturaen har blitt oppløst.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'Fakturaen er finansiert under kontantprogrammet.',
  ab5303505d045929abb1256607ca0d4d:
    'Fakturaen har blitt betalt helt. Ingen ytterligere betalinger kreves.',
  '3e159746cbdfda07b2f45750941e26f6': 'Faktura er forespurt for finansiering.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'Faktura har dokumentstatus som ikke er kvalifisert.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Fakturaen har ikke blitt oppgjort i sin helhet og respittperioden er utløpt',
  d87ca92704bcfb34fa4ac08a4d24147a:
    'Fakturaen er for øyeblikket ikke kvalifisert.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Faktura mangler sluttdato eller endelig sluttdato',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Fakturaen utstedes for nært beregnet betalingsdato.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Fakturaen er uvanlig',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Faktura merket som allerede betalt.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Fakturaen kan være en duplikat.',
  b1f771da19efea2f0fa6a542823231b9: 'Faktura er bare delvis kvalifisert',
  '4217948ca113ee4158d5dd4c07d71624': 'Fakturaen oppgjort med delbetaling',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Betalingstiden for fakturaen er ikke innen det akseptable tidsområdet',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Betalingstid for faktura er for lang',
  '90d9ac20dda384e9c07554d29b143bef': 'Fakturaverdi overskrider grensen',
  '4b36f0be327fdca8d54126cd57095a13': 'Faktura-/remitteringsvarsel-nummer',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Fakturaer',
  '8089283aad9294aadc2bde165ccc78c1': 'Utstedelsesdato',
  '3a4123f9c604369ab18781f7492f9293': 'Utstedelsesdato',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Siste utbetalingsdato',
  a94bc4d4df1d56c16e25493e22684412: 'Grense tilgjengelig',
  '92f97c843d499a1559828ba0a9134e24': 'Grense under bruk',
  b5dd8e130d88698834ee00464f01cea4: 'Grense tilgjengelig',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Grense under bruk',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Laster inn ...',
  '24cc5627dea65e22f9279be9522a06e2': 'Lånebeløp',
  bd24bcedd066a27749016a07cb729a32: 'Merket som oppgjort',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Modningsdato',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Startdato for medlemskap',
  d26429adfb846303cbd889879b23e1ba:
    'Inntekter som er samlet inn fra kjøperbetalinger, selgerremitteringer og/eller fradrag foretatt før og/eller etter løpetiden.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Netto betalt beløp',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Netto betalt beløp',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nytt beløp',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Neste virkedag',
  '325c48f099844279c0cf48584de42e64': 'Ingen nylig aktivitet',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Ingen',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Er ikke registrert som aktiv i Cash',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Ikke nok fakturahistorikk',
  '38e96e9d0dcbbe1b0f22950fdde4d82f': 'Ikke nok fakturaer i foregående måneder',
  c9e2ce8da559465a8596317c35e8f3a4: 'Ikke nok betalte fakturaer',
  e6f890deafa579eb0c24603c3101c1c7:
    'Legg merke til at saldoene vanligvis oppdateres neste arbeidsdag. Send inn en støtteforespørsel dersom du trenger å vite det gjeldende resultatet.',
  '83070a059f10db1515ac922bf3433158': 'Antall dager etter respittperiodedato.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'En rapport tilgjengelig for nedlasting',
  '2778717f6acbeb1edaac6a2656b478f3': 'Åpen',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Annen avgift',
  fad92e97e692758c0d0a9b5730663894: 'Utestående gjeldsrente',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Utestående finansiert beløp',
  '9eab1baddf2537312408088fc54f21d5': 'Utestående forfalte kostnader',
  bfba12a2c234532c0af24a4ad6831ab6: 'Utestående totalbeløp',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Utestående gjeldsrente',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Utestående finansiert beløp',
  '2f9a3de6940f818fe0376e4aece59512': 'Utestående forfalte kostnader',
  '6f79eea38bff17917740a69116270074': 'Forfalt',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Forfalte saldoer',
  '848ac8c39b11a751d697a383303e6109': 'Forfalt gjeld',
  '8d1e868665fc24a46f0cf55187f72218':
    'Forfalt gjeld vil bli rapportert i henhold til reguleringer fra RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Oversikt',
  '5b885084a5067d7fb4372cef38d8a82d': 'Betalt',
  '830b95d305cdc61a66cdf75ebff1d737': 'Betalt av kjøper',
  '6444796646c95dbc2cd1cb646e41293d':
    'En del av den pålydende fakturaen, som har blitt finansiert som en del av prosentandel for forhåndsbetaling ble godkjent av HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Informasjon for deltakende kjøper',
  a39314e5897b8baaa51ca296610ec30c: 'Partnervilkår',
  a6acd5f03fda9c8dc607e59d8be08320: 'Forsinket godkjennelse av betaling',
  e6249829dadf637c4092e4a900df529e: 'Betalingen bestrides av kjøperen',
  '07ed468d28d8e8437d8ec64036b96c03': 'Betalbart beløp er avvist',
  '042b8dfb88f7045ca9145bbcccf79200': 'Angitt som fremtidig betaling',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Betaling er for gammel',
  '8755bbc212b541efb6576f39f3f0f51f': 'Betaling tildelt',
  '8744ee10540166dfc39db2a6859a9ce1': 'Betalingsbeløp',
  '56133aa371eb0132334e4867479be942': 'Betalingsinformasjon',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Forsinket betaling',
  '2dd7164df606f9b42beefaba031b06f7': 'Betaling mottatt',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Betaling bekreftet av selger',
  '661711004f06b0d9601db365679be130': 'Utbetalingsbeløp',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Utbetalingsdato',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Strafferente',
  '0fd036622716f275c3e14c5a14faa65a': 'Venter',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Utradering på vent',
  '8cf3479829ba47a8a4123895d0088eeb': 'Utraderinger på vent',
  '270b56fd05f72407ca0607b6162d0b10': 'Ventende forbindelser',
  '4a78218416ca45d97b5633b37ae3f638':
    'Prosentandel hvor de daglige avgiftene beregnes for hele løpetiden for finansiering.',
  b586a5468c7070bf1717593ea84fa607:
    'Prosentsats hvor du daglig blir belastet ditt utestående finansierte beløp for forfalte eller betalte og avviste fakturaer.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Vennligst send inn en støtteforespørsel dersom du behøver å vite saldoene.',
  '34645fa620cae4187f27d9f96c172f26': 'Vennligst remitter beløpet',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Vennligst remitter følgende beløp til følgende bankkonto:',
  '01734a7316427fdcdcd4f937cda3802b': 'Forskuddsbetaling',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Forrige beløp',
  '500fc4731dab800a878b33906d9332fb': 'Programinnkrevingskonto',
  '7327113634d1f5f4f68632dceebfbc6f': 'Programoversikt',
  d95ebfb51a485293a134b1038d332a10: 'Program aktivert',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'programdetaljer er ikke tilgjengelig for dashbord',
  '98feb2c86a81b8caeeaa30966ba34636': 'Programinformasjon',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Betalinger for kjøpsfaktura som ikke innkasseres fullt ut innen den forventede forfallsdatoen vil generere en delbetaling. Delbetalinger fordrer en rente som er 1,5 ganger den daglige rabattsatsen og det er ditt ansvar å tilbakebetale dem begge.',
  '521689b600b8da5b0a111b89dea72126': 'Årsak',
  fe7bd622a1efcec03eb86dabb9395936: 'Mottatt',
  e84203a8833e4d0d92faf89f4107bf11: 'Kilde for tilbakebetaling',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Refusjonsbeløp',
  b0be744ae1f6312f16990bbd92a87aea: 'Utstedelsedato for refusjon',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Løpetid for refusjon',
  '654d6dcc141079bc84500fd14ca5087d': 'Refusjonsbeløp',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Informasjon om refusjon',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Utstedelsedato for refusjon',
  c0a6a91e7ca80af9de47097b06dc7618: 'Løpetid for refusjon',
  '4a2875439ab289a068abb9ea3b567bcb': 'Avvist',
  '592b34cdfa2e51826b32a0783e0edce3': 'Avvist av kjøper',
  '807d35f105b65ab2795c4f8e51795f3c': 'Avvist av selger',
  aea4dd208e871d7ca0051b0ec38c3068: 'Avvisningsdato',
  d64ed4934fbf68a8f716467b0adf89ba: 'Avvisningsdato',
  '5a77de3b287a92494be077c197f37581':
    'Gjenværende finansiert beløp som ennå ikke er innkrevd eller tilbakebetalt.',
  e442b864bf745a75477ea0ee88bc15c4: 'Tilbakebetalingsdato',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Tilbakebetalingsbeløpet per utløpsdato. Dette endres ikke for å gjenspeile eventuelle tilbakebetalinger etter denne datoen, og inkluderer heller ikke forfalt gjeld.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Tilbakebetallingsdato',
  dc20cbfb76d220c108241a451bc3faf4:
    'Informasjon om tilbakebetalingsinformasjon',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Tilbakebetalinger som forfaller er det totale utestående beløpet som må dekkes, som følge av at beløp betalt av kjøperen enten er lavere enn det på fakturaen; og/eller at Kjøper avviser fakturaen etter at den er finansiert.',
  '45b60e22719c12291932bf09db0df5cd':
    'Tilbakebetalinger som forfaller er det totale utestående beløpet som må innbetales, enten som følger av:',
  ba093b1421452b3afdebbebedaeae552: 'Be om midler',
  '34f8f9968ec0d7f91e558e906a7de982': 'Forespurt',
  dc1650cfbadb05f8fb6357692e48ee71: 'Forespurt for finansiering',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Tilbakeholdt',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Tilbakeholdt beløp',
  a476316a89541d1aa71d5b5366874e13: 'Tilbakeholdt beløp',
  '328f6585a0e78a96deefc0661474248f': 'Prøv på nytt',
  '626efcff97ad72e5eb9cc8a602aec8bd': 'DB (delbetalting)',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Salg',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Søk med fakturanummer',
  '3215999493db188b35242dc4f4fd8fb5':
    'Se programinformasjon for mer informasjon om tilbakebetalinger.',
  '69f43575c3a51e94605eb75b75f7b563': 'Valgt',
  '56a1c7c4a02e9985745969563a80799f': 'Tilbakebetalingskonto for selger',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Sender',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Sendt',
  '95d54926719f1071b2fa640f1ba23d3e': 'Oppgjort',
  e243f36392a7f264d830f04f21aa8972: 'Utlignede fakturaer',
  b548572d4123e3a569d27e4676664dad: 'Delbetaling',
  '6e09ada7e33833d259370b0cc900f082': 'Delbetalingsbeløp',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Delbetalingsfradrag',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Delbetalinger',
  '46bbc145b1f85c082950c768ade012f6': 'Delbetalingsbeløp',
  e66e6d9768e8f489c05951ee70ede350:
    'Noe gikk galt, det kan hende at alle funksjonene på dashbordet ikke er tilgjengelige. Vennligst prøv igjen eller kontakt brukerstøtte for assistanse.',
  cd969f183dc619b5716067cfda39c055: 'Sorteringskode',
  '17939071126e9e41921d79b837f63297': 'Leverandørnavn',
  '750d4460b260343039f9bc71b66a231c':
    'Leverandøren er ansett som ikke betalingsdyktig',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Leverandør er ikke satt opp på program ennå',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Støtte',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Vilkår akseptert av',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Tjenestevilkår',
  '1294cf929ec55581954a79948592dda9':
    'Dato hvor kontantprogrammet for finansiering ble aktivert.',
  f51144e4468390a486d41b4db66fc243: 'Filen kunne ikke lastes ned.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Maksimalt antall dager hvor betaling forventes fra kjøperen din, som godkjent av HSBC.',
  d438da584c455e727642a303917e690d:
    'Det gjenværende kredittbeløpet som kan benyttes. Dette er differansen mellom kredittegrense og brukt kreditt.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Totalt antall fakturaer som nå finansieres av dette programmet. Beløpet er beregnet på grunnlag av kredittgrensen, og gjeldende beløp på fakturaer som finansieres.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Totalt antall fakturaer som kan finansieres av dette programmet. Beløpet er beregnet på grunnlag av kredittgrensen, og gjeldende beløp på fakturaer som finansieres.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Det totale beløpet av penger som er tilbakeholdt før oppgjør av faktura.',
  '13f271ac486faca89c1944226ba0efff':
    'Total nominell verdi for alle importerte fakturaer.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Det totale beløpet som vises på fakturaer som ble flyttet til status som "gjort opp" da hele beløpet var betalt.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Det totale beløpet som vises på de finansierte fakturaene.',
  '565c255165bed2ff2640d0e42369c62c':
    'Den totale verdien som vises på fakturaer som ikke er kvalifisert for finansiering.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Denne kontoen er ikke registrert som aktiv i Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Dette oppdateres vanligvis neste arbeidsdag. Vennligst send inn en støtteforespørsel dersom du trenger å vite de gjeldende saldoene.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Denne verdien gjenspeiler din siste delbetalingssaldo, inkludert renter.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Sammen med',
  '603e4b3b3ac7b888a06548d14435bc25': 'For mange fordelinger',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Totalt',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Totalt innkrevd beløp',
  f786c8a1095bdf155841d510bb53142a: 'Samlet rente på forfalt gjeld.',
  '4f25d1096076868bc590da9f8a26cf55': 'Sammenlagt grense',
  '230bd56af0acab75194f58b46a763535':
    'Totalbeløp innkrevd fra kjøperbetaling(er).',
  '8f78461d739b4d6d46f961f352000cec': 'Totalbeløp innkrevd fra deg.',
  b62dd565f60dc6172605d8db223510b9:
    'Totalbeløp innkrevd gjennom kjøper-remittering, selger-remittering og/eller tilbakekreving inklusive renter på forfalte gjeld.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Totalbeløp finansiert mot omsetningen din.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Totalbeløp ikke finansiert i henhold til prosentandelen for forhåndsutbetaling, som er godkjent av HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Totalbeløpet av finansierte fakturaer som er fullt innbetalt.',
  a069138e83a8b4cb7e81145689155826:
    'Totalbeløpet for rente på gjenstående forfalt gjeld sm må betales eller som er utestående.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Totalt beløp av forfalte forsinkelsesgebyrer. Straffegebyrer legges til daglig.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Totalbeløp for forfalte forsinkelsesgebyrer som er betalt.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Totalbeløpet på forfalte forsinkelsesgebyrer på gjenstående å betale eller som er utestående.',
  d649fb9a0971deaa127067341f59be91:
    'Totalbeløp hentet fra andre fakturafradrag.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Totalsum som enda ikke er innkrevd eller tilbakebetalt.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Totalt innkrevd beløp',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Den totale nominelle verdien på fakturaer som ikke er kvalifisert for finansiering.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Samlet nominell verdi på fakturaer sendt til Kjøper(e)',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Sum finansiert beløp innkrevd via kjøper-remittering, selger-remittering og/eller tilbakekreving.',
  d4867ee77e79ded226c07918b9393715:
    'Samlet rente på forfalt gjeld som er betalt.',
  de4c28160afe1ab48bcf83be49bbb196: 'Sammenlagt grense',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Totalsum utestående beløp',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Sum utestående finansiert beløp og alle renter på forfalt gjeld som skal betales av deg. Dette kan skyldes at én eller flere finansierte fakturaer med forsinket betaling, eller kjøperen din avviser én eller flere av fakturaene dine etter at de ble finansiert.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Vanlige spørsmål om Tradeshift Kontantprogram',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Transaksjonsdetaljer',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Kan ikke laste ned faktura-csv. Vennligst kontakt kundestøtte',
  c8415d81378085ba3933015b8f5fc768:
    'Kan ikke hente avtale Vennligst kontakt kundestøtte',
  '64580a198c861aa0226c91b28f8418aa': 'Utilgjengelig til å selge',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Ubetalte fakturaer etter denne datoen betraktes som "forfalt" og vil medføre forsinkelsesrenter etter dette.',
  '9d632e72031eea3767881d6c3adcfe54': 'Benyttet kredittgrense.',
  e871a6882109bd7ef07827882236c8db: 'Se avtale',
  '2ac548c28faaa41283f9d6b7606a4844': 'Ugyldiggjort',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Velkommen til Cash!',
  '78eb58937961a18929a623397b544a01':
    'Du kan gjøre opp den skyldige tilbakebetalingssaldoen ved å utføre en direkte remittering til avbetalingskontoen (skrevet i listen over).',
  f347abf54f65274a614a9ba7515dfa98:
    'Du kan spore alle tidlige betalinger på ett sted med Cash-appen',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Cash-programmet ditt er aktivt!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Kontantprogrammet ditt er inaktiv',
  '6e6e06469dd953a124d718cd2307e32c':
    'Din deltagelse i Cash-programmet har ikke vært aktiv siden {date}. Vennligst kontakt <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'en finansiert faktura avvises av kjøperen før avslutningen av respittperioden (renter påløper daglig etter dagen for avslag)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'en finansiert faktura som ikke er betalt ved slutten av respittperioden (renter påløper daglig deretter), og/eller:',
  '33e67e73d7f54aaee31e6040f873be79': 'lagt til for fradrag',
  a9798a3e063111de4d4768c363bdc267: 'lagt til på fakturaer',
  a5be13d3bfec5fc92521a841009db3c7: 'bankkontonummer',
  '736c1e36ac779a73ff4fddd19d82008c': 'daglig rente',
  ab53acde68c117f8fbfff6a8901de083: 'daglig forsinkelsesrente',
  '4c584eabd4260d69914ac993cc4db5d2': 'dager',
  '047dbee224da5fb4ecfc21268ffd3115': 'forfallsdato',
  dd051262964c80130a2e9023ff933d89: 'tidlig betaling',
  bc55e8a10310a96eeda133ddf159303d: 'forventet forfallsdato',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'for tidlige betalinger',
  '932d9bd46daba4d09f361717c0ec03ab': 'finansiert beløp',
  '5e82566144af4903b40303fc3d1c799e': 'finansieringsdato',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'respittperiode',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'betaling ved forfall',
  '2db524546615d120af0adf844a6e1bd7': 'av',
  '99c2f4783bb6821d84ad17a22f556813': 'eller finansiert',
  '7555b72d3b4ffe69558b68b3cb86b954': 'på',
  c138be463a457afe89e5ecfefc6a3dc9: 'utestående kreditnotaer',
  '37f59e13afd5455cf130060ed55f4976': 'rutingsnummer',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'betalingstid',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'til følgende bankkonto:',
  cf4edd5f38054a752994e3242a1f3807: 'Totalt beløp',
  '3c9f634310f5e3de42b79ee83d20923c': 'total avgift',
  '573b1b7930d8030d1669945f2c34992a': 'Overført til din bank',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'vil bli trukket fra kommende betalinger',
};
