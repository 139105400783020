export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Aufgrund der Währung oder von Feiertagen angepasst)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Gebühren und Zahlungsdaten sind Beispieldaten und können nicht garantiert werden.',
  fa090ada546b5deb33cdead006822e26:
    '*Gebühren und Zahlungsdaten sind Beispieldaten und können nicht garantiert werden.',
  '52fce318164dc60017ca67121172aca9': 'Akzeptiert',
  '615a093396ed9c6f52cd7ece7217608e': 'Kontoanpassung',
  '79dd431d35b370210a3dbeb1bb653d43': 'Kontoname',
  '8c66a974a95769447407c061a36d0824': 'Kontonummer',
  '6a810f9427275516cc7ac687ea182f27': 'Angefallene Schuldzinsen',
  '21df2d8134802290caddc9a8070e9d94': 'Angefallene Mahngebühren',
  '7377dcc6989501c8299b7ccf697b731e': 'Angefallene Schuldzinsen',
  dab81136f1c6e799c5626826349a8dcf: 'Angefallene Mahngebühren',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Alle',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Alle Käufer',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Alle Währungen',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Alle Rechnungen',
  d7db3397d1ee87530b253bc69ebca041: 'Betrag',
  c7586d1e153c74713afd948b3048d03d:
    'Der Betrag, der Ihnen nach Abzug von Zinsen und Rückzahlungen vom finanzierten Betrag ausgezahlt wird.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Betrag, der für Rückzahlungen, die für andere Rechnungen fällig sind, abgezogen wird.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Zahlbarer Betrag',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Überschüssige Beträge werden Ihnen überwiesen.',
  de9800ff635c316938aece381c98b094:
    'Ausstehende Teilzahlungen und Zinsen können vom fälligen Kaufpreis oder von anderen Forderungen abgezogen werden. Falls wir nicht in der Lage sind, den Betrag auf diese Weise einzuziehen, werden Sie gebeten, den Betrag direkt an den Käufer zu zahlen.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Genehmigt',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Stellen Sie eine Frage',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Verfügbarer Kreditrahmen für neue Finanzierungen.',
  '85a6c2f454268a7b3c4156c070abc517': 'Warten auf Genehmigung',
  '99fb0198659b8076326d69a42670433b':
    'Die Kontostände werden normalerweise am nächsten Arbeitstag aktualisiert.',
  '0448c27d5574d95fc844e50416fc13a3': 'Bündel',
  '84b730c6e665f3a83a96a8511b4f63db': 'Bündeldetails',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Filiale des Käufers',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Vom Käufer abgelehnte Rechnung',
  cdb5b8f6940144b32dc7f27b7700719a: 'Überweisung des Käufers',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Käufer als bezahlt markiert',
  e7094a08ea031332f2a7e7f94b214174: 'Überweisung des Käufers',
  b8b64034058f45fe990f8377c7990c6b: 'Überweisungskonto des Käufers',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Käufer:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Käufer zahlen hier für Rechnungen. Sie zahlen hier für Teilzahlungen',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Standardmäßig wird der fällige Rückzahlungsbetrag von den nächsten finanzierten Rechnungen abgezogen.',
  '0d1e94883caf8b30026ade5dd5c242ea': 'Rechnungskorrektur',
  cb1a025651df74878d8d5af1b99991df: 'Abgebrochen',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Informationen über das Bargeldprogramm',
  '6fd54168f061cd9f269dbcbb29415486': 'Gebührenbetrag',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Zum Kopieren klicken',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Schließen',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Geschlossen',
  a150472168406890d84a6a5c3c8a894c: 'Gesammelt',
  '0743742edda54d26e47f9959ca4be4f0': 'Angefallene Schuldzinsen',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Bezahlte Mahngebühren',
  '0b2194f7621b8a036308dce1647b982d': 'Gesammelter Betrag',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Angefallene Schuldzinsen',
  b1a3b2482cd668f631d724aac2dfc685: 'Bezahlte Mahngebühren',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Eingang',
  ba18fa9a0a1da454462f71c6e36139ff: 'Kontostände der Kollektion',
  '1103815eae13527faa8d51c83c20d17e': 'Sammlungsdetails',
  '898e15d1fa445c9c37ca795f79029f6d': 'Firmenname',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Fortfahren',
  c05df0411bb18c405aaa68153432bf10: 'Kredit- & Rechnungslimit aktualisiert am',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Verfügbares Guthaben',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Kreditrahmen',
  d23014cf5a5e7f8c6440b96692831aa8: 'Rechnungskorrektur',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Guthaben verwendet',
  '207df9f33356e378a1ff2ef7b62becc7': 'Verfügbarer Kreditbetrag',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Kreditlimit',
  '208d5141343637870387b27db87ccf05': 'Das Kreditlimit wurde überschritten',
  '0e27340c6eb226df1076c4f8930d2289': 'Guthaben verwendet',
  e15a66f111bc879c77f24943a94d19e7: 'Die Währung wird nicht unterstützt',
  b1e5986f972b7599559dea22cfeae8fb: 'Währung:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Täglicher Rabatt',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Täglicher Rabattsatz',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Tägliche Verzugszinsen',
  '9e268b45f182ad1851f03539be6ab29c': 'Täglicher Rabattsatz',
  c2191b6160c46ed7a4b05cc0279158f3: 'Tägliche Verzugszinsen',
  '106202929cf48a3de3f20b3255365915': 'Datum',
  '2eca727eade526acc715c2e5605ebc7e':
    'Datum, an dem der finanzierte Betrag vollständig bezahlt wurde.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Das Datum, an dem die 30-tägige Frist endet.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Datum, an dem die Rechnung von Ihrem Käufer abgelehnt wurde.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'überfällige Tage',
  '1e1e68c46e0d719364bd445b1d605144': 'überfällige Tage',
  bd50c01a22cae65be4d588abcae65a90: 'Abzug',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Abzüge',
  d706035ec5f053ca33b234ddec228ab2: 'Beschreibung',
  '193d60da437b58b24ed69a5683c1ffcf': 'Verwässert',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Verwässerungsbetrag',
  d07e29091188f3b0871dc7712eee7255: 'Verwässerungstyp',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Direkte Überweisung des Verkäufers',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Direkte Überweisung des Verkäufers',
  afc1c7dfc9315e856f8da52e2450182b: 'Rabatt',
  '26e7eb298420701d5a03c53fe096cc0b': 'Angefochten',
  '43cca9c1b76ebf869c7bf22fff271689': 'Dokumentstatusfilter',
  ce73b429141cda25571c88d9b0c78224: 'Dokumentstatus',
  '8475be8bb8249000118af931ae75c373': 'Bericht herunterladen',
  '90567e36a540e51806d7790eb195b1d5': 'Alle Rechnungen herunterladen',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Entwurf',
  '72015a772310560b755bd03132a543b2': 'Fälligkeitsdatum',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Fälligkeitsdatum',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Fälligkeitsdatum zu früh',
  '6def6918bd02ae5117332f4262cecb52': 'Berechtigt',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Anmeldungsstatus',
  cb339c5c02cfb087fc8d393f917379df: 'Fehler beim Laden der Dashboard.',
  e9994b26424cb4bce5059d05e094df8d: 'Geschätzte Gebühren',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Ausgeschlossenes Land',
  b5cb31ac9c7d1871e9075efc1f271536: 'GEBÜHR',
  fd9d568c402dd04e0715e877d76f7da5: 'Gesamtwert der Rechnung.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Fehlgeschlagen',
  '817f1fe84c2d87894bc374681814abbf': '{title} konnte nicht geladen werden -',
  '7932079ce6ea928bfa77efd91578312d': 'Gebühren',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Nach Währung filtern, um zusammengefasste Gesamtwerte zu sehen.',
  '110df72171aeef47da3fd84ea2a1a10f': 'Prognostiziertes Zahlungsdatum zu lang',
  e5af505b37031920b08edb84df19b5ec:
    'Eingangsdatum des gesamter finanzierten Betrags',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Eingangsdatum des gesamter finanzierten Betrags',
  '6dd341953c8c405c70878a457622cf55': 'Finanziert',
  '42e20c6bb1dc82fbdbd889f769850320': 'Finanzierter Betrag eingegangen',
  cab921680af6ff282ce37398b0b231d4: 'Finanzierter Betrag',
  fc0347ceea208298049efd79021475a2: 'Finanzierter Betrag eingegangen',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Finanzierungsdatum',
  '55d900eb37f97b684c0bd5821f161216': 'Finanzierte Rechnungen',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Finanzierung',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Finanzierungsausnahme',
  '5ca89ce217f3bb07293db00a42c10200': 'Begründung(en) der Nichtfinanzierung',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Finanzierungsübersicht',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Finanzierungsanfrage erfolgreich',
  d5ec85821723434aba05986044ebdcdd: 'Finanzierungsstatus',
  '3447158e3ace443dbe27556e07c88639': 'Finanzierungsstatusfilter',
  '022848540bf0e01453be6cf79843aa67': 'Finanzierungszeitraum',
  b4a59826d5bfc276fa88bd00abac615a: 'Finanzierungsdetails',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Finanzierungszeitraum',
  e74f45b44aadcdd084b5cbdd3560f515: 'Ende der Frist',
  '5434cf75f987ef76dbdc544fa1e32096': 'So können Sie bezahlen',
  cb9f9412bd12122bb088124afccf738b: 'Wie kann ich Unterzahlungen zurückzahlen?',
  '9ad57f0b683fecb097c09bedebf68a45':
    'So klärt man das Fälligkeitsdatum der Rückzahlung:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'RG',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Wenn Sie glauben, dass dies ein Fehler ist, kontaktieren Sie bitte <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Wichtige Informationen',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'In Arbeit',
  '313b15a4fdfa2368015e2eb67416af2a': 'Inaktives Programm',
  '7b6a003ceca7fe7fec85daae919e800b': 'Unzulässig',
  '6cf0784c1ac918c22cd402e0cb558749': 'Nicht lesbarer Grund',
  '65cb9c651d2d9fb8941050a08add03c3': 'Für Frühzahlungen unzulässig',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Unlesbare Rechnungen',
  c555faa7b0b745638bb3ea785ee10137:
    'Die angezeigten Informationen wurden zuletzt aktualisiert am',
  b7311d63c44566a8562d92720cffed2e:
    'Unzureichendes Verhältnis zwischen Kollektionen und Rechnungen',
  fb07cb1753537f6969513f2d5fdb7993: 'Zinsbetrag',
  '1938e208f65e66474dc4148c3fa7da08': 'Abgezogene Zinsen',
  '42094e40339ac5c0957a980062c47659': 'Für Teilzahlungen angefallener Zins',
  d9580a19710f07e8c72c215b1270ca57:
    'Von HSBC erhobene Zinsen auf den finanzierten Betrag.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Abgezogene Zinsen',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Zinsen auf überfällige Rechnung',
  '47a565198d287d0864339c077819a361':
    'Zinsen fallen so lange auf Zahlungsvorfälle an, bis diese entweder von der nächsten Zahlung an Sie abgezogen werden oder wir eine direkte Überweisung erhalten.',
  '3c4be400f728da4526de626653d56ccc': 'Rechnung',
  '70546e9227f592c24d3c491756cb901d': 'Rechnung #',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Rechnungsbetrag',
  '6b9515d101794c31e38ae60d092fdc03': 'Rechnungsdatum',
  '2548c6a52ce9e1bffef7aad14530f490': 'Rechnung finanziert',
  '42bb3ba3a1cd691726744d3a1a8da732':
    'Übersicht über die Rechnungsfinanzierung',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Rechnungslimit',
  '734ed127b3d7226c75e8553c0711367a': 'Angewandtes Rechnungslimit',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Gesamtlimit der Rechnung',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Rechnungsnummer',
  da2db17c9b22f995d6673a754d998fc8: 'Rechnung überfällig',
  d729bd1bdced62265cd4ecd7131ef94e: 'Rechnung abgelehnt',
  e14208d4378a3378be8b27eb15984fc6: 'Rechnung abgerechnet',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Rechnungsstatus',
  '54d8df173250a197fab1644a0d0a772a': 'Rechnungsbetrag',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Die Rechnung kann derzeit nicht finanziert werden. Weitere Informationen finden Sie in den Gründen für die Nichtfinanzierung.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'Währung der Rechnung stimmt nicht mit dem Land überein',
  d0da9e977c8b309f93ee378de34fb251: 'Rechnungsdatum',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Rechnungsdatum vor dem frühestmöglichen Datum',
  ad0ab15468b719694f3193d0649ad9eb:
    'Das Rechnungsdatum liegt nach dem Stornierungsdatum des Unternehmens.',
  fc9e80713aebb3d820d888f0755fedde: 'Rechnung hat keinen abonnierten Käufer',
  e17cbec04e7786361eff9da629f09bb7:
    'Das Ausstellungsdatum der Rechnung liegt in der Zukunft.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Die Rechnung wurde storniert.',
  '89f842841b4ae213259b94a5411453f2': 'Die Rechnung wurde verwässert.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'Die Rechnung wurde im Rahmen des Bargeldprogramms finanziert.',
  ab5303505d045929abb1256607ca0d4d:
    'Die Rechnung wurde zur Gänze bezahlt. Es sind keine weiteren Zahlungen erforderlich.',
  '3e159746cbdfda07b2f45750941e26f6':
    'Es wurde die Finanzierung der Rechnung beantragt.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'Die Rechnung hat den Status „Unlesbares Dokument“.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Die Rechnung wurde nicht vollständig bezahlt und die Frist ist abgelaufen',
  d87ca92704bcfb34fa4ac08a4d24147a: 'Die Rechnung ist derzeit nicht lesbar.',
  '2bfdce2f3dd38d4d167510eb5c9434de': 'Rechnung fehlt Enddatum oder Fristende',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Die Rechnung ist zu nahe am voraussichtlichen Zahlungsdatum.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Rechnung ist ungewöhnlich',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c':
    'Die Rechnung wurde bereits als bezahlt markiert.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Die Rechnung könnte ein Duplikat sein.',
  b1f771da19efea2f0fa6a542823231b9:
    'Rechnung kann nur teilweise verarbeitet werden',
  '4217948ca113ee4158d5dd4c07d71624': 'Rechnung mit Unterzahlung beglichen',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Rechnungslaufzeit liegt außerhalb des akzeptablen Bereichs',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Rechnungslaufzeit zu lang',
  '90d9ac20dda384e9c07554d29b143bef':
    'Rechnungswert überschreitet den Grenzwert',
  '4b36f0be327fdca8d54126cd57095a13': 'Rechnungsnummer',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Rechnungen',
  '8089283aad9294aadc2bde165ccc78c1': 'Ausstellungsdatum',
  '3a4123f9c604369ab18781f7492f9293': 'Ausstellungsdatum',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Letztes Auszahlungsdatum',
  a94bc4d4df1d56c16e25493e22684412: 'Verfügbares Limit',
  '92f97c843d499a1559828ba0a9134e24': 'Angewandtes Limit',
  b5dd8e130d88698834ee00464f01cea4: 'Verfügbares Limit',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Angewandtes Limit',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Wird geladen...',
  '24cc5627dea65e22f9279be9522a06e2': 'Kreditbetrag',
  bd24bcedd066a27749016a07cb729a32: 'Als Erledigt kennzeichnen',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Fälligkeitsdatum',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Mitgliedschaftsbeginn',
  d26429adfb846303cbd889879b23e1ba:
    'Beträge, die entweder aus Käuferzahlungen, Verkäuferüberweisungen und/oder Abzügen vor und/oder nach der Frist stammen.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Bezahlter Nettobetrag',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Bezahlter Nettobetrag',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Neuer Betrag',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Nächster Geschäftstag',
  '325c48f099844279c0cf48584de42e64': 'Keine kürzliche Aktivität',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Keine',
  '7457575d324c4b8dada2e13c0f0fe5d5':
    'Nicht aktiv für das Bargeldprogramm angemeldet',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Kein ausreichender Rechnungsverlauf',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Nicht genügend Rechnungen in den vergangenen Monaten',
  c9e2ce8da559465a8596317c35e8f3a4: 'Nicht genug bezahlte Rechnungen',
  e6f890deafa579eb0c24603c3101c1c7:
    'Beachten Sie, dass die Kontostände in der Regel am nächsten Arbeitstag aktualisiert werden. Eröffnen Sie ein Support-Ticket, wenn Sie den aktuellen Kontostand wissen möchten.',
  '83070a059f10db1515ac922bf3433158':
    'Anzahl der Tage nach dem Ende der Frist.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Ein Bericht steht zum Download bereit',
  '2778717f6acbeb1edaac6a2656b478f3': 'Öffnen',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Andere Gebühr',
  fad92e97e692758c0d0a9b5730663894: 'Ausstehende Schuldzinsen',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Ausstehender finanzierter Betrag',
  '9eab1baddf2537312408088fc54f21d5': 'Ausstehende Mahngebühren',
  bfba12a2c234532c0af24a4ad6831ab6: 'Ausstehender Gesamtbetrag',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Ausstehende Schuldzinsen',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Ausstehender finanzierter Betrag',
  '2f9a3de6940f818fe0376e4aece59512': 'Ausstehende Mahngebühren',
  '6f79eea38bff17917740a69116270074': 'Überfällig',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Überfällige Kontostände',
  '848ac8c39b11a751d697a383303e6109': 'Zahlungsvorfälle',
  '8d1e868665fc24a46f0cf55187f72218':
    'Zahlungsvorfälle werden gemäß den Vorschriften des RBI gemeldet.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Überblick',
  '5b885084a5067d7fb4372cef38d8a82d': 'Bezahlt',
  '830b95d305cdc61a66cdf75ebff1d737': 'Von Käufer bezahlt',
  '6444796646c95dbc2cd1cb646e41293d':
    'Ein Teil des Gesamtwerts der Rechnung, der laut dem von der HSBC genehmigten Vorzahlungsanteil finanziert wurde.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Details zum teilnehmenden Käufer',
  a39314e5897b8baaa51ca296610ec30c: 'Partnerbedingungen',
  a6acd5f03fda9c8dc607e59d8be08320: 'Verbindlichkeit zu spät genehmigt',
  e6249829dadf637c4092e4a900df529e:
    'Verbindlichkeit wurde vom Käufer angefochten',
  '07ed468d28d8e8437d8ec64036b96c03': 'Verbindlichkeit wurde abgelehnt',
  '042b8dfb88f7045ca9145bbcccf79200': 'Verbindlichkeit liegt in der Zukunft',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Verbindlichkeit zu alt',
  '8755bbc212b541efb6576f39f3f0f51f': 'Zugewiesene Zahlung',
  '8744ee10540166dfc39db2a6859a9ce1': 'Zahlungsbetrag',
  '56133aa371eb0132334e4867479be942': 'Zahlungsdetails',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Zahlung überfällig',
  '2dd7164df606f9b42beefaba031b06f7': 'Zahlung erhalten',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Zahlung vom Verkäufer bestätigt',
  '661711004f06b0d9601db365679be130': 'Auszahlungsbetrag',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Auszahlungsdatum',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Strafzinsen',
  '0fd036622716f275c3e14c5a14faa65a': 'Offen',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Offene Verwässerung',
  '8cf3479829ba47a8a4123895d0088eeb': 'Offene Verwässerungen',
  '270b56fd05f72407ca0607b6162d0b10': 'Wartende Verbindung',
  '4a78218416ca45d97b5633b37ae3f638':
    'Prozentsatz, mit dem die Tagesgebühren für den gesamten Finanzierungszeitraum berechnet werden.',
  b586a5468c7070bf1717593ea84fa607:
    'Prozentsatz Ihres ausstehenden finanzierten Betrags, der Ihnen täglich für verspätete oder finanzierte und abgelehnte Rechnungen in Rechnung gestellt wird.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Bitte eröffnen Sie ein Support-Ticket, wenn Sie die aktuellen Kontostände wissen möchten.',
  '34645fa620cae4187f27d9f96c172f26': 'Bitte überweisen Sie den Betrag',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Bitte überweisen Sie folgenden Betrag auf folgendes Bankkonto:',
  '01734a7316427fdcdcd4f937cda3802b': 'Vorauszahlung',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Vorheriger Betrag',
  '500fc4731dab800a878b33906d9332fb': 'Inkassokonto des Programms',
  '7327113634d1f5f4f68632dceebfbc6f': 'Programmübersicht',
  d95ebfb51a485293a134b1038d332a10: 'Programm aktiviert am',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Programmdaten für das Dashboard nicht verfügbar',
  '98feb2c86a81b8caeeaa30966ba34636': 'Programminformationen',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Zahlungen für Eingangsrechnungen, die bis zum erwarteten Fälligkeitsdatum nicht zur Gänze kassiert wurden, generieren eine Teilzahlung. Für Teilzahlungen fallen Zinsen in Höhe des 1,5-fachen täglichen Rabattsatzes an und es liegt in Ihrer Verantwortung, beide zurückzuzahlen.',
  '521689b600b8da5b0a111b89dea72126': 'Grund',
  fe7bd622a1efcec03eb86dabb9395936: 'Erhalten',
  e84203a8833e4d0d92faf89f4107bf11: 'Regress-Rückzahlung',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Rückerstattungsbetrag',
  b0be744ae1f6312f16990bbd92a87aea: 'Rückerstattungsdatum',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Rückerstattungslaufzeit',
  '654d6dcc141079bc84500fd14ca5087d': 'Rückerstattungsbetrag',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Rückzahlungsdetails',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Rückerstattungsdatum',
  c0a6a91e7ca80af9de47097b06dc7618: 'Rückerstattungslaufzeit',
  '4a2875439ab289a068abb9ea3b567bcb': 'Abgelehnt',
  '592b34cdfa2e51826b32a0783e0edce3': 'Vom Käufer abgelehnt',
  '807d35f105b65ab2795c4f8e51795f3c': 'Von Verkäufer abgelehnt',
  aea4dd208e871d7ca0051b0ec38c3068: 'Ablehnungsdatum',
  d64ed4934fbf68a8f716467b0adf89ba: 'Ablehnungsdatum',
  '5a77de3b287a92494be077c197f37581':
    'Verbleibender Betrag, der noch nicht kassiert oder zurückgezahlt wurde.',
  e442b864bf745a75477ea0ee88bc15c4: 'Rückzahlung fällig',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Rückzahlungsbetrag zum Ende der Frist. Dieser Betrag ändert sich nicht, wenn die Rückzahlung nach diesem Datum erfolgt, und enthält auch keine Zahlungsvorfälle.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Rückzahlung fällig',
  dc20cbfb76d220c108241a451bc3faf4: 'Informationen zu Rückzahlungsanweisungen',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Fällige Rückzahlungen sind der Gesamtbetrag, der noch ausstehend ist. Grund dafür ist entweder, dass der vom Käufer gezahlten Betrag kleiner als der Rechnungsbetrag ist, und/oder dass der Käufer, die Rechnung nach der Finanzierung abgelehnt hat.',
  '45b60e22719c12291932bf09db0df5cd':
    'Die fällig werdenden Rückzahlungen sind der Gesamtbetrag, der zurückerstattet werden muss, und resultieren aus:',
  ba093b1421452b3afdebbebedaeae552: 'Finanzierung anfordern',
  '34f8f9968ec0d7f91e558e906a7de982': 'Angefordert',
  dc1650cfbadb05f8fb6357692e48ee71: 'Finanzierung angefordert',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Einbehalten',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Einbehaltener Betrag',
  a476316a89541d1aa71d5b5366874e13: 'Einbehaltener Betrag',
  '328f6585a0e78a96deefc0661474248f': 'Noch einmal versuchen',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Verkäufe',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Nach Rechnungsnummer suchen',
  '3215999493db188b35242dc4f4fd8fb5':
    'Weitere Informationen zur Rückzahlung finden Sie unter Programminformationen.',
  '69f43575c3a51e94605eb75b75f7b563': 'Ausgewählt',
  '56a1c7c4a02e9985745969563a80799f': 'Rückzahlungskonto des Verkäufers',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Wird gesandt',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Versandt',
  '95d54926719f1071b2fa640f1ba23d3e': 'Erledigt',
  e243f36392a7f264d830f04f21aa8972: 'Abgerechnete Rechnungen',
  b548572d4123e3a569d27e4676664dad: 'Unterzahlung',
  '6e09ada7e33833d259370b0cc900f082': 'Betrag der Unterzahlung',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Teilzahlungsabzüge',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Unterzahlungen',
  '46bbc145b1f85c082950c768ade012f6': 'Betrag der Unterzahlung',
  e66e6d9768e8f489c05951ee70ede350:
    'Es ist etwas schiefgelaufen. Möglicherweise sind nicht alle Funktionen des Dashboards verfügbar. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',
  cd969f183dc619b5716067cfda39c055: 'BLZ',
  '17939071126e9e41921d79b837f63297': 'Name des Lieferanten',
  '750d4460b260343039f9bc71b66a231c': 'Der Lieferant ist insolvent',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Dieser Lieferant ist noch nicht im Programm eingerichtet',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Kundenservice',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Bedingungen akzeptiert von',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Servicebedingungen',
  '1294cf929ec55581954a79948592dda9':
    'Das Datum der Finanzierung des Bargeldprogramms wurde aktiviert.',
  f51144e4468390a486d41b4db66fc243:
    'Die Datei konnte nicht heruntergeladen werden.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Die maximale Anzahl der Tage, an denen die Zahlung von Ihrem Käufer erwartet wird, wie von der HSBC genehmigt.',
  d438da584c455e727642a303917e690d:
    'Der verbleibende Kreditbetrag, der in Anspruch genommen werden kann. Dies ist die Differenz zwischen dem Kreditlimit und dem in Anspruch genommenen Kredit.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Die Gesamtanzahl von Rechnungen, die derzeit für dieses Programm finanziert werden. Dieser Betrag wird anhand des Kreditlimits und der aktuellen Anzahl von finanzierten Rechnungen berechnet.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Die Gesamtanzahl von Rechnungen, die für dieses Programm finanziert werden können. Dieser Betrag wird anhand des Kreditlimits und der aktuellen Anzahl von finanzierten Rechnungen berechnet.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Der Gesamtbetrag, der vor der Bezahlung der Rechnung zurückgehalten wurde.',
  '13f271ac486faca89c1944226ba0efff':
    'Der Gesamtwert aller importierten Rechnungen.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Der Gesamtwert der Rechnungen, die den Status „Abgerechnet“ haben, weil sie vollständig bezahlt wurden.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Der Gesamtwert der finanzierten Rechnungen.',
  '565c255165bed2ff2640d0e42369c62c':
    'Der Gesamtwert von Rechnungen, die noch nicht finanziert werden können.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Dieses Konto ist nicht aktiv im Bargeldprogramm angemeldet.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Dies wird normalerweise am nächsten Arbeitstag aktualisiert. Eröffnen Sie bitte ein Support-Ticket, wenn Sie die aktuellen Kontostände wissen möchten.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Dieser Wert ist Ihr letzter Teilzahlungssaldo inklusive Zinsen.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Zusammen mit',
  '603e4b3b3ac7b888a06548d14435bc25': 'Zu starke Verwässerung',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Summe',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Gesamter gesammelter Betrag',
  f786c8a1095bdf155841d510bb53142a: 'Gesamtzinsen auf Zahlungsvorfälle.',
  '4f25d1096076868bc590da9f8a26cf55': 'Gesamtlimit',
  '230bd56af0acab75194f58b46a763535':
    'Gesamtbetrag der von Zahlung(en) des Käufers eingezogen wurde.',
  '8f78461d739b4d6d46f961f352000cec':
    'Gesamtbetrag, der von Ihnen eingezogen wurde.',
  b62dd565f60dc6172605d8db223510b9:
    'Eingeganger Gesamtbetrag durch Überweisung des Käufers, Überweisung des Verkäufers und/oder Clawback, einschließlich aller Zinsen auf Zahlungsvorfälle.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Der finanzierte Gesamtbetrag im Vergleich zu Ihren Verkäufen.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Der nicht finanzierte Gesamtbetrag laut dem Vorzahlungsanteil, der von der HSBC genehmigt wurde.',
  d6c89215b3fd6857262efc2039d78dec:
    'Gesamtbetrag der finanzierten Rechnungen, die zur Gänze eingegangen sind.',
  a069138e83a8b4cb7e81145689155826:
    'Der Gesamtbetrag der Zinsen auf Zahlungsvorfälle, der noch bezahlt werden muss oder ausstehend ist.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Gesamtbetrag der fälligen Mahngebühren. Strafgebühren fallen täglich an.',
  bc37640f0d55a99b3f0b8bce05a2f0d1: 'Gesamtbetrag der bezahlten Mahngebühren.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Gesamtbetrag der Mahngebühren, die bezahlt werden müssen oder überfällig sind.',
  d649fb9a0971deaa127067341f59be91:
    'Der Gesamtbetrag wird über andere Rechnungsabzüge eingezogen.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Der Gesamtbetrag, der noch eingezogen oder zurückgezahlt werden muss.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Gesamter Einzugsbetrag',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Der gesamte Rechnungswert, der nicht finanziert werden kann.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Der Gesamtwert der Rechnungen, die an den Käufer gesendet werden.',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Der gesamte finanzierte Betrag, der durch Überweisung des Käufers, Überweisung des Verkäufers und/oder Clawback eingegangen ist.',
  d4867ee77e79ded226c07918b9393715:
    'Gesamtzinsen auf bezahlte Zahlungsvorfälle.',
  de4c28160afe1ab48bcf83be49bbb196: 'Gesamtes Limit',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Ausstehender Gesamtbetrag',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Der gesamte ausstehende Betrag und etwaige Zinsen auf Zahlungsvorfälle müssen von Ihnen zurückerstattet werden. Dies könnte darauf zurückzuführen sein, dass eine oder mehrere finanzierte Rechnungen überfällig sind, oder Ihr Käufer eine oder mehrere Ihrer Rechnungen ablehnte, nachdem sie finanziert wurden.',
  fecdb72e34cebfadf037b45b596d90e5: 'Tradeshift Bargeldprogramm FAQ',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Transaktionsdetails',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Die Rechnungs-CSV konnte nicht heruntergeladen werden. Bitte kontaktieren Sie den Kundenservice',
  c8415d81378085ba3933015b8f5fc768:
    'Vereinbarung konnte nicht abgerufen werden. Bitte kontaktieren Sie den Kundenservice',
  '64580a198c861aa0226c91b28f8418aa': 'Kann nicht verkauft werden',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Unbezahlte Rechnungen gelten ab diesem Datum als „überfällig“ und es werden täglich Verzugszinsen fällig.',
  '9d632e72031eea3767881d6c3adcfe54': 'Verwendetes Kreditlimit.',
  e871a6882109bd7ef07827882236c8db: 'Vereinbarung anzeigen',
  '2ac548c28faaa41283f9d6b7606a4844': 'Für ungültig erklärt',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Willkommen in der Bargeld-App!',
  '78eb58937961a18929a623397b544a01':
    'Sie können den fälligen Rückerstattungsbetrag zurückzahlen, indem Sie eine direkte Überweisung auf das Rückzahlungskonto (siehe oben) vornehmen.',
  f347abf54f65274a614a9ba7515dfa98:
    'Mit der Bargeld-App können Sie alle Ihre Frühzahlungen an einem Ort verfolgen',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Ihr Bargeldprogramm ist aktiv!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Ihr Bargeldprogramm ist inaktiv',
  '6e6e06469dd953a124d718cd2307e32c':
    'Ihre Teilnahme am Bargeldprogramm ist seit {date} nicht mehr aktiv. Bitte kontaktieren Sie <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'eine finanzierte Rechnung, die vom Käufer vor Ablauf der Frist abgelehnt wurde (wofür ab dem Ablehnungstag täglich Zinsen anfallen)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'eine finanzierte Rechnung, die am Ende der Frist noch unbezahlt ist (wonach täglich Zinsen anfallen); und/oder',
  '33e67e73d7f54aaee31e6040f873be79': 'auf Abzüge angewendet',
  a9798a3e063111de4d4768c363bdc267: 'auf Rechnungen angewandt',
  a5be13d3bfec5fc92521a841009db3c7: 'Kontonummer',
  '736c1e36ac779a73ff4fddd19d82008c': 'täglicher Zinssatz',
  ab53acde68c117f8fbfff6a8901de083: 'täglicher Verzugszinssatz',
  '4c584eabd4260d69914ac993cc4db5d2': 'Tage her',
  '047dbee224da5fb4ecfc21268ffd3115': 'Fälligkeitsdatum',
  dd051262964c80130a2e9023ff933d89: 'Frühzahlung',
  bc55e8a10310a96eeda133ddf159303d: 'voraussichtliches Fälligkeitsdatum',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'für Frühzahlungen',
  '932d9bd46daba4d09f361717c0ec03ab': 'finanzierter Betrag',
  '5e82566144af4903b40303fc3d1c799e': 'Finanzierungsdatum',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'Frist',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'Tilgungszahlung',
  '2db524546615d120af0adf844a6e1bd7': 'von',
  '99c2f4783bb6821d84ad17a22f556813': 'der Finanzierten',
  '7555b72d3b4ffe69558b68b3cb86b954': 'am',
  c138be463a457afe89e5ecfefc6a3dc9: 'offene Rechnungskorrekturen',
  '37f59e13afd5455cf130060ed55f4976': 'BLZ',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'Laufzeit',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'auf folgendes Bankkonto:',
  cf4edd5f38054a752994e3242a1f3807: 'Gesamtbetrag',
  '3c9f634310f5e3de42b79ee83d20923c': 'Gesamtbetrag',
  '573b1b7930d8030d1669945f2c34992a': 'an Ihre Bank überwiesen',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'wird von anstehenden Zahlungen abgezogen',
};
