export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Aangepast als gevolg van munteenheid of feestdagen)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Vergoedingen en betaaldata zijn richtwaarden en kunnen niet gegarandeerd worden.',
  fa090ada546b5deb33cdead006822e26:
    '*Vergoedingen en betaaldata zijn richtwaarden en kunnen niet gegarandeerd worden.',
  '52fce318164dc60017ca67121172aca9': 'Geaccepteerd',
  '615a093396ed9c6f52cd7ece7217608e': 'Account aanpassen',
  '79dd431d35b370210a3dbeb1bb653d43': 'Naam van de rekening',
  '8c66a974a95769447407c061a36d0824': 'Rekeningnummer',
  '6a810f9427275516cc7ac687ea182f27': 'Opgelopen schuldrente',
  '21df2d8134802290caddc9a8070e9d94': 'Opgelopen achterstallige kosten',
  '7377dcc6989501c8299b7ccf697b731e': 'Opgelopen schuldrente',
  dab81136f1c6e799c5626826349a8dcf: 'Opgelopen achterstallige kosten',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Alle',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Alle kopers',
  '59a79bbd7cb65771c64f6c687b4c8103': "Alle valuta's",
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Alle facturen',
  d7db3397d1ee87530b253bc69ebca041: 'Bedrag',
  c7586d1e153c74713afd948b3048d03d:
    'Bedrag dat na aftrek van rente en eventuele aflossingen van het gefinancierde bedrag aan u wordt uitbetaald.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Bedrag dat wordt teruggevorderd van aflossingen op andere facturen.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Over te maken bedrag',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Overschotten zullen naar u worden overgeschreven.',
  de9800ff635c316938aece381c98b094:
    "Alle openstaande deelbetalingen en rente kunnen van de te betalen koopprijs of andere incasso's worden afgetrokken. Als we het op deze manier niet kunnen innen, verzoeken we u het bedrag rechtstreeks aan de koper te betalen.",
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Goedgekeurd',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Stel een vraag',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Beschikbare kredietlimiet voor nieuwe financiering.',
  '85a6c2f454268a7b3c4156c070abc517': 'In afwachting van goedkeuring',
  '99fb0198659b8076326d69a42670433b':
    'De balans wordt meestal op de volgende werkdag bijgewerkt.',
  '0448c27d5574d95fc844e50416fc13a3': 'Bundel',
  '84b730c6e665f3a83a96a8511b4f63db': 'Bundelgegevens',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Kopersfiliaal',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Afgewezen facturen van verkoper',
  cdb5b8f6940144b32dc7f27b7700719a: 'Betalingsopdracht koper',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Inkoper heeft betaald gemarkeerd',
  e7094a08ea031332f2a7e7f94b214174: 'Betalingsopdracht koper',
  b8b64034058f45fe990f8377c7990c6b: 'Overboekingsrekening koper',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Koper:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Kopers betalen hier voor facturen. U betaalt hier voor deelbetalingen',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Standaard wordt het af te lossen bedrag in mindering gebracht op de volgende gefinancierde factuur/facturen.',
  cb1a025651df74878d8d5af1b99991df: 'Geannuleerd',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Cashprogramma-informatie',
  '6fd54168f061cd9f269dbcbb29415486': 'Toeslagbedrag',
  '252982cd07a2c1e75a9cadb781c4428f': 'Terugvorderingsbedrag',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Klik om te kopiëren',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Sluiten',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Gesloten',
  a150472168406890d84a6a5c3c8a894c: 'Geïnd',
  '0743742edda54d26e47f9959ca4be4f0': 'Geïnde schuldrente',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Geïnde achterstallige kosten',
  '0b2194f7621b8a036308dce1647b982d': 'Ontvangen bedrag',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Geïnde schuldrente',
  b1a3b2482cd668f631d724aac2dfc685: 'Geïnde achterstallige kosten',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Verzameling',
  ba18fa9a0a1da454462f71c6e36139ff: 'Incassosaldi',
  '1103815eae13527faa8d51c83c20d17e': 'Incasso-informatie',
  '898e15d1fa445c9c37ca795f79029f6d': 'Officiële bedrijfsnaam',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Doorgaan',
  c05df0411bb18c405aaa68153432bf10: 'Krediet- en factuurlimiet bijgewerkt op',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Krediet beschikbaar',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Kredietlimiet',
  d23014cf5a5e7f8c6440b96692831aa8: 'Creditnota',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Credit gebruikt',
  '207df9f33356e378a1ff2ef7b62becc7': 'Krediet beschikbaar',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Kredietlimiet',
  '208d5141343637870387b27db87ccf05': 'Kredietlimiet is overschreden',
  '0e27340c6eb226df1076c4f8930d2289': 'Gebruikt krediet',
  e15a66f111bc879c77f24943a94d19e7: 'Valuta wordt niet ondersteund',
  b1e5986f972b7599559dea22cfeae8fb: 'Valuta:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Dagelijkse korting',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Dagelijks kortingspercentage',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Dagelijkse vertragingsrente',
  '9e268b45f182ad1851f03539be6ab29c': 'Dagelijks kortingspercentage',
  c2191b6160c46ed7a4b05cc0279158f3: 'Dagelijkse vertragingsrente',
  '106202929cf48a3de3f20b3255365915': 'Datum',
  '2eca727eade526acc715c2e5605ebc7e':
    'Datum waarop het gefinancierde bedrag volledig is verrekend.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Datum waarop de coulancetermijn van 30 dagen afloopt.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Datum waarop de factuur door uw inkoper is afgewezen.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Dagen over tijd',
  '1e1e68c46e0d719364bd445b1d605144': 'Dagen over tijd',
  bd50c01a22cae65be4d588abcae65a90: 'Aftrek',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Aftrekbedragen',
  d706035ec5f053ca33b234ddec228ab2: 'Omschrijving',
  '193d60da437b58b24ed69a5683c1ffcf': 'Verwaterd',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Bedrag kapitaalverwatering',
  d07e29091188f3b0871dc7712eee7255: 'Type kapitaalverwatering',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Betaalopdracht directe verkoper',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Betaalopdracht directe verkoper',
  afc1c7dfc9315e856f8da52e2450182b: 'Korting',
  '26e7eb298420701d5a03c53fe096cc0b': 'Aangevochten',
  '43cca9c1b76ebf869c7bf22fff271689': 'Documentstatusfilters',
  ce73b429141cda25571c88d9b0c78224: 'Documentstatus',
  af13cc2ad0a7337c45420f75b7d5a668: 'Downloaden',
  '8475be8bb8249000118af931ae75c373': 'Rapport downloaden',
  '90567e36a540e51806d7790eb195b1d5': 'Alle facturen downloaden',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Concept',
  '72015a772310560b755bd03132a543b2': 'Vervaldatum',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Vervaldatum',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Vervaldatum te snel',
  '6def6918bd02ae5117332f4262cecb52': 'Gerechtigd',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Inschrijvingsstatus',
  cb339c5c02cfb087fc8d393f917379df: 'Fout tijdens laden dashboard.',
  e9994b26424cb4bce5059d05e094df8d: 'Geschatte vergoeding',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Uitgesloten land',
  fd9d568c402dd04e0715e877d76f7da5: 'Nominale waarde van de factuur.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Mislukt',
  '817f1fe84c2d87894bc374681814abbf': 'Laden {title} mislukt -',
  '7932079ce6ea928bfa77efd91578312d': 'Vergoedingen',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filter op valuta om samengevatte totalen te bekijken.',
  '110df72171aeef47da3fd84ea2a1a10f': 'Voorspelde betalingsdatum te lang',
  e5af505b37031920b08edb84df19b5ec: 'Datum van volledig gefinancierd bedrag',
  a4e77d51ea7969a9e1fd98d59f99c158: 'Datum van volledig gefinancierd bedrag',
  '6dd341953c8c405c70878a457622cf55': 'Geconsolideerd',
  '42e20c6bb1dc82fbdbd889f769850320': 'Geïnd gefinancierd bedrag',
  cab921680af6ff282ce37398b0b231d4: 'Gefinancierd bedrag',
  fc0347ceea208298049efd79021475a2: 'Geïnd gefinancierd bedrag',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Financieringsdatum',
  '55d900eb37f97b684c0bd5821f161216': 'Gefinancierde facturen',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Financiering',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Financieringsuitzondering',
  '5ca89ce217f3bb07293db00a42c10200':
    'Reden(en) voor niet in aanmerking komen voor financiering',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Financieringsoverzicht',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Financieringsverzoek gelukt',
  d5ec85821723434aba05986044ebdcdd: 'Financieringsstatus',
  '3447158e3ace443dbe27556e07c88639': 'Financieringsstatusfilters',
  '022848540bf0e01453be6cf79843aa67': 'Looptijd van financiering',
  b4a59826d5bfc276fa88bd00abac615a: 'Financieringsgegevens',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Looptijd van financiering',
  e74f45b44aadcdd084b5cbdd3560f515: 'Einddatum coulanceperiode',
  '5434cf75f987ef76dbdc544fa1e32096': 'Betaalwijze',
  cb9f9412bd12122bb088124afccf738b: 'Hoe kunt u deelbetalingen terugbetalen?',
  '9ad57f0b683fecb097c09bedebf68a45':
    'Hoe de verschuldigde terugbetaling wegwerken:',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Als u denkt dat dit niet juist is, neemt u dan contact op met <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Belangrijke informatie',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'In uitvoering',
  '313b15a4fdfa2368015e2eb67416af2a': 'Inactief programma',
  '7b6a003ceca7fe7fec85daae919e800b': 'Ongerechtigd',
  '6cf0784c1ac918c22cd402e0cb558749': 'Niet-toegestane reden',
  '65cb9c651d2d9fb8941050a08add03c3': 'Ongerechtigd voor vroege betalingen',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Niet in aanmerking komende facturen',
  c555faa7b0b745638bb3ea785ee10137:
    'De weergegeven informatie is voor het laatst bijgewerkt op',
  b7311d63c44566a8562d92720cffed2e: "Ratio's incasso’s/facturen onvoldoende",
  fb07cb1753537f6969513f2d5fdb7993: 'Rentebedrag',
  '1938e208f65e66474dc4148c3fa7da08': 'Afgetrokken rente',
  '42094e40339ac5c0957a980062c47659': 'Opgebouwde rente op deelbetaling',
  d9580a19710f07e8c72c215b1270ca57:
    'Door HSBC berekende rente over het gefinancierde bedrag.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Afgetrokken rente',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Rente op achterstallige factuur',
  '47a565198d287d0864339c077819a361':
    'Er wordt vertragingsrente over de achterstallige schuld opgebouwd totdat de volgende betaling aan u in mindering is gebracht of totdat wij een directe overboeking ontvangen.',
  '3c4be400f728da4526de626653d56ccc': 'Factuur',
  '70546e9227f592c24d3c491756cb901d': 'Factuurnr.',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Factuurbedrag',
  '6b9515d101794c31e38ae60d092fdc03': 'Factuurdatum',
  '2548c6a52ce9e1bffef7aad14530f490': 'Factuur gefinancierd',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Financieringsoverzicht facturen',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Factuurlimiet',
  '734ed127b3d7226c75e8553c0711367a': 'Factuurlimiet in gebruik',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Factuurlimiet totaal',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Factuurnummer',
  da2db17c9b22f995d6673a754d998fc8: 'Achterstallige factuur',
  d729bd1bdced62265cd4ecd7131ef94e: 'Factuur afgewezen',
  e14208d4378a3378be8b27eb15984fc6: 'Factuur verrekend',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Factuurstatus',
  '54d8df173250a197fab1644a0d0a772a': 'Factuurbedrag',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Factuur kan op dit moment niet gefinancierd worden. Voor meer informatie, zie de redenen waarom deze niet voor financiering in aanmerking komt.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'De munteenheid van de factuur komt niet overeen met het land',
  d0da9e977c8b309f93ee378de34fb251: 'Factuurdatum',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Factuurdatum vóór de eerste toegestane datum',
  ad0ab15468b719694f3193d0649ad9eb:
    'De datum van de factuur valt na de annuleringsdatum van de faciliteit.',
  fc9e80713aebb3d820d888f0755fedde: 'Factuur heeft geen ingeschreven koper',
  e17cbec04e7786361eff9da629f09bb7:
    'De factuur heeft een uitgiftedatum die in de toekomst ligt.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'De factuur is geannuleerd.',
  '89f842841b4ae213259b94a5411453f2': 'De factuur is verwaterd.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'De factuur is gefinancierd onder het Cash-programma.',
  ab5303505d045929abb1256607ca0d4d:
    'De factuur is volledig betaald. Er zijn geen verdere betalingen vereist.',
  '3e159746cbdfda07b2f45750941e26f6':
    'Voor de factuur is een aanvraag gedaan voor financiering.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'De factuur komt vanwege zijn documentstatus niet in aanmerking.',
  '8204c52c01b05791dc3e4c59ac352978':
    'De factuur is niet volledig betaald en de coulancetermijn is verstreken',
  d87ca92704bcfb34fa4ac08a4d24147a:
    'De factuur komt op dit moment niet in aanmerking.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'In de factuur ontbreekt einddatum of definitieve einddatum',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'De factuur ligt te dicht bij de voorziene betaaldatum.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'De factuur is ongebruikelijk',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c':
    'De factuur is gemarkeerd als al door u betaald.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'De factuur kan een duplicaat zijn.',
  b1f771da19efea2f0fa6a542823231b9:
    'Factuur komt slechts gedeeltelijk in aanmerking',
  '4217948ca113ee4158d5dd4c07d71624': 'Factuur voldaan met deelbetaling',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'De factuurtermijn valt niet binnen het aanvaardbare bereik',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Factuurduur te lang',
  '90d9ac20dda384e9c07554d29b143bef': 'Factuurwaarde overschrijdt limiet',
  '4b36f0be327fdca8d54126cd57095a13': 'Factuur-/BO-nummer',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Facturen',
  '8089283aad9294aadc2bde165ccc78c1': 'Uitgiftedatum',
  '3a4123f9c604369ab18781f7492f9293': 'Uitgiftedatum',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Datum laatste uitbetaling',
  a94bc4d4df1d56c16e25493e22684412: 'Limiet beschikbaar',
  '92f97c843d499a1559828ba0a9134e24': 'Limiet in gebruik',
  b5dd8e130d88698834ee00464f01cea4: 'Limiet beschikbaar',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Limiet in gebruik',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Laden...',
  '24cc5627dea65e22f9279be9522a06e2': 'Geleend bedrag',
  bd24bcedd066a27749016a07cb729a32: 'Gemarkeerd als afgewikkeld',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Vervaldatum',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Aanvangsdatum van lidmaatschap',
  d26429adfb846303cbd889879b23e1ba:
    'Bedragen ontvangen van betalingen van kopers, bankoverschrijvingen van verkopers en/of inhoudingen die vóór en/of na de coulanceperiode zijn verricht.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Netto betaalde bedrag',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Netto betaald bedrag',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nieuw bedrag',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Volgende werkdag',
  '325c48f099844279c0cf48584de42e64': 'Geen recente activiteit',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Geen',
  '7457575d324c4b8dada2e13c0f0fe5d5':
    'Niet actief ingeschreven in contant geld',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Onvoldoende factuurgeschiedenis',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Niet genoeg facturen in de afgelopen maanden',
  c9e2ce8da559465a8596317c35e8f3a4: 'Niet genoeg betaalde facturen',
  e6f890deafa579eb0c24603c3101c1c7:
    'Let wel dat de saldi meestal op de volgende werkdag worden bijgewerkt. Stel een ondersteuningsticket op als u de huidige positie moet weten.',
  '83070a059f10db1515ac922bf3433158':
    'Aantal dagen na de einddatum van de coulancetermijn.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Eén rapport beschikbaar voor download',
  '2778717f6acbeb1edaac6a2656b478f3': 'Openen',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Overige kosten',
  fad92e97e692758c0d0a9b5730663894: 'Uitstaande schuldrente',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Uitstaand gefinancierd bedrag',
  '9eab1baddf2537312408088fc54f21d5': 'Uitstaande achterstallige kosten',
  bfba12a2c234532c0af24a4ad6831ab6: 'Uitstaand totaalbedrag',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Uitstaande schuldrente',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Uitstaand gefinancierd bedrag',
  '2f9a3de6940f818fe0376e4aece59512': 'Uitstaande achterstallige kosten',
  '6f79eea38bff17917740a69116270074': 'Achterstallig',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Achterstallige saldi',
  '848ac8c39b11a751d697a383303e6109': 'Achterstallige schuld',
  '8d1e868665fc24a46f0cf55187f72218':
    'De achterstallige schulden zullen in overeenstemming met de regels van de RBI worden gerapporteerd.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Overzicht',
  '5b885084a5067d7fb4372cef38d8a82d': 'Betaald',
  '830b95d305cdc61a66cdf75ebff1d737': 'Betaald door inkoper',
  '6444796646c95dbc2cd1cb646e41293d':
    'Een deel van de nominale waarde van de factuur dat is gefinancierd volgens het door HSBC goedgekeurde vooruitbetalingspercentage.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Gegevens deelnemende inkopers',
  a39314e5897b8baaa51ca296610ec30c: 'Voorwaarden van zakenpartner',
  a6acd5f03fda9c8dc607e59d8be08320:
    'Uitstel van goedkeuring van betalingsverplichting',
  e6249829dadf637c4092e4a900df529e: 'Verplichting aangevochten door de koper',
  '07ed468d28d8e8437d8ec64036b96c03': 'Verplichting is afgewezen',
  '042b8dfb88f7045ca9145bbcccf79200': 'Verplichting is in de toekomst',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Betalingsverplichting is te oud',
  '8755bbc212b541efb6576f39f3f0f51f': 'Betaling toegewezen',
  '8744ee10540166dfc39db2a6859a9ce1': 'Betalingsbedrag',
  '56133aa371eb0132334e4867479be942': 'Betalingsgegevens',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Betaling achterstallig',
  '2dd7164df606f9b42beefaba031b06f7': 'Betaling ontvangen',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Betaling bevestigd door verkoper',
  '661711004f06b0d9601db365679be130': 'Uitbetalingsbedrag',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Uitbetalingsdatum',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Boeterente',
  '0fd036622716f275c3e14c5a14faa65a': 'In behandeling',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Kapitaalverwatering in behandeling',
  '8cf3479829ba47a8a4123895d0088eeb': 'Kapitaalverwateringen in behandeling',
  '270b56fd05f72407ca0607b6162d0b10': 'In behandeling zijnd contact',
  '4a78218416ca45d97b5633b37ae3f638':
    'Percentage waartegen de dagelijkse kosten voor de volledige looptijd van de financiering berekend worden.',
  b586a5468c7070bf1717593ea84fa607:
    'Percentage dat dagelijks in rekening wordt gebracht op uw uitstaande gefinancierde bedrag voor achterstallige of gefinancierde en afgewezen facturen.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Stel een ondersteuningsticket op als je de huidige saldi wilt kennen.',
  '34645fa620cae4187f27d9f96c172f26': 'Gelieve het bedrag over te schrijven',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Maak het volgende bedrag over naar de volgende bankrekening:',
  '01734a7316427fdcdcd4f937cda3802b': 'Vooruitbetaling',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Vorig bedrag',
  '500fc4731dab800a878b33906d9332fb': 'Incassorekening van programma',
  '7327113634d1f5f4f68632dceebfbc6f': 'Programmaoverzicht',
  d95ebfb51a485293a134b1038d332a10: 'Programma geactiveerd op',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Programma-informatie niet beschikbaar voor Dashboard',
  '98feb2c86a81b8caeeaa30966ba34636': 'Programma-informatie',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Betalingen van inkoopfacturen die niet volledig op de verwachte vervaldatum zijn geïnd, genereren een deelbetaling. De rente op deelbetalingen is 1,5x het dagelijkse discontopercentage en het is uw verantwoordelijkheid om beide terug te betalen.',
  '521689b600b8da5b0a111b89dea72126': 'Reden',
  fe7bd622a1efcec03eb86dabb9395936: 'Ontvangen',
  e84203a8833e4d0d92faf89f4107bf11: 'Terugbetaling van regres',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Restitutiebedrag',
  b0be744ae1f6312f16990bbd92a87aea: 'Uitgiftedatum restitutie',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Looptijd restitutie',
  '654d6dcc141079bc84500fd14ca5087d': 'Restitutiebedrag',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Restitutiegegevens',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Uitgiftedatum restitutie',
  c0a6a91e7ca80af9de47097b06dc7618: 'Looptijd restitutie',
  '4a2875439ab289a068abb9ea3b567bcb': 'Geweigerd',
  '592b34cdfa2e51826b32a0783e0edce3': 'Afgewezen door inkoper',
  '807d35f105b65ab2795c4f8e51795f3c': 'Afgewezen door verkoper',
  aea4dd208e871d7ca0051b0ec38c3068: 'Datum van afwijzing',
  d64ed4934fbf68a8f716467b0adf89ba: 'Datum van afwijzing',
  '5a77de3b287a92494be077c197f37581':
    'Het resterende gefinancierde bedrag moet nog geïnd of terugbetaald worden.',
  e442b864bf745a75477ea0ee88bc15c4: 'Verschuldigde terugbetalingen',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Het aflossingsbedrag op de einddatum van de coulancetermijn. Dit verandert niet vanwege eventuele aflossingen die na deze datum zijn gedaan en omvat evenmin achterstallige schulden.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Verschuldigde terugbetaling',
  dc20cbfb76d220c108241a451bc3faf4: 'Informatie over aflossingsinstructies',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Verschuldigde aflossingen zijn het totale uitstaande bedrag dat moet worden teruggevorderd, als gevolg van het feit dat het door de Koper voldane bedrag lager is dan de nominale waarde van de factuur en/of het feit dat de Koper de factuur afwijst wanneer deze al betaald is.',
  '45b60e22719c12291932bf09db0df5cd':
    'De verschuldigde aflossingen zijn het totale uitstaande bedrag dat teruggevorderd moet worden, als gevolg van:',
  ba093b1421452b3afdebbebedaeae552: 'Financiering aanvragen',
  '34f8f9968ec0d7f91e558e906a7de982': 'Aangevraagd',
  dc1650cfbadb05f8fb6357692e48ee71: 'Financiering aangevraagd',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Ingehouden',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Ingehouden bedrag',
  a476316a89541d1aa71d5b5366874e13: 'Ingehouden bedrag',
  '328f6585a0e78a96deefc0661474248f': 'Nogmaals proberen',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Verkopen',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Zoeken op factuurnummer',
  '3215999493db188b35242dc4f4fd8fb5':
    'Zie programma-informatie voor meer informatie over terugbetalingen.',
  '69f43575c3a51e94605eb75b75f7b563': 'Geselecteerd',
  '56a1c7c4a02e9985745969563a80799f': 'Rekening verkoper voor terugbetaling',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Verzenden',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Verzonden',
  '95d54926719f1071b2fa640f1ba23d3e': 'Afgewikkeld',
  e243f36392a7f264d830f04f21aa8972: 'Vereffende facturen',
  b548572d4123e3a569d27e4676664dad: 'Deelbetaling',
  '6e09ada7e33833d259370b0cc900f082': 'Deelbetalingsbedrag',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Aftrek voor deelbetaling',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Deelbetalingen',
  '46bbc145b1f85c082950c768ade012f6': 'Deelbetalingsbedrag',
  e66e6d9768e8f489c05951ee70ede350:
    'Er is iets misgegaan. Mogelijk zijn niet alle functies van het dashboard beschikbaar. Probeer het nogmaals of neem contact op met Ondersteuning voor hulp.',
  cd969f183dc619b5716067cfda39c055: 'Bankcode',
  '17939071126e9e41921d79b837f63297': 'Leveranciersnaam',
  '750d4460b260343039f9bc71b66a231c': 'Leverancier is insolvent verklaard',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Leverancier is nog niet voor programma geconfigureerd',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Ondersteuning',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Voorwaarden geaccepteerd door',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Servicevoorwaarden',
  '1294cf929ec55581954a79948592dda9':
    'De datum waarop de financiering van het Cash-programma geactiveerd werd.',
  f51144e4468390a486d41b4db66fc243: 'Het bestand kon niet gedownload worden.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Het maximale aantal dagen waarop betaling van uw koper wordt verwacht, zoals goedgekeurd door HSBC.',
  d438da584c455e727642a303917e690d:
    'Het resterende kredietbedrag dat kan gebruikt worden, dat wil zeggen, het verschil tussen de Kredietlimiet en het Gebruikte Krediet.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Het totale bedrag aan facturen dat momenteel voor dit programma gefinancierd wordt. Dit bedrag wordt berekend op basis van de kredietlimiet en het huidige bedrag aan gefinancierde facturen.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Het totale bedrag aan facturen dat voor dit programma gefinancierd kan worden. Dit bedrag wordt berekend op basis van de kredietlimiet en het huidige bedrag aan gefinancierde facturen.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Het totale bedrag dat ingehouden werd voordat de factuur betaald werd.',
  '13f271ac486faca89c1944226ba0efff':
    'De totale nominale waarde van de geïmporteerde facturen.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'De totale nominale waarde van facturen die naar een vaste status gegaan zijn omdat ze helemaal geïnd zijn.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'De totale nominale waarde van de gefinancierde facturen.',
  '565c255165bed2ff2640d0e42369c62c':
    'De totale waarde van facturen die niet in aanmerking komen voor financiering.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Dit account is niet actief ingeschreven in Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Dit wordt meestal op de volgende werkdag bijgewerkt. Stel een ondersteuningsticket op als u de huidige saldi wilt weten.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Deze waarde weerspiegelt uw meest recente deelbetalingensaldo inclusief eventuele rente.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Samen met',
  '603e4b3b3ac7b888a06548d14435bc25': 'Te veel kapitaalverwateringen',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Totaal',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Totaal geïnde bedrag',
  f786c8a1095bdf155841d510bb53142a: 'Totale rente op achterstallige schulden.',
  '4f25d1096076868bc590da9f8a26cf55': 'Totale limiet',
  '230bd56af0acab75194f58b46a763535':
    'Totaalbedrag dat is geïnd van de betaling(en) van de inkoper.',
  '8f78461d739b4d6d46f961f352000cec': 'Totaalbedrag dat bij u geïnd is.',
  b62dd565f60dc6172605d8db223510b9:
    'Totaalbedrag dat is geïnd via de overboeking van de inkoper of de verkoper en/of de terugvordering, inclusief eventuele vertragingsrente op achterstallige schulden.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Totaal gefinancierd bedrag op basis van uw verkopen.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Totaal niet-gefinancierd bedrag, volgens het door HSBC goedgekeurde vooruitbetalingspercentage.',
  d6c89215b3fd6857262efc2039d78dec:
    'Totaalbedrag van de volledig geïnde, gefinancierde facturen.',
  a069138e83a8b4cb7e81145689155826:
    'Totaalbedrag aan rente op achterstallige schulden die uitstaan of nog betaald moeten worden.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Totaalbedrag van de verschuldigde achterstallige kosten. De boetes lopen dagelijks op.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Totaalbedrag aan betaalde achterstallige kosten.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Totaalbedrag aan kosten op achterstallige schulden die uitstaan of nog betaald moeten worden.',
  d649fb9a0971deaa127067341f59be91:
    'Totaalbedrag dat is teruggevorderd van inhoudingen op andere facturen.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Totaalbedrag dat nog geïnd of terugbetaald moet worden.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Totaal te innen bedrag',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'De totale nominale waarde van facturen die niet in aanmerking komen voor financiering.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Totale nominale waarde van facturen verzonden naar de inkoper(s).',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Totaalbedrag dat is geïnd via de overboeking van de inkoper of de verkoper en/of de terugvordering.',
  d4867ee77e79ded226c07918b9393715:
    'Totale rente op achterstallige schulden betaald.',
  de4c28160afe1ab48bcf83be49bbb196: 'Totale limiet',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Totaal uitstaand bedrag',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Totaal uitstaand gefinancierd bedrag en eventuele rente op achterstallige schulden die door u afgelost moeten worden. Dit zou te wijten kunnen zijn aan het feit dat een of meer gefinancierde facturen achterstallig zijn of dat uw koper een of meer van uw facturen afwijst nadat ze gefinancierd zijn.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Veelgestelde vragen over het Tradeshift Cash-programma',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Transactie-informatie',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Kan factuur-csv niet downloaden. Neem contact op met Ondersteuning',
  c8415d81378085ba3933015b8f5fc768:
    'Kan Overeenkomst niet ophalen Neem contact op met Ondersteuning',
  '64580a198c861aa0226c91b28f8418aa': 'Niet beschikbaar om te verkopen',
  '5d7f86753376a21d28b5c1ad07c7d149':
    "Onbetaalde facturen na deze datum worden beschouwd als 'achterstallig' en hierover wordt daarna dagelijks vertragingsrente opgebouwd.",
  '9d632e72031eea3767881d6c3adcfe54': 'Limiet gebruikt krediet.',
  e871a6882109bd7ef07827882236c8db: 'Overeenkomst bekijken',
  '2ac548c28faaa41283f9d6b7606a4844': 'Nietig verklaard',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Welkom bij Cash!',
  '78eb58937961a18929a623397b544a01':
    'U kunt het verschuldigde aflossingssaldo verrekenen door een directe overschrijving op de aflossingsrekening (hierboven vermeld).',
  f347abf54f65274a614a9ba7515dfa98:
    'In de Cash-app kunt u al uw vroegtijdige betalingen op één plek volgen',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Uw Cash-programma is actief!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Uw Cash-programma is niet actief',
  '6e6e06469dd953a124d718cd2307e32c':
    'Uw deelname aan het Cash-programma is sinds {date} niet actief geweest. Neem contact op met <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'een gefinancierde factuur die vóór het verstrijken van het einde van de coulancetermijn door de koper wordt afgewezen (bouwt dagelijks rente op na de dag van afwijzing)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'een gefinancierde factuur die na het verstrijken van de coulancetermijn nog niet betaald is (bouwt daarna dagelijks rente op); en/of,',
  '33e67e73d7f54aaee31e6040f873be79': 'toegepast op inhoudingen',
  a9798a3e063111de4d4768c363bdc267: 'toegepast op facturen',
  a5be13d3bfec5fc92521a841009db3c7: 'bankrekeningnummer',
  '736c1e36ac779a73ff4fddd19d82008c': 'dagelijkse rentevoet',
  ab53acde68c117f8fbfff6a8901de083: 'dagelijkse vertragingsrente',
  '4c584eabd4260d69914ac993cc4db5d2': 'dagen',
  '047dbee224da5fb4ecfc21268ffd3115': 'vervaldatum',
  dd051262964c80130a2e9023ff933d89: 'vroegtijdige betaling',
  bc55e8a10310a96eeda133ddf159303d: 'verwachte vervaldatum',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'voor vroege betalingen',
  '932d9bd46daba4d09f361717c0ec03ab': 'gefinancierd bedrag',
  '5e82566144af4903b40303fc3d1c799e': 'financieringsdatum',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'Respijtperiode',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'vervaltijd betaling',
  '2db524546615d120af0adf844a6e1bd7': 'van',
  '99c2f4783bb6821d84ad17a22f556813': 'van gefinancierd',
  '7555b72d3b4ffe69558b68b3cb86b954': 'op */*',
  c138be463a457afe89e5ecfefc6a3dc9: "uitstaande creditnota's",
  '37f59e13afd5455cf130060ed55f4976': 'banknummer',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'looptijd',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'naar de volgende bankrekening:',
  cf4edd5f38054a752994e3242a1f3807: 'totaalbedrag',
  '3c9f634310f5e3de42b79ee83d20923c': 'totaal kosten',
  '573b1b7930d8030d1669945f2c34992a': 'overgemaakt naar uw bank',
  ea0cb39e8c47c1bcafa2a9e737db4cdf:
    'wordt afgetrokken van toekomstige betalingen',
};
