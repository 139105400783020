export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Justeret på grund af valuta eller helligdage)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Gebyr- og betalingsdatoer er vejledende og kan ikke garanteres.',
  fa090ada546b5deb33cdead006822e26:
    '*Gebyr- og betalingsdatoer er vejledende og kan ikke garanteres.',
  '52fce318164dc60017ca67121172aca9': 'Accepteret',
  '615a093396ed9c6f52cd7ece7217608e': 'Kontojustering',
  '79dd431d35b370210a3dbeb1bb653d43': 'Kontonavn',
  '8c66a974a95769447407c061a36d0824': 'Kontonummer',
  '6a810f9427275516cc7ac687ea182f27': 'Påløbne gældsrenter',
  '21df2d8134802290caddc9a8070e9d94': 'Påløbne forfaldne gebyrer',
  '7377dcc6989501c8299b7ccf697b731e': 'Påløbne gældsrenter',
  dab81136f1c6e799c5626826349a8dcf: 'Påløbne forfaldne gebyrer',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Alle',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Alle købere',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Alle valutaer',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Alle fakturaer',
  d7db3397d1ee87530b253bc69ebca041: 'Beløb',
  c7586d1e153c74713afd948b3048d03d:
    'Beløb betalt til dig efter fradrag af renter og eventuelle tilbagebetalinger på det finansierede beløb.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Tilbagebetalt beløb mod udestående tilbagebetalinger på andre fakturaer.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Beløb, der skal betales',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Alle overskydende midler vil blive overført til dig.',
  de9800ff635c316938aece381c98b094:
    'Eventuelle udestående delbetalinger og renter kan fratrækkes fra købsprisen eller andre opkrævninger. Hvis vi ikke kan opkræve på disse måder, kan vi bede dig om at betale beløbet direkte til køberen.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Godkendt',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Stil et spørgsmål',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Tilgængelig kreditgrænse for ny finansiering.',
  '85a6c2f454268a7b3c4156c070abc517': 'Afventer godkendelse',
  '99fb0198659b8076326d69a42670433b':
    'Saldi opdateres typisk den næste arbejdsdag.',
  '0448c27d5574d95fc844e50416fc13a3': 'Bundt',
  '84b730c6e665f3a83a96a8511b4f63db': 'Bundtoplysninger',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Købers afdeling',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Vis afviste fakturaer',
  cdb5b8f6940144b32dc7f27b7700719a: 'Køberindbetaling',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Køber markerede som betalt',
  e7094a08ea031332f2a7e7f94b214174: 'Køberindbetaling',
  b8b64034058f45fe990f8377c7990c6b: 'Indbetalingskonto for køber',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Køber:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Købere betaler her for fakturaer. Du betaler her for delbetalinger',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Det forfaldne tilbagebetalingsbeløb trækkes som standard fra den eller de næste finansierede fakturaer.',
  cb1a025651df74878d8d5af1b99991df: 'Annulleret',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Oplysninger om kontantprogram',
  '6fd54168f061cd9f269dbcbb29415486': 'Gebyrbeløb',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Klik for at kopiere',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Luk',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Lukket',
  a150472168406890d84a6a5c3c8a894c: 'Indsamlet',
  '0743742edda54d26e47f9959ca4be4f0': 'Opkrævet gældsrente',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Opkrævede forfaldne gebyrer',
  '0b2194f7621b8a036308dce1647b982d': 'Opkrævet beløb',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Opkrævet gældsrente',
  b1a3b2482cd668f631d724aac2dfc685: 'Opkrævede forfaldne gebyrer',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Opkrævning',
  ba18fa9a0a1da454462f71c6e36139ff: 'Saldo for opkrævning',
  '1103815eae13527faa8d51c83c20d17e': 'Inkassodetaljer',
  '898e15d1fa445c9c37ca795f79029f6d': 'Virksomhedens juridiske navn',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Fortsæt',
  c05df0411bb18c405aaa68153432bf10: 'Kredit- og fakturagrænse opdateret',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Tilgængelig kredit',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Kreditgrænse',
  d23014cf5a5e7f8c6440b96692831aa8: 'Kreditnota',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Kredit anvendt',
  '207df9f33356e378a1ff2ef7b62becc7': 'Tilgængelig kredit',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Kreditgrænse',
  '208d5141343637870387b27db87ccf05': 'Kreditgrænsen er overskredet',
  '0e27340c6eb226df1076c4f8930d2289': 'Kredit anvendt',
  e15a66f111bc879c77f24943a94d19e7: 'Valuta understøttes ikke',
  b1e5986f972b7599559dea22cfeae8fb: 'Valuta:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Daglig rabat',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Daglig rabatsats',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Daglig forfaldssats',
  '9e268b45f182ad1851f03539be6ab29c': 'Daglig rabatsats',
  c2191b6160c46ed7a4b05cc0279158f3: 'Daglig forfaldssats',
  '106202929cf48a3de3f20b3255365915': 'Dato',
  '2eca727eade526acc715c2e5605ebc7e':
    'Dato, hvor det finansierede beløb blev udlignet fuldt ud.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Datoen, hvor den 30 dages henstandsperiode slutter.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Dato, hvor fakturaen blev afvist af din køber.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Dage forfalden',
  '1e1e68c46e0d719364bd445b1d605144': 'Dage forfalden',
  bd50c01a22cae65be4d588abcae65a90: 'Fradrag',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Fradrag',
  d706035ec5f053ca33b234ddec228ab2: 'Beskrivelse',
  a39bf2f30994908fe95498524b423d43: 'Detaljer',
  '193d60da437b58b24ed69a5683c1ffcf': 'Nedskrevet',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Nedskrivningsbeløb',
  d07e29091188f3b0871dc7712eee7255: 'Nedskrivningstype',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Direkte sælgerindbetaling',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Direkte sælgerindbetaling',
  afc1c7dfc9315e856f8da52e2450182b: 'Rabat',
  '26e7eb298420701d5a03c53fe096cc0b': 'Bestridt',
  '43cca9c1b76ebf869c7bf22fff271689': 'Dokumentstatusfiltre',
  ce73b429141cda25571c88d9b0c78224: 'Dokumentstatus',
  '8475be8bb8249000118af931ae75c373': 'Download rapport',
  '90567e36a540e51806d7790eb195b1d5': 'Download alle fakturaer',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Kladde',
  '72015a772310560b755bd03132a543b2': 'Forfaldsdato',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Forfaldsdato',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Forfaldsdato snart',
  '6def6918bd02ae5117332f4262cecb52': 'Kvalificeret',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Tilmeldingsstatus',
  cb339c5c02cfb087fc8d393f917379df: 'Fejl ved indlæsning af dashboard.',
  e9994b26424cb4bce5059d05e094df8d: 'Anslået gebyr',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Ekskluderet land',
  b5cb31ac9c7d1871e9075efc1f271536: 'GEB',
  fd9d568c402dd04e0715e877d76f7da5: 'Fakturaens pålydende værdi.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Lykkedes ikke',
  '817f1fe84c2d87894bc374681814abbf': 'Kunne ikke indlæse {title} -',
  '7932079ce6ea928bfa77efd91578312d': 'Gebyrer',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filtrer efter valuta for at få vist et sammendrag over totaler.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'Forventet betalingsdato er for langt væk',
  e5af505b37031920b08edb84df19b5ec:
    'Opkrævningsdato for fuldt finansieret beløb',
  a4e77d51ea7969a9e1fd98d59f99c158: 'Forfaldsdato for fuldt finansieret beløb',
  '6dd341953c8c405c70878a457622cf55': 'Finansieret',
  '42e20c6bb1dc82fbdbd889f769850320': 'Finansieret beløb opkrævet',
  cab921680af6ff282ce37398b0b231d4: 'Finansieret beløb',
  fc0347ceea208298049efd79021475a2: 'Finansieret beløb opkrævet',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Finansieringsdato',
  '55d900eb37f97b684c0bd5821f161216': 'Finansierede fakturaer',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Finansiering',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Finansieringsundtagelse',
  '5ca89ce217f3bb07293db00a42c10200':
    'Årsag(er) til ikke berettiget finansiering',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Finansieringsoversigt',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Vellykket finansieringsforespørgsel',
  d5ec85821723434aba05986044ebdcdd: 'Finansieringsstatus',
  '3447158e3ace443dbe27556e07c88639': 'Finansieringsstatusfilter',
  '022848540bf0e01453be6cf79843aa67': 'Finansieringsperiode',
  b4a59826d5bfc276fa88bd00abac615a: 'Finansieringsdetaljer',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Finansieringsperiode',
  e74f45b44aadcdd084b5cbdd3560f515: 'Henstandsslutdato',
  '5434cf75f987ef76dbdc544fa1e32096': 'Sådan betaler du',
  cb9f9412bd12122bb088124afccf738b: 'Hvordan tilbagebetaler jeg delbetalinger?',
  '9ad57f0b683fecb097c09bedebf68a45':
    'Sådan afvikler du en tilbagebetaling med tidsfrist:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'FAK',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Hvis du mener dette er en fejl, bedes du kontakte os på <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Vigtige oplysninger',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'I gang',
  '313b15a4fdfa2368015e2eb67416af2a': 'Inaktivt program',
  '7b6a003ceca7fe7fec85daae919e800b': 'Uegnet',
  '6cf0784c1ac918c22cd402e0cb558749': 'Ukvalificeret forespørgsel',
  '65cb9c651d2d9fb8941050a08add03c3': 'Uegnet til tidlige betalinger',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Uberettigede fakturaer',
  c555faa7b0b745638bb3ea785ee10137:
    'De viste oplysninger blev sidst opdateret den',
  b7311d63c44566a8562d92720cffed2e:
    'Utilstrækkeligt inkassobeløb til at fakturere',
  fb07cb1753537f6969513f2d5fdb7993: 'Rentebeløb',
  '1938e208f65e66474dc4148c3fa7da08': 'Rente fratrukket',
  '42094e40339ac5c0957a980062c47659': 'Påløbne renter på delbetaling',
  d9580a19710f07e8c72c215b1270ca57:
    'Renter opkrævet af HSBC for det finansierede beløb.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Rente fratrukket',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Renter på forfaldne fakturaer',
  '47a565198d287d0864339c077819a361':
    'Der vil fortsat løbe renter på den forfaldne gæld, indtil de enten fratrækkes fra den næste betaling til dig, eller vi modtager en direkte indbetaling.',
  '3c4be400f728da4526de626653d56ccc': 'Faktura',
  '70546e9227f592c24d3c491756cb901d': 'Fakturanummer',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Fakturabeløb',
  '6b9515d101794c31e38ae60d092fdc03': 'Fakturadato',
  '2548c6a52ce9e1bffef7aad14530f490': 'Faktura betalt',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Oversigt over fakturafinansiering',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Fakturagrænse',
  '734ed127b3d7226c75e8553c0711367a': 'Anvendt fakturagrænse',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Fakturagrænse i alt',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Fakturanummer',
  da2db17c9b22f995d6673a754d998fc8: 'Forfalden faktura',
  d729bd1bdced62265cd4ecd7131ef94e: 'Faktura afvist',
  e14208d4378a3378be8b27eb15984fc6: 'Faktura udlignet',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Fakturastatus',
  '54d8df173250a197fab1644a0d0a772a': 'Fakturabeløb',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Faktura kan ikke finansieres på nuværende tidspunkt. Se Årsag(er) til ikke berettiget finansiering for at få yderligere oplysninger.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9': 'Fakturavaluta matcher ikke land',
  d0da9e977c8b309f93ee378de34fb251: 'Udstedelsesdato',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Fakturadato før tidligst berettigede dato',
  ad0ab15468b719694f3193d0649ad9eb:
    'Fakturadato er efter virksomhedens annulleringdato.',
  fc9e80713aebb3d820d888f0755fedde: 'Faktura har ikke en abonnerende køber',
  e17cbec04e7786361eff9da629f09bb7: 'Faktura har en fremtidig udstedelsesdato.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Faktura er blevet annulleret.',
  '89f842841b4ae213259b94a5411453f2': 'Faktura er blevet nedskrevet',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'Faktura er blevet finansieret under kontantprogrammet.',
  ab5303505d045929abb1256607ca0d4d:
    'Faktura er blevet betalt fuldt ud. Der kræves ikke yderligere betalinger.',
  '3e159746cbdfda07b2f45750941e26f6':
    'Faktura er blevet anmodet om mhp. finansiering.',
  e8a7d355e6efb25006f6e4c50fde715a: 'Faktura har ugyldig dokumentstatus.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Faktura er ikke blevet udlignet fuldstændigt, og henstandsperioden er slut',
  d87ca92704bcfb34fa4ac08a4d24147a: 'Faktura er i øjeblikket ikke berettiget.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Faktura mangler slutdato eller endelig slutdato',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Faktura er for tæt på forventet betalingsdato.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Faktura er usædvanlig',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c':
    'Faktura er allerede markeret som betalt.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Faktura kan være en dublet.',
  b1f771da19efea2f0fa6a542823231b9: 'Faktura kun delvis berettiget',
  '4217948ca113ee4158d5dd4c07d71624': 'Faktura afregnet med delbetaling',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Fakturaudløb er ikke inden for acceptabelt område',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Betalingstid for faktura er for lang',
  '90d9ac20dda384e9c07554d29b143bef': 'Fakturaværdi overskrider grænsen',
  '4b36f0be327fdca8d54126cd57095a13': 'Fakturanummer',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Fakturaer',
  '8089283aad9294aadc2bde165ccc78c1': 'Udstedelsesdato',
  '3a4123f9c604369ab18781f7492f9293': 'Udstedelsesdato',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Sidste udbetalingsdato',
  a94bc4d4df1d56c16e25493e22684412: 'Grænse tilgængelig',
  '92f97c843d499a1559828ba0a9134e24': 'Begrænsning anvendt',
  b5dd8e130d88698834ee00464f01cea4: 'Grænse tilgængelig',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Begrænsning anvendt',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Indlæser...',
  '24cc5627dea65e22f9279be9522a06e2': 'Lånebeløb',
  bd24bcedd066a27749016a07cb729a32: 'Markér som afregnet',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Modningsdato',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Startdato for medlemskab',
  d26429adfb846303cbd889879b23e1ba:
    'Indtægter opkrævet fra enten købers betalinger, sælgers indbetalinger og/eller fradrag før og/eller efter henstandsperioden.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Betalt nettobeløb',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Betalt nettobeløb',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nyt beløb',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Næste arbejdsdag',
  '325c48f099844279c0cf48584de42e64': 'Ingen nylige aktiviteter',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Ingen',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Ikke aktivt tilmeldt Cach',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Ikke nok fakturahistorik',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Ikke nok fakturaer i de foregående måneder',
  c9e2ce8da559465a8596317c35e8f3a4: 'Ikke nok betalte fakturaer',
  e6f890deafa579eb0c24603c3101c1c7:
    'Bemærk, at saldi typisk opdateres den næste arbejdsdag. Opret en supportanmodning, hvis du har brug for at kende den aktuelle position.',
  '83070a059f10db1515ac922bf3433158': 'Antal dage efter henstandsslutdato.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'En rapport tilgængelig for download',
  '2778717f6acbeb1edaac6a2656b478f3': 'Åbn',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Andet gebyr',
  fad92e97e692758c0d0a9b5730663894: 'Udestående gældsrente',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Udestående finansieret beløb',
  '9eab1baddf2537312408088fc54f21d5': 'Udestående forfaldne gebyrer',
  bfba12a2c234532c0af24a4ad6831ab6: 'Udestående beløb i alt',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Udestående gældsrente',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Udestående finansieret beløb',
  '2f9a3de6940f818fe0376e4aece59512': 'Udestående forfaldne gebyrer',
  '6f79eea38bff17917740a69116270074': 'Forfalden',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Forfalden saldo',
  '848ac8c39b11a751d697a383303e6109': 'Forfalden gæld',
  '8d1e868665fc24a46f0cf55187f72218':
    "Forfalden gæld indberettes i overensstemmelse med RBI's regler.",
  dc14337c43c60e68f4766cb5dd95bbc2: 'Oversigt',
  '5b885084a5067d7fb4372cef38d8a82d': 'Betalt',
  '830b95d305cdc61a66cdf75ebff1d737': 'Betalt af køber',
  '6444796646c95dbc2cd1cb646e41293d':
    'Del af fakturaens pålydende værdi, som er blevet finansieret i henhold til procentsatsen for forudbetaling, der er godkendt af HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Detaljer om deltagende køber',
  a39314e5897b8baaa51ca296610ec30c: 'Partnervilkår',
  a6acd5f03fda9c8dc607e59d8be08320: 'Godkendelse af betaling er forsinket',
  e6249829dadf637c4092e4a900df529e: 'Betalingen er blevet anfægtet af køberen',
  '07ed468d28d8e8437d8ec64036b96c03': 'Betalingen er afvist',
  '042b8dfb88f7045ca9145bbcccf79200': 'Betaling er i fremtiden',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Betalingsdokument er for gammelt',
  '8755bbc212b541efb6576f39f3f0f51f': 'Betaling tildelt',
  '8744ee10540166dfc39db2a6859a9ce1': 'Beløb til betaling',
  '56133aa371eb0132334e4867479be942': 'Betalingsdetaljer',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Betaling forfalden',
  '2dd7164df606f9b42beefaba031b06f7': 'Betaling modtaget',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Betaling bekræftet af leverandør',
  '661711004f06b0d9601db365679be130': 'Udbetalingsbeløb',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Udbetalingsdato',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Strafferente',
  '0fd036622716f275c3e14c5a14faa65a': 'Afventer',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Afventer nedskrivning',
  '8cf3479829ba47a8a4123895d0088eeb': 'Afventer nedskrivninger',
  '270b56fd05f72407ca0607b6162d0b10': 'Afventende kontakt',
  '4a78218416ca45d97b5633b37ae3f638':
    'Procentsatsen for de daglige gebyrer vil blive beregnet for den fulde finansieringsløbetid.',
  b586a5468c7070bf1717593ea84fa607:
    'Procentsats, som du vil blive opkrævet dagligt på dine udestående finansierede beløb for forfaldne eller finansierede og afviste fakturaer.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Opret en supportanmodning, hvis du har brug for at kende de aktuelle saldi.',
  '34645fa620cae4187f27d9f96c172f26': 'Betal venligst beløbet',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Betal følgende beløb til følgende bankkonto:',
  '01734a7316427fdcdcd4f937cda3802b': 'Forudbetaling',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Forrige beløb',
  '500fc4731dab800a878b33906d9332fb': 'Programinkassekonto',
  '7327113634d1f5f4f68632dceebfbc6f': 'Programoversigt',
  d95ebfb51a485293a134b1038d332a10: 'Program aktiveret den',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Programoplysninger er ikke tilgængelige for dashboard',
  '98feb2c86a81b8caeeaa30966ba34636': 'Programoplysninger',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Betalinger for købsfakturaer, som ikke indkasseres fuldstændigt inden den forventede forfaldsdato, vil generere en delbetaling. Delbetalinger akkumulerer en rente som er 1,5 gange den daglige rabatsats, og det er dit ansvar at tilbagebetale dem begge.',
  '521689b600b8da5b0a111b89dea72126': 'Årsag',
  fe7bd622a1efcec03eb86dabb9395936: 'Modtaget',
  e84203a8833e4d0d92faf89f4107bf11: 'Regrestilbagebetaling',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Refusionsbeløb',
  b0be744ae1f6312f16990bbd92a87aea: 'Refusionens udstedelsesdato',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Refusionsløbetid',
  '654d6dcc141079bc84500fd14ca5087d': 'Tilbagebetalingsbeløb',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Tilbagebetalingsdetaljer',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Tilbagebetalingens udstedelsesdato',
  c0a6a91e7ca80af9de47097b06dc7618: 'Tilbagebetalingsløbetid',
  '4a2875439ab289a068abb9ea3b567bcb': 'Afvist',
  '592b34cdfa2e51826b32a0783e0edce3': 'Afvist af køber',
  '807d35f105b65ab2795c4f8e51795f3c': 'Afvist af leverandør',
  aea4dd208e871d7ca0051b0ec38c3068: 'Afvisningsdato',
  d64ed4934fbf68a8f716467b0adf89ba: 'Afvisningsdato',
  '5a77de3b287a92494be077c197f37581':
    'Resterende finansierede beløb, der endnu ikke er opkrævet eller tilbagebetalt.',
  e442b864bf745a75477ea0ee88bc15c4: 'Tilbagebetalingsdato',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Tilbagebetalingsbeløb pr. henstandsslutdato. Dette ændres ikke for at afspejle eventuelle tilbagebetalinger foretaget efter denne dato, og det omfatter heller ikke forfalden gæld.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Tilbagebetalingsdato',
  dc20cbfb76d220c108241a451bc3faf4:
    'Oplysninger om tilbagebetalingsinstruktioner',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'De skyldige tilbagebetalinger er det samlede udestående beløb, der skal inddrives, som følge af at det beløb, der er betalt af køberen, er mindre end fakturaens pålydende værdi, og/eller køberen afviser fakturaen, efter den er blevet finansieret.',
  '45b60e22719c12291932bf09db0df5cd':
    'De skyldige tilbagebetalinger er det samlede udestående beløb, der skal inddrives, og som stammer fra:',
  ba093b1421452b3afdebbebedaeae552: 'Anmod om finansiering',
  '34f8f9968ec0d7f91e558e906a7de982': 'Anmodet',
  dc1650cfbadb05f8fb6357692e48ee71: 'Anmodet om finansiering',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Tilbageholdt',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Tilbageholdt beløb',
  a476316a89541d1aa71d5b5366874e13: 'Tilbageholdt beløb',
  '328f6585a0e78a96deefc0661474248f': 'Prøv igen',
  '626efcff97ad72e5eb9cc8a602aec8bd': 'DB',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Salg',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Søg efter fakturanummer',
  '3215999493db188b35242dc4f4fd8fb5':
    'Se programoplysninger for yderligere oplysninger om tilbagebetalinger.',
  '69f43575c3a51e94605eb75b75f7b563': 'Valgte',
  '56a1c7c4a02e9985745969563a80799f': 'Leverandørs tilbagebetalingskonto',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Sender',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Sendt',
  '95d54926719f1071b2fa640f1ba23d3e': 'Afregnet',
  e243f36392a7f264d830f04f21aa8972: 'Afregnede fakturaer',
  b548572d4123e3a569d27e4676664dad: 'Delbetaling',
  '6e09ada7e33833d259370b0cc900f082': 'Skyldigt beløb',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Delbetalingsafdrag',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Delbetalinger',
  '46bbc145b1f85c082950c768ade012f6': 'Skyldigt beløb',
  e66e6d9768e8f489c05951ee70ede350:
    'Noget gik galt. Ikke alle funktionerne på dashboardet er tilgængelige. Prøv igen, eller kontakt support for at få hjælp.',
  cd969f183dc619b5716067cfda39c055: 'Registreringsnummer',
  '17939071126e9e41921d79b837f63297': 'Leverandørnavn',
  '750d4460b260343039f9bc71b66a231c': 'Leverandør anses for at være insolvent',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Leverandør er ikke konfigureret på programmet endnu',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Vilkår accepteret af',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Servicevilkår',
  '1294cf929ec55581954a79948592dda9':
    'Den dato, hvor kontantprogramfinansiering blev aktiveret.',
  f51144e4468390a486d41b4db66fc243: 'Filen kan ikke downloades.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Det maksimale antal dage, hvor betalingen forventes af din køber, som godkendt af HSBC.',
  d438da584c455e727642a303917e690d:
    'Det resterende kreditbeløb, der kan anvendes. Dette er forskellen mellem kreditgrænsen og den anvendte kredit.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Det samlede antal fakturaer, der i øjeblikket finansieres for dette program. Dette beløb beregnes på grundlag af kreditgrænsen og det aktuelle beløb af fakturaer, der er finansieret.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Det samlede antal fakturaer, der kan finansieres for dette program. Dette beløb beregnes på grundlag af kreditgrænsen og det aktuelle beløb af fakturaer, der er finansieret.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Det samlede beløb, der er blevet tilbageholdt før afregning af faktura.',
  '13f271ac486faca89c1944226ba0efff':
    'Den samlede pålydende værdi af alle importerede fakturaer.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Den samlede pålydende værdi af fakturaer, der er flyttet til en afregnet status, fordi de er opkrævet fuldt ud.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Den samlede pålydende værdi af de finansierede fakturaer.',
  '565c255165bed2ff2640d0e42369c62c':
    'Den samlede værdi af fakturaer, der ikke er berettiget til finansiering.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Denne konto er ikke aktivt tilmeldt Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Dette opdateres typisk den næste arbejdsdag. Opret en supportbillet, hvis du har brug for at kende de aktuelle saldi.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Denne værdi afspejler din seneste delbetalingssaldo inklusive eventuelle renter.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Sammen med',
  '603e4b3b3ac7b888a06548d14435bc25': 'Listen over nedskrivninger er for lang',
  d356429f9bd0a4a6036707d99bf5e2b2: 'I alt',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Samlet opkrævningsbeløb',
  f786c8a1095bdf155841d510bb53142a: 'Samlede renter af forfalden gæld.',
  '4f25d1096076868bc590da9f8a26cf55': 'Samlet grænse',
  '230bd56af0acab75194f58b46a763535':
    'Samlet beløb opkrævet fra køberbetalinger.',
  '8f78461d739b4d6d46f961f352000cec': 'Samlet beløb opkrævet fra dig.',
  b62dd565f60dc6172605d8db223510b9:
    'Samlet beløb opkrævet gennem køberpengeoverførsel, sælgeroverførsel og/eller clawback, inklusive eventuelle renter af forfalden gæld.',
  '8dfcacc58acbafda8eca3d58c4d8a447': 'Samlet beløb finansieret mod dit salg.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Det samlede beløb, der ikke er finansieret, i henhold til procentsatsen for forudbetaling, der er godkendt af HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Samlet beløb af finansierede fakturaer opkrævet fuldt ud.',
  a069138e83a8b4cb7e81145689155826:
    'Samlet beløb for renter af forfalden gæld, der stadig skal betales eller som er udestående.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Det samlede skyldige beløb for forfaldne afgifter. Der opkræves dagligt straffeomkostninger.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Samlet beløb for forfaldne afgifter, der er betalt.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Samlet beløb for forfaldne udgifter, der skal betales, eller som er udestående.',
  d649fb9a0971deaa127067341f59be91:
    'Samlet beløb, der tilbagebetales af andre fakturafradrag.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Resterende finansieret beløb, der endnu ikke er opkrævet eller tilbagebetalt.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Samlet opkrævningsbeløb',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Samlet pålydende værdi af fakturaer sendt til køberen/køberne.',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Samlet finansieret beløb opkrævet via køberens betaling, sælgers betaling og/eller clawback.',
  d4867ee77e79ded226c07918b9393715:
    'Samlede renter af forfalden gæld indbetalt.',
  de4c28160afe1ab48bcf83be49bbb196: 'Samlet grænse',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Samlet udestående beløb',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Samlet udestående finansieret beløb og eventuelle renter på forfalden gæld, der skal tilbagebetales af dig. Dette kan skyldes, at en eller flere finansierede fakturaer er forfaldne, eller din køber afviser en eller flere af dine fakturaer, efter at de er blevet finansieret.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Ofte stillede spørgsmål til Tradeshift Cash-program',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Transaktionsoplysninger',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Faktura-csv kan ikke downloades. Kontakt Support',
  c8415d81378085ba3933015b8f5fc768: 'Kan ikke hente aftale. Kontakt Support',
  '64580a198c861aa0226c91b28f8418aa': 'Kan ikke sælges',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Ubetalte fakturaer efter denne dato betragtes som "forfaldne", og der påløber derefter morarenter dagligt.',
  '9d632e72031eea3767881d6c3adcfe54': 'Anvendt kreditgrænse.',
  e871a6882109bd7ef07827882236c8db: 'Vis aftale',
  '2ac548c28faaa41283f9d6b7606a4844': 'Gjort ugyldig',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Velkommen til Cash!',
  '78eb58937961a18929a623397b544a01':
    'Du kan afregne forfalden tilbagebetalingssaldo ved at foretage en direkte indbetaling på tilbagebetalingskontoen (angivet ovenfor).',
  f347abf54f65274a614a9ba7515dfa98:
    'Du kan spore alle dine tidlige betalinger på ét sted med Cash-appen',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Dit Cash-program er aktivt!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Dit Cash-program er inaktivt',
  '6e6e06469dd953a124d718cd2307e32c':
    'Din deltagelse i Cash-programmet har ikke været aktiv siden {date}. Kontakt <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'en finansieret faktura, som afvises af køberen inden udløbet af henstandsperioden (der påløber renter dagligt efter afvisningsdagen)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'en finansieret faktura, der ikke er betalt ved udløbet af henstandsperioden (påløbne renter pr. dag), og/eller',
  '33e67e73d7f54aaee31e6040f873be79': 'anvendes på fradrag',
  a9798a3e063111de4d4768c363bdc267: 'anvendes på fakturaer',
  a5be13d3bfec5fc92521a841009db3c7: 'bankkontonummer',
  '736c1e36ac779a73ff4fddd19d82008c': 'daglig rente',
  ab53acde68c117f8fbfff6a8901de083: 'daglig morarentesats',
  '4c584eabd4260d69914ac993cc4db5d2': 'dage',
  '047dbee224da5fb4ecfc21268ffd3115': 'forfaldsdato',
  dd051262964c80130a2e9023ff933d89: 'tidlig betaling',
  bc55e8a10310a96eeda133ddf159303d: 'forventet forfaldsdato',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'for tidlige betalinger',
  '932d9bd46daba4d09f361717c0ec03ab': 'finansieret beløb',
  '5e82566144af4903b40303fc3d1c799e': 'finansieret dato',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'henstandsperiode',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'forfalden betaling',
  '2db524546615d120af0adf844a6e1bd7': 'af',
  '99c2f4783bb6821d84ad17a22f556813': 'af finansieret',
  '7555b72d3b4ffe69558b68b3cb86b954': 'på',
  c138be463a457afe89e5ecfefc6a3dc9: 'udestående kreditnotaer',
  '37f59e13afd5455cf130060ed55f4976': 'registreringsnr.',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'løbetid',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'til følgende bankkonto:',
  cf4edd5f38054a752994e3242a1f3807: 'beløb i alt',
  '3c9f634310f5e3de42b79ee83d20923c': 'gebyr i alt',
  '573b1b7930d8030d1669945f2c34992a': 'overført til din bank',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'vil blive fratrukket kommende betalinger',
};
