export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(ajustat ca urmare a monedei sau a sărbătorilor legale)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Taxele și datele de plată sunt orientative și nu pot fi garantate.',
  fa090ada546b5deb33cdead006822e26:
    '* Taxele și datele de plată sunt orientative și nu pot fi garantate.',
  a60c743772480aaf26a3ee6fba11ba50: 'REGL',
  '52fce318164dc60017ca67121172aca9': 'Acceptat',
  '615a093396ed9c6f52cd7ece7217608e': 'Reglare cont',
  '79dd431d35b370210a3dbeb1bb653d43': 'Nume cont',
  '8c66a974a95769447407c061a36d0824': 'Număr de cont',
  '6a810f9427275516cc7ac687ea182f27': 'Dobândă datorie acumulată',
  '21df2d8134802290caddc9a8070e9d94': 'Taxe restanțe acumulate',
  '7377dcc6989501c8299b7ccf697b731e': 'Dobândă datorie acumulată',
  dab81136f1c6e799c5626826349a8dcf: 'Taxe restanțe acumulate',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Tot',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Toți cumpărătorii',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Toate monedele',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Toate facturile',
  d7db3397d1ee87530b253bc69ebca041: 'Sumă',
  c7586d1e153c74713afd948b3048d03d:
    'Suma plătită către dvs. după deducerea dobânzii și a oricăror rambursări datorate din suma finanțată.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Suma recuperată în schimbul rambursărilor datorate pentru alte facturi.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Sumă de remis',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Orice fonduri suplimentare vă vor fi transferate.',
  de9800ff635c316938aece381c98b094:
    'Orice plăți în timp scurt și dobânzi restante pot fi scăzute din prețul de cumpărare plătibil sau din alte colectări. Dacă nu putem colecta în acest fel, vă putem cere să plătiți suma direct cumpărătorului.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Aprobat',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Adresați o întrebare',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Limită de credit disponibilă pentru finanțări noi.',
  '85a6c2f454268a7b3c4156c070abc517': 'În așteptarea aprobării',
  '99fb0198659b8076326d69a42670433b':
    'Soldurile sunt actualizate de obicei în următoarea zi lucrătoare.',
  '0448c27d5574d95fc844e50416fc13a3': 'Pachet',
  '84b730c6e665f3a83a96a8511b4f63db': 'Detalii pachet',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Sucursală cumpărător',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Cumpărătorul a respins factura',
  cdb5b8f6940144b32dc7f27b7700719a: 'Remitere cumpărător',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Cumpărătorul a marcat ca fiind plătit',
  e7094a08ea031332f2a7e7f94b214174: 'Remitere cumpărător',
  b8b64034058f45fe990f8377c7990c6b: 'Cont remitere cumpărător',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Cumpărător:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Cumpărătorii plătesc aici pentru facturi. Dvs. plătiți aici pentru plăți în timp scurt',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'În mod implicit, rambursarea scadentă este dedusă din următoarele facturi finanțate.',
  '0d1e94883caf8b30026ade5dd5c242ea': 'NC',
  cb1a025651df74878d8d5af1b99991df: 'Anulat',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Informații despre programul Cash',
  '6fd54168f061cd9f269dbcbb29415486': 'Sumă taxă',
  '252982cd07a2c1e75a9cadb781c4428f': '„Clawback”',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Faceți clic pentru copiere',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Închideți',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Inchis',
  a150472168406890d84a6a5c3c8a894c: 'Colectat',
  '0743742edda54d26e47f9959ca4be4f0': 'Dobândă datorie colectată',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Taxe restanțe colectate',
  '0b2194f7621b8a036308dce1647b982d': 'Sumă colectată',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Dobândă datorie colectată',
  b1a3b2482cd668f631d724aac2dfc685: 'Taxe restanțe colectate',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Colectare',
  ba18fa9a0a1da454462f71c6e36139ff: 'Solduri colectate',
  '1103815eae13527faa8d51c83c20d17e': 'Detalii colectare',
  '898e15d1fa445c9c37ca795f79029f6d': 'Nume legal firmă',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Continuați',
  c05df0411bb18c405aaa68153432bf10:
    'Limita de credit și factură a fost actualizată cu',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Credit disponibil',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Limită de credit',
  d23014cf5a5e7f8c6440b96692831aa8: 'Notă de credit',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Credit utilizat',
  '207df9f33356e378a1ff2ef7b62becc7': 'Credit disponibil',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Limită de credit',
  '208d5141343637870387b27db87ccf05': 'Limita de credit a fost depășită',
  '0e27340c6eb226df1076c4f8930d2289': 'Credit utilizat',
  e15a66f111bc879c77f24943a94d19e7: 'Moneda nu este acceptată',
  b1e5986f972b7599559dea22cfeae8fb: 'Monedă:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Reducere zilnică',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Rată reducere zilnică',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Rata de întârziere zilnică',
  '9e268b45f182ad1851f03539be6ab29c': 'Rată reducere zilnică',
  c2191b6160c46ed7a4b05cc0279158f3: 'Rata de întârziere zilnică',
  e2d9ef555d350e16239f4dfc92842fdc: 'Tablou de bord',
  '106202929cf48a3de3f20b3255365915': 'Dată',
  '2eca727eade526acc715c2e5605ebc7e':
    'Data la care suma finanțată a fost achitată integral.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Data la care se încheie perioada de grație de 30 zile.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Data la care factura a fost respinsă de cumpărătorul dvs.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Zile de întârziere',
  '1e1e68c46e0d719364bd445b1d605144': 'Zile de întârziere',
  bd50c01a22cae65be4d588abcae65a90: 'Deducere',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Deduceri',
  d706035ec5f053ca33b234ddec228ab2: 'Descriere',
  a39bf2f30994908fe95498524b423d43: 'Detalii',
  '193d60da437b58b24ed69a5683c1ffcf': 'Diluat',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Sumă diluare',
  d07e29091188f3b0871dc7712eee7255: 'Tip de diluare',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Remitere vânzător direct',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Remitere vânzător direct',
  afc1c7dfc9315e856f8da52e2450182b: 'Reducere',
  '26e7eb298420701d5a03c53fe096cc0b': 'Reclamat',
  '43cca9c1b76ebf869c7bf22fff271689': 'Filtre stare document',
  ce73b429141cda25571c88d9b0c78224: 'Stare document',
  af13cc2ad0a7337c45420f75b7d5a668: 'Descărcați',
  '8475be8bb8249000118af931ae75c373': 'Descărcare raport',
  '90567e36a540e51806d7790eb195b1d5': 'Descărcați toate facturile',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Ciornă',
  '72015a772310560b755bd03132a543b2': 'Dată scadentă',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Data scadenței',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Data scadentă este prea devreme',
  '6def6918bd02ae5117332f4262cecb52': 'Eligibil',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Stare înscriere',
  cb339c5c02cfb087fc8d393f917379df: 'Eroare la încărcarea tabloului de bord.',
  e9994b26424cb4bce5059d05e094df8d: 'Taxa estimată',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Țară exclusă',
  b5cb31ac9c7d1871e9075efc1f271536: 'TAXĂ',
  fd9d568c402dd04e0715e877d76f7da5: 'Valoarea nominală a facturii.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Nu a reușit',
  '817f1fe84c2d87894bc374681814abbf': '{title} nu a putut fi încărcat -',
  '7932079ce6ea928bfa77efd91578312d': 'Taxe',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filtrați după monedă pentru a vizualiza totalurile rezumate.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'Data prognozată pentru plată este prea lungă',
  e5af505b37031920b08edb84df19b5ec:
    'Data colectării complete a sumei finanțate',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Data colectării complete a sumei finanțate',
  '6dd341953c8c405c70878a457622cf55': 'Finanțat',
  '42e20c6bb1dc82fbdbd889f769850320': 'Sumă finanțată colectată',
  cab921680af6ff282ce37398b0b231d4: 'Sumă finanțată',
  fc0347ceea208298049efd79021475a2: 'Sumă finanțată colectată',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Data finanțării',
  '55d900eb37f97b684c0bd5821f161216': 'Facturi finanțate',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Finanțare',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Excepție finanțare',
  '5ca89ce217f3bb07293db00a42c10200':
    'Motiv (motive) ineligibilitate finanțare',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Prezentare generală finanțare',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Solicitare de finanțare reușită',
  d5ec85821723434aba05986044ebdcdd: 'Stare finanțare',
  '3447158e3ace443dbe27556e07c88639': 'Filtre stare finanțare',
  '022848540bf0e01453be6cf79843aa67': 'Scadență finanțare',
  b4a59826d5bfc276fa88bd00abac615a: 'Detalii finanțare',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Scadență finanțare',
  e74f45b44aadcdd084b5cbdd3560f515: 'Data de încheiere a perioadei de grație',
  '5434cf75f987ef76dbdc544fa1e32096': 'Mod de plată',
  cb9f9412bd12122bb088124afccf738b: 'Cum puteți replăti plățile în timp scurt?',
  '9ad57f0b683fecb097c09bedebf68a45':
    'Cum puteți rezolva scadența rambursării:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'FAC',
  '7428e52e7eecc9d7211ad241a469cd65':
    'În cazul în care credeți că aceasta este o eroare, contactați <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Informații importante',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'În progres',
  '313b15a4fdfa2368015e2eb67416af2a': 'Program inactiv',
  '7b6a003ceca7fe7fec85daae919e800b': 'Neeligibil',
  '6cf0784c1ac918c22cd402e0cb558749': 'Motiv neeligibil',
  '65cb9c651d2d9fb8941050a08add03c3': 'Neeligibil pentru plăți în avans',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Facturi neeligibile',
  c555faa7b0b745638bb3ea785ee10137:
    'Informațiile afișate aut fost actualizate ultima dată pe',
  b7311d63c44566a8562d92720cffed2e:
    'Colectări insuficiente pentru ratele facturii',
  fb07cb1753537f6969513f2d5fdb7993: 'Sumă dobândă',
  '1938e208f65e66474dc4148c3fa7da08': 'Dobândă dedusă',
  '42094e40339ac5c0957a980062c47659':
    'Dobândă acumulată la plata în timp scurt',
  d9580a19710f07e8c72c215b1270ca57:
    'Dobânda percepută de HSBC pentru suma finanțată.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Dobândă dedusă',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Dobândă acumulată pentru factura scadentă',
  '47a565198d287d0864339c077819a361':
    'Dobânda se va acumula datoriei restante, până când aceasta va fi dedusă din următoarea plată către dumneavoastră sau vom primi o remitere directă.',
  '3c4be400f728da4526de626653d56ccc': 'Factură',
  '70546e9227f592c24d3c491756cb901d': 'Factură #',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Sumă factură',
  '6b9515d101794c31e38ae60d092fdc03': 'Dată factură',
  '2548c6a52ce9e1bffef7aad14530f490': 'Factură finanțată',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Prezentare generală finanțare factură',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Limită factură',
  '734ed127b3d7226c75e8553c0711367a': 'Limită de utilizare factură',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Limită totală factură',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Număr factură',
  da2db17c9b22f995d6673a754d998fc8: 'Factură cu scadența depășită',
  d729bd1bdced62265cd4ecd7131ef94e: 'Factură respinsă',
  e14208d4378a3378be8b27eb15984fc6: 'Factură decontată',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Stare factură',
  '54d8df173250a197fab1644a0d0a772a': 'Sumă factură',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Factura nu poate fi finanțată în acest moment. Consultați motivele de neeligibilitate a finanțării pentru mai multe informații.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'Moneda facturii nu se potrivește cu țara',
  d0da9e977c8b309f93ee378de34fb251: 'Data facturii',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Data facturii înainte de prima dată eligibilă',
  ad0ab15468b719694f3193d0649ad9eb:
    'Data facturii este ulterioară datei anulării facilității.',
  fc9e80713aebb3d820d888f0755fedde: 'Factura nu are un cumpărător abonat',
  e17cbec04e7786361eff9da629f09bb7: 'Factura are o dată ulterioară emiterii.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Factura a fost anulată.',
  '89f842841b4ae213259b94a5411453f2': 'Factura a fost diluată.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'Factura a fost finanțată în cadrul programului Cash.',
  ab5303505d045929abb1256607ca0d4d:
    'Factura a fost plătită integral. Nu sunt necesare alte plăți.',
  '3e159746cbdfda07b2f45750941e26f6':
    'Factura a fost solicitată pentru finanțare.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'Factura are statutul de document neeligibil.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Factura nu a fost decontată în întregime și a trecut de sfârșitul perioadei de grație',
  d87ca92704bcfb34fa4ac08a4d24147a: 'Factura este neeligibilă momentan.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Factura nu conține data de încheiere sau data de încheiere finală',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Factura este prea apropiată pentru anticiparea datei plății.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Factura este neobișnuită',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c':
    'Factura este marcată ca fiind deja plătită.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Factura poate fi un duplicat.',
  b1f771da19efea2f0fa6a542823231b9: 'Factură eligibilă doar parțial',
  '4217948ca113ee4158d5dd4c07d71624':
    'Factură decontată printr-o plată în timp scurt',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Scadența facturii nu se încadrează în intervalul acceptabil',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Scadență factură prea lungă',
  '90d9ac20dda384e9c07554d29b143bef': 'Valoarea facturii depășește limita',
  '4b36f0be327fdca8d54126cd57095a13': 'Număr factură/RA',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Facturi',
  '8089283aad9294aadc2bde165ccc78c1': 'Data eliberării',
  '3a4123f9c604369ab18781f7492f9293': 'Data eliberării',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Ultima dată de plată',
  a94bc4d4df1d56c16e25493e22684412: 'Limită disponibilă',
  '92f97c843d499a1559828ba0a9134e24': 'Limită în uz',
  b5dd8e130d88698834ee00464f01cea4: 'Limită disponibilă',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Limită în uz',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Se încarcă ...',
  '24cc5627dea65e22f9279be9522a06e2': 'Sumă împrumut',
  bd24bcedd066a27749016a07cb729a32: 'Marcat ca decontat',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Data scadenței',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Data de început a apartenenței',
  d26429adfb846303cbd889879b23e1ba:
    'Sumele colectate din plățile cumpărătorilor, remiterile vânzătorilor și/sau deducerile de înainte și/sau după perioada de grație.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Suma netă plătită',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Suma netă plătită',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Sumă nouă',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Următoarea zi lucrătoare',
  '325c48f099844279c0cf48584de42e64': 'Nicio activitate recentă',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Nimic',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Nu este înscris activ în numerar',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Istoric facturi insuficient',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Nu există suficiente facturi în lunile anterioare',
  c9e2ce8da559465a8596317c35e8f3a4: 'Nu există suficiente facturi plătite',
  e6f890deafa579eb0c24603c3101c1c7:
    'Rețineți că soldurile sunt actualizate de obicei în următoarea zi lucrătoare. Creați un tichet de asistență dacă aveți nevoie să aflați poziția curentă.',
  '83070a059f10db1515ac922bf3433158':
    'Numărul de zile după data de încheiere a perioadei de grație.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Un raport disponibil pentru descărcare',
  '2778717f6acbeb1edaac6a2656b478f3': 'Deschideți',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Alte taxe',
  fad92e97e692758c0d0a9b5730663894: 'Dobândă datorie restantă',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Sumă finanțată restantă',
  '9eab1baddf2537312408088fc54f21d5': 'Taxe cu scadența depășită restante',
  bfba12a2c234532c0af24a4ad6831ab6: 'Sumă totală restantă',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Dobândă datorie restantă',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Sumă finanțată restantă',
  '2f9a3de6940f818fe0376e4aece59512': 'Taxe cu scadența depășită restante',
  '6f79eea38bff17917740a69116270074': 'Scadență depășită',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Solduri cu scadența depășită',
  '848ac8c39b11a751d697a383303e6109': 'Datorie cu scadența depășită',
  '8d1e868665fc24a46f0cf55187f72218':
    'Datoria cu scadența depășită va fi raportată în conformitate cu reglementările RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Prezentare generală',
  '5b885084a5067d7fb4372cef38d8a82d': 'Plătit',
  '830b95d305cdc61a66cdf75ebff1d737': 'Plătită de cumpărător',
  '6444796646c95dbc2cd1cb646e41293d':
    'O parte din valoarea nominală a facturii care a fost finanțată conform procentului de preplată aprobat de HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Detaliile cumpărătorului participant',
  a39314e5897b8baaa51ca296610ec30c: 'Termenii partenerilor',
  a6acd5f03fda9c8dc607e59d8be08320: 'Întârziere aprobare restanță',
  e6249829dadf637c4092e4a900df529e:
    'Restanța a fost reclamată de către cumpărător',
  '07ed468d28d8e8437d8ec64036b96c03': 'Restanța a fost respinsă',
  '042b8dfb88f7045ca9145bbcccf79200': 'Restanța este în viitor',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Restanță prea veche',
  '8755bbc212b541efb6576f39f3f0f51f': 'Plată alocată',
  '8744ee10540166dfc39db2a6859a9ce1': 'Suma de plată',
  '56133aa371eb0132334e4867479be942': 'Detalii plată',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Plată cu scadența depășită',
  '2dd7164df606f9b42beefaba031b06f7': 'Plată primită',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Plată confirmată de vânzător',
  '661711004f06b0d9601db365679be130': 'Sumă plăți',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Data plății',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Dobândă de penalizare',
  '0fd036622716f275c3e14c5a14faa65a': 'În așteptare',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Diluare în așteptare',
  '8cf3479829ba47a8a4123895d0088eeb': 'Diluări în așteptare',
  '270b56fd05f72407ca0607b6162d0b10': 'Conexiune în așteptare',
  '4a78218416ca45d97b5633b37ae3f638':
    'Rata procentuală la care se vor calcula taxele zilnice pentru întreaga scadență a finanțării.',
  b586a5468c7070bf1717593ea84fa607:
    'Rata procentuală la care veți fi taxați zilnic din suma finanțată restantă pentru facturile cu scadența depășită sau finanțate și respinse.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Vă rugăm să creați un tichet de asistență dacă trebuie să cunoașteți soldurile curente.',
  '34645fa620cae4187f27d9f96c172f26': 'Vă rugăm să remiteți suma',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Vă rugăm să remiteți următoarea sumă în următorul cont bancar:',
  '01734a7316427fdcdcd4f937cda3802b': 'Plată în avans',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Sumă anterioară',
  '500fc4731dab800a878b33906d9332fb': 'Cont colectare program',
  '7327113634d1f5f4f68632dceebfbc6f': 'Prezentare generală program',
  d95ebfb51a485293a134b1038d332a10: 'Program activat în data de',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Detaliile de program nu sunt disponibile pentru tabloul de bord',
  '98feb2c86a81b8caeeaa30966ba34636': 'Informații despre program',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Plățile prin factură de cumpărare care nu sunt colectate integral până la data scadentă așteptată generează o plată în timp scurt. Plățile în timp scurt acumulează dobânzi de 1,5 ori rata zilnică de reducere și este responsabilitatea dvs. de a le rambursa pe amândouă.',
  '521689b600b8da5b0a111b89dea72126': 'Motiv',
  fe7bd622a1efcec03eb86dabb9395936: 'Primit',
  e84203a8833e4d0d92faf89f4107bf11: 'Recurs rambursare',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Sumă rambursare',
  b0be744ae1f6312f16990bbd92a87aea: 'Dată emitere rambursare',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Scadență rambursare',
  '654d6dcc141079bc84500fd14ca5087d': 'Sumă rambursare',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Detalii rambursare',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Dată emitere rambursare',
  c0a6a91e7ca80af9de47097b06dc7618: 'Scadență rambursare',
  '4a2875439ab289a068abb9ea3b567bcb': 'Respins',
  '592b34cdfa2e51826b32a0783e0edce3': 'Refuzată de cumpărător',
  '807d35f105b65ab2795c4f8e51795f3c': 'Refuzată de vânzător',
  aea4dd208e871d7ca0051b0ec38c3068: 'Dată respingere',
  d64ed4934fbf68a8f716467b0adf89ba: 'Dată respingere',
  '5a77de3b287a92494be077c197f37581':
    'Suma finanțată care urmează să fie colectată sau rambursată.',
  e442b864bf745a75477ea0ee88bc15c4: 'Scadență rambursare',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Suma rambursată în funcție de data de încheiere a perioadei de grație. Acest lucru nu se modifică pentru a reflecta eventualele rambursări efectuate după această dată și nici nu include datoriile cu scadența depășită.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Scadență rambursare',
  dc20cbfb76d220c108241a451bc3faf4:
    'Informații privind instrucțiunile de rambursare',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Rambursările datorate reprezintă suma restantă totală care trebuie recuperată, care rezultă fie din faptul că suma plătită de cumpărător este mai mică decât valoarea nominală a facturii și/sau cumpărătorul refuză factura după ce aceasta a fost finanțată.',
  '45b60e22719c12291932bf09db0df5cd':
    'Rambursările datorate reprezintă suma restantă totală care trebuie recuperată, rezultată din:',
  ba093b1421452b3afdebbebedaeae552: 'Cerere de finanțare',
  '34f8f9968ec0d7f91e558e906a7de982': 'Solicitată',
  dc1650cfbadb05f8fb6357692e48ee71: 'Solicitat pentru finanțare',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Reținută',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Sumă reținută',
  a476316a89541d1aa71d5b5366874e13: 'Sumă reținută',
  '328f6585a0e78a96deefc0661474248f': 'Reîncercați',
  '626efcff97ad72e5eb9cc8a602aec8bd': 'PTS',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Vânzări',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Căutare după numărul facturii',
  '3215999493db188b35242dc4f4fd8fb5':
    'Consultați informațiile despre program pentru informații suplimentare privind rambursările.',
  '69f43575c3a51e94605eb75b75f7b563': 'Selectat',
  '56a1c7c4a02e9985745969563a80799f': 'Cont de rambursare vânzător',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Se trimite',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Trimis',
  '95d54926719f1071b2fa640f1ba23d3e': 'Decontat',
  e243f36392a7f264d830f04f21aa8972: 'Facturi decontate',
  b548572d4123e3a569d27e4676664dad: 'Plată în timp scurt',
  '6e09ada7e33833d259370b0cc900f082': 'Sumă de plată în timp scurt',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Deduceri plată în timp scurt',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Plăți în timp scurt',
  '46bbc145b1f85c082950c768ade012f6': 'Sumă de plată în timp scurt',
  e66e6d9768e8f489c05951ee70ede350:
    'Ceva nu a mers bine, toate caracteristicile tabloului de bord este posibil să nu fie disponibile. Vă rugăm să încercați din nou sau contactați departamentul de sprijin pentru asistență.',
  cd969f183dc619b5716067cfda39c055: 'Cod de sortare',
  '17939071126e9e41921d79b837f63297': 'Nume furnizor',
  '750d4460b260343039f9bc71b66a231c': 'Furnizorul a fost declarat insolvent',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Furnizorul încă nu este configurat în program',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Asistență',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Termeni acceptați de',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Condițiile de furnizare a serviciilor',
  '1294cf929ec55581954a79948592dda9':
    'Data activării finanțării prin programul Cash.',
  f51144e4468390a486d41b4db66fc243: 'Imposibil de descărcat fișierul.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Numărul maxim de zile în care este așteptată plata de la cumpărătorul dvs., aprobată de HSBC.',
  d438da584c455e727642a303917e690d:
    'Suma rămasă din credit care poate fi utilizată. Reprezintă diferența dintre limita de credit și creditul utilizat.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Suma totală a facturilor care sunt finanțate momentan pentru acest program. Această sumă este calculată pe baza limitei de credit și a sumei curente a facturilor care sunt finanțate.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Suma totală a facturilor care pot fi finanțate momentan pentru acest program. Această sumă este calculată pe baza limitei de credit și a sumei curente a facturilor care sunt finanțate.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Suma totală de bani care a fost reținută înainte de decontarea facturii.',
  '13f271ac486faca89c1944226ba0efff':
    'Valoarea nominală totală a tuturor facturilor importate.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Valoarea nominală totală a facturilor care au trecut la statutul decontat deoarece au fost colectate complet.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Valoarea nominală totală a facturilor finanțate.',
  '565c255165bed2ff2640d0e42369c62c':
    'Valoarea totală a facturilor care nu sunt eligibile pentru finanțare.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Acest cont nu este înregistrat activ în Numerar.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Acestea sunt actualizate de obicei în următoarea zi lucrătoare. Vă rugăm să creați un tichet de asistență în cazul în care doriți să aflați soldurile curente.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Această valoare reflectă cel mai recent sold al plăților în timp scurt, inclusiv dobânzile.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Împreună cu',
  '603e4b3b3ac7b888a06548d14435bc25': 'Prea multe diluări',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Sumă totală colectată',
  f786c8a1095bdf155841d510bb53142a:
    'Dobânda totală pentru datoriile cu scadența depășită.',
  '4f25d1096076868bc590da9f8a26cf55': 'Limită totală',
  '230bd56af0acab75194f58b46a763535':
    'Suma totală colectată din plățile cumpărătorului.',
  '8f78461d739b4d6d46f961f352000cec': 'Sumă totală colectată de la dvs.',
  b62dd565f60dc6172605d8db223510b9:
    'Suma totală colectată prin remiterea de către cumpărător, remiterea de către vânzător și/sau taxele „Clawback”, inclusiv dobânzile aferente datoriilor cu scadența depășită.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Suma totală finanțată în schimbul vânzărilor.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Suma totală nefinanțată, conform procentului de preplată aprobat de HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Suma totală a facturilor finanțate colectate integral.',
  a069138e83a8b4cb7e81145689155826:
    'Suma totală a dobânzii aferente datoriei cu scadența depășită rămase de plătit sau restante.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Suma totală a taxelor cu scadența depășită datorate. Taxele penale sunt percepute zilnic.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Suma totală a taxelor cu scadența depășită plătite.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Suma totală a taxelor cu scadența depășită rămase de plătit sau restante.',
  d649fb9a0971deaa127067341f59be91:
    'Suma totală recuperată din alte deduceri ale facturii.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Suma totală care urmează să fie colectată sau rambursată.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Suma totală colectată',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Valoarea nominală totală a facturilor neeligibile pentru finanțare.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Valoarea nominală totală a facturilor trimise cumpărătorului (cumpărătorilor).',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Suma finanțată totală colectată prin remiterea de către cumpărător, remiterea de către vânzător și/sau taxele „Clawback”.',
  d4867ee77e79ded226c07918b9393715:
    'Dobânda totală pentru datoriile cu scadența depășită plătite.',
  de4c28160afe1ab48bcf83be49bbb196: 'Limita totală',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Suma restantă totală',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Suma finanțată restantă totală și orice dobândă pentru datorii cu scadența depășită care vă vor fi rambursate. Aceasta poate rezulta în urma unei sau mai multor facturi finanțate cu scadența depășită sau în urma respingerii uneia sau mai multor facturi de către cumpărător, după finanțare.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Întrebări frecvente despre programul Cash Tradeshift',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Detaliile tranzacției',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Imposibil de descărcat fișierul csv cu factura. Vă rugăm contactați serviciul de asistență',
  c8415d81378085ba3933015b8f5fc768:
    'Imposibil de recuperat acordul. Vă rugăm contactați serviciul de asistență',
  '64580a198c861aa0226c91b28f8418aa': 'Indisponibil pentru vânzare',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Facturile neplătite care au trecut de această dată sunt considerate „cu scadența depășită” și acumulează dobânda zilnică.',
  '9d632e72031eea3767881d6c3adcfe54': 'Limită de credit utilizată.',
  e871a6882109bd7ef07827882236c8db: 'Vizualizați acordul',
  '2ac548c28faaa41283f9d6b7606a4844': 'Anulat',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Bun venit la Cash!',
  '78eb58937961a18929a623397b544a01':
    'Puteți deconta soldul de rambursare printr-o remitere directă în contul de rambursare (prezentat mai sus).',
  f347abf54f65274a614a9ba7515dfa98:
    'Vă puteți urmări toate plățile înj avans într-un singur loc, cu aplicația Cash',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Programul dvs. Cash este activ!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Programul dvs. Cash este inactiv',
  '6e6e06469dd953a124d718cd2307e32c':
    'Participarea dvs. la programul de Numerar nu a fost activă de la {date}. Vă rugăm să contactați <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'o factură finanțată respinsă de cumpărător înainte de sfârșitul perioadei de grație (dobândă acumulată zilnic după data respingerii)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'o factură finanțată neplătită la sfârșitul perioadei de grație (dobândă acumulată zilnic ulterior); și/sau',
  '33e67e73d7f54aaee31e6040f873be79': 'aplicat la deduceri',
  a9798a3e063111de4d4768c363bdc267: 'aplicat facturilor',
  a5be13d3bfec5fc92521a841009db3c7: 'număr de cont bancar',
  '736c1e36ac779a73ff4fddd19d82008c': 'rata zilnică a dobânzii',
  ab53acde68c117f8fbfff6a8901de083: 'rată zilnică a dobânzii scadente',
  '4c584eabd4260d69914ac993cc4db5d2': 'zile',
  '047dbee224da5fb4ecfc21268ffd3115': 'dată scadentă',
  dd051262964c80130a2e9023ff933d89: 'plată în avans',
  bc55e8a10310a96eeda133ddf159303d: 'dată scadentă așteptată',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'pentru plăți în avans',
  '932d9bd46daba4d09f361717c0ec03ab': 'sumă finanțată',
  '5e82566144af4903b40303fc3d1c799e': 'dată finanțată',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'perioadă de grație',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'plată scadentă',
  '2db524546615d120af0adf844a6e1bd7': 'din',
  '99c2f4783bb6821d84ad17a22f556813': 'din finanțat',
  '7555b72d3b4ffe69558b68b3cb86b954': 'la',
  c138be463a457afe89e5ecfefc6a3dc9: 'note de credit restante',
  '37f59e13afd5455cf130060ed55f4976': 'număr de repartizare',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'scadență',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'pentru următorul cont bancar:',
  cf4edd5f38054a752994e3242a1f3807: 'sumă totală',
  '3c9f634310f5e3de42b79ee83d20923c': 'Total taxe',
  '573b1b7930d8030d1669945f2c34992a': 'transferat la banca dvs.',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'va fi dedus din următoarele plăți',
};
