export default {
  b8f66a73dd67296edf7d6126839b8b00: '(Ajuste debido a moneda o días festivos)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Las tasas y fechas de pago son indicativas, y no se pueden garantizar.',
  fa090ada546b5deb33cdead006822e26:
    '* Las tasas y fechas de pago son indicativas, y no se pueden garantizar.',
  '52fce318164dc60017ca67121172aca9': 'Aceptada',
  '615a093396ed9c6f52cd7ece7217608e': 'Ajuste de cuenta',
  '79dd431d35b370210a3dbeb1bb653d43': 'Nombre de la cuenta',
  '8c66a974a95769447407c061a36d0824': 'Número de cuenta',
  '6a810f9427275516cc7ac687ea182f27': 'Interés de deuda acumulada',
  '21df2d8134802290caddc9a8070e9d94': 'Cargos vencidos acumulados',
  '7377dcc6989501c8299b7ccf697b731e': 'Interés de deuda acumulada',
  dab81136f1c6e799c5626826349a8dcf: 'Cargos vencidos acumulados',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Todas',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Todos los compradores',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Todas las divisas',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Todas las facturas',
  d7db3397d1ee87530b253bc69ebca041: 'Cantidad',
  c7586d1e153c74713afd948b3048d03d:
    'Importe que se te ha pagado tras la deducción de interés y cualquier reembolso pendiente del importe financiado.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Importe recuperado contra reembolsos debidos en otra/s factura/s.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Cantidad a remitir',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Cualquier exceso de fondos le será transferido.',
  de9800ff635c316938aece381c98b094:
    'Cualquier pago parcial e interés pendientes se pueden sustraer del Precio de Compra a pagar u otros cobros. Si no pudiéramos cobrar de esta forma, podríamos pedirle que pague el importe directamente al comprador.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Aprobada',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Hacer una pregunta',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Límite de crédito disponible para nueva financiación.',
  '85a6c2f454268a7b3c4156c070abc517': 'Aprobación en espera',
  '99fb0198659b8076326d69a42670433b':
    'Normalmente, los saldos se actualizan en el siguiente día laborable.',
  '0448c27d5574d95fc844e50416fc13a3': 'Paquete',
  '84b730c6e665f3a83a96a8511b4f63db': 'Detalles del paquete',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Sucursal del comprador',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Factura rechazada por el comprador',
  cdb5b8f6940144b32dc7f27b7700719a: 'Pago del comprador',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Comprador marcado como pagado',
  e7094a08ea031332f2a7e7f94b214174: 'Pago del comprador',
  b8b64034058f45fe990f8377c7990c6b: 'Cuenta de pago del comprador',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Comprador:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Los compradores pagan aquí por facturas. Usted paga aquí por pagos parciales.',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'De forma predeterminada, el importe debido del reembolso se deducirá de las próximas facturas financiadas.',
  cb1a025651df74878d8d5af1b99991df: 'Cancelado',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Información del programa de efectivo',
  '6fd54168f061cd9f269dbcbb29415486': 'importe de cargos',
  '252982cd07a2c1e75a9cadb781c4428f': 'Recuperación',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Clic para copiar',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Cerrar',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Cerrado',
  a150472168406890d84a6a5c3c8a894c: 'Recopilado',
  '0743742edda54d26e47f9959ca4be4f0': 'Interés de la deuda cobrada',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Cargos vencidos cobrados',
  '0b2194f7621b8a036308dce1647b982d': 'Importe cobrado',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Interés de la deuda cobrada',
  b1a3b2482cd668f631d724aac2dfc685: 'Cargos vencidos cobrados',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Recaudación',
  ba18fa9a0a1da454462f71c6e36139ff: 'Saldos de cobros',
  '1103815eae13527faa8d51c83c20d17e': 'Detalle de cobro',
  '898e15d1fa445c9c37ca795f79029f6d': 'Nombre legal de la empresa',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Continuar',
  c05df0411bb18c405aaa68153432bf10:
    'Límite de crédito y factura actualizado en',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Crédito disponible',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Límite de crédito',
  d23014cf5a5e7f8c6440b96692831aa8: 'Nota de crédito',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Crédito utilizado',
  '207df9f33356e378a1ff2ef7b62becc7': 'Crédito disponible',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Límite de crédito',
  '208d5141343637870387b27db87ccf05': 'Se ha superado el límite de crédito',
  '0e27340c6eb226df1076c4f8930d2289': 'Crédito utilizado',
  e15a66f111bc879c77f24943a94d19e7: 'Moneda no aceptada',
  b1e5986f972b7599559dea22cfeae8fb: 'Moneda:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Descuento diario',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Tasa de descuento diario',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Tasa diaria por retraso',
  '9e268b45f182ad1851f03539be6ab29c': 'Tasa de descuento diaria',
  c2191b6160c46ed7a4b05cc0279158f3: 'Tasa diaria de vencimiento',
  e2d9ef555d350e16239f4dfc92842fdc: 'Panel',
  '106202929cf48a3de3f20b3255365915': 'Fecha',
  '2eca727eade526acc715c2e5605ebc7e':
    'Fecha en la que se liquidó en su totalidad el importe de la financiación.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'La fecha en la que termina el periodo de gracia de 30 días.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Fecha en la que la factura fue rechazada por tu comprador.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Días atrasados',
  '1e1e68c46e0d719364bd445b1d605144': 'Días atrasados',
  bd50c01a22cae65be4d588abcae65a90: 'Deducción',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Deducciones',
  d706035ec5f053ca33b234ddec228ab2: 'Descripción',
  a39bf2f30994908fe95498524b423d43: 'Detalles',
  '193d60da437b58b24ed69a5683c1ffcf': 'Diluido',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Importe de merma',
  d07e29091188f3b0871dc7712eee7255: 'Tipo de merma',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Remesas de Vendedor directo',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Remesa del vendedor directo',
  afc1c7dfc9315e856f8da52e2450182b: 'Descuento',
  '26e7eb298420701d5a03c53fe096cc0b': 'Disputada',
  '43cca9c1b76ebf869c7bf22fff271689': 'Filtros de Estado del documento',
  ce73b429141cda25571c88d9b0c78224: 'Estado del documento',
  af13cc2ad0a7337c45420f75b7d5a668: 'Descargar',
  '8475be8bb8249000118af931ae75c373': 'Descargar informe',
  '90567e36a540e51806d7790eb195b1d5': 'Descargar todas las facturas',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Borrador',
  '72015a772310560b755bd03132a543b2': 'Fecha de vencimiento',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Fecha de vencimiento',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Fecha límite demasiado pronto',
  '6def6918bd02ae5117332f4262cecb52': 'Elegible',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Estado de la inscripción',
  cb339c5c02cfb087fc8d393f917379df: 'Error al cargar el panel de control.',
  e9994b26424cb4bce5059d05e094df8d: 'Tasa estimada',
  f159aa99d4a54cda8b884530e2ad2a1b: 'País excluido',
  b5cb31ac9c7d1871e9075efc1f271536: 'TASA',
  fd9d568c402dd04e0715e877d76f7da5: 'Valor nominal de la factura.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Error',
  '817f1fe84c2d87894bc374681814abbf': 'No se ha podido cargar {title} -',
  '7932079ce6ea928bfa77efd91578312d': 'Tasas',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filtrar por divisa para ver totales resumidos.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'La fecha de pago de previsión es demasiado larga',
  e5af505b37031920b08edb84df19b5ec:
    'Fecha del cobro completo del importe financiado',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Fecha del cobro completo del importe financiado',
  '6dd341953c8c405c70878a457622cf55': 'Con fondos',
  '42e20c6bb1dc82fbdbd889f769850320': 'Importe financiado cobrado',
  cab921680af6ff282ce37398b0b231d4: 'Importe financiado',
  fc0347ceea208298049efd79021475a2: 'Importe financiado cobrado',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Fecha de financiación',
  '55d900eb37f97b684c0bd5821f161216': 'Facturas financiadas',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Financiación',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Excepción de fondos',
  '5ca89ce217f3bb07293db00a42c10200': 'Motivos de no aptitud para financiación',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Resumen de financiación',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Solicitud de financiación correcta',
  d5ec85821723434aba05986044ebdcdd: 'Estado de financiación',
  '3447158e3ace443dbe27556e07c88639': 'Filtros de Estado de financiación',
  '022848540bf0e01453be6cf79843aa67': 'Plazo de financiación',
  b4a59826d5bfc276fa88bd00abac615a: 'Detalles de financiación',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Plazo de financiación',
  e74f45b44aadcdd084b5cbdd3560f515: 'Fecha de finalización de gracia',
  '5434cf75f987ef76dbdc544fa1e32096': 'Cómo pagar',
  cb9f9412bd12122bb088124afccf738b: '¿Cómo reembolsar pagos incompletos?',
  '9ad57f0b683fecb097c09bedebf68a45': 'Cómo resolver el reembolso adeudado:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'FRA',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Si cree que se trata de un error, póngase en contacto con <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Información importante',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'En curso',
  '313b15a4fdfa2368015e2eb67416af2a': 'Programa inactivo',
  '7b6a003ceca7fe7fec85daae919e800b': 'No elegible',
  '6cf0784c1ac918c22cd402e0cb558749': 'El motivo no se puede elegir',
  '65cb9c651d2d9fb8941050a08add03c3': 'No elegible para pronto pago',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Facturas no elegibles',
  c555faa7b0b745638bb3ea785ee10137:
    'La información mostrada se actualizó por última vez el',
  b7311d63c44566a8562d92720cffed2e:
    'Cobros insuficientes para ratios de factura',
  fb07cb1753537f6969513f2d5fdb7993: 'Importe del interés',
  '1938e208f65e66474dc4148c3fa7da08': 'Interés deducido',
  '42094e40339ac5c0957a980062c47659': 'Interés acumulado en pagos parciales',
  d9580a19710f07e8c72c215b1270ca57:
    'Intereses cargados por HSBC sobre el importe financiado.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Interés deducido',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6:
    'Penalización de interés en factura vencida',
  '47a565198d287d0864339c077819a361':
    'Los intereses se siguen acumulando a la deuda atrasada hasta que se deduzcan del siguiente pago o recibamos una devolución directa.',
  '3c4be400f728da4526de626653d56ccc': 'Factura',
  '70546e9227f592c24d3c491756cb901d': 'Factura nº',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Importe de factura',
  '6b9515d101794c31e38ae60d092fdc03': 'Fecha de la factura',
  '2548c6a52ce9e1bffef7aad14530f490': 'Factura financiada',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Resumen de la financiación de facturas',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Límite de factura',
  '734ed127b3d7226c75e8553c0711367a': 'Límite de factura activo',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Límite total de la factura',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Número de factura',
  da2db17c9b22f995d6673a754d998fc8: 'Factura vencida',
  d729bd1bdced62265cd4ecd7131ef94e: 'Factura rechazada',
  e14208d4378a3378be8b27eb15984fc6: 'Factura liquidada',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Estado de la factura',
  '54d8df173250a197fab1644a0d0a772a': 'Importe de factura',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'La factura no se puede financiar en estos momentos. Para obtener más información, consulte los motivos de aptitud para la financiación.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'La divisa de la factura no coincide con el país',
  d0da9e977c8b309f93ee378de34fb251: 'Fecha de la factura',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Fecha de factura anterior a la fecha más temprana elegible',
  ad0ab15468b719694f3193d0649ad9eb:
    'La fecha de la factura es posterior a la fecha de cancelación para esta oficina.',
  fc9e80713aebb3d820d888f0755fedde: 'La factura no tiene un comprador suscrito',
  e17cbec04e7786361eff9da629f09bb7:
    'La factura tiene una fecha de emisión futura.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'La factura ha sido cancelada.',
  '89f842841b4ae213259b94a5411453f2': 'La factura ha sido mermada.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'La factura se ha financiado mediante el programa de efectivo (Cash).',
  ab5303505d045929abb1256607ca0d4d:
    'La factura se ha pagado en su totalidad. No hacen falta pagos adicionales.',
  '3e159746cbdfda07b2f45750941e26f6':
    'Se ha solicitado una factura para la financiación.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'La factura tiene un estado de documento no apto.',
  '8204c52c01b05791dc3e4c59ac352978':
    'La factura no se ha liquidado en su totalidad y el período de gracia ha finalizado',
  d87ca92704bcfb34fa4ac08a4d24147a: 'La factura actualmente no es apta.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Falta la fecha de finalización o la fecha de finalización final de la factura',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'La factura está demasiado próxima a la fecha prevista de pago.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'La factura es inusual',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Factura marcada como ya pagada.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'La factura podría estar duplicada.',
  b1f771da19efea2f0fa6a542823231b9: 'Factura sólo parcialmente elegible',
  '4217948ca113ee4158d5dd4c07d71624': 'Factura liquidada por pronto pago',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'El plazo de factura no está dentro del rango aceptable',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Tenor de factura demasiado largo',
  '90d9ac20dda384e9c07554d29b143bef': 'El valor de la factura excede el límite',
  '4b36f0be327fdca8d54126cd57095a13': 'Número de factura/RA (Aut. devol.)',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Facturas',
  '8089283aad9294aadc2bde165ccc78c1': 'Fecha de emisión',
  '3a4123f9c604369ab18781f7492f9293': 'Fecha de emisión',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Fecha final de pago',
  a94bc4d4df1d56c16e25493e22684412: 'Límite disponible',
  '92f97c843d499a1559828ba0a9134e24': 'Límite activo',
  b5dd8e130d88698834ee00464f01cea4: 'Límite disponible',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Límite en uso',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Cargando...',
  '24cc5627dea65e22f9279be9522a06e2': 'Importe del préstamo',
  bd24bcedd066a27749016a07cb729a32: 'Marcada como liquidada',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Fecha de madurez',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Fecha de inicio de miembro',
  d26429adfb846303cbd889879b23e1ba:
    'Dinero recaudado de los pagos del comprador, los pagos del vendedor y/o deducciones que se hayan realizado antes y/o después del periodo de gracia.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Cantidad neta pagada',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Importe neto pagado',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nuevo importe',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Siguiente día laboral',
  '325c48f099844279c0cf48584de42e64': 'No hay actividad reciente',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Ninguno',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'No está activamente inscrito en Cash',
  '97d4ed503f1a63577fc5befd1c7a2663': 'No hay suficiente historial de facturas',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'No hay suficientes facturas en los meses anteriores',
  c9e2ce8da559465a8596317c35e8f3a4: 'No hay suficientes facturas pagadas',
  e6f890deafa579eb0c24603c3101c1c7:
    'Tenga en cuenta que los saldos normalmente se actualizan en el siguiente día laborable. Envíe una incidencia de soporte si necesita saber la posición actual.',
  '83070a059f10db1515ac922bf3433158': 'Días tras la fecha final de la gracia.',
  '9d40ba58bf72a5bf003cd0d9a1fac469':
    'Hay un informe disponible para su descarga',
  '2778717f6acbeb1edaac6a2656b478f3': 'Abrir',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Otra tasa',
  fad92e97e692758c0d0a9b5730663894: 'Interés de deuda pendiente',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Importe financiado pendiente',
  '9eab1baddf2537312408088fc54f21d5': 'Cargos pendientes atrasados',
  bfba12a2c234532c0af24a4ad6831ab6: 'Importe total pendiente',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Interés de deuda pendiente',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Importe financiado pendiente',
  '2f9a3de6940f818fe0376e4aece59512': 'Cargos pendientes atrasados',
  '6f79eea38bff17917740a69116270074': 'Vencida',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Saldos vencidos',
  '848ac8c39b11a751d697a383303e6109': 'Deuda vencida',
  '8d1e868665fc24a46f0cf55187f72218':
    'La deuda atrasada se notificará según la normativa de RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Descripción general',
  '5b885084a5067d7fb4372cef38d8a82d': 'Pagado',
  '830b95d305cdc61a66cdf75ebff1d737': 'Pagado por el comprador',
  '6444796646c95dbc2cd1cb646e41293d':
    'Parte del valor nominal de la factura que ha sido financiado según el porcentaje de prepago aprobado por HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Detalles de comprador participante',
  a39314e5897b8baaa51ca296610ec30c: 'Términos del socio',
  a6acd5f03fda9c8dc607e59d8be08320: 'Retraso de aprobación de pagadera',
  e6249829dadf637c4092e4a900df529e: 'El comprador ha cuestionado el pago',
  '07ed468d28d8e8437d8ec64036b96c03': 'El pago ha sido rechazado',
  '042b8dfb88f7045ca9145bbcccf79200': 'Pago en el futuro',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Pagader demasiado antigua',
  '8755bbc212b541efb6576f39f3f0f51f': 'Pago asignado',
  '8744ee10540166dfc39db2a6859a9ce1': 'Importe del pago',
  '56133aa371eb0132334e4867479be942': 'Detalles del pago',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Pago atrasado',
  '2dd7164df606f9b42beefaba031b06f7': 'Pago recibido',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Pago confirmado por el vendedor',
  '661711004f06b0d9601db365679be130': 'Importe desembolsado',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Fecha de pago',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Interés de penalización',
  '0fd036622716f275c3e14c5a14faa65a': 'Pendiente',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Merma pendiente',
  '8cf3479829ba47a8a4123895d0088eeb': 'Mermas pendientes',
  '270b56fd05f72407ca0607b6162d0b10': 'Conexión pendiente',
  '4a78218416ca45d97b5633b37ae3f638':
    'Tasa de porcentaje a la que se calcularán los cargos diarios para el plazo total de la financiación.',
  b586a5468c7070bf1717593ea84fa607:
    'Tasa de porcentaje a la que se le cobrará diariamente el importe pendiente financiado pendiente para las facturas vencidas, o financiadas y rechazadas.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Envíe una incidencia de soporte si necesita conocer los saldos actuales.',
  '34645fa620cae4187f27d9f96c172f26': 'Remita el importe',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Remite la cantidad siguiente a esta cuenta bancaria:',
  '01734a7316427fdcdcd4f937cda3802b': 'Pago por adelantado',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Cantidad anterior',
  '500fc4731dab800a878b33906d9332fb': 'Programa de Cuentas de cobro',
  '7327113634d1f5f4f68632dceebfbc6f': 'Vista general del programa',
  d95ebfb51a485293a134b1038d332a10: 'Programa activado el',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'No hay disponibles detalles del programa para el Panel',
  '98feb2c86a81b8caeeaa30966ba34636': 'Información del programa',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Los pagos de factura adquiridos que no se cobran íntegramente en la fecha de vencimiento esperada generan un pago parcial. Los pagos parciales acumulan intereses a 1,5 veces el tipo de descuento diario, y es su responsabilidad reembolsar ambos.',
  '521689b600b8da5b0a111b89dea72126': 'Motivo',
  fe7bd622a1efcec03eb86dabb9395936: 'Recibido',
  e84203a8833e4d0d92faf89f4107bf11: 'Reembolso de recurso',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Importe de reembolso',
  b0be744ae1f6312f16990bbd92a87aea: 'Fecha de emisión de reembolso',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Plazo de reembolso',
  '654d6dcc141079bc84500fd14ca5087d': 'Importe del reembolso',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Detalles del reembolso',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Fecha de emisión del reembolso',
  c0a6a91e7ca80af9de47097b06dc7618: 'Plazo del reembolso',
  '4a2875439ab289a068abb9ea3b567bcb': 'Rechazada',
  '592b34cdfa2e51826b32a0783e0edce3': 'Rechazado por el comprador',
  '807d35f105b65ab2795c4f8e51795f3c': 'Rechazado por el vendedor',
  aea4dd208e871d7ca0051b0ec38c3068: 'Fecha de rechazo',
  d64ed4934fbf68a8f716467b0adf89ba: 'Fecha de rechazo',
  '5a77de3b287a92494be077c197f37581':
    'Importe financiado restante aún por cobrar o reembolsar.',
  e442b864bf745a75477ea0ee88bc15c4: 'Reembolso adeudado',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Importe del reembolso al finalizar el periodo de gracia. Esto no cambia para reflejar los reembolsos realizados después de esta fecha, ni incluye la deuda vencida.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Reembolso adeudado',
  dc20cbfb76d220c108241a451bc3faf4: 'Información de instrucciones de reembolso',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Los pagos adeudados son el importe pendiente total que hay que recuperar, como resultado de que la cantidad pagada por el Comprador sea menor que el valor nominal de la factura y/o de que el Comprador rechace la factura después de que haya sido financiada.',
  '45b60e22719c12291932bf09db0df5cd':
    'Los reembolsos adeudados son el importe pendiente total que hay que recuperar, resultante de:',
  ba093b1421452b3afdebbebedaeae552: 'Solicitar financiación',
  '34f8f9968ec0d7f91e558e906a7de982': 'Solicitado',
  dc1650cfbadb05f8fb6357692e48ee71: 'Solicitado para financiación',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Retenido',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Importe retenido',
  a476316a89541d1aa71d5b5366874e13: 'Importe retenido',
  '328f6585a0e78a96deefc0661474248f': 'Reintentar',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Ventas',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Buscar por número de factura',
  '3215999493db188b35242dc4f4fd8fb5':
    'Consulte la Información del programa para obtener más información sobre los reembolsos.',
  '69f43575c3a51e94605eb75b75f7b563': 'Seleccionada',
  '56a1c7c4a02e9985745969563a80799f': 'Cuenta de reembolso del vendedor',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Enviando',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Enviado',
  '95d54926719f1071b2fa640f1ba23d3e': 'Liquidada',
  e243f36392a7f264d830f04f21aa8972: 'Facturas liquidadas',
  b548572d4123e3a569d27e4676664dad: 'Pago breve',
  '6e09ada7e33833d259370b0cc900f082': 'Importe de pago parcial',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Deducciones de pago parcial',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Pagos parciales',
  '46bbc145b1f85c082950c768ade012f6': 'Importe de pago parcial',
  e66e6d9768e8f489c05951ee70ede350:
    'Algo salió mal, y es posible que no estén disponibles todas las características del panel de control. Inténtelo de nuevo o consulte al servicio de asistencia técnica.',
  cd969f183dc619b5716067cfda39c055: 'Código SORT',
  '17939071126e9e41921d79b837f63297': 'Nombre del proveedor',
  '750d4460b260343039f9bc71b66a231c':
    'El proveedor ha sido considerado insolvente',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'El proveedor aún no está configurado en el programa',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Ayuda',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Términos aceptados por',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Condiciones del servicio',
  '1294cf929ec55581954a79948592dda9':
    'La financiación del programa Cash se ha activado.',
  f51144e4468390a486d41b4db66fc243: 'El archivo no se ha podido descargar.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'El máximo de días en los que se espera el pago de su comprador, según lo aprobado por HSBC.',
  d438da584c455e727642a303917e690d:
    'La cantidad de crédito restante que se puede utilizar. Esta es la diferencia entre el límite de crédito y el crédito utilizado.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'La cantidad total de facturas que actualmente están financiadas para este programa. Este importe se calcula en función del límite de crédito y de la cantidad actual de facturas que se financian.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'La cantidad total de facturas que se pueden financiar para este programa. Este importe se calcula en función del límite de crédito y de la cantidad actual de facturas que se financian.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'La cantidad total de dinero que se ha conservado antes de la liquidación de la factura.',
  '13f271ac486faca89c1944226ba0efff':
    'El valor nominal total de todas las facturas importadas.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'El valor nominal total de las facturas que han pasado al estado liquidado porque se han pagado completamente.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'El valor nominal total de las facturas financiadas.',
  '565c255165bed2ff2640d0e42369c62c':
    'El valor total de las facturas no aptas para financiación.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Esta cuenta no está activamente inscrita en Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Se suele actualizar el siguiente día laborable. Si quieres saber los saldos actuales, puedes abrir una incidencia de soporte.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Este valor refleja su saldo de pagos parciales más recientes, incluyendo los intereses que procedan.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Junto con',
  '603e4b3b3ac7b888a06548d14435bc25': 'Demasiadas mermas',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Cantidad total cobrada',
  f786c8a1095bdf155841d510bb53142a: 'Interés total sobre la deuda vencida.',
  '4f25d1096076868bc590da9f8a26cf55': 'Límite total',
  '230bd56af0acab75194f58b46a763535':
    'Importe total recaudado de los pagos del comprador.',
  '8f78461d739b4d6d46f961f352000cec': 'Importe total recaudado por ti.',
  b62dd565f60dc6172605d8db223510b9:
    'Cantidad total recaudada a través del pago de comprador, de vendedor y/o recuperación, incluyendo cualquier interés sobre la deuda vencida.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Cantidad total financiada contra sus ventas.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Cantidd total no financiada, según el porcentaje de prepago aprobado por HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Cantidad total de facturas financiadas recaudadas en su totalidad.',
  a069138e83a8b4cb7e81145689155826:
    'Cantidad total de intereses sobre la deuda atrasada que queda por pagar o pendiente.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'El importe total de los cargos vencidos debidos. Los cargos de penalización se acumulan diariamente.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Cantidad total de cargos vencidos pagados.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Cantidad total de cargos vencidos que quedan por pagar o pendientes.',
  d649fb9a0971deaa127067341f59be91:
    'Cantidad total recuperada de otras deducciones de facturas.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Cantidad total aún por cobrar o reembolsar.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Cantidad total de cobro',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'El valor nominal total de las facturas no aptas para financiación.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Valor nominal total de las facturas enviadas a/l comprador/es.',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Cantidad total financiada recaudada a través de pago del comprador, del vendedor y/o recuperación.',
  d4867ee77e79ded226c07918b9393715:
    'Interés total sobre la deuda vencida pagada.',
  de4c28160afe1ab48bcf83be49bbb196: 'Límite total',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Cantidad total pendiente',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Cantidad total financiada pendiente de liquidación y cualquier interés en deuda vencida que debas reembolsar. Podría ser debido a una o más facturas financiadas atrasadas, o que el comprador ha rechazado una o más de tus facturas después de que fueran financiadas.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Preguntas frecuentes del Programa de efectivo de Tradeshift',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Detalles de transacción',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'La factura CSV no se ha podido descargar. Consultar al equipo de soporte',
  c8415d81378085ba3933015b8f5fc768:
    'No se ha podido recuperar el Acuerdo. Consultar al equipo de soporte',
  '64580a198c861aa0226c91b28f8418aa': 'No disponible para la venta',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Las facturas sin pagar tras esta fecha se consideran "Atrasadas" y acumularán intereses diarios a partir de dicha fecha.',
  '9d632e72031eea3767881d6c3adcfe54': 'Límite de crédito utilizado.',
  e871a6882109bd7ef07827882236c8db: 'Ver acuerdo',
  '2ac548c28faaa41283f9d6b7606a4844': 'Anulada',
  '0bcb6eb0e07f04a47296d8c341dd94ad': '¡Bienvenido a Cash!',
  '78eb58937961a18929a623397b544a01':
    'Puedes liquidar el saldo de reembolso adeudado realizando un pago directo en la cuenta de reembolso (indicada anteriormente).',
  f347abf54f65274a614a9ba7515dfa98:
    'Puede realizar un seguimiento de todos sus pagos anticipados en un solo lugar con la app Cash',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Su programa de Cash está activo',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'El Programa de efectivo está inactivo',
  '6e6e06469dd953a124d718cd2307e32c':
    'Su participación el programa de efectivo (Cash) no ha estado activa desde {date}. Póngase en contacto con <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'una factura financiada rechazada por el comprador antes del final del período de gracia (se acumulan intereses diarios a partir de la fecha de rechazo)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'una factura financiada que impagada al final del período de gracia (se acumulan intereses diarios a partir de la fecha); y/o',
  '33e67e73d7f54aaee31e6040f873be79': 'aplicado a las deducciones',
  a9798a3e063111de4d4768c363bdc267: 'se aplica a facturas',
  a5be13d3bfec5fc92521a841009db3c7: 'número de cuenta bancaria',
  '736c1e36ac779a73ff4fddd19d82008c': 'Tasa de interés diario',
  ab53acde68c117f8fbfff6a8901de083: 'tasa diaria de interés por retraso',
  '4c584eabd4260d69914ac993cc4db5d2': 'días',
  '047dbee224da5fb4ecfc21268ffd3115': 'fecha de vencimiento',
  dd051262964c80130a2e9023ff933d89: 'pronto pago/pago anticipado',
  bc55e8a10310a96eeda133ddf159303d: 'fecha de vencimiento prevista',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'para pronto pago',
  '932d9bd46daba4d09f361717c0ec03ab': 'importe financiado',
  '5e82566144af4903b40303fc3d1c799e': 'fecha de financiación',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'período de gracia',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'pago de liquidación completa',
  '2db524546615d120af0adf844a6e1bd7': 'de',
  '99c2f4783bb6821d84ad17a22f556813': 'con fondos',
  '7555b72d3b4ffe69558b68b3cb86b954': 'en*/*',
  c138be463a457afe89e5ecfefc6a3dc9: 'notas de crédito pendientes',
  '37f59e13afd5455cf130060ed55f4976': 'número de ruta',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'plazo',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'a la siguiente cuenta bancaria:',
  cf4edd5f38054a752994e3242a1f3807: 'monto total',
  '3c9f634310f5e3de42b79ee83d20923c': 'tasa total',
  '573b1b7930d8030d1669945f2c34992a': 'transferido a su banco',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'se deducirá en los próximos pagos',
};
