export default {
  '52fce318164dc60017ca67121172aca9': '受理済み',
  '79dd431d35b370210a3dbeb1bb653d43': '口座名義',
  '8c66a974a95769447407c061a36d0824': '口座番号',
  f752dafdd1cbcfd9e276ee02daed1c39: 'すべて',
  '6a21e27ab1553bb3bf93e0a73e645087': '全てのバイヤー',
  '59a79bbd7cb65771c64f6c687b4c8103': 'すべての通貨',
  d7db3397d1ee87530b253bc69ebca041: '金額',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': '承認済み',
  '85a6c2f454268a7b3c4156c070abc517': '承認待ち',
  cb1a025651df74878d8d5af1b99991df: 'キャンセル済み',
  '6fd54168f061cd9f269dbcbb29415486': '請求額',
  ffa075e58de62f4c4a7af41bb3645aaf: '閉じる',
  f7a61d011f9f4aa24be33b4ddb96eaa0: '完了',
  a150472168406890d84a6a5c3c8a894c: '回収済み',
  c16ee5d7a1e74972b7b98e206f1f5231: '進む',
  d23014cf5a5e7f8c6440b96692831aa8: 'クレジットノート',
  e2d9ef555d350e16239f4dfc92842fdc: 'ダッシュボード',
  '106202929cf48a3de3f20b3255365915': '日付',
  bd50c01a22cae65be4d588abcae65a90: '控除',
  d706035ec5f053ca33b234ddec228ab2: '内容',
  a39bf2f30994908fe95498524b423d43: '詳細情報',
  afc1c7dfc9315e856f8da52e2450182b: '割引',
  '26e7eb298420701d5a03c53fe096cc0b': '内容確認中',
  af13cc2ad0a7337c45420f75b7d5a668: 'ダウンロード',
  '8475be8bb8249000118af931ae75c373': 'レポートをダウンロード',
  '2a669c26b8086630dc4da5c2ee81e70c': 'ドラフト',
  f12ae1ac50389e2b7470ae1f3d1a0795: '期限日',
  '5bbfbbeb3649c8a75f2e910414f2a448': '失敗',
  '5de88f4d34f61b9e39961eecbe0ef8d2': '実行中',
  '3c4be400f728da4526de626653d56ccc': '請求書',
  '64a857c3c7502d4299e4d8798fc9dc05': '請求書番号',
  d0da9e977c8b309f93ee378de34fb251: '請求日',
  cb5bf06bec8dcbfb20319ad13bdf57fd: '請求書の件数',
  '8089283aad9294aadc2bde165ccc78c1': '発行日',
  '3a4123f9c604369ab18781f7492f9293': '発行日',
  '4a739fa0b571805cc56fc4080ea82fcb': '読み込み中...',
  bd24bcedd066a27749016a07cb729a32: '決済済み',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'なし',
  '2778717f6acbeb1edaac6a2656b478f3': '開く',
  '6f79eea38bff17917740a69116270074': '期日超過',
  dc14337c43c60e68f4766cb5dd95bbc2: '概要',
  '5b885084a5067d7fb4372cef38d8a82d': '入金確認済み',
  '0fd036622716f275c3e14c5a14faa65a': '承認待ち',
  '270b56fd05f72407ca0607b6162d0b10': '接続待ち',
  '521689b600b8da5b0a111b89dea72126': '備考',
  fe7bd622a1efcec03eb86dabb9395936: '受信済み',
  '4a2875439ab289a068abb9ea3b567bcb': '却下済み',
  '328f6585a0e78a96deefc0661474248f': '再度実行',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': '販売',
  '69f43575c3a51e94605eb75b75f7b563': '選択済みの所在地',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: '送信中',
  '3e7dc5ef26363b6a0176875cb5d6fee0': '送信済み',
  '95d54926719f1071b2fa640f1ba23d3e': '送金済み',
  '17939071126e9e41921d79b837f63297': 'サプライヤ名',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'サポート',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'サービス規約',
  d356429f9bd0a4a6036707d99bf5e2b2: '合計',
  '2ac548c28faaa41283f9d6b7606a4844': '無効',
  '4c584eabd4260d69914ac993cc4db5d2': '日',
  '2db524546615d120af0adf844a6e1bd7': 'の',
  '7555b72d3b4ffe69558b68b3cb86b954': '/ 注文日:',
  cf4edd5f38054a752994e3242a1f3807: '合計金額',
};
