export default {
  b8f66a73dd67296edf7d6126839b8b00: '(upravené z dôvodu meny alebo sviatkov)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '*Poplatky a dátumy platieb sú orientačné a nemožno ich zaručiť.',
  fa090ada546b5deb33cdead006822e26:
    '*Poplatky a dátumy platieb sú orientačné a nemožno ich zaručiť.',
  '52fce318164dc60017ca67121172aca9': 'Prijatá',
  '615a093396ed9c6f52cd7ece7217608e': 'Adjustácia účtu',
  '79dd431d35b370210a3dbeb1bb653d43': 'Názov účtu',
  '8c66a974a95769447407c061a36d0824': 'Číslo účtu',
  '6a810f9427275516cc7ac687ea182f27': 'Naakumulované úroky z dlhu',
  '21df2d8134802290caddc9a8070e9d94':
    'Naakumulované poplatky za oneskorené platby',
  '7377dcc6989501c8299b7ccf697b731e': 'Naakumulované úroky z dlhu',
  dab81136f1c6e799c5626826349a8dcf:
    'Naakumulované poplatky za oneskorené platby',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Všetky',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Všetci kupujúci',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Všetky meny',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Všetky faktúry',
  d7db3397d1ee87530b253bc69ebca041: 'Čiastka',
  c7586d1e153c74713afd948b3048d03d:
    'Suma, ktorá vám bola vyplatená po odpočítaní úrokov a všetkých splatných splátok z financovanej sumy.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Suma vrátená za splatné splátky z inej faktúry (faktúr).',
  '57e646b300b4b551d662b0cd536a6ad5': 'Suma na poukázanie',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Všetky prebytočné prostriedky vám budú prevedené.',
  de9800ff635c316938aece381c98b094:
    'Všetky zostávajúce splátky a úroky sa môžu odpočítať od kúpnej ceny alebo iných poplatkov. Ak nedokážeme inkasovať platbu týmto spôsobom, môžeme vás požiadať, aby ste sumu zaplatili priamo kupujúcemu.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Schválené',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Položiť otázku',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Dostupný úverový limit pre nové financovanie.',
  '85a6c2f454268a7b3c4156c070abc517': 'Čakajúce na schválenie',
  '99fb0198659b8076326d69a42670433b':
    'Zostatky sa zvyčajne aktualizujú nasledujúci pracovný deň.',
  '0448c27d5574d95fc844e50416fc13a3': 'Zväzok',
  '84b730c6e665f3a83a96a8511b4f63db': 'Údaje o zväzku',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Pobočka kupujúceho',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Faktúra odmietnutá predajcom',
  cdb5b8f6940144b32dc7f27b7700719a: 'Prevod kupujúceho',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Označené zaplatené',
  e7094a08ea031332f2a7e7f94b214174: 'Prevod kupujúceho',
  b8b64034058f45fe990f8377c7990c6b: 'Platobný účet kupujúceho',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Kupujúci:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Kupujúci tu uhrádzajú faktúry. Vy tu platíte čiastočné úhrady.',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'V predvolenom nastavení sa suma splatnej splátky odpočíta z ďalšej financovanej faktúry (faktúr).',
  '0d1e94883caf8b30026ade5dd5c242ea': 'DB',
  cb1a025651df74878d8d5af1b99991df: 'Zrušené',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Informácie o hotovostnom programe',
  '6fd54168f061cd9f269dbcbb29415486': 'Výška poplatku',
  '252982cd07a2c1e75a9cadb781c4428f': 'Vrátiť späť',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Kliknutím skopírujete',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Zavrieť',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Uzavreté',
  a150472168406890d84a6a5c3c8a894c: 'Vybrané',
  '0743742edda54d26e47f9959ca4be4f0': 'Inkasované úroky z dlhu',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Inkasované poplatky za oneskorené platby',
  '0b2194f7621b8a036308dce1647b982d': 'Vybratá suma',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Inkasované úroky z dlhu',
  b1a3b2482cd668f631d724aac2dfc685: 'Inkasované poplatky za oneskorené platby',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Inkaso',
  ba18fa9a0a1da454462f71c6e36139ff: 'Zostatky inkasa',
  '1103815eae13527faa8d51c83c20d17e': 'Podrobnosti o kolekcii',
  '898e15d1fa445c9c37ca795f79029f6d': 'Oficiálny názov spoločnosti',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Pokračovať',
  c05df0411bb18c405aaa68153432bf10:
    'Úverový a fakturačný limit bol aktualizovaný na',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Dostupný kredit',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Úverový limit',
  d23014cf5a5e7f8c6440b96692831aa8: 'Dobropis',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Využitý úver',
  '207df9f33356e378a1ff2ef7b62becc7': 'Dostupný úver',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Úverový limit',
  '208d5141343637870387b27db87ccf05': 'Úverový limit bol prekročený.',
  '0e27340c6eb226df1076c4f8930d2289': 'Využitý úver',
  e15a66f111bc879c77f24943a94d19e7: 'Mena nie je podporovaná',
  b1e5986f972b7599559dea22cfeae8fb: 'Mena:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Denná zľava',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Denná diskontná sadzba',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Denná sadzba za omeškanie',
  '9e268b45f182ad1851f03539be6ab29c': 'Denná diskontná sadzba',
  c2191b6160c46ed7a4b05cc0279158f3: 'Denná sadzba za omeškanie',
  e2d9ef555d350e16239f4dfc92842fdc: 'Tabuľa',
  '106202929cf48a3de3f20b3255365915': 'Dátum',
  '2eca727eade526acc715c2e5605ebc7e':
    'Dátum, ku ktorému bola financovaná suma vyrovnaná v plnej výške.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Dátum, kedy sa končí 30-dňová doba odkladu.',
  b914f3ab262190a5dd60478fa6b7b828: 'Dátum, kedy kupujúci faktúru odmietol.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Dni po splatnosti',
  '1e1e68c46e0d719364bd445b1d605144': 'Dni po splatnosti',
  bd50c01a22cae65be4d588abcae65a90: 'Odpočet',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Odpočty',
  d706035ec5f053ca33b234ddec228ab2: 'Popis',
  a39bf2f30994908fe95498524b423d43: 'Podrobnosti',
  '193d60da437b58b24ed69a5683c1ffcf': 'Zriedené',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Objem zriedenia',
  d07e29091188f3b0871dc7712eee7255: 'Typ zriedenia',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Priamy bankový prevod od predávajúceho',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Priamy bankový prevod od predávajúceho',
  afc1c7dfc9315e856f8da52e2450182b: 'Zľava',
  '26e7eb298420701d5a03c53fe096cc0b': 'Rozporované',
  '43cca9c1b76ebf869c7bf22fff271689': 'Filtre stavu dokumentu',
  ce73b429141cda25571c88d9b0c78224: 'Stav dokumentu',
  af13cc2ad0a7337c45420f75b7d5a668: 'Prevziať',
  '8475be8bb8249000118af931ae75c373': 'Prevziať zostavu',
  '90567e36a540e51806d7790eb195b1d5': 'Stiahnuť všetky faktúry',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Koncept',
  '72015a772310560b755bd03132a543b2': 'Dátum splatnosti',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Dátum splatnosti',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Príliš skorý dátum splatnosti',
  '6def6918bd02ae5117332f4262cecb52': 'Oprávnené',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Stav registrácie',
  cb339c5c02cfb087fc8d393f917379df: 'Chyba pri načítavaní tabule.',
  e9994b26424cb4bce5059d05e094df8d: 'Odhadovaný poplatok',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Vylúčená krajina',
  b5cb31ac9c7d1871e9075efc1f271536: 'POPLATOK',
  fd9d568c402dd04e0715e877d76f7da5: 'Nominálna hodnota faktúry.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Zlyhalo',
  '817f1fe84c2d87894bc374681814abbf': 'Načítanie {title} sa nepodarilo –',
  '7932079ce6ea928bfa77efd91578312d': 'Poplatky',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Celkové súčty zobrazíte filtrovaním podľa meny.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'Predpokladaný dátum platby je príliš ďaleko',
  e5af505b37031920b08edb84df19b5ec:
    'Dátum inkasovania celkovej financovanej sumy',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Dátum inkasovania celkovej financovanej sumy',
  '6dd341953c8c405c70878a457622cf55': 'Financované',
  '42e20c6bb1dc82fbdbd889f769850320': 'Inkasovaná finančná suma',
  cab921680af6ff282ce37398b0b231d4: 'Financovaná suma',
  fc0347ceea208298049efd79021475a2: 'Inkasovaná finančná suma',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Dátum financovania',
  '55d900eb37f97b684c0bd5821f161216': 'Financované faktúry',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Financovanie',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Výnimka z financovania',
  '5ca89ce217f3bb07293db00a42c10200': 'Dôvody nespôsobilosti financovania',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Prehľad financovania',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Požiadavka na financovanie bola úspešná',
  d5ec85821723434aba05986044ebdcdd: 'Stav financovania',
  '3447158e3ace443dbe27556e07c88639': 'Filtre stavu financovania',
  '022848540bf0e01453be6cf79843aa67': 'Doba financovania do splatnosti',
  b4a59826d5bfc276fa88bd00abac615a: 'Podrobnosti financovania',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Doba financovania do splatnosti',
  e74f45b44aadcdd084b5cbdd3560f515: 'Dátum ukončenia doby odkladu',
  '5434cf75f987ef76dbdc544fa1e32096': 'Spôsob úhrady',
  cb9f9412bd12122bb088124afccf738b: 'Ako môžem splatiť nedoplatky?',
  '9ad57f0b683fecb097c09bedebf68a45': 'Ako vyriešiť splátky po splatnosti:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'FA',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Ak sa domnievate, že ide o chybu, kontaktujte <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Dôležité informácie',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'Uložený priebeh',
  '313b15a4fdfa2368015e2eb67416af2a': 'Neaktívny program',
  '7b6a003ceca7fe7fec85daae919e800b': 'Nevhodné',
  '6cf0784c1ac918c22cd402e0cb558749': 'Nevoliteľná odpoveď',
  '65cb9c651d2d9fb8941050a08add03c3': 'Nevhodné pre skoršie platby',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Neoprávnené faktúry',
  c555faa7b0b745638bb3ea785ee10137:
    'Zobrazené informácie boli naposledy aktualizované dňa',
  b7311d63c44566a8562d92720cffed2e: 'Nedostatočný pomer sumy inkasa a faktúr',
  fb07cb1753537f6969513f2d5fdb7993: 'Výška úroku',
  '1938e208f65e66474dc4148c3fa7da08': 'Odpočítaný úrok',
  '42094e40339ac5c0957a980062c47659': 'Úroky vzniknuté pri čiastočnej platbe',
  d9580a19710f07e8c72c215b1270ca57:
    'Úrok účtovaný bankou HSBC z financovanej sumy.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Odpočítané úroky',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Úroky z faktúr po splatnosti',
  '47a565198d287d0864339c077819a361':
    'Úroky z omeškaných platieb budú narastať dovtedy, kým vám nebudú odpočítané z ďalšej platby alebo kým nedostaneme priamu úhradu.',
  '3c4be400f728da4526de626653d56ccc': 'Faktúra',
  '70546e9227f592c24d3c491756cb901d': 'Faktúra',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Čiastka dokumentu',
  '6b9515d101794c31e38ae60d092fdc03': 'Dátum faktúry',
  '2548c6a52ce9e1bffef7aad14530f490': 'Faktúry financované',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Prehľad financovania faktúr',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Limit faktúry',
  '734ed127b3d7226c75e8553c0711367a': 'Použitý limit faktúry',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Celkový limit faktúry',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Číslo ID',
  da2db17c9b22f995d6673a754d998fc8: 'Faktúra po splatnosti',
  d729bd1bdced62265cd4ecd7131ef94e: 'Faktúry odmietnuté',
  e14208d4378a3378be8b27eb15984fc6: 'Faktúry uhradené',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Stav faktúry',
  '54d8df173250a197fab1644a0d0a772a': 'Suma faktúry',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Faktúru v súčasnosti nie je možné financovať. Viac informácií nájdete v dôvodoch nespôsobilosti financovania.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9': 'Mena na faktúre nezodpovedá krajine',
  d0da9e977c8b309f93ee378de34fb251: 'Dátum fakturácie',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Dátum faktúry je pred najskorším oprávneným dátumom',
  ad0ab15468b719694f3193d0649ad9eb:
    'Dátum faktúry je po dátume zrušenia podniku.',
  fc9e80713aebb3d820d888f0755fedde: 'Faktúra nemá žiadneho kupujúceho',
  e17cbec04e7786361eff9da629f09bb7: 'Dátum vystavenia faktúry je v budúcnosti.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Faktúra bola zrušená.',
  '89f842841b4ae213259b94a5411453f2': 'Faktúra bola znížená.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'Faktúra bola financovaná v rámci hotovostného programu.',
  ab5303505d045929abb1256607ca0d4d:
    'Faktúra bola uhradená v plnej výške. Nevyžadujú sa žiadne ďalšie platby.',
  '3e159746cbdfda07b2f45750941e26f6':
    'Bola podaná žiadosť o financovanie faktúry.',
  e8a7d355e6efb25006f6e4c50fde715a: 'Faktúra má status neoprávneného dokladu.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Faktúra nebola uhradená v plnej výške a uplynula doba odkladu jej splatnosti',
  d87ca92704bcfb34fa4ac08a4d24147a: 'Faktúra je v súčasnosti neoprávnená.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Faktúra nemá dátum ukončenia alebo konečný dátum ukončenia',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Faktúra je príliš blízko k očakávanému dátumu platby.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Faktúra je nezvyčajná',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Faktúra je už označená ako zaplatená.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Faktúra môže byť duplicitná.',
  b1f771da19efea2f0fa6a542823231b9: 'Faktúra je spôsobilá iba čiastočne',
  '4217948ca113ee4158d5dd4c07d71624': 'Faktúra zúčtovaná s čiastočnou platbou',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Doba splatnosti faktúry nie je v prijateľnom časovom rozmedzí',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Doba splatnosti faktúry je príliš dlhá',
  '90d9ac20dda384e9c07554d29b143bef': 'Hodnota faktúry prekračuje limit',
  '4b36f0be327fdca8d54126cd57095a13': 'Číslo faktúry/RA',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Faktúry',
  '8089283aad9294aadc2bde165ccc78c1': 'Dátum vystavenia',
  '3a4123f9c604369ab18781f7492f9293': 'Dátum vystavenia',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Dátum poslednej platby',
  a94bc4d4df1d56c16e25493e22684412: 'Dostupný limit',
  '92f97c843d499a1559828ba0a9134e24': 'Používaný limit',
  b5dd8e130d88698834ee00464f01cea4: 'Dostupný limit',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Používaný limit',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Načítava sa...',
  '24cc5627dea65e22f9279be9522a06e2': 'Výška úveru',
  bd24bcedd066a27749016a07cb729a32: 'Označené uhradené',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Mauritánia',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Dátum začiatku členstva',
  d26429adfb846303cbd889879b23e1ba:
    'Peniaze získané z platieb kupujúceho alebo prevodov predávajúceho a/alebo zrážok pred a/alebo po období odkladu.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Zaplatená čistá suma',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Čistá zaplatená suma',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nová suma',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Nasledujúci pracovný deň',
  '325c48f099844279c0cf48584de42e64': 'Žiadna nedávna aktivita',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Žiadny',
  '7457575d324c4b8dada2e13c0f0fe5d5':
    'Nie je aktívne zaregistrovaný v systéme Cash',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Nedostačná história faktúr',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Nedostatok faktúr v predchádzajúcich mesiacoch',
  c9e2ce8da559465a8596317c35e8f3a4: 'Nedostatok uhradených faktúr',
  e6f890deafa579eb0c24603c3101c1c7:
    'Upozorňujeme, že zostatky sa zvyčajne aktualizujú nasledujúci pracovný deň. Ak potrebujete poznať aktuálny stav, podajte požiadavku na podporu.',
  '83070a059f10db1515ac922bf3433158':
    'Počet dní po dátume ukončenia doby odkladu.',
  '9d40ba58bf72a5bf003cd0d9a1fac469':
    'Jedna zostava je k dispozícii na stiahnutie',
  '2778717f6acbeb1edaac6a2656b478f3': 'Otvoriť',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Iné poplatky',
  fad92e97e692758c0d0a9b5730663894: 'Nesplatené úroky z dlhu',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Nesplatená financovaná suma',
  '9eab1baddf2537312408088fc54f21d5': 'Neuhradené poplatky po splatnosti',
  bfba12a2c234532c0af24a4ad6831ab6: 'Neuhradená celková suma',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Nesplatené úroky z dlhu',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Nesplatená financovaná suma',
  '2f9a3de6940f818fe0376e4aece59512': 'Neuhradené poplatky po splatnosti',
  '6f79eea38bff17917740a69116270074': 'Po splatnosti',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Zostatky po splatnosti',
  '848ac8c39b11a751d697a383303e6109': 'Dlh po splatnosti',
  '8d1e868665fc24a46f0cf55187f72218':
    'Dlh po splatnosti sa bude vykazovať v súlade s predpismi RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Kontrola',
  '5b885084a5067d7fb4372cef38d8a82d': 'Zaplatená',
  '830b95d305cdc61a66cdf75ebff1d737': 'Zaplatené kupujúcim',
  '6444796646c95dbc2cd1cb646e41293d':
    'Časť nominálnej hodnoty faktúry, ktorá bola financovaná podľa percenta zálohovej platby schváleného HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Údaje zúčastnených kupujúcich',
  a39314e5897b8baaa51ca296610ec30c: 'Partnerské podmienky',
  a6acd5f03fda9c8dc607e59d8be08320: 'Oneskorenie schválenia splatnej čiastky',
  e6249829dadf637c4092e4a900df529e: 'Kupujúci spochybnil splatnú čisatku',
  '07ed468d28d8e8437d8ec64036b96c03': 'Splatná čiastka bola odmietnutá',
  '042b8dfb88f7045ca9145bbcccf79200': 'Splatná čistka je v budúcnosti',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Splatná čiastka je príliš stará',
  '8755bbc212b541efb6576f39f3f0f51f': 'Platba bola pridelená',
  '8744ee10540166dfc39db2a6859a9ce1': 'Čiastka na úhradu',
  '56133aa371eb0132334e4867479be942': 'Detaily platby',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Platba po splatnosti',
  '2dd7164df606f9b42beefaba031b06f7': 'Platba bola prijatá',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Platba potvrdená predávajúcim',
  '661711004f06b0d9601db365679be130': 'Čiastka dane',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Dátum dokumentu',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Úroky z omeškania',
  '0fd036622716f275c3e14c5a14faa65a': 'Čakajúca',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Čaká sa na zriedenie',
  '8cf3479829ba47a8a4123895d0088eeb': 'Čakajúce zriedenia',
  '270b56fd05f72407ca0607b6162d0b10': 'Čakajúce pripojenie',
  '4a78218416ca45d97b5633b37ae3f638':
    'Percentuálna sadzba, podľa ktorej sa vypočítajú denné poplatky počas celej doby financovania.',
  b586a5468c7070bf1717593ea84fa607:
    'Percentuálna sadzba, ktorá vám bude denne účtovaná z nesplatenej financovanej sumy za omeškané alebo financované a odmietnuté faktúry.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Ak potrebujete poznať aktuálny stav, podajte požiadavku na podporu.',
  '34645fa620cae4187f27d9f96c172f26': 'Prosím, poukážte sumu',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Prosím, poukážte nasledujúcu sumu na nasledujúci bankový účet:',
  '01734a7316427fdcdcd4f937cda3802b': 'Platba vopred',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Predchádzajúca čiastka',
  '500fc4731dab800a878b33906d9332fb': 'Inkasný účet programu',
  '7327113634d1f5f4f68632dceebfbc6f': 'Prehľad programu',
  d95ebfb51a485293a134b1038d332a10: 'Program aktivovaný dňa',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Podrobnosti o programe nie sú k dispozícii pre tabuľu',
  '98feb2c86a81b8caeeaa30966ba34636': 'Informácie o programe',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Platby za nákupné faktúry, ktoré nebudú inkasované v plnej výške pred očakávaným dátumom splatnosti, vygenerujú čiastočnú platbu. Čiastočné platby kumulujú úrokovú sadzbu, ktorá predstavuje 1,5-násobok dennej diskontnej sadzby a vašou povinnosťou je obidve splácať.',
  '521689b600b8da5b0a111b89dea72126': 'Dôvod',
  fe7bd622a1efcec03eb86dabb9395936: 'Doručené',
  e84203a8833e4d0d92faf89f4107bf11: 'Splácanie formou regresu',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Suma vrátenia platby',
  b0be744ae1f6312f16990bbd92a87aea: 'Dátum vystavenia vrátenia platby',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Doba splatnosti vrátenia platby',
  '654d6dcc141079bc84500fd14ca5087d': 'Vrátená suma',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Údaje o vrátení',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Dátum vystavenia vrátenia platby',
  c0a6a91e7ca80af9de47097b06dc7618: 'Doba splatnosti vrátenia platby',
  '4a2875439ab289a068abb9ea3b567bcb': 'Odmietnuté',
  '592b34cdfa2e51826b32a0783e0edce3': 'Odmietnuté kupujúcim',
  '807d35f105b65ab2795c4f8e51795f3c': 'Odmietnuté predajcom',
  aea4dd208e871d7ca0051b0ec38c3068: 'Dátum odmietnutia',
  d64ed4934fbf68a8f716467b0adf89ba: 'Dátum odmietnutia',
  '5a77de3b287a92494be077c197f37581':
    'Zostávajúca financovaná suma, ktorá má byť ešte inkasovaná alebo splatená.',
  e442b864bf745a75477ea0ee88bc15c4: 'Splatnosť splátky',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Výška splátky ku dňu ukončenia odkladu splátok. Táto hodnota sa nemení tak, aby odrážala všetky splátky uskutočnené po tomto dátume, ani nezahŕňa dlhy po splatnosti.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Splatnosť splátky',
  dc20cbfb76d220c108241a451bc3faf4: 'Informácie o pokynoch na splácanie',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Splatné splátky predstavujú celkovú neuhradenú sumu, ktorú je potrebné vymôcť, a to buď v dôsledku toho, že suma zaplatená kupujúcim je nižšia ako nominálna hodnota faktúry, a/alebo v dôsledku toho, že kupujúci faktúru po jej uhradení odmietne.',
  '45b60e22719c12291932bf09db0df5cd':
    'Splatné splátky predstavujú celkovú sumu, ktorá musí byť vrátená a vyplývajú z:',
  ba093b1421452b3afdebbebedaeae552: 'Požiadať o zmenu',
  '34f8f9968ec0d7f91e558e906a7de982': 'Požiadané',
  dc1650cfbadb05f8fb6357692e48ee71: 'Požadované financovanie',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Zadržané',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Zadržaná suma',
  a476316a89541d1aa71d5b5366874e13: 'Zadržaná suma',
  '328f6585a0e78a96deefc0661474248f': 'Znova',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Predaj',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Hľadať podľa čísla faktúry',
  '3215999493db188b35242dc4f4fd8fb5':
    'Ďalšie informácie o splátkach nájdete v časti Informácie o programe.',
  '69f43575c3a51e94605eb75b75f7b563': 'Vybraté',
  '56a1c7c4a02e9985745969563a80799f': 'Splátkový účet predávajúceho',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Odosiela sa',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Odoslané',
  '95d54926719f1071b2fa640f1ba23d3e': 'Uhradené',
  e243f36392a7f264d830f04f21aa8972: 'Uhradené faktúry',
  b548572d4123e3a569d27e4676664dad: 'Skrátená platba',
  '6e09ada7e33833d259370b0cc900f082': 'Suma čiastočnej platby',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Odpočty za čiastočnú platbu',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Čiastočné platby',
  '46bbc145b1f85c082950c768ade012f6': 'Suma čiastočnej platby',
  e66e6d9768e8f489c05951ee70ede350:
    'Vyskytol sa problém, niektoré funkcie panela nemusia byť k dispozícii. Skúste to znova alebo kontaktujte tím podpory a požiadajte o pomoc.',
  cd969f183dc619b5716067cfda39c055: 'Kód pobočky',
  '17939071126e9e41921d79b837f63297': 'Typ dodávateľa',
  '750d4460b260343039f9bc71b66a231c':
    'Dodávateľ sa považuje za platobne neschopného',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Dodávateľ ešte nie je v programe nakonfigurovaný',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Podpora',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Podmienky prijal',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Podmienky poskytovania služby',
  '1294cf929ec55581954a79948592dda9':
    'Dátum aktivácie financovania hotovostného programu.',
  f51144e4468390a486d41b4db66fc243: 'Súbor sa nepodarilo stiahnuť.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Maximálny počet dní, do ktorých sa očakáva platba od vášho kupujúceho, schválený HSBC.',
  d438da584c455e727642a303917e690d:
    'Zostávajúca suma úveru, ktorú možno využiť. Ide o rozdiel medzi Úverovým limitom a Využitým úverom.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Celková suma faktúr, ktoré sú v súčasnosti financované v rámci tohto programu. Táto suma sa vypočíta na základe úverového limitu a aktuálnej sumy faktúr, ktoré sú financované.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Celková suma faktúr, ktoré môžu byť financované v rámci tohto programu. Táto suma sa vypočíta na základe úverového limitu a aktuálnej sumy faktúr, ktoré sú financované.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Celková suma peňazí, ktorá bola zadržaná pred úhradou faktúry.',
  '13f271ac486faca89c1944226ba0efff':
    'Celková nominálna hodnota všetkých importovaných faktúr.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Celková nominálna hodnota faktúr, ktoré boli presunuté do stavu uhradené, pretože boli v plnej výške zinkasované.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Celková nominálna hodnota financovaných faktúr.',
  '565c255165bed2ff2640d0e42369c62c':
    'Celková hodnota faktúr, ktoré nie sú oprávnené na financovanie.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Tento účet nie je aktívne zaregistrovaný v systéme Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Tento údaj sa zvyčajne aktualizuje nasledujúci pracovný deň. Ak potrebujete poznať aktuálne zostatky, pošlite požiadavku na podporu.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Táto hodnota odráža váš posledný zostatok čiastkových platieb vrátane úrokov.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Spolu s',
  '603e4b3b3ac7b888a06548d14435bc25': 'Príliš veľa zriedení',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Celkom',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Celková inkasovaná suma',
  f786c8a1095bdf155841d510bb53142a: 'Celkový úrok z omeškania.',
  '4f25d1096076868bc590da9f8a26cf55': 'Celkový limit',
  '230bd56af0acab75194f58b46a763535':
    'Celková suma inkasovaná z platieb kupujúceho.',
  '8f78461d739b4d6d46f961f352000cec': 'Celková suma inkasovaná od vás.',
  b62dd565f60dc6172605d8db223510b9:
    'Celková suma inkasovaná prostredníctvom prevodu kupujúceho, prevodu predávajúceho alebo spätného vrátenia vrátane úrokov z omeškania.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Celková financovaná suma v porovnaní s vašimi predajmi.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Celková suma, ktorá nebola financovaná, podľa percentuálneho podielu preddavkovej platby schváleného HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Celková suma financovaných faktúr inkasovaných v plnej výške.',
  a069138e83a8b4cb7e81145689155826:
    'Celková suma úrokov z omeškania, ktorá zostáva k úhrade alebo je po splatnosti.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Celková suma splatných poplatkov za oneskorené platby. Penále sa účtujú denne.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Celková suma uhradených poplatkov za oneskorené platby.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Celková suma poplatkov za oneskorené platby, ktorá zostáva k úhrade alebo je po splatnosti.',
  d649fb9a0971deaa127067341f59be91:
    'Celková suma získaná z iných odpočtov na faktúre.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Celková suma, ktorá má byť ešte inkasovaná alebo splatená.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Celková inkasovaná suma',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Celková nominálna hodnota faktúr, ktoré nie sú oprávnené na financovanie.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Celková nominálna hodnota faktúr zaslaných kupujúcemu (kupujúcim).',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Celková financovaná suma prijatá bankovým prevodom od kupujúceho, bankovým prevodom od predávajúceho alebo vrátením späť.',
  d4867ee77e79ded226c07918b9393715: 'Celkové zaplatené úroky z omeškania.',
  de4c28160afe1ab48bcf83be49bbb196: 'Celkový limit',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Celková neuhradená suma',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Celková neuhradená financovaná suma a všetky úroky z omeškania, ktoré máte uhradiť. Dôvodom môže byť omeškanie splatnosti jednej alebo viacerých financovaných faktúr alebo odmietnutie jednej alebo viacerých vašich faktúr kupujúcim po ich financovaní.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Najčastejšie otázky na hotovostný program Tradeshift',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Typ transakcie',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Nepodarilo sa stiahnuť faktúru vo formáte csv. Kontaktujte technickú podporu',
  c8415d81378085ba3933015b8f5fc768:
    'Nepodarilo sa načítať zmluvu. Kontaktujte technickú podporu',
  '64580a198c861aa0226c91b28f8418aa': 'Nie je k dispozícii na predaj',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Nezaplatené faktúry po tomto dátume sa považujú za faktúry „po splatnosti“ a následne sa z nich budú denne počítať úroky.',
  '9d632e72031eea3767881d6c3adcfe54': 'Použitý úverový limit',
  e871a6882109bd7ef07827882236c8db: 'Zobraziť zmluvu',
  '2ac548c28faaa41283f9d6b7606a4844': 'Zrušené',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Vitajte v aplikácii Cash!',
  '78eb58937961a18929a623397b544a01':
    'Splatný zostatok dlžnej splátky môžete uhradiť priamym prevodom na splátkový účet (uvedený vyššie).',
  f347abf54f65274a614a9ba7515dfa98:
    'Pomocou aplikácie Cash môžete sledovať všetky svoje skoré platby na jednom mieste',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Váš program Cash je aktívny!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Váš hotovostný program je neaktívny',
  '6e6e06469dd953a124d718cd2307e32c':
    'Vaša účasť v programe Cash nie je aktívna od {date}. Kontaktujte prosím <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'financovaná faktúra, ktorá bola odmietnutá kupujúcim pred uplynutím odkladnej doby (úrok narastá denne odo dňa odmietnutia).',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'neuhradená financovaná faktúra na konci obdobia odkladu (s následným denným úročením) a/alebo,',
  '33e67e73d7f54aaee31e6040f873be79': 'uplatnené na odpočty',
  a9798a3e063111de4d4768c363bdc267: 'aplikované na faktúry',
  a5be13d3bfec5fc92521a841009db3c7: 'číslo bankového účtu',
  '736c1e36ac779a73ff4fddd19d82008c': 'denná úroková sadzba',
  ab53acde68c117f8fbfff6a8901de083: 'denná sadzba úroku z omeškania',
  '4c584eabd4260d69914ac993cc4db5d2': 'dní',
  '047dbee224da5fb4ecfc21268ffd3115': 'Dátum splatnosti',
  dd051262964c80130a2e9023ff933d89: 'skoršia platba',
  bc55e8a10310a96eeda133ddf159303d: 'predpokladaný dátum splatnosti',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'pre skoršie platby',
  '932d9bd46daba4d09f361717c0ec03ab': 'financovaná čiastka',
  '5e82566144af4903b40303fc3d1c799e': 'dátum financovania',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'doba odkladu',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'splatnosť',
  '2db524546615d120af0adf844a6e1bd7': 'z',
  '99c2f4783bb6821d84ad17a22f556813': 'z financovaného',
  '7555b72d3b4ffe69558b68b3cb86b954': 'Žiadne',
  c138be463a457afe89e5ecfefc6a3dc9: 'nesplatené dobropisy',
  '37f59e13afd5455cf130060ed55f4976': 'smerový kód banky',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'doba splatnosti',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'na nasledujúci bankový účet:',
  cf4edd5f38054a752994e3242a1f3807: 'celková suma',
  '3c9f634310f5e3de42b79ee83d20923c': 'celkové poplatky',
  '573b1b7930d8030d1669945f2c34992a': 'prevedené do vašej banky',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'budú odpočítané z nasledujúcich platieb',
};
