export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Upraveno z důvodu měny nebo státních svátků)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Poplatky a data plateb jsou pouze orientační a nelze je zaručit.',
  fa090ada546b5deb33cdead006822e26:
    '*Poplatky a data plateb jsou pouze orientační a nelze je zaručit.',
  '52fce318164dc60017ca67121172aca9': 'Přijaté',
  '615a093396ed9c6f52cd7ece7217608e': 'Úprava účtu',
  '79dd431d35b370210a3dbeb1bb653d43': 'Název účtu',
  '8c66a974a95769447407c061a36d0824': 'Číslo účtu',
  '6a810f9427275516cc7ac687ea182f27': 'Naběhlý úrok z dlužné částky',
  '21df2d8134802290caddc9a8070e9d94': 'Naběhlé poplatky z prodlení',
  '7377dcc6989501c8299b7ccf697b731e': 'Naběhlý úrok z dlužné částky',
  dab81136f1c6e799c5626826349a8dcf: 'Naběhlé poplatky z prodlení',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Vše',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Všichni odběratelé',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Všechny měny',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Všechny faktury',
  d7db3397d1ee87530b253bc69ebca041: 'Částka',
  c7586d1e153c74713afd948b3048d03d:
    'Částka, která vám byla zaplacena po odečtení úroků a případných opravných prostředků z financované částky.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Částka, která byla připsána zpět na základě opravných prostředků splatných u jiné faktury (jiných faktur).',
  '57e646b300b4b551d662b0cd536a6ad5': 'Částka k poukázání',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Veškeré přebytečné finanční prostředky budou převedeny na vás.',
  de9800ff635c316938aece381c98b094:
    'Veškeré nesplacené nedostatečné úhrady a úroky lze odečíst od splatné kupní ceny nebo od jiných inkas. Nemůžeme-li inkasovat těmito způsoby, můžeme vás požádat, abyste uhradili částku přímo kupujícímu.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Schválené',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Položit otázku',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Dostupný úvěrový limit pro nové financování.',
  '85a6c2f454268a7b3c4156c070abc517': 'Čekající na schválení',
  '99fb0198659b8076326d69a42670433b':
    'Zůstatky se obvykle aktualizují v následující pracovní den.',
  '0448c27d5574d95fc844e50416fc13a3': 'Balíček',
  '84b730c6e665f3a83a96a8511b4f63db': 'Podrobnosti balíčku',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Pobočka odběratele',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Odmítnutá faktura kupujícího',
  cdb5b8f6940144b32dc7f27b7700719a: 'Avízo kupujícího',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Odběratel označen jako uhrazený',
  e7094a08ea031332f2a7e7f94b214174: 'Avízo kupujícího',
  b8b64034058f45fe990f8377c7990c6b: 'Účet pro vrácení peněz kupujícímu',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Kupující:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Kupující zde uhrazují faktury. Zde se uhrazují nedostatečné úhrady',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Při výchozím nastavení se splatná částka opravného prostředku odečte od další financované faktury (faktur).',
  cb1a025651df74878d8d5af1b99991df: 'Zrušeno',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Informace o hotovostním programu',
  '6fd54168f061cd9f269dbcbb29415486': 'Výše poplatku',
  '252982cd07a2c1e75a9cadb781c4428f': 'Získat zpět',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Kliknutím zkopírujete',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Zavřít',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Uzavřeno',
  a150472168406890d84a6a5c3c8a894c: 'Shromážděno',
  '0743742edda54d26e47f9959ca4be4f0': 'Inkasovaný úrok z prodlení',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Inkasované poplatky z prodlení',
  '0b2194f7621b8a036308dce1647b982d': 'Vybraná částka',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Inkasovaný úrok z prodlení',
  b1a3b2482cd668f631d724aac2dfc685: 'Inkasované poplatky z prodlení',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Inkaso',
  ba18fa9a0a1da454462f71c6e36139ff: 'Zůstatky inkasa',
  '1103815eae13527faa8d51c83c20d17e': 'Údaje k inkasu',
  '898e15d1fa445c9c37ca795f79029f6d': 'Oficiální název společnosti',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Pokračovat',
  c05df0411bb18c405aaa68153432bf10:
    'Úvěrový limit a limit faktury byly aktualizovány na',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Dostupný kredit',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Úvěrový limit',
  d23014cf5a5e7f8c6440b96692831aa8: 'Dobropis',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Využitý kredit',
  '207df9f33356e378a1ff2ef7b62becc7': 'Dostupný úvěr',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Úvěrový limit',
  '208d5141343637870387b27db87ccf05': 'Byl překročen úvěrový limit',
  '0e27340c6eb226df1076c4f8930d2289': 'Úvěr využit',
  e15a66f111bc879c77f24943a94d19e7: 'Měna není podporována',
  b1e5986f972b7599559dea22cfeae8fb: 'Měna:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Denní sleva',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Denní diskontní sazba',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Denní sazba po splatnosti',
  '9e268b45f182ad1851f03539be6ab29c': 'Denní diskontní sazba',
  c2191b6160c46ed7a4b05cc0279158f3: 'Denní sazba po splatnosti',
  e2d9ef555d350e16239f4dfc92842fdc: 'Řídicí panel',
  '106202929cf48a3de3f20b3255365915': 'Datum',
  '2eca727eade526acc715c2e5605ebc7e':
    'Datum, ke kterému byla financovaná částka plně bypořádána.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Datum, kdy končí třicetidenní lhůta odkladu.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Datum, kdy byla faktura odmítnuta vaším kupujícím.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Dny po termínu splatnosti',
  '1e1e68c46e0d719364bd445b1d605144': 'Dny po termínu splatnosti',
  bd50c01a22cae65be4d588abcae65a90: 'Sleva',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Odpočty',
  d706035ec5f053ca33b234ddec228ab2: 'Popis',
  a39bf2f30994908fe95498524b423d43: 'Podrobnosti',
  '193d60da437b58b24ed69a5683c1ffcf': 'Ovlivněno odpočty',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Výše odpočtů',
  d07e29091188f3b0871dc7712eee7255: 'Typ odpočtů',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Přímé poukázání platby prodejcem',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Přímé poukázání platby prodejcem',
  afc1c7dfc9315e856f8da52e2450182b: 'Sleva',
  '26e7eb298420701d5a03c53fe096cc0b': 'S námitkami',
  '43cca9c1b76ebf869c7bf22fff271689': 'Filtry stavu dokladu',
  ce73b429141cda25571c88d9b0c78224: 'Stav dokladu',
  af13cc2ad0a7337c45420f75b7d5a668: 'Stáhnout',
  '8475be8bb8249000118af931ae75c373': 'Stáhnout sestavu',
  '90567e36a540e51806d7790eb195b1d5': 'Stáhnout všechny faktury',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Koncept',
  '72015a772310560b755bd03132a543b2': 'Datum splatnosti',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Datum splatnosti',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Datum splatnosti je příliš brzy',
  '6def6918bd02ae5117332f4262cecb52': 'Způsobilé',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Stav registrace',
  cb339c5c02cfb087fc8d393f917379df: 'Chyba při načítání řídicího panelu.',
  e9994b26424cb4bce5059d05e094df8d: 'Odhadovaný poplatek',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Vyloučená země',
  fd9d568c402dd04e0715e877d76f7da5: 'Nominální hodnota faktury.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Neúspěšné',
  '817f1fe84c2d87894bc374681814abbf': 'Načtení obsahu {title} se nezdařilo –',
  '7932079ce6ea928bfa77efd91578312d': 'Poplatky',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Celkové součty zobrazíte filtrováním podle měny.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'Předpokládané datum platby je příliš pozdě',
  e5af505b37031920b08edb84df19b5ec: 'Datum inkasování plně financované částky',
  a4e77d51ea7969a9e1fd98d59f99c158: 'Datum inkasování plně financované částky',
  '6dd341953c8c405c70878a457622cf55': 'Financováno',
  '42e20c6bb1dc82fbdbd889f769850320': 'Financovaná částka inkasována',
  cab921680af6ff282ce37398b0b231d4: 'Financovaná částka',
  fc0347ceea208298049efd79021475a2: 'Financovaná částka inkasována',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Datum zafinancování',
  '55d900eb37f97b684c0bd5821f161216': 'Financované faktury',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Financování',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Výjimka z financování',
  '5ca89ce217f3bb07293db00a42c10200':
    'Důvod (důvody) nezpůsobilosti financování',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Přehled financování',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Žádost o financování byla úspěšná',
  d5ec85821723434aba05986044ebdcdd: 'Stav financování',
  '3447158e3ace443dbe27556e07c88639': 'Filtry stavu financování',
  '022848540bf0e01453be6cf79843aa67': 'Doba do splatnosti financování faktury',
  b4a59826d5bfc276fa88bd00abac615a: 'Podrobnosti financování',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Doba do splatnosti financování faktury',
  e74f45b44aadcdd084b5cbdd3560f515: 'Datum ukončení lhůty odkladu',
  '5434cf75f987ef76dbdc544fa1e32096': 'Způsob úhrady',
  cb9f9412bd12122bb088124afccf738b: 'Jak splatit nedostatečné úhrady?',
  '9ad57f0b683fecb097c09bedebf68a45':
    'Jak vyřešit splatnou částku opravného prostředku:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'Fakt.',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Pokud se domníváte, že se jedná o chybu, kontaktujte prosím uživatele <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Důležité informace',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'Probíhá',
  '313b15a4fdfa2368015e2eb67416af2a': 'Neaktivní program',
  '7b6a003ceca7fe7fec85daae919e800b': 'Neoprávněné',
  '6cf0784c1ac918c22cd402e0cb558749': 'Nezpůsobilý důvod',
  '65cb9c651d2d9fb8941050a08add03c3': 'Neoprávněné pro včasné platby',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Nezpůsobilé faktury',
  c555faa7b0b745638bb3ea785ee10137:
    'Zobrazené informace byly naposled aktualizovány dne',
  b7311d63c44566a8562d92720cffed2e: 'Nedostatečné inkaso k poměrné fakturaci',
  fb07cb1753537f6969513f2d5fdb7993: 'Výše úroku',
  '1938e208f65e66474dc4148c3fa7da08': 'Odpočtený úrok',
  '42094e40339ac5c0957a980062c47659': 'Úrok naběhlý z nedostatečné úhrady',
  d9580a19710f07e8c72c215b1270ca57:
    'Úroky z financované částky účtované společností HSBC.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Odpočtený úrok',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Úroky z prodlení u faktury po splatnosti',
  '47a565198d287d0864339c077819a361':
    'Úroky z nesplacené dlužné částky budou dále nabíhat, dokud nebudou buď odečteny od další platby pro vás nebo dokud neobdržíme přímou úhradu.',
  '3c4be400f728da4526de626653d56ccc': 'Faktura',
  '70546e9227f592c24d3c491756cb901d': 'Faktura č.',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Fakturovaná částka',
  '6b9515d101794c31e38ae60d092fdc03': 'Datum faktury',
  '2548c6a52ce9e1bffef7aad14530f490': 'Faktura je financovaná',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Přehled financování faktury',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Limit faktury',
  '734ed127b3d7226c75e8553c0711367a': 'Použitý limit faktury',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Celkový limit faktury',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Číslo faktury',
  da2db17c9b22f995d6673a754d998fc8: 'Faktura po splatnosti',
  d729bd1bdced62265cd4ecd7131ef94e: 'Faktura odmítnuta',
  e14208d4378a3378be8b27eb15984fc6: 'Faktura uhrazena',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Stav faktury',
  '54d8df173250a197fab1644a0d0a772a': 'Fakturovaná částka',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Fakturu nelze v tuto chvíli financovat. Více informací naleznete v důvodech pro nezpůsobilost k financování.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'Měna na faktuře neodpovídá příslušné zemi',
  d0da9e977c8b309f93ee378de34fb251: 'Datum faktury',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Datum faktury před nejdřívějším datem způsobilosti',
  ad0ab15468b719694f3193d0649ad9eb:
    'Datum faktury je po datu zrušení zařízení.',
  fc9e80713aebb3d820d888f0755fedde:
    'Na faktuře není kupující přihlášený k odběru',
  e17cbec04e7786361eff9da629f09bb7: 'Faktura má budoucí datum vystavení.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Faktura byla zrušena.',
  '89f842841b4ae213259b94a5411453f2': 'Faktura je ovlivněna odpočty.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'Faktura je financována v rámci hotovostního programu.',
  ab5303505d045929abb1256607ca0d4d:
    'Faktura byla uhrazena v plné výši. Nejsou vyžadovány žádné další platby.',
  '3e159746cbdfda07b2f45750941e26f6':
    'Bylo požádáno o financování této faktury.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'Stav dokladu této faktury je „nezpůsobilý“.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Faktura nebyla zcela uhrazena a překročila konec lhůty odkladu',
  d87ca92704bcfb34fa4ac08a4d24147a: 'Faktura je aktuálně nezpůsobilá.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Na faktuře chybí datum ukončení nebo závěrečné datum ukončení',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Faktura je příliš blízko k předpokládanému datu splatnosti.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Faktura je neobvyklá',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Faktura je označena jako již uhrazená.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Faktura může být duplikát.',
  b1f771da19efea2f0fa6a542823231b9: 'Faktura pouze částečně způsobilá',
  '4217948ca113ee4158d5dd4c07d71624': 'Faktura uhrazená nedostatečnou úhradou',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Doba do splatnosti faktury není v přípustném rozsahu',
  '7c3fa246e8cca51273ddf5062ff2d593':
    'Doba do splatnosti faktury je příliš dlouhá',
  '90d9ac20dda384e9c07554d29b143bef': 'Hodnota faktury překračuje limit',
  '4b36f0be327fdca8d54126cd57095a13': 'Faktura/číslo RA',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Faktury',
  '8089283aad9294aadc2bde165ccc78c1': 'Datum vystavení',
  '3a4123f9c604369ab18781f7492f9293': 'Datum vystavení',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Datum poslední výplaty',
  a94bc4d4df1d56c16e25493e22684412: 'Dostupný limit',
  '92f97c843d499a1559828ba0a9134e24': 'Používaný limit',
  b5dd8e130d88698834ee00464f01cea4: 'Dostupnýu limit',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Používaný limit',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Nahrává se...',
  '24cc5627dea65e22f9279be9522a06e2': 'Výše půjčky',
  bd24bcedd066a27749016a07cb729a32: 'Označené jako uhrazené',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Datum splatnosti',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Datum zahájení členství',
  d26429adfb846303cbd889879b23e1ba:
    'Peněžní částky vybrané buď z úhrad kupujícího, provedených plateb prodávajícím nebo z odpočtů před uplynutím doby odkladu, popř. po něm.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Čistá zaplacená částka',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Čistá zaplacená částka',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nová částka',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Následující pracovní den',
  '325c48f099844279c0cf48584de42e64': 'Žádná nedávná aktivita',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Žádné',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Není aktivně zapsán do programu Cash',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Není dostatečná historie faktur',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Nedostatečné množství faktur v předchozích měsících',
  c9e2ce8da559465a8596317c35e8f3a4: 'Nedostatečné množství uhrazených faktur',
  e6f890deafa579eb0c24603c3101c1c7:
    'Upozorňujeme, že zůstatky jsou obvykle aktualizovány v následující pracovní den. Pokud potřebujete znát aktuální siatuaci, vytvořte požadavek na zákaznickou podporu.',
  '83070a059f10db1515ac922bf3433158':
    'Počet dní po datu ukončení lhůty odkladu.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Je k dispozici jedna sestava ke stažení',
  '2778717f6acbeb1edaac6a2656b478f3': 'Otevřít',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Jiný poplatek',
  fad92e97e692758c0d0a9b5730663894: 'Nesplacený úrok z dlužné částky',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Nesplacená financovaná částka',
  '9eab1baddf2537312408088fc54f21d5':
    'Neuhrazené poplatky po termínu splatnosti',
  bfba12a2c234532c0af24a4ad6831ab6: 'Nesplacená celková částka',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Nesplacený úrok z dlužné částky',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Nesplacená financovaná částka',
  '2f9a3de6940f818fe0376e4aece59512':
    'Neuhrazené poplatky po termínu splatnosti',
  '6f79eea38bff17917740a69116270074': 'Po termínu splatnosti',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Zůstatky po termínu splatnosti',
  '848ac8c39b11a751d697a383303e6109': 'Dlužná částka po termínu splatnosti',
  '8d1e868665fc24a46f0cf55187f72218':
    'Dlužná částka po splatnosti se vykazuje v souladu s předpisy RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Přehled',
  '5b885084a5067d7fb4372cef38d8a82d': 'Zaplacené',
  '830b95d305cdc61a66cdf75ebff1d737': 'Zaplaceno kupujícím',
  '6444796646c95dbc2cd1cb646e41293d':
    'Část nominální hodnoty faktury, která je financována podle procentního podílu záloh schváleného institucí HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Údaje o zúčastněném kupujícím',
  a39314e5897b8baaa51ca296610ec30c: 'Partnerské podmínky',
  a6acd5f03fda9c8dc607e59d8be08320: 'Zpoždění schválení závazků',
  e6249829dadf637c4092e4a900df529e: 'Splatná částka byla kupujícím zpochybněna',
  '07ed468d28d8e8437d8ec64036b96c03': 'Splatná částka byla odmítnuta',
  '042b8dfb88f7045ca9145bbcccf79200': 'Splatná částka je v budoucnu',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Závazky jsou příliš staré',
  '8755bbc212b541efb6576f39f3f0f51f': 'Platba byla přidělena',
  '8744ee10540166dfc39db2a6859a9ce1': 'Částka úhrady',
  '56133aa371eb0132334e4867479be942': 'Platební údaje',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Prodlení platby',
  '2dd7164df606f9b42beefaba031b06f7': 'Platba byla obdržena',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Platba potvrzena prodejcem',
  '661711004f06b0d9601db365679be130': 'Výplatní částka',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Datum výplaty',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Penále',
  '0fd036622716f275c3e14c5a14faa65a': 'Nevyřízené',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Nevyřízené odpočty',
  '8cf3479829ba47a8a4123895d0088eeb': 'Nevyřízené odpočty',
  '270b56fd05f72407ca0607b6162d0b10': 'Nevyřízené propojení',
  '4a78218416ca45d97b5633b37ae3f638':
    'Procentní sazba, kterou se vypočítávají denní poplatky pro dobu trvání celé doby do splatnosti financování faktury.',
  b586a5468c7070bf1717593ea84fa607:
    'Procentuální sazba, kterou vám budou denně účtovány poplatky z nesplacené financované částky za zpožděné nebo financované a odmítnuté faktury.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Pokud potřebujete znát aktuální zůstatky, vytvořte požadavek na zákaznickou podporu.',
  '34645fa620cae4187f27d9f96c172f26': 'Pošlete prosím částku',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Poukažte prosím následující částku na následující bankovní účet:',
  '01734a7316427fdcdcd4f937cda3802b': 'Poskytnutá záloha',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Předchozí částka',
  '500fc4731dab800a878b33906d9332fb': 'Inkasní účet programu',
  '7327113634d1f5f4f68632dceebfbc6f': 'Přehled programu',
  d95ebfb51a485293a134b1038d332a10: 'Program aktivován dne',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Podrobnosti o programu nejsou k dispozici pro Řídicí panel',
  '98feb2c86a81b8caeeaa30966ba34636': 'Informace o programu',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Nedostatečná úhrada se vygeneruje při úhradě faktur na zakoupené položky, které nejsou inkasovány v plné výši do očekávaného termínu splatnosti. Úrok z nedostatečných úhrad činí 1,5násobek denní diskontní sazby a vaší povinností je splatit obojí.',
  '521689b600b8da5b0a111b89dea72126': 'Důvod',
  fe7bd622a1efcec03eb86dabb9395936: 'Obdržené',
  e84203a8833e4d0d92faf89f4107bf11: 'Vrácení opravného prostředku',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Refundovaná částka',
  b0be744ae1f6312f16990bbd92a87aea: 'Datum vystavení refundace',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Doba splatnosti refundace',
  '654d6dcc141079bc84500fd14ca5087d': 'Refundovaná částka',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Údaje o refundaci',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Datum vystavení refundace',
  c0a6a91e7ca80af9de47097b06dc7618: 'Doba splatnosti refundace',
  '4a2875439ab289a068abb9ea3b567bcb': 'Odmítnuto',
  '592b34cdfa2e51826b32a0783e0edce3': 'Odmítnuto kupujícím',
  '807d35f105b65ab2795c4f8e51795f3c': 'Odmítnuto prodejcem',
  aea4dd208e871d7ca0051b0ec38c3068: 'Datum odmítnutí',
  d64ed4934fbf68a8f716467b0adf89ba: 'Datum odmítnutí',
  '5a77de3b287a92494be077c197f37581':
    'Zbývající financovaná částka, která má být ještě inkasována nebo splacena.',
  e442b864bf745a75477ea0ee88bc15c4: 'Splatnost splátky',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Částka opravného prostředku k datu ukončení lhůty odkladu. Změna této částky neodráží případné splátky opravného prostředku provedené po tomto datu, ani nezahrnuje dlužnou částku po termínu splatnosti.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Splatný opravný prostředek',
  dc20cbfb76d220c108241a451bc3faf4: 'Informace s pokyny k opravnému prostředku',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Splatné opravné prostředky představují celkovou nesplacenou částku, kterou je třeba vymoci, jako následek buď toho, že částka uhrazená kupujícím je nižší než nominální hodnota na faktuře; nebo že kupující odmítl fakturu poté, co již byla zafinancována.',
  '45b60e22719c12291932bf09db0df5cd':
    'Splatné opravné prostředky představují celkovou nesplacenou částku, kterou je třeba dostat zpět, v důsledku jednoho ze dvou důvodů:',
  ba093b1421452b3afdebbebedaeae552: 'Požádat o financování',
  '34f8f9968ec0d7f91e558e906a7de982': 'Vyžádáno',
  dc1650cfbadb05f8fb6357692e48ee71: 'Žádost o financování',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Zadrženo',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Zadržená částka',
  a476316a89541d1aa71d5b5366874e13: 'Zadržená částka',
  '328f6585a0e78a96deefc0661474248f': 'Zkusit znovu',
  '626efcff97ad72e5eb9cc8a602aec8bd': 'NÚ',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Odbyt',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Hledat podle čísla faktury',
  '3215999493db188b35242dc4f4fd8fb5':
    'Další informace o opravných prostředcích naleznete v Informacích o programu.',
  '69f43575c3a51e94605eb75b75f7b563': 'Vybrané',
  '56a1c7c4a02e9985745969563a80799f': 'Účet pro opravné prostředky prodejce',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Odesílání',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Odeslané',
  '95d54926719f1071b2fa640f1ba23d3e': 'Uhrazené',
  e243f36392a7f264d830f04f21aa8972: 'Uhrazené faktury',
  b548572d4123e3a569d27e4676664dad: 'Krátká platba',
  '6e09ada7e33833d259370b0cc900f082': 'Částka nedostatečné úhrady',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Odpočty nedostatečných úhrad',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Nedostatečné úhrady',
  '46bbc145b1f85c082950c768ade012f6': 'Částka nedostatečné úhrady',
  e66e6d9768e8f489c05951ee70ede350:
    'Něco se pokazilo, všechny funkce řídicího panelu nemusí být k dispozici. Zkuste to prosím znovu nebo kontaktujte podporu pro pomoc.',
  cd969f183dc619b5716067cfda39c055: 'Kód pobočky',
  '17939071126e9e41921d79b837f63297': 'Jméno dodavatele',
  '750d4460b260343039f9bc71b66a231c': 'Dodavatel je považován za insolventního',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Dodavatel zatím není v programu nastaven',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Podpora',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Podmínky přijal',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Podmínky poskytování služby',
  '1294cf929ec55581954a79948592dda9':
    'Datum, kdy bylo aktivováno financování hotovostního programu.',
  f51144e4468390a486d41b4db66fc243: 'Soubor nelze stáhnout.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Maximální počet dní, kdy je očekávána platba od vašeho kupujícího, jak byla schválena institucí HSBC.',
  d438da584c455e727642a303917e690d:
    'Zbývající částka úvěru, kterou lze využít. Představuje rozdíl mezi hodnotami Úvěrový limit a Využitý úvěr.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Celková výše faktur, které jsou v současné době financovány u tohoto programu. Tato částka je vypočtena na základě úvěrového limitu a současné výše financovaných faktur.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Celková výše faktur, které jsou v současné době financovány u tohoto programu. Tato částka je vypočtena na základě úvěrového limitu a současné výše financovaných faktur.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Celková výše peněžních prostředků, které byly zadrženy před vyrovnáním faktury.',
  '13f271ac486faca89c1944226ba0efff':
    'Celková nominální hodnota všech importovnaých faktur.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Celková nominální hodnota faktur, které byly přesunuty do stavu Urovnáno, protože byly plně zinkasovány.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Celková nominální hodnota financovaných faktur.',
  '565c255165bed2ff2640d0e42369c62c':
    'Celková hodnota faktur, které nejsou způsobilé k financování.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Tento účet není aktivně zapsán do programu Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Obvykle aktualizováno následující pracovní den. Pokud potřebujete znát aktuální zůstatky, vystavte požadavek na zákaznickou podporu.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Tato hodnota odráží váš poslední zůstatek nedostatečných úhrad včetně úroků.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Spolu s',
  '603e4b3b3ac7b888a06548d14435bc25': 'Příliš mnoho odpočtů',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Celkem',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Celková inkasovaná částka',
  f786c8a1095bdf155841d510bb53142a: 'Celkový úrok z prodlení',
  '4f25d1096076868bc590da9f8a26cf55': 'Celkový limit',
  '230bd56af0acab75194f58b46a763535':
    'Celková částka inkasovaná z plateb kupujícího.',
  '8f78461d739b4d6d46f961f352000cec': 'Celková částka inkasovaná od vás.',
  b62dd565f60dc6172605d8db223510b9:
    'Celková částka inkasovaná prostřednictvím poukázání od kupujícího/prodávajícího, popř. prostřednictvím zpětného získání včetně případných úroků z prodlení.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Celková částka financovaná proti vašemu prodeji.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Celková nefinancovaná částka na základě procentního podílu záloh schváleného společností HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Celková částka financovaných faktur inkasovaných v plné výši.',
  a069138e83a8b4cb7e81145689155826:
    'Celková výše úroku z prodlení nesplacené dlužné částky nebo dlužné částky k úhradě.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Celková výše splatných poplatků z prodlení. Penále nabíhají denně.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Celková částka uhrazených poplatků z prodlení.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Celková výše nesplacených poplatků z prodlení nebo poplatků z prodlení k úhradě.',
  d649fb9a0971deaa127067341f59be91:
    'Celková částka získaná zpět z jiných odpočtů na faktuře.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Celková částka, která má být ještě inkasována nebo splacena.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Celková částka k inkasu',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Celková nominální hodnota faktur, které nejsou způsobilé pro financování.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Celková nominální hodnota faktur zaslaných kupujícím(u).',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Celková financovaná částka, která má být inkasována prostřednictvím poukázek kupujícího, prodávajícího, popř. prostřednictvím zpětného získání.',
  d4867ee77e79ded226c07918b9393715: 'Celkový splacený úrok z prodlení.',
  de4c28160afe1ab48bcf83be49bbb196: 'Celkový limit',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Celková nesplacená částka',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Celková nesplacená financovaná částka a veškeré úroky z prodlení, které máte splatit. To může být způsobeno zpožděním jedné nebo více financovaných faktur nebo tím, že váš kupující odmítl jednu nebo více faktur po jejich zafinancování.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Časté dotazy na hotovostní program Tradeshift',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Podrobnosti transakce',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Soubor CSV s fakturou nelze stáhnout. Kontaktujte podporu',
  c8415d81378085ba3933015b8f5fc768: 'Smlouvu nelze načíst. Kontaktujte podporu',
  '64580a198c861aa0226c91b28f8418aa': 'Není k dispozici k prodeji',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Neuhrazené faktury po tomto datu jsou považovány za „zpožděné“. Následně budou každý den nabíhat úroky.',
  '9d632e72031eea3767881d6c3adcfe54': 'Použitý úvěrový limit.',
  e871a6882109bd7ef07827882236c8db: 'Zobrazit smlouvu',
  '2ac548c28faaa41283f9d6b7606a4844': 'Anulované',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Vítejte v aplikaci Cash!',
  '78eb58937961a18929a623397b544a01':
    'Splatný zůstatek opravného prostředku můžete uhradit přímým poukázáním na účet opravných prostředků (uvedený výše).',
  f347abf54f65274a614a9ba7515dfa98:
    'Pomocí aplikace Cash můžete sledovat všechny své včasné úhrady na jednom místě',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Váš program Cash je aktivní!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Váš hotovostní program je neaktivní',
  '6e6e06469dd953a124d718cd2307e32c':
    'Vaše účast v programu Cash není od data {date} aktivní. Kontaktujte prosím uživatele <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'financovaná faktura byla před uplynutím odkladné lhůty odmítnuta kupujícím (úrok každý den od data odmítnutí narůstá)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'financovaná faktura je na konci doby odkladu neuhrazená (poté každý den narůstá úrok), nebo',
  '33e67e73d7f54aaee31e6040f873be79': 'uplatněné na odpočty',
  a9798a3e063111de4d4768c363bdc267: 'použito na faktury',
  a5be13d3bfec5fc92521a841009db3c7: 'číslo bankovního účtu',
  '736c1e36ac779a73ff4fddd19d82008c': 'denní úroková míra',
  ab53acde68c117f8fbfff6a8901de083: 'denní úroková míra po splatnosti',
  '4c584eabd4260d69914ac993cc4db5d2': 'dní',
  '047dbee224da5fb4ecfc21268ffd3115': 'datum splatnosti',
  dd051262964c80130a2e9023ff933d89: 'předčasná úhrada',
  bc55e8a10310a96eeda133ddf159303d: 'předpokládaný termín',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'pro včasné platby',
  '932d9bd46daba4d09f361717c0ec03ab': 'financovaná částka',
  '5e82566144af4903b40303fc3d1c799e': 'datum financování',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'doba odkladu',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'platba v den splatnosti',
  '2db524546615d120af0adf844a6e1bd7': 'z',
  '99c2f4783bb6821d84ad17a22f556813': 'z financovaných faktur',
  '7555b72d3b4ffe69558b68b3cb86b954': 'dne',
  c138be463a457afe89e5ecfefc6a3dc9: 'nesplacené dobropisy',
  '37f59e13afd5455cf130060ed55f4976': 'směrový kód banky',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'obsah',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'na následující bankovní účet:',
  cf4edd5f38054a752994e3242a1f3807: 'celková částka',
  '3c9f634310f5e3de42b79ee83d20923c': 'poplatky celkem',
  '573b1b7930d8030d1669945f2c34992a': 'převedeno do vaší banky',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'bude odečteno od nadcházejících plateb',
};
