export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Disesuaikan karena mata uang atau hari libur bank)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Biaya dan tanggal pembayaran bersifat indikatif dan tidak dapat dijamin.',
  fa090ada546b5deb33cdead006822e26:
    '*Biaya dan tanggal pembayaran bersifat indikatif dan tidak dapat dijamin.',
  '52fce318164dc60017ca67121172aca9': 'Diterima',
  '615a093396ed9c6f52cd7ece7217608e': 'Penyesuaian Akun',
  '79dd431d35b370210a3dbeb1bb653d43': 'Nama Rekening',
  '8c66a974a95769447407c061a36d0824': 'Nomor Rekening',
  '6a810f9427275516cc7ac687ea182f27': 'Bunga Utang yang Masih Harus Dibayar',
  '21df2d8134802290caddc9a8070e9d94':
    'Biaya yang Masih Harus Dibayar yang Telah Jatuh Tempo',
  '7377dcc6989501c8299b7ccf697b731e': 'Bunga utang yang masih harus dibayar',
  dab81136f1c6e799c5626826349a8dcf:
    'Biaya yang masih harus dibayar yang telah jatuh tempo',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Semua',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Semua Pembeli',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Semua Mata Uang',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Semua Faktur',
  d7db3397d1ee87530b253bc69ebca041: 'Nilai',
  c7586d1e153c74713afd948b3048d03d:
    'Jumlah yang dibayarkan kepada Anda setelah potongan bunga dan pelunasan yang jatuh tempo dari jumlah yang didanai.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Jumlah yang dipulihkan terhadap pelunasan yang jatuh tempo atas faktur lainnya.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Jumlah yang akan dikirim',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Setiap kelebihan dana akan diteruskan kepada Anda.',
  de9800ff635c316938aece381c98b094:
    'Segala pembayaran parsial dan bunga terutang dapat dikurangkan dari utang Harga Pembelian, atau penagihan lainnya. Jika kami tidak dapat mengumpulkan dengan cara ini, maka kami dapat meminta Anda untuk membayar nilai tersebut secara langsung kepada Pembeli.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Disetujui',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Ajukan Pertanyaan',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Batas Kredit yang tersedia untuk pendanaan baru.',
  '85a6c2f454268a7b3c4156c070abc517': 'Menunggu persetujuan',
  '99fb0198659b8076326d69a42670433b':
    'Saldo biasanya diperbarui di hari kerja berikutnya.',
  '0448c27d5574d95fc844e50416fc13a3': 'Bundel',
  '84b730c6e665f3a83a96a8511b4f63db': 'Detail Bundel',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Cabang Pembeli',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Faktur yang Ditolak Pembeli',
  cdb5b8f6940144b32dc7f27b7700719a: 'Pengiriman Uang Pembeli',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Pembeli ditandai telah dibayar',
  e7094a08ea031332f2a7e7f94b214174: 'Pengiriman uang pembeli',
  b8b64034058f45fe990f8377c7990c6b: 'Akun pengiriman uang pembeli',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Pembeli:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Pembeli membayar di sini untuk faktur. Anda membayar di sini untuk pembayaran parsial',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Secara default, nilai jatuh tempo pelunasan dikurangkan dari faktur yang dibiayai berikutnya.',
  cb1a025651df74878d8d5af1b99991df: 'Dibatalkan',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Informasi Program Tunai',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Klik untuk menyalin',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Tutup',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Ditutup',
  a150472168406890d84a6a5c3c8a894c: 'Telah Dikumpulkan',
  '0743742edda54d26e47f9959ca4be4f0': 'Bunga Utang yang Ditagih',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Biaya Lewat Jatuh Tempo yang Ditagih',
  '0b2194f7621b8a036308dce1647b982d': 'Nilai yang dikumpulkan',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Bunga utang yang ditagih',
  b1a3b2482cd668f631d724aac2dfc685: 'Biaya lewat jatuh tempo yang ditagih',
  ba18fa9a0a1da454462f71c6e36139ff: 'Saldo Penagihan',
  '1103815eae13527faa8d51c83c20d17e': 'Detail koleksi',
  '898e15d1fa445c9c37ca795f79029f6d': 'Nama Legal Perusahaan',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Lanjutkan',
  c05df0411bb18c405aaa68153432bf10: 'Batas kredit & faktur diperbarui pada',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Kredit Tersedia',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Batas Kredit',
  d23014cf5a5e7f8c6440b96692831aa8: 'Nota Kredit',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Kredit Digunakan',
  '207df9f33356e378a1ff2ef7b62becc7': 'Kredit tersedia',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Batas kredit',
  '208d5141343637870387b27db87ccf05': 'Batas kredit telah terlampaui',
  '0e27340c6eb226df1076c4f8930d2289': 'Kredit digunakan',
  e15a66f111bc879c77f24943a94d19e7: 'Mata uang tidak didukung',
  b1e5986f972b7599559dea22cfeae8fb: 'Mata uang:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Diskon Harian',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Tingkat Diskon Harian',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Tingkat Lewat Jatuh Tempo Harian',
  '9e268b45f182ad1851f03539be6ab29c': 'Tingkat diskon harian',
  c2191b6160c46ed7a4b05cc0279158f3: 'Tingkat lewat jatuh tempo harian',
  e2d9ef555d350e16239f4dfc92842fdc: 'Dasbor',
  '106202929cf48a3de3f20b3255365915': 'Tanggal',
  '2eca727eade526acc715c2e5605ebc7e':
    'Tanggal di mana jumlah dana yang didanai dilunasi secara penuh.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Tanggal berakhirnya masa tenggang 30 hari.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Tanggal saat faktur ditolak oleh pembeli Anda.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Hari Lewat Jatuh Tempo',
  '1e1e68c46e0d719364bd445b1d605144': 'Hari lewat jatuh tempo',
  bd50c01a22cae65be4d588abcae65a90: 'Potongan',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Potongan',
  d706035ec5f053ca33b234ddec228ab2: 'Deskripsi',
  a39bf2f30994908fe95498524b423d43: 'Detail',
  '193d60da437b58b24ed69a5683c1ffcf': 'Dicairkan',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Jumlah Pencairan',
  d07e29091188f3b0871dc7712eee7255: 'Jenis Pencairan',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Pengiriman Uang Penjual Langsung',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Pengiriman uang penjual langsung',
  afc1c7dfc9315e856f8da52e2450182b: 'Diskon',
  '26e7eb298420701d5a03c53fe096cc0b': 'Dibantah',
  '43cca9c1b76ebf869c7bf22fff271689': 'Filter Status Dokumen',
  ce73b429141cda25571c88d9b0c78224: 'Status dokumen',
  '8475be8bb8249000118af931ae75c373': 'Unduh Laporan',
  '90567e36a540e51806d7790eb195b1d5': 'Unduh semua faktur',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Draf',
  '72015a772310560b755bd03132a543b2': 'Tanggal Jatuh Tempo',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Batas akhir',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Jatuh tempo terlalu cepat',
  '6def6918bd02ae5117332f4262cecb52': 'Memenuhi syarat',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Status Pendaftaran',
  cb339c5c02cfb087fc8d393f917379df: 'Kesalahan saat memuat leaderboard',
  e9994b26424cb4bce5059d05e094df8d: 'Estimasi Biaya',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Negara yang dikecualikan',
  b5cb31ac9c7d1871e9075efc1f271536: 'BIAYA',
  fd9d568c402dd04e0715e877d76f7da5: 'Nilai nominal faktur.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Gagal',
  '817f1fe84c2d87894bc374681814abbf': 'Gagal memuat {title} -',
  '7932079ce6ea928bfa77efd91578312d': 'Biaya',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filter berdasarkan mata uang untuk melihat total yang dirangkum.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'Tanggal perkiraan pembayaran terlalu lama',
  e5af505b37031920b08edb84df19b5ec:
    'Tanggal Dikumpulkan Nilai yang Didanai Penuh',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Tanggal pengumpulan nilai yang didanai penuh',
  '6dd341953c8c405c70878a457622cf55': 'Didanai',
  '42e20c6bb1dc82fbdbd889f769850320': 'Nilai Didanai yang Terkumpul',
  cab921680af6ff282ce37398b0b231d4: 'Nilai yang didanai',
  fc0347ceea208298049efd79021475a2: 'Nilai didanai yang terkumpul',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Tanggal yang didanai',
  '55d900eb37f97b684c0bd5821f161216': 'Faktur yang didanai',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Pendanaan',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Pengecualian Pendanaan',
  '5ca89ce217f3bb07293db00a42c10200': 'Alasan Ketidaklayakan Pendanaan',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Gambaran Sekilas Pendanaan',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Permintaan Pendanaan Berhasil',
  d5ec85821723434aba05986044ebdcdd: 'Status Pendanaan',
  '3447158e3ace443dbe27556e07c88639': 'Filter Status Pendanaan',
  '022848540bf0e01453be6cf79843aa67': 'Tenor Pembiayaan',
  b4a59826d5bfc276fa88bd00abac615a: 'Detail pendanaan',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Tenor pembiayaan',
  e74f45b44aadcdd084b5cbdd3560f515: 'Tanggal selesai tenggang',
  '5434cf75f987ef76dbdc544fa1e32096': 'Cara membayar',
  cb9f9412bd12122bb088124afccf738b:
    'Bagaimana cara melunasi pembayaran parsial?',
  '9ad57f0b683fecb097c09bedebf68a45':
    'Cara mengatasi pelunasan yang jatuh tempo:',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Jika Anda yakin ini adalah kesalahan, silakan hubungi <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Informasi penting',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'Sedang Berlangsung',
  '313b15a4fdfa2368015e2eb67416af2a': 'Program Tidak Aktif',
  '7b6a003ceca7fe7fec85daae919e800b': 'Tidak memenuhi syarat',
  '6cf0784c1ac918c22cd402e0cb558749': 'Alasan Tidak Memenuhi Syarat',
  '65cb9c651d2d9fb8941050a08add03c3':
    'Tidak memenuhi syarat untuk Pembayaran Dini',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Faktur yang Tidak Layak',
  c555faa7b0b745638bb3ea785ee10137:
    'Informasi yang ditampilkan terakhir kali diperbarui pada',
  b7311d63c44566a8562d92720cffed2e:
    'Rasio penagihan terhadap faktur yang tidak cukup',
  fb07cb1753537f6969513f2d5fdb7993: 'Nilai Bunga',
  '1938e208f65e66474dc4148c3fa7da08': 'Bunga yang Dipotong',
  '42094e40339ac5c0957a980062c47659':
    'Bunga yang diperoleh dari pembayaran parsial',
  d9580a19710f07e8c72c215b1270ca57:
    'Bunga yang dibebankan oleh HSBC atas nilai yang didanai.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Bunga yang dipotong',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6:
    'Bunga yang timbul atas faktur yang lewat jatuh tempo',
  '47a565198d287d0864339c077819a361':
    'Bunga akan terus bertambah pada utang yang lewat jatuh tempo sampai dipotong dari pembayaran berikutnya kepada Anda, atau kami menerima pengiriman uang langsung.',
  '3c4be400f728da4526de626653d56ccc': 'Faktur',
  '70546e9227f592c24d3c491756cb901d': 'Faktur No.',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Nilai Faktur',
  '6b9515d101794c31e38ae60d092fdc03': 'Tanggal Faktur',
  '2548c6a52ce9e1bffef7aad14530f490': 'Faktur Didanai',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Gambaran Sekilas Pendanaan Faktur',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Batas Faktur',
  '734ed127b3d7226c75e8553c0711367a': 'Batas Faktur yang Sedang Digunakan',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Total Batas Faktur',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Nomor Faktur',
  da2db17c9b22f995d6673a754d998fc8: 'Faktur Lewat Jatuh Tempo',
  d729bd1bdced62265cd4ecd7131ef94e: 'Faktur Ditolak',
  e14208d4378a3378be8b27eb15984fc6: 'Faktur Dilunasi',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Status Faktur',
  '54d8df173250a197fab1644a0d0a772a': 'Nilai faktur',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Faktur tidak dapat didanai saat ini. Lihat Alasan Ketidaklayakan Pendanaan untuk informasi selengkapnya.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'Mata uang faktur tidak cocok dengan negara',
  d0da9e977c8b309f93ee378de34fb251: 'Tanggal faktur',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Tanggal faktur sebelum tanggal paling awal yang memenuhi syarat',
  ad0ab15468b719694f3193d0649ad9eb:
    'Tanggal faktur adalah setelah tanggal pembatalan fasilitas.',
  fc9e80713aebb3d820d888f0755fedde:
    'Faktur tidak memiliki pembeli yang berlangganan',
  e17cbec04e7786361eff9da629f09bb7:
    'Faktur memiliki tanggal penerbitan di masa mendatang.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Faktur telah dibatalkan.',
  '89f842841b4ae213259b94a5411453f2': 'Faktur telah dicairkan.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'Faktur telah dibiayai di bawah program Tunai.',
  ab5303505d045929abb1256607ca0d4d:
    'Faktur telah dibayar lunas. Tidak diperlukan pembayaran lebih lanjut.',
  '3e159746cbdfda07b2f45750941e26f6': 'Faktur telah diajukan untuk pendanaan.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'Faktur memiliki status dokumen yang tidak memenuhi syarat.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Faktur belum dilunasi secara penuh dan telah melewati batas akhir masa tenggang',
  d87ca92704bcfb34fa4ac08a4d24147a: 'Faktur saat ini tidak memenuhi syarat.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Faktur tidak memiliki tanggal akhir atau tanggal akhir final',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Faktur terlalu dekat dengan perkiraan tanggal pembayaran.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Faktur tidak biasa',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Faktur ditandai sebagai sudah dibayar.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Faktur mungkin merupakan duplikat.',
  b1f771da19efea2f0fa6a542823231b9: 'Faktur hanya memenuhi syarat sebagian',
  '4217948ca113ee4158d5dd4c07d71624':
    'Faktur yang dilunasi dengan pembayaran parsial',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Jangka waktu faktur tidak dalam rentang yang dapat diterima',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Tenor faktur terlalu lama',
  '90d9ac20dda384e9c07554d29b143bef': 'Nilai faktur melebihi batas',
  '4b36f0be327fdca8d54126cd57095a13': 'Nomor Faktur/RA',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Faktur',
  '8089283aad9294aadc2bde165ccc78c1': 'Tanggal Pembuatan',
  '3a4123f9c604369ab18781f7492f9293': 'Tanggal pembuatan',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Tanggal Pembayaran Terakhir',
  a94bc4d4df1d56c16e25493e22684412: 'Batas yang Tersedia',
  '92f97c843d499a1559828ba0a9134e24': 'Batasi yang Sedang Digunakan',
  b5dd8e130d88698834ee00464f01cea4: 'Batas yang tersedia',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Batasi yang sedang digunakan',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Memuat...',
  '24cc5627dea65e22f9279be9522a06e2': 'Nilai Pinjaman',
  bd24bcedd066a27749016a07cb729a32: 'Ditandai telah dilunasi',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Tanggal Jatuh Tempo',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Tanggal mulai keanggotaan',
  d26429adfb846303cbd889879b23e1ba:
    'Uang yang dikumpulkan baik dari pembayaran pembeli, pengiriman uang dari penjual, dan/atau pemotongan sebelum dan/atau setelah masa tenggang.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Nilai Pembayaran Bersih',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Nilai pembayaran bersih',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nilai Baru',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Hari kerja berikutnya',
  '325c48f099844279c0cf48584de42e64': 'Tidak ada aktivitas terkini',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Tidak Didaftarkan Secara Aktif di Cash',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Riwayat faktur tidak cukup',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Faktur tidak cukup di bulan-bulan sebelumnya',
  c9e2ce8da559465a8596317c35e8f3a4: 'Faktur yang dibayar tidak cukup',
  e6f890deafa579eb0c24603c3101c1c7:
    'Perhatikan bahwa saldo biasanya diperbarui di hari kerja berikutnya. Ajukan tiket dukungan jika Anda perlu mengetahui posisi saat ini.',
  '83070a059f10db1515ac922bf3433158':
    'Jumlah hari setelah Tanggal Akhir Masa Tenggang.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Satu laporan tersedia untuk diunduh',
  '2778717f6acbeb1edaac6a2656b478f3': 'Buka',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Biaya Lainnya',
  fad92e97e692758c0d0a9b5730663894: 'Bunga Utang yang Belum Dibayarkan',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Nilai Didanai yang Menggantung',
  '9eab1baddf2537312408088fc54f21d5':
    'Biaya Lewat Jatuh Tempo yang Belum Dibayar',
  bfba12a2c234532c0af24a4ad6831ab6: 'Nilai Total Menggantung',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Bunga utang yang belum dibayarkan',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Nilai didanai yang menggantung',
  '2f9a3de6940f818fe0376e4aece59512':
    'Biaya lewat jatuh tempo yang belum dibayar',
  '6f79eea38bff17917740a69116270074': 'Lewat jatuh temp',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Saldo yang Lewat Jatuh Tempo',
  '848ac8c39b11a751d697a383303e6109': 'Utang yang lewat jatuh tempo',
  '8d1e868665fc24a46f0cf55187f72218':
    'Utang tunggakan akan dilaporkan sesuai dengan peraturan RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Gambaran Sekilas',
  '5b885084a5067d7fb4372cef38d8a82d': 'Dibayar',
  '830b95d305cdc61a66cdf75ebff1d737': 'Telah dibayar oleh pembeli',
  '6444796646c95dbc2cd1cb646e41293d':
    'Bagian dari nilai nominal tagihan yang telah didanai sesuai dengan persentase pembayaran di muka yang disetujui oleh HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Detail pembeli yang berpartisipasi',
  a39314e5897b8baaa51ca296610ec30c: 'Syarat Mitra',
  a6acd5f03fda9c8dc607e59d8be08320: 'Penundaan persetujuan utang',
  e6249829dadf637c4092e4a900df529e: 'Utang telah disengketakan oleh pembeli',
  '07ed468d28d8e8437d8ec64036b96c03': 'Utang telah ditolak',
  '042b8dfb88f7045ca9145bbcccf79200': 'Utang ada di masa depan',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Utang terlalu lama',
  '8755bbc212b541efb6576f39f3f0f51f': 'Pembayaran yang Dialokasikan',
  '8744ee10540166dfc39db2a6859a9ce1': 'Nilai Pembayaran',
  '56133aa371eb0132334e4867479be942': 'Detail Pembayaran',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Pembayaran Lewat Jatuh Tempo',
  '2dd7164df606f9b42beefaba031b06f7': 'Pembayaran Telah Diterima',
  '4e62b46d2c536f88dcd8e6c6a70d67ce':
    'Pembayaran telah dikonfirmasi oleh penjual',
  '661711004f06b0d9601db365679be130': 'Nilai Pembayaran',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Tanggal Pembayaran',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Bunga Penalti',
  '0fd036622716f275c3e14c5a14faa65a': 'Tertunda',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Pencairan Tertunda',
  '8cf3479829ba47a8a4123895d0088eeb': 'Pencairan Tertunda',
  '270b56fd05f72407ca0607b6162d0b10': 'Koneksi tertunda',
  '4a78218416ca45d97b5633b37ae3f638':
    'Tingkat persentase di mana biaya harian akan dihitung untuk tenor pendanaan penuh.',
  b586a5468c7070bf1717593ea84fa607:
    'Tingkat persentase di mana Anda akan ditagih setiap hari atas jumlah dana Anda yang belum dibayar untuk faktur yang lewat jatuh tempo atau faktur yang sudah dibayar & ditolak.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Silakan ajukan tiket dukungan jika Anda perlu mengetahui saldo saat ini.',
  '34645fa620cae4187f27d9f96c172f26': 'Harap kirimkan nilai',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Harap kirimkan jumlah berikut ke rekening bank berikut:',
  '01734a7316427fdcdcd4f937cda3802b': 'Prapembayaran',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Nilai Sebelumnya',
  '500fc4731dab800a878b33906d9332fb': 'Akun Koleksi Program',
  '7327113634d1f5f4f68632dceebfbc6f': 'Gambaran Sekilas Program',
  d95ebfb51a485293a134b1038d332a10: 'Program diaktifkan pada',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Detail program tidak tersedia untuk Dasbor',
  '98feb2c86a81b8caeeaa30966ba34636': 'Informasi program',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Pembayaran Faktur yang Dibeli yang tidak dikumpulkan secara penuh pada Tanggal Jatuh Tempo yang Diharapkan menghasilkan pembayaran parsial. Pembayaran parsial menghasilkan bunga 1,5x tingkat Diskon Harian & Anda bertanggung jawab untuk membayar kembali keduanya.',
  '521689b600b8da5b0a111b89dea72126': 'Alasan',
  fe7bd622a1efcec03eb86dabb9395936: 'Diterima',
  e84203a8833e4d0d92faf89f4107bf11: 'Pelunasan permintaan kompensasi',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Jumlah Pengembalian Dana',
  b0be744ae1f6312f16990bbd92a87aea: 'Tanggal Pengembalian Dana',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Tenor Pengembalian Dana',
  '654d6dcc141079bc84500fd14ca5087d': 'Jumlah pengembalian dana',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Detail pengembalian dana',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Tanggal pengembalian dana',
  c0a6a91e7ca80af9de47097b06dc7618: 'Tenor pengembalian dana',
  '4a2875439ab289a068abb9ea3b567bcb': 'Ditolak',
  '592b34cdfa2e51826b32a0783e0edce3': 'Ditolak oleh pembeli',
  '807d35f105b65ab2795c4f8e51795f3c': 'Ditolak oleh penjual',
  aea4dd208e871d7ca0051b0ec38c3068: 'Tanggal Penolakan',
  d64ed4934fbf68a8f716467b0adf89ba: 'Tanggal penolakan',
  '5a77de3b287a92494be077c197f37581':
    'Jumlah sisa dana yang belum ditagih atau dilunasi.',
  e442b864bf745a75477ea0ee88bc15c4: 'Jatuh Tempo Pelunasan',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Nilai pelunasan pada tanggal akhir masa tenggang. Nilai ini tidak berubah untuk menunjukkan pelunasan yang dilakukan setelah tanggal ini, juga tidak termasuk utang yang telah jatuh tempo.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Jatuh tempo pelunasan',
  dc20cbfb76d220c108241a451bc3faf4: 'Informasi instruksi pelunasan',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Pelunasan yang jatuh tempo adalah total jumlah terutang yang perlu dipulihkan, akibat dari jumlah yang dibayarkan oleh Pembeli kurang dari nilai nominal faktur; dan/atau Pembeli menolak faktur setelah dibiayai.',
  '45b60e22719c12291932bf09db0df5cd':
    'Pelunasan yang jatuh tempo adalah total jumlah terutang yang harus dipulihkan, sebagai akibat dari salah satu yang berikut:',
  ba093b1421452b3afdebbebedaeae552: 'Minta Pendanaan',
  '34f8f9968ec0d7f91e558e906a7de982': 'Diminta',
  dc1650cfbadb05f8fb6357692e48ee71: 'Diminta untuk mendanai',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Ditahan',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Jumlah yang Ditahan',
  a476316a89541d1aa71d5b5366874e13: 'Jumlah yang ditahan',
  '328f6585a0e78a96deefc0661474248f': 'Coba lagi',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Penjualan',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Cari berdasarkan nomor faktur',
  '3215999493db188b35242dc4f4fd8fb5':
    'Lihat Informasi Program untuk informasi lebih lanjut tentang pelunasan.',
  '69f43575c3a51e94605eb75b75f7b563': 'Dipilih',
  '56a1c7c4a02e9985745969563a80799f': 'Rekening pelunasan penjual',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Mengirim',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Dikirim',
  '95d54926719f1071b2fa640f1ba23d3e': 'Dilunasi',
  e243f36392a7f264d830f04f21aa8972: 'Faktur yang telah dilunasi',
  b548572d4123e3a569d27e4676664dad: 'Pembayaran Parsial',
  '6e09ada7e33833d259370b0cc900f082': 'Nilai Pembayaran Parsial',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Potongan Pembayaran Parsial',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Pembayaran Parsial',
  '46bbc145b1f85c082950c768ade012f6': 'Nilai pembayaran parsial',
  e66e6d9768e8f489c05951ee70ede350:
    'Ada yang salah, semua fitur dasbor mungkin tidak tersedia. Silakan coba lagi atau hubungi dukungan untuk meminta bantuan.',
  cd969f183dc619b5716067cfda39c055: 'Kode Sortir',
  '17939071126e9e41921d79b837f63297': 'Nama Pemasok',
  '750d4460b260343039f9bc71b66a231c': 'Pemasok dianggap bangkrut',
  '7f6562457845bcf84c0cfece3d1eaa51': 'Pemasok belum disiapkan pada program',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Dukungan',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Syarat telah diterima oleh',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Syarat Layanan',
  '1294cf929ec55581954a79948592dda9':
    'Tanggal saat pembiayaan program Tunai diaktifkan.',
  f51144e4468390a486d41b4db66fc243: 'File tidak dapat diunduh.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Jumlah maksimum hari di mana pembayaran diharapkan dari pembeli Anda, sebagaimana disetujui oleh HSBC.',
  d438da584c455e727642a303917e690d:
    'Nilai sisa kredit yang dapat digunakan. Nilai ini adalah selisih antara Batas Kredit dan Kredit yang Digunakan.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Nilai total faktur yang saat ini didanai untuk program ini. Nilai ini dihitung berdasarkan batas kredit dan nilai faktur yang didanai saat ini.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Nilai total faktur yang dapat didanai untuk program ini. Nilai ini dihitung berdasarkan batas kredit dan nilai faktur yang didana saat ini.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Nilai total uang yang telah ditahan sebelum pelunasan faktur.',
  '13f271ac486faca89c1944226ba0efff':
    'Total nilai nominal semua faktur yang diimpor.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Total nilai nominal faktur yang dipindahkan ke status lunas karena telah sepenuhnya ditagih.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Total nilai nominal faktur yang didanai.',
  '565c255165bed2ff2640d0e42369c62c':
    'Total nilai faktur yang tidak memenuhi syarat untuk pendanaan.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Akun ini tidak terdaftar secara aktif di Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Ini biasanya diperbarui di hari kerja berikutnya. Silakan ajukan tiket bantuan jika Anda ingin mengetahui saldo saat ini.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Nilai ini menunjukkan saldo pembayaran parsial terbaru Anda termasuk bunga apa saja.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Bersama dengan',
  '603e4b3b3ac7b888a06548d14435bc25': 'Terlalu banyak pencairan',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Nilai Total yang Ditagih',
  f786c8a1095bdf155841d510bb53142a:
    'Total Bunga atas utang yang lewat jatuh tempo.',
  '4f25d1096076868bc590da9f8a26cf55': 'Batas Total',
  '230bd56af0acab75194f58b46a763535':
    'Nilai total yang terkumpul dari pembayaran pembeli.',
  '8f78461d739b4d6d46f961f352000cec': 'Nilai total yang terkumpul dari Anda.',
  b62dd565f60dc6172605d8db223510b9:
    'Nilai total yang dikumpulkan melalui pengiriman uang oleh pembeli, pengiriman uang oleh penjual dan/atau clawback, termasuk bunga atas utang yang lewat jatuh tempo.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Nilai total yang didanai terhadap penjualan Anda.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Nilai total yang tidak didanai, sesuai dengan persentase pembayaran di muka yang disetujui oleh HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Nilai total faktur yang didanai yang terkumpul secara penuh.',
  a069138e83a8b4cb7e81145689155826:
    'Nilai total bunga atas utang yang telah jatuh tempo yang masih harus dibayar atau terutang.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Nilai total biaya yang telah jatuh tempo. Tagihan denda bertambah setiap hari.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Nilai total biaya yang telah jatuh tempo yang dibayarkan.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Nilai total biaya yang telah jatuh tempo yang masih harus dibayar atau terutang.',
  d649fb9a0971deaa127067341f59be91:
    'Nilai total yang dipulihkan dari potongan faktur lainnya.',
  '28fcda8475061ec7eb4894565e49fc87':
    'NIlai total yang belum ditagih atau dilunasi.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'NIlai total penagihan',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Total nilai nominal faktur yang tidak memenuhi syarat untuk pendanaan.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Total nilai nominal faktur yang dikirimkan kepada Pembeli.',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Nilai total dana yang terkumpul melalui pengiriman uang dari pembeli, pengiriman uang dari penjual, dan/atau clawback.',
  d4867ee77e79ded226c07918b9393715:
    'Total Bunga atas utang lewat jatuh tempo yang dibayar.',
  de4c28160afe1ab48bcf83be49bbb196: 'Batas total',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Nilai total yang terutang',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Nilai total yang didanai dan bunga atas utang yang telah jatuh tempo yang harus Anda lunasi. Hal ini dapat disebabkan oleh satu atau beberapa faktur yang didanai telah jatuh tempo, atau pembeli Anda menolak satu atau beberapa faktur setelah didanai.',
  fecdb72e34cebfadf037b45b596d90e5: 'Pertanyaan Umum Program Tunai Tradeshift',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Detail transaksi',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Tidak dapat mengunduh faktur csv. Silakan hubungi bagian Dukungan',
  c8415d81378085ba3933015b8f5fc768:
    'Tidak dapat mengambil Perjanjian. Silakan hubungi bagian Dukungan',
  '64580a198c861aa0226c91b28f8418aa': 'Tidak tersedia untuk dijual',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Faktur yang belum dibayar setelah tanggal tersebut dianggap "Lewat jatuh tempo" dan akan dikenakan bunga setiap harinya.',
  '9d632e72031eea3767881d6c3adcfe54': 'Batas Kredit Terpakai.',
  e871a6882109bd7ef07827882236c8db: 'Lihat Perjanjian',
  '2ac548c28faaa41283f9d6b7606a4844': 'Dibatalkan',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Selamat Datang di Cash!',
  '78eb58937961a18929a623397b544a01':
    'Anda dapat melunasi saldo pelunasan dengan melakukan pengiriman uang langsung ke rekening pelunasan (tercantum di atas).',
  f347abf54f65274a614a9ba7515dfa98:
    'Anda dapat melacak semua pembayaran dini di satu tempat menggunakan aplikasi Cash',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Program tunai Anda aktif!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Program tunai Anda tidak aktif',
  '6e6e06469dd953a124d718cd2307e32c':
    'Partisipasi Anda dalam program Cash belum aktif sejak {date}. Silakan hubungi <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'faktur yang didanai ditolak oleh pembeli sebelum akhir masa tenggang (dikenakan bunga setiap hari setelah hari penolakan)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'faktur yang didanai belum dibayar pada akhir masa tenggang (dikenakan bunga setiap hari setelahnya); dan/atau,',
  '33e67e73d7f54aaee31e6040f873be79': 'diterapkan ke potongan',
  a9798a3e063111de4d4768c363bdc267: 'diterapkan ke faktur',
  a5be13d3bfec5fc92521a841009db3c7: 'nomor rekening bank',
  '736c1e36ac779a73ff4fddd19d82008c': 'suku bunga harian',
  ab53acde68c117f8fbfff6a8901de083: 'suku bunga jatuh tempo harian',
  '047dbee224da5fb4ecfc21268ffd3115': 'batas akhir',
  dd051262964c80130a2e9023ff933d89: 'pembayaran dini',
  bc55e8a10310a96eeda133ddf159303d: 'Tanggal jatuh tempo yang diharapkan',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'untuk pembayaran dini',
  '932d9bd46daba4d09f361717c0ec03ab': 'nilai yang didanai',
  '5e82566144af4903b40303fc3d1c799e': 'tanggal yang didanai',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'masa tenggang',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'pembayaran jatuh tempo',
  '2db524546615d120af0adf844a6e1bd7': 'dari',
  '99c2f4783bb6821d84ad17a22f556813': 'dari yang Didanai',
  '7555b72d3b4ffe69558b68b3cb86b954': 'pada',
  c138be463a457afe89e5ecfefc6a3dc9: 'nota kredit yang menggantung',
  '37f59e13afd5455cf130060ed55f4976': 'nomor perutean',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'jangka waktu',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'ke rekening bank berikut:',
  cf4edd5f38054a752994e3242a1f3807: 'nilai total',
  '3c9f634310f5e3de42b79ee83d20923c': 'total biaya',
  '573b1b7930d8030d1669945f2c34992a': 'ditransfer ke bank Anda',
  ea0cb39e8c47c1bcafa2a9e737db4cdf:
    'akan dikurangkan dari pembayaran yang akan datang',
};
