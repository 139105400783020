export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Ajustada para variações de moeda ou feriados bancários)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Taxas e datas de pagamento são apenas indicativas e não podem ser garantidas.',
  fa090ada546b5deb33cdead006822e26:
    '* Taxas e datas de pagamento são apenas indicativas e não podem ser garantidas.',
  '52fce318164dc60017ca67121172aca9': 'Aceito',
  '615a093396ed9c6f52cd7ece7217608e': 'Ajuste de conta',
  '79dd431d35b370210a3dbeb1bb653d43': 'Nome da conta',
  '8c66a974a95769447407c061a36d0824': 'Número da conta',
  '6a810f9427275516cc7ac687ea182f27': 'Juros de dívida acumulados',
  '21df2d8134802290caddc9a8070e9d94': 'Cobranças em atraso acumuladas',
  '7377dcc6989501c8299b7ccf697b731e': 'Juros de dívida acumulados',
  dab81136f1c6e799c5626826349a8dcf: 'Cobranças em atraso acumuladas',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Todas',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Todos os Compradores',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Todas as Moedas',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Todas as faturas',
  d7db3397d1ee87530b253bc69ebca041: 'Valor',
  c7586d1e153c74713afd948b3048d03d:
    'Valor pago a você após a dedução de juros e qualquer reembolso devido do valor provisionado.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Valor recuperado contra reembolsos devido em outra(s) fatura(s).',
  '57e646b300b4b551d662b0cd536a6ad5': 'Valor a transferir',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Recursos excedentes serão transferidos a você.',
  de9800ff635c316938aece381c98b094:
    'Quaisquer pagamentos e juros pendentes ou incompletos poderão ser subtraídos do Preço de Compra a ser pago ou outras cobranças. Se não conseguirmos cobrar dessas formas, poderemos pedir que você pague o valor diretamente ao comprador.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Aprovado(a)',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Faça uma pergunta',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Limite de crédito disponível para novas provisões.',
  '85a6c2f454268a7b3c4156c070abc517': 'Esperando aprovação',
  '99fb0198659b8076326d69a42670433b':
    'Os saldos são normalmente atualizados no dia útil seguinte.',
  '0448c27d5574d95fc844e50416fc13a3': 'Pacote',
  '84b730c6e665f3a83a96a8511b4f63db': 'Detalhes do grupo',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Filial do comprador',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Fatura rejeitada pelo comprador',
  cdb5b8f6940144b32dc7f27b7700719a: 'Transferência do comprador',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Comprador marcou como pago',
  e7094a08ea031332f2a7e7f94b214174: 'Transferência do comprador',
  b8b64034058f45fe990f8377c7990c6b: 'Conta de transferência do cliente',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Comprador:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Os compradores realizam o pagamento por aqui por meio de faturas. Você faz pagamentos incompletos por aqui',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Por padrão, o valor do reembolso pendente é deduzido da(s) próxima(s) fatura(s) provisionada(s).',
  cb1a025651df74878d8d5af1b99991df: 'Cancelado',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c':
    'Informações do programa de financiamento',
  '6fd54168f061cd9f269dbcbb29415486': 'Valor da cobrança',
  '252982cd07a2c1e75a9cadb781c4428f': 'Restituição',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Clique para copiar',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Fechar',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Fechada',
  a150472168406890d84a6a5c3c8a894c: 'Recebido',
  '0743742edda54d26e47f9959ca4be4f0': 'Juros de dívida recebidos',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Cobranças em atraso recebidas',
  '0b2194f7621b8a036308dce1647b982d': 'Valor recebido',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Juros de dívida recebidos',
  b1a3b2482cd668f631d724aac2dfc685: 'Cobranças em atraso recebidas',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Cobrança',
  ba18fa9a0a1da454462f71c6e36139ff: 'Saldos do recolhimento',
  '1103815eae13527faa8d51c83c20d17e': 'Detalhes da coleção',
  '898e15d1fa445c9c37ca795f79029f6d': 'Razão social',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Continuar',
  c05df0411bb18c405aaa68153432bf10: 'Limite de crédito & fatura atualizados em',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Crédito disponível',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Limite de crédito',
  d23014cf5a5e7f8c6440b96692831aa8: 'Nota de crédito',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Crédito utilizado',
  '207df9f33356e378a1ff2ef7b62becc7': 'Crédito disponível',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Limite de crédito',
  '208d5141343637870387b27db87ccf05': 'O limite de crédito foi excedido',
  '0e27340c6eb226df1076c4f8930d2289': 'Crédito utilizado',
  e15a66f111bc879c77f24943a94d19e7: 'A moeda não é aceita',
  b1e5986f972b7599559dea22cfeae8fb: 'Moeda:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Desconto diário',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Taxa de desconto diária',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Taxa diária de atraso',
  '9e268b45f182ad1851f03539be6ab29c': 'Taxa de desconto diário',
  c2191b6160c46ed7a4b05cc0279158f3: 'Taxa diária de atraso',
  e2d9ef555d350e16239f4dfc92842fdc: 'Painel de controle',
  '106202929cf48a3de3f20b3255365915': 'Data',
  '2eca727eade526acc715c2e5605ebc7e':
    'Data em que a quantia provisionada foi totalmente liquidada.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Data final do período de carência de 30 dias.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Data em que a fatura foi rejeitada pelo seu comprador.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Dias de atraso',
  '1e1e68c46e0d719364bd445b1d605144': 'Dias de atraso',
  bd50c01a22cae65be4d588abcae65a90: 'Dedução',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Deduções',
  d706035ec5f053ca33b234ddec228ab2: 'Descrição',
  a39bf2f30994908fe95498524b423d43: 'Detalhes',
  '193d60da437b58b24ed69a5683c1ffcf': 'Diluído',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Valor de diluição',
  d07e29091188f3b0871dc7712eee7255: 'Tipo de diluição',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Transferência direta do vendedor',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Transferência direta do vendedor',
  afc1c7dfc9315e856f8da52e2450182b: 'Desconto',
  '26e7eb298420701d5a03c53fe096cc0b': 'Contestado',
  '43cca9c1b76ebf869c7bf22fff271689': 'Filtros de status do documento',
  ce73b429141cda25571c88d9b0c78224: 'Status do documento',
  af13cc2ad0a7337c45420f75b7d5a668: 'Descarregar',
  '8475be8bb8249000118af931ae75c373': 'Baixar relatório',
  '90567e36a540e51806d7790eb195b1d5': 'Baixar todas as faturas',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Rascunho',
  '72015a772310560b755bd03132a543b2': 'Data-limite',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Data de vencimento',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Data de vencimento muito próxima',
  '6def6918bd02ae5117332f4262cecb52': 'Elegível',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Status da inscrição',
  cb339c5c02cfb087fc8d393f917379df: 'Erro ao carregar painel.',
  e9994b26424cb4bce5059d05e094df8d: 'Tarifa estimada',
  f159aa99d4a54cda8b884530e2ad2a1b: 'País excluído',
  b5cb31ac9c7d1871e9075efc1f271536: 'TAXA',
  fd9d568c402dd04e0715e877d76f7da5: 'Valor nominal da fatura.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Falha',
  '817f1fe84c2d87894bc374681814abbf': 'Falha ao carregar {title} -',
  '7932079ce6ea928bfa77efd91578312d': 'Taxas',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filtrar por moeda para exibir totais resumidos.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'Data de previsão de pagamento muito longa',
  e5af505b37031920b08edb84df19b5ec:
    'Data do recebimento do valor total provisionado',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Data da cobrança da quantia total provisionada',
  '6dd341953c8c405c70878a457622cf55': 'Financiado',
  '42e20c6bb1dc82fbdbd889f769850320': 'Recebimento do valor provisionado',
  cab921680af6ff282ce37398b0b231d4: 'Quantia provisionada',
  fc0347ceea208298049efd79021475a2: 'Recebimento do valor provisionado',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Data da provisão',
  '55d900eb37f97b684c0bd5821f161216': 'Faturas financiadas',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Provisionamento',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Exceção de provisionamento',
  '5ca89ce217f3bb07293db00a42c10200':
    'Motivo(s) da ineligibilidade do provisionamento',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Visão geral do financiamento',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Sucesso ao solicitar pagamento',
  d5ec85821723434aba05986044ebdcdd: 'Status do financiamento',
  '3447158e3ace443dbe27556e07c88639': 'Filtros de status de financiamento',
  '022848540bf0e01453be6cf79843aa67': 'Prazo de financiamento',
  b4a59826d5bfc276fa88bd00abac615a: 'Detalhes do financiamento',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Prazo de financiamento',
  e74f45b44aadcdd084b5cbdd3560f515: 'Data final da carência',
  '5434cf75f987ef76dbdc544fa1e32096': 'Como pagar',
  cb9f9412bd12122bb088124afccf738b:
    'Como reembolsar pagamentos de curto prazo?',
  '9ad57f0b683fecb097c09bedebf68a45': 'Como resolver o reembolso pendente:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'FATURA',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Se você acha que é um erro, entre em contato com <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Informações importantes',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'Em andamento',
  '313b15a4fdfa2368015e2eb67416af2a': 'Programa inativo',
  '7b6a003ceca7fe7fec85daae919e800b': 'Não elegível',
  '6cf0784c1ac918c22cd402e0cb558749': 'Razão ilegível',
  '65cb9c651d2d9fb8941050a08add03c3':
    'Não elegível para pagamentos antecipados',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Faturas não elegíveis',
  c555faa7b0b745638bb3ea785ee10137:
    'A informação exibida foi atualizada pela última vez em',
  b7311d63c44566a8562d92720cffed2e:
    'Recebimentos insuficientes de taxas de fatura',
  fb07cb1753537f6969513f2d5fdb7993: 'Valor dos juros',
  '1938e208f65e66474dc4148c3fa7da08': 'Juros deduzidos',
  '42094e40339ac5c0957a980062c47659':
    'Juros acumulados no pagamento incompleto',
  d9580a19710f07e8c72c215b1270ca57:
    'Juros cobrados pelo HSBC sobre a quantia provisionada.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Juros deduzidos',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Juros incorridos em fatura atrasada',
  '47a565198d287d0864339c077819a361':
    'Os juros acumularão sobre o débito em atraso até que sejam deduzidos do próximo pagamento para você ou nós recebermos a transferência direta.',
  '3c4be400f728da4526de626653d56ccc': 'Fatura',
  '70546e9227f592c24d3c491756cb901d': 'Fatura nº',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Valor da fatura',
  '6b9515d101794c31e38ae60d092fdc03': 'Data da fatura',
  '2548c6a52ce9e1bffef7aad14530f490': 'Faturas pagas',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Visão geral do provisionamento',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Limite de fatura',
  '734ed127b3d7226c75e8553c0711367a': 'Limite de fatura em uso',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Limite total da fatura',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Número da fatura',
  da2db17c9b22f995d6673a754d998fc8: 'Fatura vencida',
  d729bd1bdced62265cd4ecd7131ef94e: 'Fatura rejeitada',
  e14208d4378a3378be8b27eb15984fc6: 'Fatura liquidada',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Status da fatura',
  '54d8df173250a197fab1644a0d0a772a': 'Valor da fatura',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'A fatura não pode ser provisionada neste momento. Consulte Motivo(s) da ineligibilidade do provisionamento para obter mais informações.',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'A moeda da fatura não corresponde ao país',
  d0da9e977c8b309f93ee378de34fb251: 'Data da fatura',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Data da fatura antes da data de elegível mais antiga',
  ad0ab15468b719694f3193d0649ad9eb:
    'A data da fatura é posterior à data de cancelamento do estabelecimento.',
  fc9e80713aebb3d820d888f0755fedde: 'A fatura não tem um cliente inscrito',
  e17cbec04e7786361eff9da629f09bb7: 'A fatura tem data de emissão no futuro.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'A fatura foi cancelada.',
  '89f842841b4ae213259b94a5411453f2': 'A fatura foi diluída.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'A fatura foi provisionada pelo programa de financiamento.',
  ab5303505d045929abb1256607ca0d4d:
    'A fatura foi paga completamente. Não são necessários pagamentos adicionais.',
  '3e159746cbdfda07b2f45750941e26f6':
    'A fatura foi solicitada para provisionamento.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'A fatura tem status de documento inelegível',
  '8204c52c01b05791dc3e4c59ac352978':
    'A fatura não foi liquidada por completo e já ultrapassou o período de carência',
  d87ca92704bcfb34fa4ac08a4d24147a: 'A fatura está atualmente inelegível.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Data de término ou data de término final de fatura não informada',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'A fatura está muito próxima da previsão de data de pagamento.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'A fatura é incomum',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Fatura marcada como já paga.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'A fatura pode estar duplicada.',
  b1f771da19efea2f0fa6a542823231b9: 'Fatura parcialmente elegível',
  '4217948ca113ee4158d5dd4c07d71624':
    'Fatura liquidada com pagamento de curto prazo',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'O prazo da fatura não está dentro do intervalo aceitável',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Prazo da fatura muito longo',
  '90d9ac20dda384e9c07554d29b143bef': 'Valor da fatura excede o limite',
  '4b36f0be327fdca8d54126cd57095a13': 'Número da fatura/AR',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Faturas',
  '8089283aad9294aadc2bde165ccc78c1': 'Data de emissão',
  '3a4123f9c604369ab18781f7492f9293': 'Data de emissão',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Data do último pagamento',
  a94bc4d4df1d56c16e25493e22684412: 'Limite disponível',
  '92f97c843d499a1559828ba0a9134e24': 'Limite em uso',
  b5dd8e130d88698834ee00464f01cea4: 'Limite disponível',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Limite em uso',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Carregando...',
  '24cc5627dea65e22f9279be9522a06e2': 'Valor do empréstimo',
  bd24bcedd066a27749016a07cb729a32: 'Marcado como liquidado',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Data de maturidade',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Data inicial da adesão',
  d26429adfb846303cbd889879b23e1ba:
    'Quantias recebidas de pagamentos de compradores, transferências de vendedores e/ou com deduções e/ou depois do período de carência.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Valor líquido pago',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Valor líquido pago',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Novo valor',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Próximo dia útil',
  '325c48f099844279c0cf48584de42e64': 'Nenhuma atividade recente',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Nenhuma',
  '7457575d324c4b8dada2e13c0f0fe5d5':
    'Não inscrito ativamente no financiamento',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Sem histórico de fatura suficiente',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Não há faturas suficientes dos meses anteriores',
  c9e2ce8da559465a8596317c35e8f3a4: 'Faturas pagas insuficientes',
  e6f890deafa579eb0c24603c3101c1c7:
    'Observe que os saldos são normalmente atualizados no dia útil seguinte. Abra uma solicitação de ajuda se precisar saber a posição atual.',
  '83070a059f10db1515ac922bf3433158':
    'Número de dias após a data final da carência.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Um relatório disponível para download',
  '2778717f6acbeb1edaac6a2656b478f3': 'Abrir',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Outras taxas',
  fad92e97e692758c0d0a9b5730663894: 'Juros de dívida pendente',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Valor provisionado pendente',
  '9eab1baddf2537312408088fc54f21d5': 'Cobranças de atraso pendentes',
  bfba12a2c234532c0af24a4ad6831ab6: 'Valor total pendente',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Juros de dívida pendente',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Valor provisionado pendente',
  '2f9a3de6940f818fe0376e4aece59512': 'Cobranças de atraso pendentes',
  '6f79eea38bff17917740a69116270074': 'Vencido',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Saldos devedores',
  '848ac8c39b11a751d697a383303e6109': 'Débito em atraso',
  '8d1e868665fc24a46f0cf55187f72218':
    'O débito em atraso será declarado de acordo com as normas da RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Visão geral',
  '5b885084a5067d7fb4372cef38d8a82d': 'Pago',
  '830b95d305cdc61a66cdf75ebff1d737': 'Pago pelo cliente',
  '6444796646c95dbc2cd1cb646e41293d':
    'Parte do valor nominal da fatura que foi provisionado de acordo com o percentual de pré-pagamento aprovado pelo HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Detalhes do comprador participante',
  a39314e5897b8baaa51ca296610ec30c: 'Termos de parceiro',
  a6acd5f03fda9c8dc607e59d8be08320: 'Atraso na autorização devida',
  e6249829dadf637c4092e4a900df529e: 'O pagamento foi contestado pelo comprador',
  '07ed468d28d8e8437d8ec64036b96c03': 'O pagamento foi rejeitado',
  '042b8dfb88f7045ca9145bbcccf79200': 'Pagável no futuro',
  f8768f5b4055c45a4d30591bea9cc7b1: 'O pagamento é muito antigo',
  '8755bbc212b541efb6576f39f3f0f51f': 'Pagamento alocado',
  '8744ee10540166dfc39db2a6859a9ce1': 'Valor do pagamento',
  '56133aa371eb0132334e4867479be942': 'Detalhes de Pagamento',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Pagamento em atraso',
  '2dd7164df606f9b42beefaba031b06f7': 'Pagamento Recebido',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Pagamento confirmado pelo vendedor',
  '661711004f06b0d9601db365679be130': 'Valor do pagamento',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Data de pagamento',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Juros de penalização',
  '0fd036622716f275c3e14c5a14faa65a': 'Pendente',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Diluição pendente',
  '8cf3479829ba47a8a4123895d0088eeb': 'Diluições pendentes',
  '270b56fd05f72407ca0607b6162d0b10': 'Conexão pendente',
  '4a78218416ca45d97b5633b37ae3f638':
    'Taxa percentual pela qual serão calculadas as taxas diárias para a maturidade do provisionamento.',
  b586a5468c7070bf1717593ea84fa607:
    'Taxa percentual pela qual haverá cobrança diária sobre seu valor provisionado pendente referente a faturas em atraso ou provisionadas e rejeitadas.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Abra uma solicitação de ajuda se você precisa saber os saldos atuais.',
  '34645fa620cae4187f27d9f96c172f26': 'Transfira o valor',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Transfira a seguinte quantia para a conta bancária:',
  '01734a7316427fdcdcd4f937cda3802b': 'Pré-pagamento',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Valor anterior',
  '500fc4731dab800a878b33906d9332fb': 'Conta de coleção de programas',
  '7327113634d1f5f4f68632dceebfbc6f': 'Visão geral do programa',
  d95ebfb51a485293a134b1038d332a10: 'Programa ativado',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Detalhes do programa não disponíveis no Painel',
  '98feb2c86a81b8caeeaa30966ba34636': 'Informações do programa',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Os pagamentos de faturas de compra que não são coletados integralmente até a data de vencimento esperada geram pagamentos incompletos. Os pagamentos incompletos rendem juros de 1,5x de taxa de desconto diário e é sua responsabilidade reembolsar esses valores.',
  '521689b600b8da5b0a111b89dea72126': 'Motivo',
  fe7bd622a1efcec03eb86dabb9395936: 'Recebido',
  e84203a8833e4d0d92faf89f4107bf11: 'Recorrer o reembolso',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Valor do reembolso',
  b0be744ae1f6312f16990bbd92a87aea: 'Data de emissão do reembolso',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Prazo do reembolso',
  '654d6dcc141079bc84500fd14ca5087d': 'Valor do reembolso',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Dados para reembolso',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Data de emissão do reembolso',
  c0a6a91e7ca80af9de47097b06dc7618: 'Prazo do reembolso',
  '4a2875439ab289a068abb9ea3b567bcb': 'Rejeitado',
  '592b34cdfa2e51826b32a0783e0edce3': 'Rejeitado pelo cliente',
  '807d35f105b65ab2795c4f8e51795f3c': 'Rejeitado pelo vendedor',
  aea4dd208e871d7ca0051b0ec38c3068: 'Data de rejeição',
  d64ed4934fbf68a8f716467b0adf89ba: 'Data de rejeição',
  '5a77de3b287a92494be077c197f37581':
    'Valor provisionado restante a ser recebido ou reembolsado.',
  e442b864bf745a75477ea0ee88bc15c4: 'Prazo do repagamento',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Valor do reembolso na data final da carência. Não é alterado para refletir reembolsos efetuados após esta data, nem inclui débitos em atraso.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Reembolso vencido',
  dc20cbfb76d220c108241a451bc3faf4: 'Informações das instruções de reembolso',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Os reembolsos consistem no total do montante pendente que precisa ser recuperado, resultante do valor pago pelo comprador e menor do que o valor nominal da fatura; e/ou o da rejeição da fatura pelo comprador depois que é provisionada.',
  '45b60e22719c12291932bf09db0df5cd':
    'Os reembolsos devidos referem-se ao valor total pendente que precisa ser recuperado, resultante de:',
  ba093b1421452b3afdebbebedaeae552: 'Solicitar consolidação',
  '34f8f9968ec0d7f91e558e906a7de982': 'Solicitado',
  dc1650cfbadb05f8fb6357692e48ee71: 'Solicitado financiamento',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Retido',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Valor retido',
  a476316a89541d1aa71d5b5366874e13: 'Valor retido',
  '328f6585a0e78a96deefc0661474248f': 'Tentar novamente',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Vendas',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Pesquisar por número de fatura',
  '3215999493db188b35242dc4f4fd8fb5':
    'Consulte Informações do programa para obter mais informações sobre reembolsos.',
  '69f43575c3a51e94605eb75b75f7b563': 'Selecionado',
  '56a1c7c4a02e9985745969563a80799f': 'Conta de reembolso do vendedor',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Enviando',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Enviada',
  '95d54926719f1071b2fa640f1ba23d3e': 'Liquidado',
  e243f36392a7f264d830f04f21aa8972: 'Faturas fixadas',
  b548572d4123e3a569d27e4676664dad: 'Pagamento de curto prazo',
  '6e09ada7e33833d259370b0cc900f082': 'Valor reduzido de pagamento',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Deduções de pagamento incompleto',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Pagamento de curto prazo',
  '46bbc145b1f85c082950c768ade012f6': 'Valor reduzido de pagamento',
  e66e6d9768e8f489c05951ee70ede350:
    'Algo deu errado, todos os recursos do painel podem não estar disponíveis. Tente novamente ou entre em contato com o suporte para obter assistência.',
  cd969f183dc619b5716067cfda39c055: 'Código de classificação',
  '17939071126e9e41921d79b837f63297': 'Nome do fornecedor',
  '750d4460b260343039f9bc71b66a231c': 'O fornecedor foi considerado insolvente',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'O fornecedor ainda não está configurado no programa',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Suporte',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Termos aceitos por',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Termos de Serviço',
  '1294cf929ec55581954a79948592dda9':
    'O provisionamento do programa de financiamento da data foi ativado.',
  f51144e4468390a486d41b4db66fc243: 'Não foi possível baixar o arquivo.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'O número máximo de dias em que o pagamento pelo seu comprador é esperado, conforme aprovado pelo HSBC.',
  d438da584c455e727642a303917e690d:
    'O valor restante do crédito que pode ser utilizado. Consiste na diferença entre o Limite de crédito e o Crédito utilizado.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'O total de faturas que atualmente são provisionadas por esse programa. Esse valor é calculado com base no limite de crédito e no valor atual das faturas que são provisionadas.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'O total de faturas que podem ser provisionadas por esse programa. Esse valor é calculado com base no limite de crédito e no valor atual das faturas que são provisionadas.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'O montante total que foi retido antes da liquidação da fatura.',
  '13f271ac486faca89c1944226ba0efff':
    'O valor nominal total de todas as faturas importadas.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'O valor real total das faturas que mudaram para o status liquidado porque foram pagas totalmente.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'O valor real total das faturas financiadas.',
  '565c255165bed2ff2640d0e42369c62c':
    'O valor total das faturas não elegíveis para financiamento.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Esta conta não está ativamente registrada para o financiamento.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Normalmente, é atualizado no próximo dia útil. Abra um ticket de suporte se precisar saber os saldos correntes.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Esse valor reflete seu valor de pagamento incompleto mais recente, incluindo juros.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Junto com',
  '603e4b3b3ac7b888a06548d14435bc25': 'Muitas diluições',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Valor total recebido',
  f786c8a1095bdf155841d510bb53142a: 'Total de juros sobre débito em atraso.',
  '4f25d1096076868bc590da9f8a26cf55': 'Limite total',
  '230bd56af0acab75194f58b46a763535':
    'Valor total recebido do(s) pagamento(s) do comprador.',
  '8f78461d739b4d6d46f961f352000cec': 'Valor total recebido de você.',
  b62dd565f60dc6172605d8db223510b9:
    'Valor total recebido por transferência do comprador, transferência do vendedor e/ou restituição, inclusive de juros de débito em atraso.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Valor total pago em relação às suas vendas.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Valor total não provisionado, conforme o percentual do pré-pagamento aprovado pelo HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Valor total de faturas provisionadas e pagas integralmente.',
  a069138e83a8b4cb7e81145689155826:
    'Valor total de juros sobre débito em atraso que restam a ser pago ou pendente.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Quantidade total de cobranças atrasadas. Encargos penais são acumulados diariamente.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Valor total das cobranças atrasadas pagas.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Valor total de cobranças atrasadas que restam a serem pagas ou pendentes.',
  d649fb9a0971deaa127067341f59be91:
    'Valor total recuperado de outras deduções de faturas.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Valor total a ser recolhido ou reembolsado.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Valor total da cobrança',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Valor nominal total das faturas não elegível para provisionamento.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Valor nominal total das faturas enviadas ao(s) Comprador(es).',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Valor total provisionado e recebido por transferências do comprador, transferências do vendedor e/ou restituições.',
  d4867ee77e79ded226c07918b9393715:
    'Total pago de juros sobre débito em atraso.',
  de4c28160afe1ab48bcf83be49bbb196: 'Limite total',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Saldo devedor total',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Saldo devedor total provisionado e juros sobre a débito em atraso a serem reembolsados para você. Talvez haja atraso em uma ou mais faturas provisionadas ou porque seu comprador rejeitou uma ou mais das suas faturas após ter sido paga.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Perguntas frequentes do programa de financiamento do Tradeshift',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Detalhes da transação',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Não foi possível baixar a fatura csv. Entre em contato com o suporte',
  c8415d81378085ba3933015b8f5fc768:
    'Não foi possível recuperar o contrato. Entre em contato com o suporte',
  '64580a198c861aa0226c91b28f8418aa': 'Indisponível para venda',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Faturas a pagar depois dessa data são consideradas “atrasadas” e serão incorridos juros diários de atraso.',
  '9d632e72031eea3767881d6c3adcfe54': 'Limite de crédito utilizado.',
  e871a6882109bd7ef07827882236c8db: 'Ver Contrato',
  '2ac548c28faaa41283f9d6b7606a4844': 'Anulado',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Bem-vindo ao Cash!',
  '78eb58937961a18929a623397b544a01':
    'É possível liquidar o saldo de reembolso devido ao realizar uma transferência direta para a conta de reembolso (relacionada acima).',
  f347abf54f65274a614a9ba7515dfa98:
    'Com o aplicativo Cash, você pode acompanhar todos os seus pagamentos antecipados em um só lugar',
  '0b28e75aa5587b8f49edaa7e620b5cf0':
    'Seu programa de financiamento está ativo!',
  '7807cbebc0a0ffe93df0d4b4f626eed5':
    'Seu programa de financiamento está inativo',
  '6e6e06469dd953a124d718cd2307e32c':
    'Sua participação no programa de financiamento não está ativa desde {date}. Entre em contato com <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'uma fatura financiada sendo rejeitada pelo comprador antes do final do período de tolerância (acumulando juros diários após o dia da rejeição)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'uma fatura financiada que não foi paga no final do período de carência (acumulando juros diários posteiormente); e/ou',
  '33e67e73d7f54aaee31e6040f873be79': 'aplicado às deduções',
  a9798a3e063111de4d4768c363bdc267: 'aplicado às faturas',
  a5be13d3bfec5fc92521a841009db3c7: 'número da conta bancária',
  '736c1e36ac779a73ff4fddd19d82008c': 'taxa de juros diária',
  ab53acde68c117f8fbfff6a8901de083: 'Taxa de juros diária vencida',
  '4c584eabd4260d69914ac993cc4db5d2': 'dias',
  '047dbee224da5fb4ecfc21268ffd3115': 'data de vencimento',
  dd051262964c80130a2e9023ff933d89: 'pagamento antecipado',
  bc55e8a10310a96eeda133ddf159303d: 'prazo previsto',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'para pagamentos antecipados',
  '932d9bd46daba4d09f361717c0ec03ab': 'valor financiado',
  '5e82566144af4903b40303fc3d1c799e': 'data do financiamento',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'Período de tolerância',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'pagamento de vencimento',
  '2db524546615d120af0adf844a6e1bd7': 'de',
  '99c2f4783bb6821d84ad17a22f556813': 'de financiado',
  '7555b72d3b4ffe69558b68b3cb86b954': 'em',
  c138be463a457afe89e5ecfefc6a3dc9: 'notas de crédito pendentes',
  '37f59e13afd5455cf130060ed55f4976': 'número identificador',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'maturidade',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'para a seguinte conta bancária:',
  cf4edd5f38054a752994e3242a1f3807: 'quantia total',
  '3c9f634310f5e3de42b79ee83d20923c': 'taxa total',
  '573b1b7930d8030d1669945f2c34992a': 'transferido para o seu banco',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'será deduzido dos próximos pagamentos',
};
