export default {
  '52fce318164dc60017ca67121172aca9': '已接受',
  '79dd431d35b370210a3dbeb1bb653d43': '賬戶名稱',
  '8c66a974a95769447407c061a36d0824': '賬號',
  f752dafdd1cbcfd9e276ee02daed1c39: '全部',
  d7db3397d1ee87530b253bc69ebca041: '金額',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': '已核準',
  '85a6c2f454268a7b3c4156c070abc517': '等待核準',
  '2e5e060fbc0b1324e5e2059482c65be6': '買方標記為已付款',
  '6fd54168f061cd9f269dbcbb29415486': '收費金額',
  ffa075e58de62f4c4a7af41bb3645aaf: '關閉',
  fbdc4c51ab56c2706190a13fa4d02f16: '集',
  c16ee5d7a1e74972b7b98e206f1f5231: '繼續',
  d23014cf5a5e7f8c6440b96692831aa8: '貸項憑證',
  e2d9ef555d350e16239f4dfc92842fdc: '儀表板',
  '106202929cf48a3de3f20b3255365915': '日期',
  d706035ec5f053ca33b234ddec228ab2: '描述',
  a39bf2f30994908fe95498524b423d43: '詳細資料',
  afc1c7dfc9315e856f8da52e2450182b: '折扣',
  '26e7eb298420701d5a03c53fe096cc0b': '已提出爭議',
  af13cc2ad0a7337c45420f75b7d5a668: '下載',
  '8475be8bb8249000118af931ae75c373': '下載報告',
  '2a669c26b8086630dc4da5c2ee81e70c': '草稿',
  f12ae1ac50389e2b7470ae1f3d1a0795: '到期日',
  '5bbfbbeb3649c8a75f2e910414f2a448': '失敗',
  '3c4be400f728da4526de626653d56ccc': '發票',
  '64a857c3c7502d4299e4d8798fc9dc05': '發票號碼',
  d0da9e977c8b309f93ee378de34fb251: '發票日期',
  cb5bf06bec8dcbfb20319ad13bdf57fd: '發票',
  '8089283aad9294aadc2bde165ccc78c1': '開具日期',
  '3a4123f9c604369ab18781f7492f9293': '核發日期',
  '4a739fa0b571805cc56fc4080ea82fcb': '正在加載...',
  bd24bcedd066a27749016a07cb729a32: '標記為已結算',
  '8ab2d59e3a0a751ccbf443e0b04fca48': '無',
  '2778717f6acbeb1edaac6a2656b478f3': '打開',
  '6f79eea38bff17917740a69116270074': '已過期',
  dc14337c43c60e68f4766cb5dd95bbc2: '概覽',
  '5b885084a5067d7fb4372cef38d8a82d': '已付款',
  '0fd036622716f275c3e14c5a14faa65a': '待定',
  '270b56fd05f72407ca0607b6162d0b10': '未決連線',
  '521689b600b8da5b0a111b89dea72126': '原因',
  fe7bd622a1efcec03eb86dabb9395936: '已接收',
  '4a2875439ab289a068abb9ea3b567bcb': '已拒絕',
  '328f6585a0e78a96deefc0661474248f': '重試',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': '銷售',
  '69f43575c3a51e94605eb75b75f7b563': '已選擇',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: '正在傳送',
  '3e7dc5ef26363b6a0176875cb5d6fee0': '已傳送',
  '95d54926719f1071b2fa640f1ba23d3e': '已結算',
  '17939071126e9e41921d79b837f63297': '供應商名稱',
  '8876cfd0d8d60c5defe5e40d9d563da3': '支援',
  d356429f9bd0a4a6036707d99bf5e2b2: '總計',
  '2ac548c28faaa41283f9d6b7606a4844': '已作廢',
  '4c584eabd4260d69914ac993cc4db5d2': '天',
  '2db524546615d120af0adf844a6e1bd7': '/',
  '7555b72d3b4ffe69558b68b3cb86b954': '在',
  b8f66a73dd67296edf7d6126839b8b00: '（根据货币或银行假期进行调整）',
  eba8e32b871086c08ac4b90ef5ede94b: '* 费用和付款日期仅供参考',
  fa090ada546b5deb33cdead006822e26:
    '* 费用和付款日期仅供参考，不能保证为最终结果。',
  '615a093396ed9c6f52cd7ece7217608e': '帐户调整',
  '6a810f9427275516cc7ac687ea182f27': '应计债务利息',
  '21df2d8134802290caddc9a8070e9d94': '应计逾期费用',
  '7377dcc6989501c8299b7ccf697b731e': '应计债务利息',
  dab81136f1c6e799c5626826349a8dcf: '应计逾期费用',
  '6a21e27ab1553bb3bf93e0a73e645087': '所有买家',
  '59a79bbd7cb65771c64f6c687b4c8103': '所有货币',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': '所有发票',
  c7586d1e153c74713afd948b3048d03d:
    '从放款金额中扣除利息和任何到期还款后支付给您的金额。',
  c3a614afd7dbee1c3e19828e0999b8c8: '根据其他发票到期还款收回的金额。',
  '57e646b300b4b551d662b0cd536a6ad5': '汇款金额',
  d35a870d0219f3f9cbde80ed0eb11aa1: '任何多余的资金都会转给您。',
  de9800ff635c316938aece381c98b094:
    '任何未付的短款和利息均可从应付款或其他收款中扣除。如果我们无法通过这些方式收款，可能会要求您直接向购买者支付该笔金额。',
  '40cba7837184cb0c15ca4a7cc7b557eb': '提问',
  '2cab8574e420020ef12b4729f81e5d6c': '新融资的可用信贷限额。',
  '99fb0198659b8076326d69a42670433b': '通常在下一个工作日更新余额。',
  '0448c27d5574d95fc844e50416fc13a3': '捆绑包',
  '84b730c6e665f3a83a96a8511b4f63db': '捆绑包详情',
  e03b84fcbe917d6adfa8bf31db40f9ae: '买家分公司',
  '2be86945c7f60b651a0cf58d8e73dabf': '买家拒绝的发票',
  cdb5b8f6940144b32dc7f27b7700719a: '买家汇款',
  e7094a08ea031332f2a7e7f94b214174: '买家汇款',
  b8b64034058f45fe990f8377c7990c6b: '买家汇款账户',
  b58bdf039e33f8abf293173c9e0c1ee6: '买家:',
  '9c2c1e1e0ef092c2668b7ddf551d517c': '买家在这里支付发票。您在这里支付短款',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    '在默认情况下，应从后续的融资发票中扣除到期还款额。',
  cb1a025651df74878d8d5af1b99991df: '已取消',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Cash 计划信息',
  '252982cd07a2c1e75a9cadb781c4428f': '回收款',
  a78a36ca61f82c4cde3e21bb4eb1bc20: '点击复制',
  f7a61d011f9f4aa24be33b4ddb96eaa0: '已关闭',
  a150472168406890d84a6a5c3c8a894c: '已收集',
  '0743742edda54d26e47f9959ca4be4f0': '收取的债务利息',
  e706d0d09fc62c7ec9fd51f4963a1af5: '收取的逾期费用',
  '0b2194f7621b8a036308dce1647b982d': '已收款金额',
  '0ccb7eb111a7a0b5f47f72c526b7352d': '收取的债务利息',
  b1a3b2482cd668f631d724aac2dfc685: '收取的逾期费用',
  ba18fa9a0a1da454462f71c6e36139ff: '收款余额',
  '1103815eae13527faa8d51c83c20d17e': '收款详情',
  '898e15d1fa445c9c37ca795f79029f6d': '公司法定名称',
  c05df0411bb18c405aaa68153432bf10: '信用额度和发票限额更新于',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': '可以使用的信用',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': '信用额度',
  bb1f8a35aeec1e9eadef6c63117a19d9: '使用的信用',
  '207df9f33356e378a1ff2ef7b62becc7': '可以使用的信用',
  '62337ac81597ce5c99fa74c6f57cbbf5': '信用额度',
  '208d5141343637870387b27db87ccf05': '已超出信用额度',
  '0e27340c6eb226df1076c4f8930d2289': '使用的信用',
  e15a66f111bc879c77f24943a94d19e7: '不支持此种货币',
  b1e5986f972b7599559dea22cfeae8fb: '货币:',
  '90322bb69cffbb5d303e93c3a98cc595': '每日折扣',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': '每日折扣率',
  '05042f185370ad98dcaa943ec8b2fbd1': '每日逾期率',
  '9e268b45f182ad1851f03539be6ab29c': '每日折扣率',
  c2191b6160c46ed7a4b05cc0279158f3: '每日逾期率',
  '2eca727eade526acc715c2e5605ebc7e': '全额结清放款金额的日期。',
  d8b0ba98f5a8b8a8e0dfe049222009ad: '30天宽限期结束的日期。',
  b914f3ab262190a5dd60478fa6b7b828: '您的买家拒绝发票的日期。',
  d5c0c15ebf4ff694fdd870b89754c7cf: '逾期天数',
  '1e1e68c46e0d719364bd445b1d605144': '逾期天数',
  bd50c01a22cae65be4d588abcae65a90: '扣除',
  cbe998aee5abfa07da9ece7ae8c67be1: '扣除',
  '193d60da437b58b24ed69a5683c1ffcf': '摊薄',
  '57dfeb9af6705752d57d3b50b3a2f401': '摊薄金额',
  d07e29091188f3b0871dc7712eee7255: '摊薄类型',
  f8a1650d7f4ba3f69777ad4352ee9288: '卖家直接汇款',
  bba135fe5cb16d1a6c251e9fd5225eff: '卖家直接汇款',
  '43cca9c1b76ebf869c7bf22fff271689': '文档状态筛选器',
  ce73b429141cda25571c88d9b0c78224: '文档状态',
  '90567e36a540e51806d7790eb195b1d5': '下载所有发票',
  '72015a772310560b755bd03132a543b2': '截止日期',
  aebf0bb5575e8a95e1eaa6f0de3d0416: '截止日期太早',
  '6def6918bd02ae5117332f4262cecb52': '符合条件',
  '6426c30d14da5d40d03fc7e85d0c9677': '注册状态',
  cb339c5c02cfb087fc8d393f917379df: '加载看板时出错。',
  e9994b26424cb4bce5059d05e094df8d: '预估费用',
  f159aa99d4a54cda8b884530e2ad2a1b: '排除的国家',
  fd9d568c402dd04e0715e877d76f7da5: '发票面值。',
  '817f1fe84c2d87894bc374681814abbf': '加载{title}失败 -',
  '7932079ce6ea928bfa77efd91578312d': '费用',
  '85624d3e9c7eb6862afe19de8d77dc45': '按货币筛选以查看汇总的值。',
  '110df72171aeef47da3fd84ea2a1a10f': '预测付款日期太长',
  e5af505b37031920b08edb84df19b5ec: '放款金额全额收款日期',
  a4e77d51ea7969a9e1fd98d59f99c158: '放款金额全额收款日期',
  '6dd341953c8c405c70878a457622cf55': '已放款',
  '42e20c6bb1dc82fbdbd889f769850320': '收取的放款金额',
  cab921680af6ff282ce37398b0b231d4: '放款金额',
  fc0347ceea208298049efd79021475a2: '收取的放款金额',
  cb293fb8bb3c321741d7350d5b4ff8f3: '放款日期',
  '55d900eb37f97b684c0bd5821f161216': '已放款发票',
  '6ccedf75a47ac6f5064b12a63f3e0844': '融资',
  '14e5c248a47ad6166a23fb7d4d60062a': '融资例外',
  '5ca89ce217f3bb07293db00a42c10200': '融资不符合条件的原因',
  eac067c62df8f46d6bbdbfc4f9e69803: '融资概述',
  '08109e77ecbcb34d6e7391b293d8ba20': '融资申请成功',
  d5ec85821723434aba05986044ebdcdd: '融资状态',
  '3447158e3ace443dbe27556e07c88639': '融资状态筛选器',
  '022848540bf0e01453be6cf79843aa67': '放款票期',
  b4a59826d5bfc276fa88bd00abac615a: '融资详情',
  '8fcddea1bfb1a15dd931657f61c1b1cd': '放款票期',
  e74f45b44aadcdd084b5cbdd3560f515: '宽限结束日期',
  '5434cf75f987ef76dbdc544fa1e32096': '如何付款',
  cb9f9412bd12122bb088124afccf738b: '如何偿付短款?',
  '9ad57f0b683fecb097c09bedebf68a45': '如何解决还款到期:',
  '7428e52e7eecc9d7211ad241a469cd65': '如果您认为这是错误所致，请联系 <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': '重要信息',
  '5de88f4d34f61b9e39961eecbe0ef8d2': '进行中',
  '313b15a4fdfa2368015e2eb67416af2a': '非活动程序',
  '7b6a003ceca7fe7fec85daae919e800b': '不符合条件',
  '6cf0784c1ac918c22cd402e0cb558749': '不符合条件的原因',
  '65cb9c651d2d9fb8941050a08add03c3': '不符合提前付款的条件',
  '0c184dc925e7a1c0bcb3066929300d6c': '不合格的发票',
  c555faa7b0b745638bb3ea785ee10137: '所示信息的最近更新时间为',
  b7311d63c44566a8562d92720cffed2e: '收款与发票的比率不足',
  fb07cb1753537f6969513f2d5fdb7993: '利息金额',
  '1938e208f65e66474dc4148c3fa7da08': '已扣除利息',
  '42094e40339ac5c0957a980062c47659': '短款应计利息',
  d9580a19710f07e8c72c215b1270ca57: '汇丰银行对放款金额收取的利息。',
  d73af19f82bdaf892c8ab4b1a020fe55: '已扣除利息',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: '逾期发票产生的利息',
  '47a565198d287d0864339c077819a361':
    '利息将继续计入逾期债务，直至从下次支付给您的款项中扣除，或我们收到您的直接汇款。',
  '70546e9227f592c24d3c491756cb901d': '发票 #',
  '49ab71fcac9df0f0fe5bad215adbc318': '发票金额',
  '6b9515d101794c31e38ae60d092fdc03': '发票日期',
  '2548c6a52ce9e1bffef7aad14530f490': '发票已放款',
  '42bb3ba3a1cd691726744d3a1a8da732': '发票融资概览',
  d6d9690b8dbcb32f5bd6d620c89ffadb: '发票限额',
  '734ed127b3d7226c75e8553c0711367a': '已使用的发票限额',
  '3d2825494935cd9b356babbd5d0fbdc2': '发票限额共计',
  da2db17c9b22f995d6673a754d998fc8: '发票逾期',
  d729bd1bdced62265cd4ecd7131ef94e: '发票被拒绝',
  e14208d4378a3378be8b27eb15984fc6: '发票已结算',
  '89e202e1d2ba477fb79304c9ea0a989e': '发票状态',
  '54d8df173250a197fab1644a0d0a772a': '发票金额',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    '目前无法为发票放款。请参阅“融资不符合条件的原因”，以了解更多信息。',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9': '发票货币与国家不匹配',
  '52814d7b6ec80234a8e2ecc4b5fda5b9': '发票日期早于符合条件的最早日期',
  ad0ab15468b719694f3193d0649ad9eb: '发票日期在设施取消日期之后。',
  fc9e80713aebb3d820d888f0755fedde: '发票没有订阅的买家',
  e17cbec04e7786361eff9da629f09bb7: '发票开具日期在未来。',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': '发票已取消。',
  '89f842841b4ae213259b94a5411453f2': '发票已摊薄。',
  ddd4d4560a24bcd3bb32dffdd8986c03: '发票已通过 Cash 计划融资。',
  ab5303505d045929abb1256607ca0d4d: '发票已全额支付。无需进一步付款。',
  '3e159746cbdfda07b2f45750941e26f6': '已要求开具发票以获得融资。',
  e8a7d355e6efb25006f6e4c50fde715a: '发票文档状态不合格。',
  '8204c52c01b05791dc3e4c59ac352978': '发票未全额结清且已过宽限期结束日',
  d87ca92704bcfb34fa4ac08a4d24147a: '发票目前不合格。',
  '2bfdce2f3dd38d4d167510eb5c9434de': '发票缺少结束日期或最终结束日期',
  '9ab3b9c101b4053a3818c764fcfcde35': '发票太接近预测付款日期。',
  c43016d28717b24a7ac4bd2c99f26c0d: '发票异常。',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': '发票被标记为已支付。',
  '4ac6a84899b9b6422f4e92b091a5448a': '发票可能存在重复。',
  b1f771da19efea2f0fa6a542823231b9: '发票仅部分符合条件',
  '4217948ca113ee4158d5dd4c07d71624': '以短期付款方式结算的发票',
  b6d4f7035794b3bc3a0be36237fedd2b: '发票票期不在可接受的范围内',
  '7c3fa246e8cca51273ddf5062ff2d593': '发票票期太长',
  '90d9ac20dda384e9c07554d29b143bef': '发票值超出限制',
  '4b36f0be327fdca8d54126cd57095a13': '发票/RA编号',
  f2cc23904a2b3bf60e1c059712a12cd7: '最后付款日期',
  a94bc4d4df1d56c16e25493e22684412: '可用限额',
  '92f97c843d499a1559828ba0a9134e24': '已使用的限额',
  b5dd8e130d88698834ee00464f01cea4: '可用限额',
  '08f8f98f90b3eb19ef77ad80b322921f': '已使用的限额',
  '24cc5627dea65e22f9279be9522a06e2': '贷款金额',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: '到期日',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': '会员开始日期',
  d26429adfb846303cbd889879b23e1ba:
    '在宽限期之前和/或之后从买家付款、卖家汇款和/或扣款中收取的款项。',
  '2e5b5d9440ab665bfd8ad63a16c6738b': '付款净额',
  '3d18f259085bdd0acd2ffebd2cc95e4b': '付款净额',
  eb094ee2b99cdffc1c6c4db9349d0cc2: '新金额',
  ff47fd34d6a57673fa57ad67ec26feb0: '下一个工作日',
  '325c48f099844279c0cf48584de42e64': '近期无活动',
  '7457575d324c4b8dada2e13c0f0fe5d5': '未在 Cash 中进行有效注册',
  '97d4ed503f1a63577fc5befd1c7a2663': '发票历史记录不足',
  '38e96e9d0dcbbe1b0f22950fdde4d82f': '前几个月没有足够的发票',
  c9e2ce8da559465a8596317c35e8f3a4: '没有足够的已付款发票',
  e6f890deafa579eb0c24603c3101c1c7:
    '请注意，通常在下一个工作日更新余额。如需了解当前情况，请提交支持工单。',
  '83070a059f10db1515ac922bf3433158': '宽限结束日期后的天数。',
  '9d40ba58bf72a5bf003cd0d9a1fac469': '有一份报告可供下载',
  '2c825aed78dedab44e18d2c0e60e7cf7': '其他费用',
  fad92e97e692758c0d0a9b5730663894: '未付的债务利息',
  '6c2e55b7a6b08252eb4124bd2f75b5df': '未付的放款金额',
  '9eab1baddf2537312408088fc54f21d5': '未付的逾期费用',
  bfba12a2c234532c0af24a4ad6831ab6: '未付的总金额',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': '未付的债务利息',
  '3b77ef3a1af4e72eb5072092bae35ddd': '未付的放款金额',
  '2f9a3de6940f818fe0376e4aece59512': '未付的逾期费用',
  '1fdf67a5f18bc408f2ad55412310c39f': '逾期余额',
  '848ac8c39b11a751d697a383303e6109': '逾期债务',
  '8d1e868665fc24a46f0cf55187f72218': '逾期的债务将按照 RBI 的规定进行报告。',
  '830b95d305cdc61a66cdf75ebff1d737': '买家已支付',
  '6444796646c95dbc2cd1cb646e41293d':
    '按照汇丰银行批准的预付款比例放款的部分发票面值。',
  '92cacee97a99fe8c26a5c1999ae51293': '参与买家详情',
  a39314e5897b8baaa51ca296610ec30c: '合作伙伴条款',
  a6acd5f03fda9c8dc607e59d8be08320: '应付账款审批延迟',
  e6249829dadf637c4092e4a900df529e: '买家已对应付账款提出异议。',
  '07ed468d28d8e8437d8ec64036b96c03': '应付账款已被拒绝',
  '042b8dfb88f7045ca9145bbcccf79200': '应付账款在未来处理',
  f8768f5b4055c45a4d30591bea9cc7b1: '应付账款太旧',
  '8755bbc212b541efb6576f39f3f0f51f': '应付账款已分配',
  '8744ee10540166dfc39db2a6859a9ce1': '付款金额',
  '56133aa371eb0132334e4867479be942': '付款详情',
  '28d03c82bf3e37e2ff159922908c1ecb': '逾期付款',
  '2dd7164df606f9b42beefaba031b06f7': '付款已接收',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': '卖家已确认付款',
  '661711004f06b0d9601db365679be130': '支付金额',
  c6e4c2b52f344cb3dc4f21eb1af772db: '支付日期',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: '罚息',
  '5d6bc30e401a38c04005a5d1962b46bc': '待定摊薄',
  '8cf3479829ba47a8a4123895d0088eeb': '待定摊薄',
  '4a78218416ca45d97b5633b37ae3f638':
    '在整个融资票期内计算每日费用的百分比率。',
  b586a5468c7070bf1717593ea84fa607:
    '对于逾期或已放款但被拒绝的发票，每天将按未付放款金额向您收取费用的百分比。',
  f8c94740abd5c69fb813bd0f4827de0e: '如需了解当前余额，请提交支持工单。',
  '34645fa620cae4187f27d9f96c172f26': '汇款金额为',
  '4c54e70d0c88c94d16c03e32519d6be7': '请将以下金额汇到以下银行帐户：',
  '01734a7316427fdcdcd4f937cda3802b': '预付款',
  '8181a4caaa4413f5585df4a5a5f979ba': '上一个金额',
  '500fc4731dab800a878b33906d9332fb': '项目收款帐户',
  '7327113634d1f5f4f68632dceebfbc6f': '程序概述',
  d95ebfb51a485293a134b1038d332a10: '项目激活日期',
  '6e5f8b0b9bafba4816b7a0430194289a': '项目详情不适用于仪表板',
  '98feb2c86a81b8caeeaa30966ba34636': '项目信息',
  d202f03687187e744a1f4b11a1e5f8e4:
    '采购发票付款如果在预期的到期日前没有全额收款，就会产生短款。短款的利息是每日折扣率的 1.5 倍，并且您有责任偿付这两笔款项。',
  e84203a8833e4d0d92faf89f4107bf11: '追索还款',
  '7e7c742f83bcdbd977c9e32d053b3f52': '退款金额',
  b0be744ae1f6312f16990bbd92a87aea: '退款发放日期',
  '39591f42bcdde6d7ce9409e167aa7e80': '退款票期',
  '654d6dcc141079bc84500fd14ca5087d': '退款金额',
  '50618267079bccdc7f15c8a8e8aa6b73': '退款详情',
  '5b2508eaa0c48b46fe2034cdf1916867': '退款发放日期',
  c0a6a91e7ca80af9de47097b06dc7618: '退款票期',
  '592b34cdfa2e51826b32a0783e0edce3': '买家已拒绝',
  '807d35f105b65ab2795c4f8e51795f3c': '卖家已拒绝',
  aea4dd208e871d7ca0051b0ec38c3068: '拒绝日期',
  d64ed4934fbf68a8f716467b0adf89ba: '拒绝日期',
  '5a77de3b287a92494be077c197f37581': '尚未收款或偿还的剩余放款金额。',
  e442b864bf745a75477ea0ee88bc15c4: '还款到期',
  b4f924c1a7c5d182fab9d3088cc22471:
    '宽限结束日期的还款金额。这不会进行更改以反映此日期后的任何还款，也不包括逾期债务。',
  '636a65df0d453f8e6f73e87e65276c5e': '还款到期',
  dc20cbfb76d220c108241a451bc3faf4: '还款说明信息',
  c8c021e2a7c9064a0b97db4aa96f6623:
    '到期还款是指由于买家支付的金额低于发票面值，和/或买家在收到资金后拒绝发票而导致的需要收回的未付款项总额。',
  '45b60e22719c12291932bf09db0df5cd':
    '到期还款是指因以下两种情况之一而需要收回的未付总金额：',
  ba093b1421452b3afdebbebedaeae552: '申请资金',
  '34f8f9968ec0d7f91e558e906a7de982': '已请求',
  dc1650cfbadb05f8fb6357692e48ee71: '请求融资',
  '563dff6e09a85e6fb07b91c48e9d0fca': '保留',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': '保留金额',
  a476316a89541d1aa71d5b5366874e13: '保留金额',
  '87d4fbccb70028d34d4260b9527e3fd3': '按发票号码搜索',
  '3215999493db188b35242dc4f4fd8fb5': '关于还款的进一步信息，请参阅程序信息。',
  '56a1c7c4a02e9985745969563a80799f': '卖家还款帐户',
  e243f36392a7f264d830f04f21aa8972: '已结算发票',
  b548572d4123e3a569d27e4676664dad: '短款',
  '6e09ada7e33833d259370b0cc900f082': '短款金额',
  bbb44706c9f67b578a7aac0a5ed30e40: '短款扣款',
  '8be3fe50247f2256bd4aa1eb8bc67fae': '短款',
  '46bbc145b1f85c082950c768ade012f6': '短款金额',
  e66e6d9768e8f489c05951ee70ede350:
    '出错了，仪表板的所有功能可能无法使用。请重试或向支持团队寻求帮助。',
  cd969f183dc619b5716067cfda39c055: 'Sort代码',
  '750d4460b260343039f9bc71b66a231c': '供应商已被视为破产',
  '7f6562457845bcf84c0cfece3d1eaa51': '供应商尚未进行条目设置',
  '9ef0225cd6b858ef31c2f3c1bd312baa': '条款接受方:',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': '服务条款',
  '1294cf929ec55581954a79948592dda9': '激活 Cash 计划融资的日期。',
  f51144e4468390a486d41b4db66fc243: '该文件无法下载。',
  '692eebfa29fc5d4a7090a22f5aa668fd': '汇丰银行批准的预计买家付款的最长天数。',
  d438da584c455e727642a303917e690d:
    '可使用的剩余信贷金额。这是信用额度与已用信用之差。',
  '78c4e85a6398bb0b164ed2479f1faeca':
    '当前已为该计划放款的发票总额。该金额根据信用额度和当前放款的发票金额计算得出。',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    '能够为该计划放款的发票总额。该金额根据信用额度和当前放款的发票金额计算得出。',
  '9c9fa02e1129e31e3423a0dfca81732d': '发票结算前留存的总金额。',
  '13f271ac486faca89c1944226ba0efff': '导入的所有发票的面值总额。',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    '由于已全面收集而转变为已结清状态的发票面值总额。',
  '8cbe218cd17eada461e5da93d4cbd541': '已放款发票的面值总额',
  '565c255165bed2ff2640d0e42369c62c': '发票总价值不符合放款条件。',
  '3463c9dcfdb9aa161c07a692207786bf': '此帐户没有在 Cash 中进行有效注册。',
  '1fa3524cbb92859434dcb01b98f71c7d':
    '通常会在下一个工作日更新。如需了解当前余额，请提交支持工单。',
  '0678bf00ae9e72b3053f3d5f0f64e639': '此值反映您最近的短款余额（包括利息）。',
  '32460c75c8ea6599665b77eeb2df37a3': '连同',
  '603e4b3b3ac7b888a06548d14435bc25': '摊薄太多',
  '7cfb9dc5c11c2fb869c897552b563d14': '总收款金额',
  f786c8a1095bdf155841d510bb53142a: '逾期债务利息总额。',
  '4f25d1096076868bc590da9f8a26cf55': '总限额',
  '230bd56af0acab75194f58b46a763535': '从买家付款中收取的总额。',
  '8f78461d739b4d6d46f961f352000cec': '从您这里收取的总额。',
  b62dd565f60dc6172605d8db223510b9:
    '通过买家汇款、卖家汇款和/或回收款收取的总金额，包括逾期债务的所有利息。',
  '8dfcacc58acbafda8eca3d58c4d8a447': '为您的销售放款的资金总额。',
  '1c27145f9530e0e06436fea1e3e645cf':
    '按照汇丰银行批准的预付款比例计算的未放款总额。',
  d6c89215b3fd6857262efc2039d78dec: '全额收取的放款发票总额。',
  a069138e83a8b4cb7e81145689155826: '逾期债务中尚未支付或未付的利息总额。',
  '45f2193bb0825a19bb1ede965692c3a4': '逾期应付费用总额。罚金按日累计。',
  bc37640f0d55a99b3f0b8bce05a2f0d1: '已支付的逾期费用总额。',
  bec87347063c8e0b9fac78e381fa0bff: '尚未支付或未付的逾期费用总额。',
  d649fb9a0971deaa127067341f59be91: '从其他发票扣款中收回的总额。',
  '28fcda8475061ec7eb4894565e49fc87': '尚未收取或偿还的总额。',
  d6b59ced2fa8fba56e7e9c3c451fd409: '总收款金额',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3': '发票总面值不符合融资条件。',
  '02bd64f3a39f4190918d46eb0e86a1c6': '发送给买家的发票的面值总额。',
  f0164c47e6c3d2497856505cb08e6e4f:
    '通过买家汇款、卖家汇款和/或回收款收取的融资总额。',
  d4867ee77e79ded226c07918b9393715: '已支付的逾期债务利息总额。',
  de4c28160afe1ab48bcf83be49bbb196: '总限额',
  '9c9f73042961fc50756dc8ae2bbe2a6b': '未付总额',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    '您应偿还的未付融资总额和任何逾期债务利息。这可能是因为一张或多张融资发票逾期，或者您的买家在融资后拒绝了您的一张或多张发票。',
  fecdb72e34cebfadf037b45b596d90e5: 'Tradeshift Cash 程序常见问题',
  '528a7c5ab621e9a113ff3fa6e16323e5': '交易详情',
  e00ab25ba9a337b2f39d3d7dc06790c1: '无法下载发票 csv。请联系支持团队',
  c8415d81378085ba3933015b8f5fc768: '无法获取协议。请联系支持团队。',
  '64580a198c861aa0226c91b28f8418aa': '无法销售',
  '5d7f86753376a21d28b5c1ad07c7d149':
    '超过此日期的未付发票将被视为“逾期”，此后将按日计息。',
  '9d632e72031eea3767881d6c3adcfe54': '使用的信用限额。',
  e871a6882109bd7ef07827882236c8db: '查看协议',
  '0bcb6eb0e07f04a47296d8c341dd94ad': '欢迎使用 Cash!',
  '78eb58937961a18929a623397b544a01':
    '您可以向还款帐户(上文所列)直接汇款来结清到期的还款余额。',
  f347abf54f65274a614a9ba7515dfa98:
    '您可以通过 Cash 应用在一个地方跟踪所有提前付款',
  '0b28e75aa5587b8f49edaa7e620b5cf0': '您的 Cash 程序处于活动状态!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': '您的 Cash 程序处于非活动状态',
  '6e6e06469dd953a124d718cd2307e32c':
    '您对 Cash 计划的参与自 {date} 起不再有效。请联系<a href=',
  '626afbd7141286d15df45bccdb695a66':
    '在宽限期结束前被买家拒绝的融资发票（拒绝当日起按日计息）',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    '在宽限期结束时仍未付款的融资发票（此后按日计息）；和/或',
  '33e67e73d7f54aaee31e6040f873be79': '已应用于扣除',
  a9798a3e063111de4d4768c363bdc267: '已应用于发票',
  a5be13d3bfec5fc92521a841009db3c7: '银行账号',
  '736c1e36ac779a73ff4fddd19d82008c': '日利率',
  ab53acde68c117f8fbfff6a8901de083: '每日逾期利率',
  '047dbee224da5fb4ecfc21268ffd3115': '到期日期',
  dd051262964c80130a2e9023ff933d89: '提前付款',
  bc55e8a10310a96eeda133ddf159303d: '预计到期日期',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': '对于提前付款',
  '932d9bd46daba4d09f361717c0ec03ab': '放款金额',
  '5e82566144af4903b40303fc3d1c799e': '放款日期',
  '97048ad7f0d40ee95f476fdd4c7bf81e': '宽限期',
  '0a6949f0e73bc3c42b0e93fe10e63fff': '到期付款',
  '99c2f4783bb6821d84ad17a22f556813': '已放款',
  c138be463a457afe89e5ecfefc6a3dc9: '未付红字发票',
  '37f59e13afd5455cf130060ed55f4976': '路由号码',
  '1e5c6ba6010146f42f6fa180329cf2dc': '票期',
  '763d4d3568ec0349aa3c3dbe3064fd59': '到以下银行账户:',
  cf4edd5f38054a752994e3242a1f3807: '总金额',
  '3c9f634310f5e3de42b79ee83d20923c': '总费用',
  '573b1b7930d8030d1669945f2c34992a': '已转账到您的银行',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: '将从接下来的付款中扣除',
};
