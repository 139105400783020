export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Säädetty valuutan tai pyhäpäivien vuoksi)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* Maksut ja maksupäivät ovat viitteellisiä eikä niitä voida taata.',
  fa090ada546b5deb33cdead006822e26:
    '* Maksut ja maksupäivät ovat viitteellisiä eikä niitä voida taata.',
  '52fce318164dc60017ca67121172aca9': 'Hyväksytty',
  '615a093396ed9c6f52cd7ece7217608e': 'Tilin säätö',
  '79dd431d35b370210a3dbeb1bb653d43': 'Tilin nimi',
  '8c66a974a95769447407c061a36d0824': 'Tilinumero',
  '6a810f9427275516cc7ac687ea182f27': 'Kertyneet velan korot',
  '21df2d8134802290caddc9a8070e9d94': 'Kertyneet erääntyneet maksut',
  '7377dcc6989501c8299b7ccf697b731e': 'Kertyneet velan korot',
  dab81136f1c6e799c5626826349a8dcf: 'Kertyneet erääntyneet maksut',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Kaikki',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Kaikki ostajat',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Kaikki valuutat',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Kaikki laskut',
  d7db3397d1ee87530b253bc69ebca041: 'Summa',
  c7586d1e153c74713afd948b3048d03d:
    'Maksettu määrä sen jälkeen, kun rahoitetusta määrästä on vähennetty korot ja mahdolliset erääntyneet takaisinmaksut.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Muilta laskuilta erääntyvistä takaisinmaksuista perittävä määrä.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Lähetettävä summa',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Kaikki ylimääräiset varat välitetään sinulle.',
  de9800ff635c316938aece381c98b094:
    'Maksamattomat lyhennykset ja korot voidaan vähentää maksettavasta ostohinnasta tai muista perittävistä. Jos emme pysty perimään kyseisillä tavoilla, voimme pyytää sinua maksamaan summan suoraan ostajalle.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Hyväksytty',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Kysy kysymys',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Käytettävissä oleva luottoraja uudelle rahoitukselle.',
  '85a6c2f454268a7b3c4156c070abc517': 'Odottaa hyväksyntää',
  '99fb0198659b8076326d69a42670433b':
    'Saldot päivitetään tyypillisesti seuraavana arkipäivänä.',
  '0448c27d5574d95fc844e50416fc13a3': 'Nippu',
  '84b730c6e665f3a83a96a8511b4f63db': 'Nipun tiedot',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Ostajan sivukonttori',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Ostaja hylkäsi laskun',
  cdb5b8f6940144b32dc7f27b7700719a: 'Ostajan maksusuoritus',
  '2e5e060fbc0b1324e5e2059482c65be6': 'maksettu (Ostaja)',
  e7094a08ea031332f2a7e7f94b214174: 'Ostajan maksusuoritus',
  b8b64034058f45fe990f8377c7990c6b: 'Ostajan maksutili',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Ostaja:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Ostajat maksavat tässä laskut. Maksat tässä lyhennyksiä',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Maksamatta oleva takaisinmaksusumma vähennetään oletusarvoisesti seuraavista rahoitetuista laskuista.',
  cb1a025651df74878d8d5af1b99991df: 'Peruutettu',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Käteisohjelman tiedot',
  '6fd54168f061cd9f269dbcbb29415486': 'Veloituksen määrä',
  '252982cd07a2c1e75a9cadb781c4428f': 'Takaisinperintä',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Klikkaa kopioidaksesi',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Sulje',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Suljettu',
  a150472168406890d84a6a5c3c8a894c: 'Kerätty',
  '0743742edda54d26e47f9959ca4be4f0': 'Kerätyt velkakorot',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Kerätyt erääntyneet maksut',
  '0b2194f7621b8a036308dce1647b982d': 'Peritty määrä',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Kerätyt velkakorot',
  b1a3b2482cd668f631d724aac2dfc685: 'Kerätyt erääntyneet maksut',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Kokoelma',
  ba18fa9a0a1da454462f71c6e36139ff: 'Kokoelman saldot',
  '1103815eae13527faa8d51c83c20d17e': 'Kokoelman tiedot',
  '898e15d1fa445c9c37ca795f79029f6d': 'Yrityksen rekisteröity nimi',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Jatka',
  c05df0411bb18c405aaa68153432bf10: 'Luotto- ja laskutusraja päivitetty',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Käytettävissä oleva luotto',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Luottoraja',
  d23014cf5a5e7f8c6440b96692831aa8: 'Hyvityslasku',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Kulutettu luotto',
  '207df9f33356e378a1ff2ef7b62becc7': 'Käytettävissä oleva luotto',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Luottoraja',
  '208d5141343637870387b27db87ccf05': 'Luottoraja on ylitetty',
  '0e27340c6eb226df1076c4f8930d2289': 'Luotto käytetty',
  e15a66f111bc879c77f24943a94d19e7: 'Valuuttaa ei tueta',
  b1e5986f972b7599559dea22cfeae8fb: 'Valuutta:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Päivittäinen alennus',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Päivittäinen alennussumma',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Päivittäinen erääntymisprosentti',
  '9e268b45f182ad1851f03539be6ab29c': 'Päivittäinen alennussumma',
  c2191b6160c46ed7a4b05cc0279158f3: 'Päivittäinen erääntymisprosentti',
  '106202929cf48a3de3f20b3255365915': 'Päivämäärä',
  '2eca727eade526acc715c2e5605ebc7e':
    'Päivämäärä, jona rahoitettu summa maksettiin kokonaisuudessaan.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Päivämäärä, jolloin 30 päivän maksuajan pidennys päättyy.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Päivämäärä, jolloin ostaja hylkäsi laskun.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Päivää myöhässä',
  '1e1e68c46e0d719364bd445b1d605144': 'Päivää myöhässä',
  bd50c01a22cae65be4d588abcae65a90: 'Vähennys',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Vähennykset',
  d706035ec5f053ca33b234ddec228ab2: 'Kuvaus',
  a39bf2f30994908fe95498524b423d43: 'Lisätietoja',
  '193d60da437b58b24ed69a5683c1ffcf': 'Dilutoitu',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Diluution summa',
  d07e29091188f3b0871dc7712eee7255: 'Diluution tyyppi',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Suora myyjän maksusuoritus',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Suora myyjän maksusuoritus',
  afc1c7dfc9315e856f8da52e2450182b: 'Alennus',
  '26e7eb298420701d5a03c53fe096cc0b': 'Kiistetty',
  '43cca9c1b76ebf869c7bf22fff271689': 'Asiakirjan tilasuodatin',
  ce73b429141cda25571c88d9b0c78224: 'Asiakirjan tila',
  af13cc2ad0a7337c45420f75b7d5a668: 'Lataa',
  '8475be8bb8249000118af931ae75c373': 'Lataa raportti',
  '90567e36a540e51806d7790eb195b1d5': 'Lataa kaikki laskut',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Vedos',
  '72015a772310560b755bd03132a543b2': 'Eräpäivä',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Määräpäivä',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Määräaika liian pian',
  '6def6918bd02ae5117332f4262cecb52': 'Hyväksyttävä',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Ilmoittautumistila',
  cb339c5c02cfb087fc8d393f917379df: 'Virhe koontinäyttöä ladatessa.',
  e9994b26424cb4bce5059d05e094df8d: 'Arvioitu maksu',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Poissuljettu maa',
  b5cb31ac9c7d1871e9075efc1f271536: 'MAKSU',
  fd9d568c402dd04e0715e877d76f7da5: 'Laskun nimellisarvo.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Epäonnistui',
  '817f1fe84c2d87894bc374681814abbf':
    'Kohteen {title} lataaminen epäonnistui -',
  '7932079ce6ea928bfa77efd91578312d': 'MAKSUT',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Suodata valuutan mukaan nähdäksesi summat yhteensä.',
  '110df72171aeef47da3fd84ea2a1a10f': 'Ennakoitu maksupäivämäärä liian pitkä',
  e5af505b37031920b08edb84df19b5ec: 'Koko rahoitetun summan Peritty-päivämäärä',
  a4e77d51ea7969a9e1fd98d59f99c158: 'Koko rahoitetun summan lunastuspäivämäärä',
  '6dd341953c8c405c70878a457622cf55': 'Rahoitettu',
  '42e20c6bb1dc82fbdbd889f769850320': 'Rahoitettu summa peritty',
  cab921680af6ff282ce37398b0b231d4: 'Rahoitettu summa',
  fc0347ceea208298049efd79021475a2: 'Kerätty rahoitettu summa',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Rahoituspäivä',
  '55d900eb37f97b684c0bd5821f161216': 'Rahoitetut laskut',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Rahoitus',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Rahoituksen poikkeus',
  '5ca89ce217f3bb07293db00a42c10200': 'Rahoituksen kelvottomuuden syy(t)',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Rahoituksen yleiskatsaus',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Rahoituspyyntö onnistui',
  d5ec85821723434aba05986044ebdcdd: 'Rahoituksen tila',
  '3447158e3ace443dbe27556e07c88639': 'Rahoituksen tilasuodattimet',
  '022848540bf0e01453be6cf79843aa67': 'Rahoituksen sisältö',
  b4a59826d5bfc276fa88bd00abac615a: 'Rahoitustiedot',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Rahoituksen sisältö',
  e74f45b44aadcdd084b5cbdd3560f515: 'Maksuajan pidennyksen päättymispäivä',
  '5434cf75f987ef76dbdc544fa1e32096': 'Maksuohjeet',
  cb9f9412bd12122bb088124afccf738b: 'Kuinka osamaksut maksetaan takaisin?',
  '9ad57f0b683fecb097c09bedebf68a45':
    'Kuinka ratkaista erääntynyt takaisinmaksu:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'LASKU',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Jos uskot, että tämä on virhe, ota yhteyttä <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Tärkeää tietoa',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'Käynnissä',
  '313b15a4fdfa2368015e2eb67416af2a': 'Passiivinen ohjelma',
  '7b6a003ceca7fe7fec85daae919e800b': 'Kelpaamaton',
  '6cf0784c1ac918c22cd402e0cb558749': 'Sopimaton syy',
  '65cb9c651d2d9fb8941050a08add03c3': 'Ei kelpaa Early payment -maksuksi',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Kelvottomat laskut',
  c555faa7b0b745638bb3ea785ee10137: 'Näytettävät tiedot on päivitetty viimeksi',
  b7311d63c44566a8562d92720cffed2e: 'Riittämättömät perinnät laskusuhteisiin',
  fb07cb1753537f6969513f2d5fdb7993: 'Koron määrä',
  '1938e208f65e66474dc4148c3fa7da08': 'Korot vähennetty',
  '42094e40339ac5c0957a980062c47659': 'Lyhynnyksestä kertynyt korko',
  d9580a19710f07e8c72c215b1270ca57:
    'Korot, jotka HSBC on veloittanut rahoitetusta summasta.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Korot vähennetty',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Erääntyneestä laskusta kertyneet korot',
  '47a565198d287d0864339c077819a361':
    'Korot kertyvät erääntyneestä velasta, kunnes ne joko vähennetään seuraavasta maksusta sinulle, tai saamme suoran maksusuorituksen.',
  '3c4be400f728da4526de626653d56ccc': 'Lasku',
  '70546e9227f592c24d3c491756cb901d': 'Lasku #',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Laskun määrä',
  '6b9515d101794c31e38ae60d092fdc03': 'Laskun päiväys',
  '2548c6a52ce9e1bffef7aad14530f490': 'Lasku maksettu',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Laskun rahoituksen yleiskatsaus',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Laskutusraja',
  '734ed127b3d7226c75e8553c0711367a': 'Laskuraja käytössä',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Laskuraja yhteensä',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Laskun rivinumero',
  da2db17c9b22f995d6673a754d998fc8: 'Lasku erääntynyt',
  d729bd1bdced62265cd4ecd7131ef94e: 'Lasku hylätty',
  e14208d4378a3378be8b27eb15984fc6: 'Lasku soviteltu',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Laskun tila',
  '54d8df173250a197fab1644a0d0a772a': 'Laskun määrä',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'Laskua ei voi rahoittaa tällä hetkellä. Katso lisätietoa kohdasta Rahoituksen kelvottomuuden syy(t).',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9': 'Laskun valuutta ei täsmää maata',
  d0da9e977c8b309f93ee378de34fb251: 'Laskun päivämäärä',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Laskun päivämäärä ennen varhaisinta hyväksyttävää päivää',
  ad0ab15468b719694f3193d0649ad9eb:
    'Laskun päivämäärä on laitoksen peruutuspäivän jälkeen.',
  fc9e80713aebb3d820d888f0755fedde: 'Laskulla ei ole tilattua ostajaa',
  e17cbec04e7786361eff9da629f09bb7:
    'Laskun julkaisupäivämäärä on tulevaisuudessa.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'Lasku on peruutettu.',
  '89f842841b4ae213259b94a5411453f2': 'Lasku on dilutoitu.',
  ddd4d4560a24bcd3bb32dffdd8986c03: 'Lasku on rahoitettu käteisohjelmalla.',
  ab5303505d045929abb1256607ca0d4d:
    'Lasku on maksettu kokonaan. Muita maksuja ei tarvita.',
  '3e159746cbdfda07b2f45750941e26f6': 'Laskun rahoitusta on pyydetty.',
  e8a7d355e6efb25006f6e4c50fde715a: 'Laskussa on kelvoton asiakirjatila.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Laskua ei ole maksettu kokonaan ja sen maksuajan pidennys on umpeutunut',
  d87ca92704bcfb34fa4ac08a4d24147a: 'Lasku on tällä hetkellä kelpaamaton.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Laskusta puuttuu päättymispäivämäärä tai viimeinen päättymispäivä',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'Lasku on liian lähellä ennusteen maksupäivää.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'Lasku on epätavallinen',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Lasku on merkitty jo maksetuksi.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Lasku voi olla kaksoiskappale.',
  b1f771da19efea2f0fa6a542823231b9: 'Lasku vain osittain kelvollinen',
  '4217948ca113ee4158d5dd4c07d71624': 'Lasku selvitetty lyhyellä maksulla',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'Laskun sisältö ei ole hyväksyttävän alueen sisällä',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Laskun sisältö liian pitkä',
  '90d9ac20dda384e9c07554d29b143bef': 'Laskun arvo ylittää rajan',
  '4b36f0be327fdca8d54126cd57095a13': 'Laskun/RA-numero',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Laskut',
  '8089283aad9294aadc2bde165ccc78c1': 'Julkaisupäivämäärä',
  '3a4123f9c604369ab18781f7492f9293': 'Julkaisupäivämäärä',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Viimeisin maksupäivämäärä',
  a94bc4d4df1d56c16e25493e22684412: 'Käytettävissä oleva raja',
  '92f97c843d499a1559828ba0a9134e24': 'Raja käytössä',
  b5dd8e130d88698834ee00464f01cea4: 'Käytettävissä oleva raja',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Raja käytössä',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Ladataan...',
  '24cc5627dea65e22f9279be9522a06e2': 'Lainamäärä',
  bd24bcedd066a27749016a07cb729a32: 'Merkitty suoritetuksi',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Eräpäivä',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'Jäsenyyden aloituspäivä',
  d26429adfb846303cbd889879b23e1ba:
    'Ostajan maksuista, myyjän rahalähetyksistä ja/tai vähennyksistä kerätyt rahat, jotka on tehty ennen lisäaikaa ja/tai sen jälkeen.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Maksettu nettosumma',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Maksettu nettosumma',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Uusi summa',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Seuraava arkipäivä',
  '325c48f099844279c0cf48584de42e64': 'Ei viimeaikaista toimintaa',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Ei mitään',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Ei aktiivisesti ilmoittautunut Cashiin',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Laskuhistoriaa ei riittävästi',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Ei tarpeeksi laskuja edellisinä kuukausina',
  c9e2ce8da559465a8596317c35e8f3a4: 'Ei tarpeeksi maksettuja laskuja',
  e6f890deafa579eb0c24603c3101c1c7:
    'Huomaa, että saldot päivitetään tyypillisesti seuraavana arkipäivänä. Lähetä tukipyyntö, jos sinun täytyy tietää nykyinen asema.',
  '83070a059f10db1515ac922bf3433158':
    'Päivien määrä maksuajan pidennyksen päättymispäivän jälkeen.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Yksi raportti ladattavissa',
  '2778717f6acbeb1edaac6a2656b478f3': 'Avaa',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Muu maksu',
  fad92e97e692758c0d0a9b5730663894: 'Maksamaton velkakorko',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Maksamaton rahoitettu summa',
  '9eab1baddf2537312408088fc54f21d5': 'Maksamattomat erääntyneet maksut',
  bfba12a2c234532c0af24a4ad6831ab6: 'Maksettava summa yhteensä',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Maksamaton velkakorko',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Maksamaton rahoitettu summa',
  '2f9a3de6940f818fe0376e4aece59512': 'Maksamattomat erääntyneet maksut',
  '6f79eea38bff17917740a69116270074': 'Erääntynyt',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Erääntyneet saldot',
  '848ac8c39b11a751d697a383303e6109': 'Erääntynyt velka',
  '8d1e868665fc24a46f0cf55187f72218':
    'Erääntyneet velat ilmoitetaan RBI:n säännösten mukaisesti.',
  '5b885084a5067d7fb4372cef38d8a82d': 'Maksettu',
  '830b95d305cdc61a66cdf75ebff1d737': 'Ostajan maksama',
  '6444796646c95dbc2cd1cb646e41293d':
    'Osa laskun nimellisarvosta, joka on rahoitettu HSBC:n hyväksymän ennakkomaksun prosenttiosuuden mukaisesti.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Osallistuvan ostajan tiedot',
  a39314e5897b8baaa51ca296610ec30c: 'Kumppanin ehdot',
  a6acd5f03fda9c8dc607e59d8be08320: 'Maksun hyväksymisviive',
  e6249829dadf637c4092e4a900df529e: 'Ostaja on kiistänyt maksettavan summan',
  '07ed468d28d8e8437d8ec64036b96c03': 'Maksettava summa on hylätty',
  '042b8dfb88f7045ca9145bbcccf79200': 'Maksettava summa on tulevaisuudessa',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Maksu on liian vanha',
  '8755bbc212b541efb6576f39f3f0f51f': 'Maksu kohdistettu',
  '8744ee10540166dfc39db2a6859a9ce1': 'Maksun summa',
  '56133aa371eb0132334e4867479be942': 'Maksuehdot',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Maksu erääntynyt',
  '2dd7164df606f9b42beefaba031b06f7': 'Maksu vastaanotettu',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Myyjän vahvistama maksu',
  '661711004f06b0d9601db365679be130': 'Maksun määrä',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Maksupäivämäärä',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Viivästyskorko',
  '0fd036622716f275c3e14c5a14faa65a': 'Odota',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Odottava diluutio',
  '8cf3479829ba47a8a4123895d0088eeb': 'Odottavat diluutiot',
  '270b56fd05f72407ca0607b6162d0b10': 'Odottaa yhteyttä',
  '4a78218416ca45d97b5633b37ae3f638':
    'Prosenttiosuus, jolla päivittäiset maksut lasketaan koko rahoitussisällön osalta.',
  b586a5468c7070bf1717593ea84fa607:
    'Prosenttiosuus, jonka pohjalta sinua veloitetaan päivittäin maksamattomasta rahoitetusta summasta koskien erääntyneitä tai rahoitettuja ja hylättyjä laskuja.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Ole hyvä ja lähetä tukipyyntö, jos haluat tietää nykyiset saldot.',
  '34645fa620cae4187f27d9f96c172f26': 'Lähetä summa',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Ole hyvä ja maksa seuraava summa seuraavalle pankkitilille:',
  '01734a7316427fdcdcd4f937cda3802b': 'Ennakkomaksu',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Edellinen summa',
  '500fc4731dab800a878b33906d9332fb': 'Ohjelman perintätili',
  '7327113634d1f5f4f68632dceebfbc6f': 'Ohjelman yleiskatsaus',
  d95ebfb51a485293a134b1038d332a10: 'Ohjelma aktivoitu',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Ohjelman tiedot eivät saatavissa koontinäytölle',
  '98feb2c86a81b8caeeaa30966ba34636': 'Ohjelman tiedot',
  d202f03687187e744a1f4b11a1e5f8e4:
    'Ostetut laskutusmaksut, joita ei ole peritty kokonaan odotettuun eräpäivään mennessä, luovat lyhennyksen. Lyhennyksistä kertyy korkoa 1,5-kertaisesti päivittäiseen alennukseen verran ja sinun vastuullasi on maksaa molemmat.',
  '521689b600b8da5b0a111b89dea72126': 'Syy',
  fe7bd622a1efcec03eb86dabb9395936: 'Vastaanotettu',
  e84203a8833e4d0d92faf89f4107bf11: 'Takausmaksun takaisinmaksu',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Hyvityksen summa',
  b0be744ae1f6312f16990bbd92a87aea: 'Hyvityksen myöntämispäivä',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Hyvityksen sisältö',
  '654d6dcc141079bc84500fd14ca5087d': 'Hyvityssumma',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Hyvityksen tiedot',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Hyvityksen myöntämispäivä',
  c0a6a91e7ca80af9de47097b06dc7618: 'Hyvityksen sisältö',
  '4a2875439ab289a068abb9ea3b567bcb': 'Hylätty',
  '592b34cdfa2e51826b32a0783e0edce3': 'Ostajan hylkäämä',
  '807d35f105b65ab2795c4f8e51795f3c': 'Myyjän hylkäämä',
  aea4dd208e871d7ca0051b0ec38c3068: 'Hylkäyspäivä',
  d64ed4934fbf68a8f716467b0adf89ba: 'Hylkäyspäivä',
  '5a77de3b287a92494be077c197f37581':
    'Jäljellä oleva rahoitettu määrä, joka on perittävä tai maksettava takaisin.',
  e442b864bf745a75477ea0ee88bc15c4: 'Takaisinmaksun eräpäivä',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Takaisinmaksusumma maksuajan pidennyksen päättymispäivänä. Tämä ei muuta kyseisen päivän jälkeen suoritettuja takaisinmaksuja eikä se sisällä erääntyneitä velkoja.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Takaisinmaksun eräpäivä',
  dc20cbfb76d220c108241a451bc3faf4: 'Takaisinmaksun ohjetiedot',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Erääntyvät takaisinmaksut ovat maksamatta olevia kokonaissummia, jotka on perittävä takaisin ja jotka muodostuvat ostajan maksaman summan ollessa laskun nimellisarvoa pienempi ja/tai ostajan hylätessä laskun sen jälkeen, kun se on rahoitettu.',
  '45b60e22719c12291932bf09db0df5cd':
    'Erääntyneet takaisinmaksut ovat perittäviä maksamattomia summia, jotka johtuvat joko seuraavasta:',
  ba093b1421452b3afdebbebedaeae552: 'Rahoituspyyntö',
  '34f8f9968ec0d7f91e558e906a7de982': 'Pyydetty',
  dc1650cfbadb05f8fb6357692e48ee71: 'Pyydetty rahoitusta varten',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Pidätetty',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Pidätetty summa',
  a476316a89541d1aa71d5b5366874e13: 'Pidätetty summa',
  '328f6585a0e78a96deefc0661474248f': 'Yritä uudelleen',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Myynnit',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Etsi laskunumerolla',
  '3215999493db188b35242dc4f4fd8fb5':
    'Katso ohjelmatiedot saadaksesi lisätietoja takaisinmaksuista.',
  '69f43575c3a51e94605eb75b75f7b563': 'Valittu',
  '56a1c7c4a02e9985745969563a80799f': 'Myyjän takaisinmaksutili',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Lähettämässä',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Lähetetty',
  '95d54926719f1071b2fa640f1ba23d3e': 'Suoritettu',
  e243f36392a7f264d830f04f21aa8972: 'Maksetut laskut',
  b548572d4123e3a569d27e4676664dad: 'Osittainen maksu',
  '6e09ada7e33833d259370b0cc900f082': 'Osittaisen maksun summa',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Lyhynnyksien vähennykset',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Osittaiset maksut',
  '46bbc145b1f85c082950c768ade012f6': 'Osittaisen maksun summa',
  e66e6d9768e8f489c05951ee70ede350:
    'Jokin meni pieleen, kaikki hallintapaneelin toiminnot eivät välttämättä ole käytettävissä. Yritä uudelleen tai pyydä apua ottamalla yhteyttä tukeen.',
  cd969f183dc619b5716067cfda39c055: 'Konttorin tunnusnumero',
  '17939071126e9e41921d79b837f63297': 'Toimittajan nimi',
  '750d4460b260343039f9bc71b66a231c':
    'Toimittajan katsotaan olevan maksukyvytön',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Toimittajaa ei ole vielä asetettu ohjelmaan',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Tuki',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Ehdot hyväksynyt',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Käyttöehdot',
  '1294cf929ec55581954a79948592dda9':
    'Päivämäärä, jolloin häteisohjelman rahoitus otettiin käyttöön.',
  f51144e4468390a486d41b4db66fc243: 'Tiedostoa ei voitu ladata.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'Niiden päivien enimmäismäärä, joina ostajalta odotetaan maksua, kuten HSBC on hyväksynyt.',
  d438da584c455e727642a303917e690d:
    'Luoton jäljellä oleva määrä, joka on edelleen käytettävissä. Vastaa luottorajan ja käytetyn luoton välistä erotusta.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'Tällä hetkellä tätä ohjelmaa varten rahoitettavien laskujen summa. Tämä summa lasketaan luottorajan ja rahoitettavien laskujen nykyisen summan perusteella.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'Tätä ohjelmaa varten rahoitettavien laskujen summa. Tämä summa lasketaan luottorajan ja rahoitettavien laskujen nykyisen summan perusteella.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'Ennen laskun maksamaista pidätetty summa yhteensä.',
  '13f271ac486faca89c1944226ba0efff':
    'Kaikkien tuotujen laskujen yhteenlaskettu nimellisarvo.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Kokonaan maksettujen ja maksetuiksi merkittyjen laskujen koko nimellisarvo.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Rahoitettujen laskujen yhteenlaskettu nimellisarvo.',
  '565c255165bed2ff2640d0e42369c62c':
    'Laskujen kokonaisarvo ei ole rahoituskelpoinen.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Tätä tiliä ei ole aktiivisesti kirjattu Cashiin.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Tämä päivitetään tyypillisesti seuraavana arkipäivänä. Lähetä tukipyyntö, jos haluat tietää nykyiset saldot.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Tämä arvo osoittaa viimeisimmän lyhennyksen taseen, mukaan lukien mahdolliset korot.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Yhdistetty seuraavaan:',
  '603e4b3b3ac7b888a06548d14435bc25': 'Liikaa epävarmuuksia',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Yhteensä',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Peritty summa yhteensä',
  f786c8a1095bdf155841d510bb53142a:
    'Erääntyneistä veloista kertyneet korot yhteensä.',
  '4f25d1096076868bc590da9f8a26cf55': 'Limiitti yhteensä',
  '230bd56af0acab75194f58b46a763535':
    'Ostajan maksamista maksuista perittävä summa',
  '8f78461d739b4d6d46f961f352000cec': 'Sinulta peritty summa yhteensä.',
  b62dd565f60dc6172605d8db223510b9:
    'Ostajan maksusuorituksen, myyjän maksusuorituksen ja/tai takaisinperinnän kautta perityt kokonaissummat, mukaan lukien erääntyneiden velkojen korot.',
  '8dfcacc58acbafda8eca3d58c4d8a447': 'Myynnistäsi rahoitettu summa yhteensä.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Rahoittamatta jäävä summa yhteensä HSBC:n hyväksymän ennakkomaksun prosenttiosuuden mukaisesti.',
  d6c89215b3fd6857262efc2039d78dec:
    'Kokonaan perittyjen rahoitettujen laskujen summa yhteensä.',
  a069138e83a8b4cb7e81145689155826:
    'Erääntyneiden tai maksamatta olevien velkojen korkojen summa.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Erääntyneiden maksujen summa erääntyy. Lisämaksut kertyvät päivittäin.',
  bc37640f0d55a99b3f0b8bce05a2f0d1: 'Maksetut erääntyneet maksut yhteensä.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Erääntyneiden tai maksamatta olevien erääntyneiden maksujen summa.',
  d649fb9a0971deaa127067341f59be91:
    'Muista laskun vähennyksistä peritty summa.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Summa, joka on perittävä tai maksettava takaisin.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Perintäsumma yhteensä',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Laskujen nimellisarvo yhteensä ei ole rahoituskelpoinen.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Ostajalle (ostajille) lähetettyjen laskujen nimellisarvo yhteensä.',
  f0164c47e6c3d2497856505cb08e6e4f:
    'Ostajan maksusuorituksella, myyjän maksusuorituksella ja/tai takaisinperinnällä peritty rahoitettu summa.',
  d4867ee77e79ded226c07918b9393715:
    'Erääntyneistä veloista maksetut korot yhteensä.',
  de4c28160afe1ab48bcf83be49bbb196: 'Raja yhteensä',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Maksamatta oleva summa yhteensä',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Maksamatta oleva rahoitettu summa ja erääntyneiden velkojen mahdolliset korot, jotka sinun on maksettava takaisin. Tämä voisi johtua siitä, että yksi tai useampi rahoitettu lasku on erääntynyt, tai ostaja hylkää yhden tai useamman laskun niiden rahoituksen jälkeen.',
  fecdb72e34cebfadf037b45b596d90e5:
    'Tradeshiftin käteisohjelman usein kysyttyä',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Tapahtumatiedot',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Laskun csv-tiedostoa ei voitu ladata. Ota yhteyttä tukeen',
  c8415d81378085ba3933015b8f5fc768:
    'Sopimusta ei voitu hakea. Ota yhteyttä tukeen',
  '64580a198c861aa0226c91b28f8418aa': 'Ei saatavilla myytäväksi',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Tämän päivämäärän jälkeen maksamattomat laskut katsotaan erääntyneiksi ja niille kertyy viivästyskorkoa.',
  '9d632e72031eea3767881d6c3adcfe54': 'Käytetty luottoraja.',
  e871a6882109bd7ef07827882236c8db: 'Näytä sopimus',
  '2ac548c28faaa41283f9d6b7606a4844': 'Tyhjätty',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Tervetuloa Cashiin!',
  '78eb58937961a18929a623397b544a01':
    'Voit maksaa erääntyneen takaisinmaksun saldon tekemällä suoran maksusuorituksen takaisinmaksutilille (annettu edellä).',
  f347abf54f65274a614a9ba7515dfa98:
    'Voit seurata kaikkia ennakkomaksujasi yhdestä paikasta Cash-sovelluksella',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Cash-ohjelmasi on käytössä!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Käteisohjalmasi on käyttämätön',
  '6e6e06469dd953a124d718cd2307e32c':
    'Osallistumisesi käteisohjelmaan ei ole ollut käytössä {date} jälkeen. Ota yhteyttä <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'rahoitettu lasku, jonka ostaja on hylännyt ennen maksuajan pidennyksen päättymistä (kertyvä korko päivittäin hylkäämispäivän jälkeen)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'rahoitettu lasku, joka on maksamaton maksuajan pidennyksen päättyessä (sen jälkeen päivittäin kertyvä korko); ja/tai',
  '33e67e73d7f54aaee31e6040f873be79': 'sovellettu vähennyksiin',
  a9798a3e063111de4d4768c363bdc267: 'sovellettu laskuihin',
  a5be13d3bfec5fc92521a841009db3c7: 'tilinumero',
  '736c1e36ac779a73ff4fddd19d82008c': 'päivittäinen korkotaso',
  ab53acde68c117f8fbfff6a8901de083: 'päiväkohtainen erääntynyt korko',
  '4c584eabd4260d69914ac993cc4db5d2': 'päivää',
  '047dbee224da5fb4ecfc21268ffd3115': 'eräpäivä',
  dd051262964c80130a2e9023ff933d89: 'ennakkomaksu',
  bc55e8a10310a96eeda133ddf159303d: 'odotettu eräpäivä',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'aikaisille maksuille',
  '932d9bd46daba4d09f361717c0ec03ab': 'rahoitettu määrä',
  '5e82566144af4903b40303fc3d1c799e': 'rahoituspäivä',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'maksuajan pidennys',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'maksu eräpäivänä',
  '2db524546615d120af0adf844a6e1bd7': '/',
  '99c2f4783bb6821d84ad17a22f556813': 'rahoitetuista',
  c138be463a457afe89e5ecfefc6a3dc9: 'maksamattomat hyvitysmaksut',
  '37f59e13afd5455cf130060ed55f4976': 'reittinumero',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'sisältö',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'seuraavalle pankkitilille:',
  cf4edd5f38054a752994e3242a1f3807: 'kokonaishinta',
  '3c9f634310f5e3de42b79ee83d20923c': 'maksu yhteensä',
  '573b1b7930d8030d1669945f2c34992a': 'siirretty pankkiisi',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'vähennetään tulevista maksuista',
};
