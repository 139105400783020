export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(Szerkesztve pénznem vagy ünnepnapok miatt)',
  eba8e32b871086c08ac4b90ef5ede94b:
    '* A díjak és a kifizetés dátumok csak például szolgálnak, és nem tudjuk azokat garantálni',
  fa090ada546b5deb33cdead006822e26:
    '* A díjak és a kifizetés dátumok csak például szolgálnak, és nem tudjuk azokat garantálni',
  '52fce318164dc60017ca67121172aca9': 'Elfogadva',
  '615a093396ed9c6f52cd7ece7217608e': 'Fiók módosítása',
  '79dd431d35b370210a3dbeb1bb653d43': 'Fióknév',
  '8c66a974a95769447407c061a36d0824': 'Számlaszám',
  '6a810f9427275516cc7ac687ea182f27': 'Elhatárolt adósságkamatok',
  '21df2d8134802290caddc9a8070e9d94': 'Elhatárolt késedelmes díjak',
  '7377dcc6989501c8299b7ccf697b731e': 'Elhatárolt adósságkamatok',
  dab81136f1c6e799c5626826349a8dcf: 'Elhatárolt késedelmes díjak',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Összes',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Összes vevő',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Összes pénznem',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Összes számla',
  d7db3397d1ee87530b253bc69ebca041: 'Összeg',
  c7586d1e153c74713afd948b3048d03d:
    'Az Önnek kifizetett összeg a kamatok és a finanszírozott összegből esedékes visszafizetések levonása után.',
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Egyéb számlá(k)ra esedékes visszafizetésekkel szemben behajtott összeg.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Átutalandó összeg',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Az esetleges többletpénzeket átutaljuk Önnek.',
  de9800ff635c316938aece381c98b094:
    'A fennálló gyors fizetések és kamatok levonhatók a fizetendő vételárból vagy más egyéb beszedésekből. Ha nem tudunk ilyen módon beszedni, akkor felkérhetjük, hogy az összeget közvetlenül a Vevőnek fizesse be.',
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Jóváhagyva',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Kérdezzen',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Új finanszírozásra rendelkezésre álló hitelkeret.',
  '85a6c2f454268a7b3c4156c070abc517': 'Jóváhagyásra várakozás',
  '99fb0198659b8076326d69a42670433b':
    'Az egyenlegek frissítése általában a következő munkanapon történik.',
  '0448c27d5574d95fc844e50416fc13a3': 'Köteg',
  '84b730c6e665f3a83a96a8511b4f63db': 'Köteg részletei',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Vevő fióktelepe',
  '2be86945c7f60b651a0cf58d8e73dabf': 'Vevő által visszautasított számla',
  cdb5b8f6940144b32dc7f27b7700719a: 'Vevői átutalás',
  '2e5e060fbc0b1324e5e2059482c65be6': 'Vásárló által fizetettnek jelölve',
  e7094a08ea031332f2a7e7f94b214174: 'Vevői átutalás',
  b8b64034058f45fe990f8377c7990c6b: 'Vevői átutalási számla',
  b58bdf039e33f8abf293173c9e0c1ee6: 'Vevő:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'A vevők itt fizetnek a számlákért. Ön itt fizet a gyors fizetésekért',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    'Alapértelmezés szerint az esedékes törlesztőrészletet a következő finanszírozott számlákból vonják le.',
  cb1a025651df74878d8d5af1b99991df: 'Megszakítva',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Tudnivalók a készpénzprogramról',
  '6fd54168f061cd9f269dbcbb29415486': 'Díj összege',
  '252982cd07a2c1e75a9cadb781c4428f': 'Visszaigénylés',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Kattintson a másoláshoz.',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Bezárás',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Zárva',
  a150472168406890d84a6a5c3c8a894c: 'Összegyűjtve',
  '0743742edda54d26e47f9959ca4be4f0': 'Behajtott adósságkamatok',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Begyűjtött késedelmes díjak',
  '0b2194f7621b8a036308dce1647b982d': 'Összegyűjtött összeg',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Behajtott adósságkamatok',
  b1a3b2482cd668f631d724aac2dfc685: 'Begyűjtött késedelmes díjak',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Gy?jtemény',
  ba18fa9a0a1da454462f71c6e36139ff: 'Behajtás egyenlege',
  '1103815eae13527faa8d51c83c20d17e': 'Gyűjtemény adatai',
  '898e15d1fa445c9c37ca795f79029f6d': 'Vállalat hivatalos neve',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Folytatás',
  c05df0411bb18c405aaa68153432bf10:
    'A hitelkeret és a számlahatárérték frissítési dátuma',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Hitelkeret elérhető',
  d23014cf5a5e7f8c6440b96692831aa8: 'Jovairas',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Felhasznált hitel',
  '207df9f33356e378a1ff2ef7b62becc7': 'Elérhető hitel',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Hitelkeret',
  '208d5141343637870387b27db87ccf05': 'Túllépték a hitelkeretet.',
  '0e27340c6eb226df1076c4f8930d2289': 'Felhasznált hitel',
  e15a66f111bc879c77f24943a94d19e7: 'Pénznem nem támogatott.',
  b1e5986f972b7599559dea22cfeae8fb: 'Pénznem:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Napi árkedvezmény',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Napi árkedvezmény mértéke',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Napi késedelmi kamatláb',
  '9e268b45f182ad1851f03539be6ab29c': 'Napi árkedvezmény mértéke',
  c2191b6160c46ed7a4b05cc0279158f3: 'Napi késedelmi kamatláb',
  '106202929cf48a3de3f20b3255365915': 'Dátum',
  '2eca727eade526acc715c2e5605ebc7e':
    'Az a dátum, amikor a finanszírozott összeg teljes egészében kiegyenlítésre került.',
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Az a dátum, amikor a 30 napos türelmi idő lejár.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Az a dátum, amikor a számlát a vevő elutasította.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Késedelmes napok',
  '1e1e68c46e0d719364bd445b1d605144': 'Késedelmes napok',
  bd50c01a22cae65be4d588abcae65a90: 'Levonás',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Levonások',
  d706035ec5f053ca33b234ddec228ab2: 'Leírás',
  a39bf2f30994908fe95498524b423d43: 'Részletek',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Hígítás összege',
  d07e29091188f3b0871dc7712eee7255: 'Hígítás típusa',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Közvetlen eladói átutalás',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Közvetlen eladói átutalás',
  afc1c7dfc9315e856f8da52e2450182b: 'Kedvezmény',
  '26e7eb298420701d5a03c53fe096cc0b': 'Vitatott',
  '43cca9c1b76ebf869c7bf22fff271689': 'Dokumentum státusz szűrői',
  ce73b429141cda25571c88d9b0c78224: 'Dokumentum állapota',
  af13cc2ad0a7337c45420f75b7d5a668: 'Letöltés',
  '8475be8bb8249000118af931ae75c373': 'Jelentés letöltése',
  '90567e36a540e51806d7790eb195b1d5': 'Összes számla letöltése',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Vázlat',
  '72015a772310560b755bd03132a543b2': 'Esedékesség',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Lejárati időpont',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Hamarosan esedékes',
  '6def6918bd02ae5117332f4262cecb52': 'Jogosult',
  '6426c30d14da5d40d03fc7e85d0c9677': 'Nyilvántartásba vételáll apota',
  cb339c5c02cfb087fc8d393f917379df: 'Irányítópult letöltési hibája',
  e9994b26424cb4bce5059d05e094df8d: 'Becsült díj',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Kizárt ország',
  b5cb31ac9c7d1871e9075efc1f271536: 'DÍJ',
  fd9d568c402dd04e0715e877d76f7da5: 'A számla névértéke.',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Sikertelen',
  '817f1fe84c2d87894bc374681814abbf': 'Betöltés sikertelen {title} -',
  '7932079ce6ea928bfa77efd91578312d': 'Díjak',
  '85624d3e9c7eb6862afe19de8d77dc45': 'Pénznem szerinti szűrő végösszeg.',
  '110df72171aeef47da3fd84ea2a1a10f':
    'Az előrejelzett fizetési határidő túl hosszú',
  e5af505b37031920b08edb84df19b5ec:
    'Teljes finanszírozott összeg a begyűjtés napján',
  a4e77d51ea7969a9e1fd98d59f99c158:
    'Teljes finanszírozott összeg a begyűjtés napján',
  '6dd341953c8c405c70878a457622cf55': 'Finanszírozott',
  '42e20c6bb1dc82fbdbd889f769850320': 'Finanszírozott begyűjtött összeg',
  cab921680af6ff282ce37398b0b231d4: 'Finanszírozott összeg',
  fc0347ceea208298049efd79021475a2: 'Finanszírozott begyűjtött összeg',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Finanszírozás időpontja',
  '55d900eb37f97b684c0bd5821f161216': 'Finanszírozott számlák',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Finanszírozás',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Finanszírozási kivétel',
  '5ca89ce217f3bb07293db00a42c10200':
    'Finanszírozás nem támogathatóságának oka(i)',
  eac067c62df8f46d6bbdbfc4f9e69803: 'Finanszírozás áttekintése',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Sikeres finanszírozási kérelem',
  d5ec85821723434aba05986044ebdcdd: 'Finanszírozási állapot',
  '3447158e3ace443dbe27556e07c88639': 'Finanszírozási állapot szűrők',
  '022848540bf0e01453be6cf79843aa67': 'Tenor finanszírozása',
  b4a59826d5bfc276fa88bd00abac615a: 'Finanszírozási adatok',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Tenor finanszírozása',
  e74f45b44aadcdd084b5cbdd3560f515: 'Türelmi időszak befejező dátuma',
  '5434cf75f987ef76dbdc544fa1e32096': 'Hogyan fizethetek',
  cb9f9412bd12122bb088124afccf738b:
    'Rövid lejáratú kifizetések visszafizetésének módja',
  '9ad57f0b683fecb097c09bedebf68a45':
    'Hogyan oldható meg az esedékes törlesztőrészlet:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'SZLA',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Ha úgy gondolja, hogy ez tévedés, kérjük, lépjen kapcsolatba a következővel: <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Fontos információk',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'Folyamatban',
  '313b15a4fdfa2368015e2eb67416af2a': 'Inaktív program',
  '7b6a003ceca7fe7fec85daae919e800b': 'Nem megfelelõ',
  '6cf0784c1ac918c22cd402e0cb558749': 'Nem támogatható ok',
  '65cb9c651d2d9fb8941050a08add03c3': 'Elõrefizetésre nem alkalmas',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Nem megfelelő számlák',
  c555faa7b0b745638bb3ea785ee10137:
    'A megjelenített információkat utoljára frissítették',
  b7311d63c44566a8562d92720cffed2e:
    'Nem megfelelő gyűjtemény a számla mutatószámhoz',
  fb07cb1753537f6969513f2d5fdb7993: 'Kamatösszeg',
  '1938e208f65e66474dc4148c3fa7da08': 'Levont kamat',
  '42094e40339ac5c0957a980062c47659': 'Gyors fizetés után felhalmozódott kamat',
  d9580a19710f07e8c72c215b1270ca57:
    'A HSBC által a finanszírozott összegre felszámított kamat.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Levont kamat',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Késedelmes számla után felmerült kamatok',
  '47a565198d287d0864339c077819a361':
    'A kamatok a késedelmes tartozásra mindaddig felhalmozódnak, amíg vagy le nem vonják az Ön részére történő következő fizetésből, vagy amíg közvetlen átutalást nem kapunk.',
  '3c4be400f728da4526de626653d56ccc': 'Számla',
  '70546e9227f592c24d3c491756cb901d': 'Számla #',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Számla összege',
  '6b9515d101794c31e38ae60d092fdc03': 'Számla kiállítási dátuma',
  '2548c6a52ce9e1bffef7aad14530f490': 'Finanszírozott számlák',
  '42bb3ba3a1cd691726744d3a1a8da732': 'Számlák finanszírozásának áttekintése',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Számla határértéke',
  '734ed127b3d7226c75e8553c0711367a': 'Számlakorlát használatban',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Számlakorlát Összesen',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Számla Száma',
  da2db17c9b22f995d6673a754d998fc8: 'Lejárt számla',
  d729bd1bdced62265cd4ecd7131ef94e: 'Visszautasított számlák',
  e14208d4378a3378be8b27eb15984fc6: 'Kifizetett számlák',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Számla állapota',
  '54d8df173250a197fab1644a0d0a772a': 'Számla összege',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    'A számla jelenleg nem finanszírozható. További információkért lásd a Nem támogatható finanszírozás okai című részt..',
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'A számla pénzneme nem egyezik az országgal',
  d0da9e977c8b309f93ee378de34fb251: 'Számla kelte',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'A számla legkorábbi elfogadható dátum előtti dátuma',
  ad0ab15468b719694f3193d0649ad9eb:
    'A számla dátuma későbbi, mint a hitelkeret törlési dátuma.',
  fc9e80713aebb3d820d888f0755fedde: 'A számlának nincs előfizetett vevője',
  e17cbec04e7786361eff9da629f09bb7:
    'A számla jövőbeli kibocsátási dátummal rendelkezik.',
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'A számlát törölték.',
  '89f842841b4ae213259b94a5411453f2': 'A számlát felhígították.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    'A számlát a Készpénz program keretében finanszírozták.',
  ab5303505d045929abb1256607ca0d4d:
    'A számla teljes egészében kifizetésre került. További kifizetésekre nincs szükség.',
  '3e159746cbdfda07b2f45750941e26f6': 'Számlát kértek a finanszírozáshoz.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'A számla nem támogatható dokumentum státuszú.',
  '8204c52c01b05791dc3e4c59ac352978':
    'Ia számla nem került teljes egészében kiegyenlítésre a múltban, és a türelmi idő lejárt',
  d87ca92704bcfb34fa4ac08a4d24147a: 'A számla jelenleg nem támogatható.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'Hiányzik a számla záró dátuma vagy végső záró dátuma',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'A számla túl közel van az előre jelzett fizetési határidőhöz.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'A számla szokatlan',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'A számla már kifizetettnek van jelölve.',
  '4ac6a84899b9b6422f4e92b091a5448a': 'Lehet, hogy a számla duplikátum.',
  b1f771da19efea2f0fa6a542823231b9: 'Csak részben megfelelő számla',
  '4217948ca113ee4158d5dd4c07d71624':
    'Rövid fizetési határidővel kiegyenlített számla',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'A számla lejárati ideje nem az elfogadható tartományon belül van',
  '7c3fa246e8cca51273ddf5062ff2d593': 'A számla lejárati ideje túl hosszú',
  '90d9ac20dda384e9c07554d29b143bef': 'A számlaérték meghaladja a határértéket',
  '4b36f0be327fdca8d54126cd57095a13': 'Számla/RA-szám',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Számlák',
  '8089283aad9294aadc2bde165ccc78c1': 'Kiállítás dátuma',
  '3a4123f9c604369ab18781f7492f9293': 'Kibocsátás kelte',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Utolsó kifizetési dátum',
  a94bc4d4df1d56c16e25493e22684412: 'Elérhető hitelkeret',
  '92f97c843d499a1559828ba0a9134e24': 'Használatban lévő hitelkeret',
  b5dd8e130d88698834ee00464f01cea4: 'Elérhető hitelkeret',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Használatban lévő hitelkeret',
  '4a739fa0b571805cc56fc4080ea82fcb': 'Tölt?dik...',
  '24cc5627dea65e22f9279be9522a06e2': 'Kölcsön összege',
  bd24bcedd066a27749016a07cb729a32: 'Megjelölve elintézettként',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Lejárat napja',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': 'A tagság kezdete',
  d26429adfb846303cbd889879b23e1ba:
    'A türelmi idő előtt és/vagy után a vevői kifizetésekből, az eladói átutalásokból és/vagy levonásokból beszedett pénzösszegek.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Nettó kifizetett összeg',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Nettó kifizetett összeg',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Új összeg',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Következő munkanap',
  '325c48f099844279c0cf48584de42e64': 'Jelenleg nem használt',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'egyik sem',
  '7457575d324c4b8dada2e13c0f0fe5d5':
    'Nem aktívan nyilvántartásba vett készpénben',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Nincs elegendő számlaelőzmény',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Nincs elegendő számla az előző hónapokban',
  c9e2ce8da559465a8596317c35e8f3a4: 'Nem elég kifizetett számla',
  e6f890deafa579eb0c24603c3101c1c7:
    'Vegye figyelembe, hogy az egyenlegek frissítése általában a következő munkanapon történik. Hozzon létre támogatási jegyet, ha tudni szeretné az aktuális helyzetet.',
  '83070a059f10db1515ac922bf3433158':
    'A türelmi idő lejárta utáni napok száma.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Egy jelentés letölthető',
  '2778717f6acbeb1edaac6a2656b478f3': 'Megnyitás',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Egyéb díjak',
  fad92e97e692758c0d0a9b5730663894: 'Kintlévőségek kamatai',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Kintlévő finanszírozott összeg',
  '9eab1baddf2537312408088fc54f21d5': 'Kintlévő késedelmes díjak',
  bfba12a2c234532c0af24a4ad6831ab6: 'Kintlévőségek teljes összege',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Kintlévőségek kamatai',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Kintlévő finanszírozott összeg',
  '2f9a3de6940f818fe0376e4aece59512': 'Kintlévő késedelmes díjak',
  '6f79eea38bff17917740a69116270074': 'Esedékes',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Késedelmes egyenlegek',
  '848ac8c39b11a751d697a383303e6109': 'Késedelmes tartozás',
  '8d1e868665fc24a46f0cf55187f72218':
    'A késedelmes tartozást az RBI előírásainak megfelelően jelentik.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Áttekintés',
  '5b885084a5067d7fb4372cef38d8a82d': 'Fizetett',
  '830b95d305cdc61a66cdf75ebff1d737': 'Vevő által fizetve',
  '6444796646c95dbc2cd1cb646e41293d':
    'A számla névértékének az a része, amelyet a HSBC által jóváhagyott előlegfizetési százaléknak megfelelően finanszíroztak.',
  '92cacee97a99fe8c26a5c1999ae51293': 'Résztvevő vevő adatai',
  a39314e5897b8baaa51ca296610ec30c: 'Partner feltételei',
  a6acd5f03fda9c8dc607e59d8be08320: 'Fizetendő jóváhagyási késedeleme',
  e6249829dadf637c4092e4a900df529e: 'Vevő által vitatott fizetendő összeg',
  '07ed468d28d8e8437d8ec64036b96c03': 'Fizetés visszautasítva',
  '042b8dfb88f7045ca9145bbcccf79200': 'Jövőbeni kifizetés',
  f8768f5b4055c45a4d30591bea9cc7b1: 'Fizetendő túl régi',
  '8755bbc212b541efb6576f39f3f0f51f': 'Előirányzott kifizetés',
  '8744ee10540166dfc39db2a6859a9ce1': 'Fizetendő összeg',
  '56133aa371eb0132334e4867479be942': 'Fizetés adatai',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Fizetési határidő lejárt',
  '2dd7164df606f9b42beefaba031b06f7': 'Beérkező fizetés',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Az eladó által megerősített fizetés',
  '661711004f06b0d9601db365679be130': 'Kifizetési összeg',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Kifizetési dátum',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Büntetőkamat',
  '0fd036622716f275c3e14c5a14faa65a': 'Folyamatban',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Függőben lévő hígítás',
  '8cf3479829ba47a8a4123895d0088eeb': 'Függőben lévő hígítások',
  '270b56fd05f72407ca0607b6162d0b10': 'Várakozó kapcsolat',
  '4a78218416ca45d97b5633b37ae3f638':
    'Az a százalékos arány, amely alapján a napi díjakat a teljes finanszírozási futamidőre kiszámítják..',
  b586a5468c7070bf1717593ea84fa607:
    'Az a százalékos arány, amelyet naponta felszámítanak Önnek a kintlévő, finanszírozott összegre a késedelmes vagy finanszírozott és elutasított számlák esetében.',
  f8c94740abd5c69fb813bd0f4827de0e:
    'Hozzon létre támogatási jegyet, ha tudni szeretné az aktuális egyenlegeket.',
  '34645fa620cae4187f27d9f96c172f26':
    'Kérjük, utalványozza a következő összeget:',
  '4c54e70d0c88c94d16c03e32519d6be7':
    'Utalja át az alábbi összeget az alábbi bankszámlára:',
  '01734a7316427fdcdcd4f937cda3802b': 'Átvállalt összeg',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Előző összeg',
  '500fc4731dab800a878b33906d9332fb': 'Programgyűjtési fiók',
  '7327113634d1f5f4f68632dceebfbc6f': 'A program áttekintése',
  d95ebfb51a485293a134b1038d332a10: 'Program aktiválási dátuma',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'A program adatai nem elérhetõk az irányítópult számára',
  '98feb2c86a81b8caeeaa30966ba34636': 'Program információ',
  d202f03687187e744a1f4b11a1e5f8e4:
    'A megvásárolt számlafizetések, amelyeket a várható határidőig nem gyűjtenek be teljes egészében, gyors fizetést generálnak. Gyors fizetésekre a napi diszkontráta 1,5-szeresével számítják a kamatot, és mindkettő törlesztése az Ön felelőssége.',
  '521689b600b8da5b0a111b89dea72126': 'Magyarázat',
  fe7bd622a1efcec03eb86dabb9395936: 'Átvett',
  e84203a8833e4d0d92faf89f4107bf11: 'Visszafizetés visszkeresete',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Visszatérítés összege',
  b0be744ae1f6312f16990bbd92a87aea: 'Visszatérítés kibocsátási dátuma',
  '39591f42bcdde6d7ce9409e167aa7e80': 'Visszatérítés lejárati ideje',
  '654d6dcc141079bc84500fd14ca5087d': 'Visszatérítés összege',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Visszatérítési adatok',
  '5b2508eaa0c48b46fe2034cdf1916867': 'Visszatérítés kibocsátási dátuma',
  c0a6a91e7ca80af9de47097b06dc7618: 'Visszatérítés lejárati ideje',
  '4a2875439ab289a068abb9ea3b567bcb': 'Visszautasítva',
  '592b34cdfa2e51826b32a0783e0edce3': 'Vevő által visszautasítva',
  '807d35f105b65ab2795c4f8e51795f3c': 'Eladó által visszautasítva',
  aea4dd208e871d7ca0051b0ec38c3068: 'Visszautasítási dátum',
  d64ed4934fbf68a8f716467b0adf89ba: 'Visszautasítási dátum',
  '5a77de3b287a92494be077c197f37581':
    'A fennmaradó finanszírozott összeg, amelyet még be kell szedni vagy vissza kell fizetni.',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Törlesztési összeg a türelmi idő végének időpontjában. Ez az összeg nem változik az ezen időpont után teljesített törlesztések figyelembevételével, és nem tartalmazza a késedelmes tartozásokat sem.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Esedékes visszafizetés',
  dc20cbfb76d220c108241a451bc3faf4: 'Információ a visszafizetési utasításokról',
  c8c021e2a7c9064a0b97db4aa96f6623:
    'Az esedékes visszafizetések a teljes kintlévőség, amelyet be kell hajtani, ami abból adódik, hogy a Vevő által kifizetett összeg alacsonyabb, mint a számla névértéke; és/vagy a Vevő a számlát a finanszírozást követően visszautasítja.',
  '45b60e22719c12291932bf09db0df5cd':
    'Az esedékes visszafizetések a teljes kintlévőség, amelyet vissza kell fizetni, és amely vagy a következőkből adódik:',
  ba093b1421452b3afdebbebedaeae552: 'Finanszírozási kérelem',
  '34f8f9968ec0d7f91e558e906a7de982': 'Kérve',
  dc1650cfbadb05f8fb6357692e48ee71: 'Finanszírozás kérelem',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Visszatartott',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Visszatartott összeg',
  a476316a89541d1aa71d5b5366874e13: 'Visszatartott összeg',
  '328f6585a0e78a96deefc0661474248f': 'Újra',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Eladások',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Keresés számlaszám szerint',
  '3215999493db188b35242dc4f4fd8fb5':
    'A visszafizetéssel kapcsolatos további információkért lásd a Programinformációkat.',
  '69f43575c3a51e94605eb75b75f7b563': 'Kiválasztott',
  '56a1c7c4a02e9985745969563a80799f': 'Eladó visszafizetési számlája',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Küldés folyamatban',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Elküldve',
  '95d54926719f1071b2fa640f1ba23d3e': 'Elintézve',
  e243f36392a7f264d830f04f21aa8972: 'Kifizetett számlák',
  b548572d4123e3a569d27e4676664dad: 'Gyors fizetés',
  '6e09ada7e33833d259370b0cc900f082': 'Rövid kifizetés összege',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Gyorsfizetési levonások',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Gyors fizetések',
  '46bbc145b1f85c082950c768ade012f6': 'Rövid kifizetés összege',
  e66e6d9768e8f489c05951ee70ede350:
    'Hiba történt. Előfordulhat, hogy az irányítópult összes funkciója nem érhető el. Kérjük, próbálja újra vagy vegye fel a kapcsolatot az ügyféltámogatással.',
  cd969f183dc619b5716067cfda39c055: 'Fajta kód',
  '17939071126e9e41921d79b837f63297': 'Szállítmányozó neve',
  '750d4460b260343039f9bc71b66a231c': 'A szállító fizetésképtelennek minősült',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'A szállító még nincs beállítva a programban',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Segítség',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Feltételeket elfogadta',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Szolgáltatási feltételek',
  '1294cf929ec55581954a79948592dda9':
    'A készpénzprogram finanszírozásának aktiválásának dátuma.',
  f51144e4468390a486d41b4db66fc243: 'A fájl nem tölthetõ le.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    'A HSBC által jóváhagyott maximális napok száma, amikor a vásárlótól fizetés várható.',
  d438da584c455e727642a303917e690d:
    'A felhasználható hitel fennmaradó összege. Ez a Hitelkeret és a Felhasznált hitel közötti különbség.',
  '78c4e85a6398bb0b164ed2479f1faeca':
    'A program keretében jelenleg finanszírozott számlák teljes összege. Ez az összeg a hitelkeret és a finanszírozott számlák aktuális összege alapján kerül kiszámításra.',
  '43fc53cbc779fd0ef3f42d350a2274b5':
    'A program keretében finanszírozható számlák teljes összege. Ez az összeg a hitelkeret és a finanszírozott számlák aktuális összege alapján kerül kiszámításra.',
  '9c9fa02e1129e31e3423a0dfca81732d':
    'A számla kiegyenlítése előtt visszatartott teljes összeg.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Azoknak a számláknak a teljes névértéke, amelyek kiegyenlített státuszba kerültek, mert teljes mértékben beszedésre kerültek.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'A finanszírozott számlák teljes névértéke.',
  '565c255165bed2ff2640d0e42369c62c':
    'A finanszírozásra nem jogosult számlák összértéke.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Ez a számla nincs aktívan regisztrálva a Cash rendszerben.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    'Ez általában a következő munkanapon frissül. Kérjük, hozzon létre hibajegyet, ha az aktuális egyenlegekre van szüksége.',
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Ez az érték a legutóbbi gyors fizetési egyenleget tükrözi, beleértve a kamatokat is.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Együtt a következővel:',
  '603e4b3b3ac7b888a06548d14435bc25': 'Túl sok hígítás',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Összeg',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Teljes összegyűjtött összeg',
  f786c8a1095bdf155841d510bb53142a: 'Késedelmes tartozások összes kamata.',
  '4f25d1096076868bc590da9f8a26cf55': 'Teljes határérték',
  '230bd56af0acab75194f58b46a763535':
    'A vevői fizetés(ek)ből beszedett teljes összeg.',
  '8f78461d739b4d6d46f961f352000cec': 'Az Öntől beszedett teljes összeg.',
  b62dd565f60dc6172605d8db223510b9:
    'A vevői átutalással, eladói átutalással és/vagy visszaköveteléssel beszedett teljes összeg, beleértve a késedelmes tartozások kamatait is.',
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Az Ön eladásai ellenében finanszírozott teljes összeg.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'A nem finanszírozott teljes összeg, a HSBC által jóváhagyott előlegfizetési százaléknak megfelelően.',
  d6c89215b3fd6857262efc2039d78dec:
    'A teljes egészében beszedett, finanszírozott számlák teljes összege.',
  a069138e83a8b4cb7e81145689155826:
    'A késedelmes tartozások után még fizetendő vagy fennálló kamatok teljes összege.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Az esedékes késedelmes díjak teljes összege. A büntető díjak naponta keletkeznek.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'A kifizetett késedelmes díjak teljes összege.',
  bec87347063c8e0b9fac78e381fa0bff:
    'A hátralékos díjak teljes összege, amelyet még ki kell fizetni, vagy amely még nem került kiegyenlítésre.',
  d649fb9a0971deaa127067341f59be91:
    'Egyéb számlákból történő levonásokból behajtott teljes összeg.',
  '28fcda8475061ec7eb4894565e49fc87':
    'A még beszedendő vagy visszafizetendő teljes összeg.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Teljes összegyűjtött összeg',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'A finanszírozásra nem jogosult számlák teljes névértéke.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'A Vevő(k)nek küldött számlák teljes névértéke.',
  f0164c47e6c3d2497856505cb08e6e4f:
    'A vevői átutalással, eladói átutalással és/vagy visszaköveteléssel beszedett teljes finanszírozott összeg.',
  d4867ee77e79ded226c07918b9393715:
    'Késedelmes tartozás után fizetett kamatok összesen.',
  de4c28160afe1ab48bcf83be49bbb196: 'Teljes határérték',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Kintlévőségek teljes összege',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'A teljes fennálló finanszírozott összeg és az Ön által visszafizetendő késedelmes tartozás kamatai. Ennek oka lehet, hogy egy vagy több finanszírozott számla késedelmes, vagy a vevője egy vagy több számláját elutasította a finanszírozás után.',
  fecdb72e34cebfadf037b45b596d90e5: 'Tradeshift készpénzprogram GYIK',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Tranzakció részletei',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'A számla nem tölthetõ le CSV-formátumban Kérjük, forduljon az ügyfélszolgálathoz.',
  c8415d81378085ba3933015b8f5fc768:
    'Nem olvasható be a megállapodás. Kérjük, forduljon az ügyfélszolgálathoz.',
  '64580a198c861aa0226c91b28f8418aa': 'Nem adható el',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Az ezen időpont után ki nem fizetett számlák „késedelmesnek” minősülnek, és ezután naponta kamatoznak.',
  '9d632e72031eea3767881d6c3adcfe54': 'Használt hitelkeret.',
  e871a6882109bd7ef07827882236c8db: 'Megállapodás megtekintése',
  '2ac548c28faaa41283f9d6b7606a4844': 'Kiürítve',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Üdvözöli a Cash!',
  '78eb58937961a18929a623397b544a01':
    'Az esedékes törlesztőrészletet a (fent felsorolt) törlesztési számlára történő közvetlen átutalással egyenlítheti ki.',
  f347abf54f65274a614a9ba7515dfa98:
    'A Cash alkalmazással egy helyen követheti nyomon az összes korai befizetését',
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Az Ön Cash programja aktív.',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Az Ön készpénzprogramja inaktív.',
  '6e6e06469dd953a124d718cd2307e32c':
    'A készpénzprogramban való részvétele {dátum} óta nem aktív. Kérjük, vegye fel a kapcsolatot a következővel: <a href=',
  '626afbd7141286d15df45bccdb695a66':
    'ha a vevő a türelmi idő lejárta előtt visszautasítja a finanszírozott számlát (az elutasítás napjától kezdve naponta kamatozik)',
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'a türelmi idő lejártakor a finanszírozott számla kifizetetlen (és ezután naponta kamatozik); és/vagy,',
  '33e67e73d7f54aaee31e6040f873be79': 'levonásokra alkalmazva',
  a9798a3e063111de4d4768c363bdc267: 'számlákra alkalmazva',
  a5be13d3bfec5fc92521a841009db3c7: 'bankszámlaszám',
  '736c1e36ac779a73ff4fddd19d82008c': 'napi kamatláb',
  ab53acde68c117f8fbfff6a8901de083: 'napi késedelmi kamatláb',
  '4c584eabd4260d69914ac993cc4db5d2': 'nappal',
  '047dbee224da5fb4ecfc21268ffd3115': 'lejárati időpont',
  dd051262964c80130a2e9023ff933d89: 'előrefizetés',
  bc55e8a10310a96eeda133ddf159303d: 'várható lejárati dátum',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'elõrefizetéshez',
  '932d9bd46daba4d09f361717c0ec03ab': 'finanszírozott összeg',
  '5e82566144af4903b40303fc3d1c799e': 'finanszírozás időpontja',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'türelmi időszak',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'esedékes kifizetés',
  '2db524546615d120af0adf844a6e1bd7': '/',
  '99c2f4783bb6821d84ad17a22f556813': 'finanszírozott',
  c138be463a457afe89e5ecfefc6a3dc9: 'esedékes jóváírási értesítő',
  '37f59e13afd5455cf130060ed55f4976': 'banki azonosítószámo',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'lejárati idő',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'a következő bankszámlára:',
  cf4edd5f38054a752994e3242a1f3807: 'teljes összeg',
  '3c9f634310f5e3de42b79ee83d20923c': 'összes díj',
  '573b1b7930d8030d1669945f2c34992a': 'átutalva az Ön bankjának',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'a beérkező kifizetésből kerül levonásra',
};
