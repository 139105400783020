export default {
  b8f66a73dd67296edf7d6126839b8b00:
    '(rettificato causa valuta o giorno festivo)',
  eba8e32b871086c08ac4b90ef5ede94b:
    'Tasse e date di pagamento sono indicative e non possono essere garantite.',
  fa090ada546b5deb33cdead006822e26:
    '*Tasse e date di pagamento sono indicative e non possono essere garantite.',
  '52fce318164dc60017ca67121172aca9': 'Accettato',
  '615a093396ed9c6f52cd7ece7217608e': 'Adeguamento del conto',
  '79dd431d35b370210a3dbeb1bb653d43': 'Codice utente',
  '8c66a974a95769447407c061a36d0824': 'Numero conto',
  '6a810f9427275516cc7ac687ea182f27': 'Interessi maturati sul debito',
  '21df2d8134802290caddc9a8070e9d94': 'Addebiti maturati sugli arretrati',
  '7377dcc6989501c8299b7ccf697b731e': 'Interessi maturati sul debito',
  dab81136f1c6e799c5626826349a8dcf: 'Addebiti maturati sugli arretrati',
  f752dafdd1cbcfd9e276ee02daed1c39: 'Tutto',
  '6a21e27ab1553bb3bf93e0a73e645087': 'Tutti gli acquirenti',
  '59a79bbd7cb65771c64f6c687b4c8103': 'Tutte le valute',
  '5cd2457bf9ea161b1d3a628bef3a1cb9': 'Tutte le fatture',
  d7db3397d1ee87530b253bc69ebca041: 'Importo',
  c7586d1e153c74713afd948b3048d03d:
    "Importo che ti viene versato dopo la deduzione degli interessi e degli eventuali rimborsi dovuti dall'importo finanziato.",
  c3a614afd7dbee1c3e19828e0999b8c8:
    'Importo recuperato a fronte di rimborsi dovuti su altre fatture.',
  '57e646b300b4b551d662b0cd536a6ad5': 'Importo da versare',
  d35a870d0219f3f9cbde80ed0eb11aa1:
    'Eventuali fondi in eccesso ti saranno trasferiti.',
  de9800ff635c316938aece381c98b094:
    "Tutti i pagamenti parziali ed interessi non pagati possono essere sottratti dal prezzo d'acquisto pagabile, o altre riscossioni. Se non fossimo in grado di riscuotere il dovuto tramite queste modalità, potremmo chiederti di pagare l'importo direttamente all'acquirente.",
  '3aeaa80d4eaafcaaf03a7a4d1c47bc2c': 'Approvato',
  '40cba7837184cb0c15ca4a7cc7b557eb': 'Fai una domanda',
  '2cab8574e420020ef12b4729f81e5d6c':
    'Limite di credito disponibile per nuovi finanziamenti.',
  '85a6c2f454268a7b3c4156c070abc517': 'In attesa di approvazione',
  '99fb0198659b8076326d69a42670433b':
    'I saldi sono generalmente aggiornati il giorno lavorativo successivo.',
  '0448c27d5574d95fc844e50416fc13a3': 'Gruppo',
  '84b730c6e665f3a83a96a8511b4f63db': 'Dettagli gruppo',
  e03b84fcbe917d6adfa8bf31db40f9ae: 'Filiale acquirente',
  '2be86945c7f60b651a0cf58d8e73dabf': "Fattura rifiutata dall'acquirente",
  cdb5b8f6940144b32dc7f27b7700719a: 'Avviso di pagamento acquirente',
  '2e5e060fbc0b1324e5e2059482c65be6':
    'Acquirente ha contrassegnato come pagato',
  e7094a08ea031332f2a7e7f94b214174: 'Avviso di pagamento acquirente',
  b8b64034058f45fe990f8377c7990c6b: "Conto di regolamento dell'acquirente",
  b58bdf039e33f8abf293173c9e0c1ee6: 'Acquirente:',
  '9c2c1e1e0ef092c2668b7ddf551d517c':
    'Gli acquirenti pagano qui per le fatture. Tu paghi qui per i pagamenti parziali',
  '7cb0b1efd5ad2a5759542ea7b414faba':
    "Per impostazione predefinita, l'importo del rimborso dovuto viene dedotto dalla o dalle fatture successive finanziate.",
  '0d1e94883caf8b30026ade5dd5c242ea': 'NC',
  cb1a025651df74878d8d5af1b99991df: 'Cancellato',
  '5bdcb598d6b9a3ccc8b04cf8b0fec15c': 'Informazioni sul programma contanti',
  '6fd54168f061cd9f269dbcbb29415486': 'Importo',
  '252982cd07a2c1e75a9cadb781c4428f': 'Restituzione',
  a78a36ca61f82c4cde3e21bb4eb1bc20: 'Clicca per copiare',
  ffa075e58de62f4c4a7af41bb3645aaf: 'Chiudi',
  f7a61d011f9f4aa24be33b4ddb96eaa0: 'Chiuso',
  a150472168406890d84a6a5c3c8a894c: 'Riscosso',
  '0743742edda54d26e47f9959ca4be4f0': 'Interessi di debito riscossi',
  e706d0d09fc62c7ec9fd51f4963a1af5: 'Addebiti sugli arretrati riscossi',
  '0b2194f7621b8a036308dce1647b982d': 'Importo riscosso',
  '0ccb7eb111a7a0b5f47f72c526b7352d': 'Interessi di debito riscossi',
  b1a3b2482cd668f631d724aac2dfc685: 'Addebiti sugli arretrati riscossi',
  fbdc4c51ab56c2706190a13fa4d02f16: 'Riscossione',
  ba18fa9a0a1da454462f71c6e36139ff: 'Saldi di riscossione',
  '1103815eae13527faa8d51c83c20d17e': 'Dettagli della collezione',
  '898e15d1fa445c9c37ca795f79029f6d': 'Ragione sociale',
  c16ee5d7a1e74972b7b98e206f1f5231: 'Continua',
  c05df0411bb18c405aaa68153432bf10:
    'Limiti di credito e di fattura aggiornati il',
  '1ca7e972f6aca1aa19fdf76ee58a56b2': 'Credito disponibile',
  '4f023c0edbea4a6dbbda7d4a2533e5b1': 'Limite di credito',
  d23014cf5a5e7f8c6440b96692831aa8: 'Nota di credito',
  bb1f8a35aeec1e9eadef6c63117a19d9: 'Credito utilizzato',
  '207df9f33356e378a1ff2ef7b62becc7': 'Credito disponibile',
  '62337ac81597ce5c99fa74c6f57cbbf5': 'Limite di credito',
  '208d5141343637870387b27db87ccf05': 'Il limite di credito è stato superato',
  '0e27340c6eb226df1076c4f8930d2289': 'Credito utilizzato',
  e15a66f111bc879c77f24943a94d19e7: 'La valuta non è supportata',
  b1e5986f972b7599559dea22cfeae8fb: 'Valuta:',
  '90322bb69cffbb5d303e93c3a98cc595': 'Sconto giornaliero',
  '6d8cbcbdafc9fbd774aa01f9f20b9112': 'Tasso di sconto giornaliero',
  '05042f185370ad98dcaa943ec8b2fbd1': 'Tasso di mora giornaliero',
  '9e268b45f182ad1851f03539be6ab29c': 'Tasso di sconto giornaliero',
  c2191b6160c46ed7a4b05cc0279158f3: 'Tasso di mora giornaliero',
  e2d9ef555d350e16239f4dfc92842fdc: 'Bacheca',
  '106202929cf48a3de3f20b3255365915': 'Data',
  '2eca727eade526acc715c2e5605ebc7e':
    "Data in cui l'importo finanziato è stato liquidato per intero.",
  d8b0ba98f5a8b8a8e0dfe049222009ad:
    'Data in cui termina il periodo di tolleranza di 30 giorni.',
  b914f3ab262190a5dd60478fa6b7b828:
    'Data in cui la fattura è stata respinta dal tuo acquirente.',
  d5c0c15ebf4ff694fdd870b89754c7cf: 'Giorni di ritardo',
  '1e1e68c46e0d719364bd445b1d605144': 'Giorni di ritardo',
  bd50c01a22cae65be4d588abcae65a90: 'Detrazione',
  cbe998aee5abfa07da9ece7ae8c67be1: 'Detrazioni',
  d706035ec5f053ca33b234ddec228ab2: 'Descrizione',
  a39bf2f30994908fe95498524b423d43: 'Dettagli',
  '193d60da437b58b24ed69a5683c1ffcf': 'Diluito',
  '57dfeb9af6705752d57d3b50b3a2f401': 'Importo diluizione',
  d07e29091188f3b0871dc7712eee7255: 'Tipo di diluizione',
  f8a1650d7f4ba3f69777ad4352ee9288: 'Avviso di pagamento diretto del venditore',
  bba135fe5cb16d1a6c251e9fd5225eff: 'Avviso di pagamento diretto del venditore',
  afc1c7dfc9315e856f8da52e2450182b: 'Sconto',
  '26e7eb298420701d5a03c53fe096cc0b': 'Contestato',
  '43cca9c1b76ebf869c7bf22fff271689': 'Filtri di stato del documento',
  ce73b429141cda25571c88d9b0c78224: 'Stato del documento',
  af13cc2ad0a7337c45420f75b7d5a668: 'Scarica',
  '8475be8bb8249000118af931ae75c373': 'Scarica report',
  '90567e36a540e51806d7790eb195b1d5': 'Scarica tutte le fatture',
  '2a669c26b8086630dc4da5c2ee81e70c': 'Bozza',
  '72015a772310560b755bd03132a543b2': 'Data di scadenza',
  f12ae1ac50389e2b7470ae1f3d1a0795: 'Data di scadenza',
  aebf0bb5575e8a95e1eaa6f0de3d0416: 'Data di scadenza troppo vicina',
  '6def6918bd02ae5117332f4262cecb52': 'Ammissibile',
  '6426c30d14da5d40d03fc7e85d0c9677': "Stato dell'iscrizione",
  cb339c5c02cfb087fc8d393f917379df:
    'Errore nel caricamento del pannello di controllo.',
  e9994b26424cb4bce5059d05e094df8d: 'Commissione stimata',
  f159aa99d4a54cda8b884530e2ad2a1b: 'Paese escluso',
  b5cb31ac9c7d1871e9075efc1f271536: 'COMMISSIONE',
  fd9d568c402dd04e0715e877d76f7da5: 'Valore nominale della fattura',
  '5bbfbbeb3649c8a75f2e910414f2a448': 'Fallito',
  '817f1fe84c2d87894bc374681814abbf': 'Impossibile caricare {title} -',
  '7932079ce6ea928bfa77efd91578312d': 'Commissioni',
  '85624d3e9c7eb6862afe19de8d77dc45':
    'Filtra per valuta per visualizzare i totali riepilogati.',
  '110df72171aeef47da3fd84ea2a1a10f': 'Data di pagamento prevista troppo lunga',
  e5af505b37031920b08edb84df19b5ec:
    "Data di riscossione dell'importo totale finanziato",
  a4e77d51ea7969a9e1fd98d59f99c158:
    "Data di riscossione dell'importo totale finanziato",
  '6dd341953c8c405c70878a457622cf55': 'Finanziato',
  '42e20c6bb1dc82fbdbd889f769850320': 'Importo finanziato riscosso',
  cab921680af6ff282ce37398b0b231d4: 'Importo finanziato',
  fc0347ceea208298049efd79021475a2: 'Importo finanziato riscosso',
  cb293fb8bb3c321741d7350d5b4ff8f3: 'Data del finanziamento',
  '55d900eb37f97b684c0bd5821f161216': 'Fatture finanziate',
  '6ccedf75a47ac6f5064b12a63f3e0844': 'Finanziamento',
  '14e5c248a47ad6166a23fb7d4d60062a': 'Eccezione di finanziamento',
  '5ca89ce217f3bb07293db00a42c10200':
    "Motivi d'inammissibilità del finanziamento",
  eac067c62df8f46d6bbdbfc4f9e69803: 'Panoramica dei finanziamenti',
  '08109e77ecbcb34d6e7391b293d8ba20': 'Richiesta di finanziamento riuscita',
  d5ec85821723434aba05986044ebdcdd: 'Stato del finanziamento',
  '3447158e3ace443dbe27556e07c88639': 'Filtri dello stato di finanziamento',
  '022848540bf0e01453be6cf79843aa67': 'Durata del finanziamento',
  b4a59826d5bfc276fa88bd00abac615a: 'Dettagli di finanziamento',
  '8fcddea1bfb1a15dd931657f61c1b1cd': 'Durata del finanziamento',
  e74f45b44aadcdd084b5cbdd3560f515: 'Data di fine del periodo di tolleranza',
  '5434cf75f987ef76dbdc544fa1e32096': 'Istruzioni di pagamento',
  cb9f9412bd12122bb088124afccf738b: 'Come rimorsare i pagamenti parziali?',
  '9ad57f0b683fecb097c09bedebf68a45': 'Come regolare il rimborso dovuto:',
  '993620b9fcdc8e20ebb0a0fe3b069464': 'FAT',
  '7428e52e7eecc9d7211ad241a469cd65':
    'Se pensi che si tratta di un errore, ti preghiamo di contattare <a href=',
  '4bc3804f1b4b93cccd24f86f94c4d566': 'Informazioni importanti',
  '5de88f4d34f61b9e39961eecbe0ef8d2': 'In corso',
  '313b15a4fdfa2368015e2eb67416af2a': 'Programma inattivo',
  '7b6a003ceca7fe7fec85daae919e800b': 'Ineleggibile',
  '6cf0784c1ac918c22cd402e0cb558749': 'Ragione non ammissibile',
  '65cb9c651d2d9fb8941050a08add03c3': 'Ineleggibile per pagamenti anticipati',
  '0c184dc925e7a1c0bcb3066929300d6c': 'Fatture non elegibili',
  c555faa7b0b745638bb3ea785ee10137:
    "Le informazioni visualizzate sono state aggiornate per l'ultima volta il",
  b7311d63c44566a8562d92720cffed2e: 'Ratio riscossioni / fatture insufficiente',
  fb07cb1753537f6969513f2d5fdb7993: 'Importo interessi',
  '1938e208f65e66474dc4148c3fa7da08': 'Interessi dedotti',
  '42094e40339ac5c0957a980062c47659':
    'Interessi maturati sul pagamento parziale',
  d9580a19710f07e8c72c215b1270ca57:
    'Interessi addebitati da HSBC sull’importo finanziato.',
  d73af19f82bdaf892c8ab4b1a020fe55: 'Interessi dedotti',
  bf4c9a1d5b84c3b2efdc3dd43f95b9b6: 'Interessi maturati sulla fattura scaduta',
  '47a565198d287d0864339c077819a361':
    'Gli interessi continueranno a maturare sul debito scaduto fino a quando non verranno dedotti dal prossimo pagamento a tuo favore, oppure fino a quando non riceveremo un pagamento diretto.',
  '3c4be400f728da4526de626653d56ccc': 'Fattura',
  '70546e9227f592c24d3c491756cb901d': 'Fattura #',
  '49ab71fcac9df0f0fe5bad215adbc318': 'Importo della fattura',
  '6b9515d101794c31e38ae60d092fdc03': 'Data fattura',
  '2548c6a52ce9e1bffef7aad14530f490': 'Fattura finanziata',
  '42bb3ba3a1cd691726744d3a1a8da732':
    'Panoramica dei finanziamenti delle fatture',
  d6d9690b8dbcb32f5bd6d620c89ffadb: 'Limite di fattura',
  '734ed127b3d7226c75e8553c0711367a': 'Limite di fattura in uso',
  '3d2825494935cd9b356babbd5d0fbdc2': 'Limite totale di fattura',
  '64a857c3c7502d4299e4d8798fc9dc05': 'Numero fattura',
  da2db17c9b22f995d6673a754d998fc8: 'Fattura in arretrato',
  d729bd1bdced62265cd4ecd7131ef94e: 'Fattura respinta',
  e14208d4378a3378be8b27eb15984fc6: 'Fattura saldata',
  '89e202e1d2ba477fb79304c9ea0a989e': 'Stato fattura',
  '54d8df173250a197fab1644a0d0a772a': 'Importo della fattura',
  '2f0b0caf0ba91c42ecfbfebe00d85e4a':
    "La fattura non può essere finanziata in questo momento. Vedi i motivi d'inammissibilità del finanziamento per ulteriori informazioni.",
  '358a6ccfcf92e3d5cf8f6aa3861cd4d9':
    'La valuta della fattura non coincide con il paese',
  d0da9e977c8b309f93ee378de34fb251: 'Data fattura',
  '52814d7b6ec80234a8e2ecc4b5fda5b9':
    'Data della fattura anteriore alla data più recente ammissibile',
  ad0ab15468b719694f3193d0649ad9eb:
    'La data di fatturazione è successiva alla data di cancellazione della struttura.',
  fc9e80713aebb3d820d888f0755fedde: 'La fattura non ha un acquirente abbonato',
  e17cbec04e7786361eff9da629f09bb7:
    "La fattura ha una data d'emissione futura.",
  '8d1bfc3f25976dc4887bfbd7bc98ed8f': 'La fattura è stata cancellata.',
  '89f842841b4ae213259b94a5411453f2': 'La fattura è stata diluita.',
  ddd4d4560a24bcd3bb32dffdd8986c03:
    "La fattura è stata finanziata nell'ambito del programma Contanti.",
  ab5303505d045929abb1256607ca0d4d:
    'La fattura è stata interamente pagata. Non sono necessari ulteriori pagamenti.',
  '3e159746cbdfda07b2f45750941e26f6':
    'È stata richiesta una fattura per il finanziamento.',
  e8a7d355e6efb25006f6e4c50fde715a:
    'La fattura ha lo stato di documento non ammissibile.',
  '8204c52c01b05791dc3e4c59ac352978':
    'La fattura non è stata saldata completamente ed è scaduto il periodo di tolleranza.',
  d87ca92704bcfb34fa4ac08a4d24147a: 'La fattura è attualmente inammissibile.',
  '2bfdce2f3dd38d4d167510eb5c9434de':
    'La fattura non ha una data di fine o una data finale',
  '9ab3b9c101b4053a3818c764fcfcde35':
    'La fattura è troppo vicina alla data di pagamento prevista.',
  c43016d28717b24a7ac4bd2c99f26c0d: 'La fattura è insolita',
  '4df9e5dc4fbad79cc905b0b1f43eaa8c': 'Fattura contrassegnata come già pagata.',
  '4ac6a84899b9b6422f4e92b091a5448a':
    'La fattura potrebbe essere un duplicato.',
  b1f771da19efea2f0fa6a542823231b9: 'Fattura ammissibile solo parzialmente',
  '4217948ca113ee4158d5dd4c07d71624': 'Fattura regolata con pagamento parziale',
  b6d4f7035794b3bc3a0be36237fedd2b:
    'La durata della fattura non è compresa in un intervallo accettabile',
  '7c3fa246e8cca51273ddf5062ff2d593': 'Durata della fattura troppo lungo',
  '90d9ac20dda384e9c07554d29b143bef':
    'Il valore della fattura supera il limite',
  '4b36f0be327fdca8d54126cd57095a13': 'Numero fattura/ADP',
  cb5bf06bec8dcbfb20319ad13bdf57fd: 'Fatture',
  '8089283aad9294aadc2bde165ccc78c1': 'Data di emissione',
  '3a4123f9c604369ab18781f7492f9293': 'Data di emissione',
  f2cc23904a2b3bf60e1c059712a12cd7: 'Ultima data di pagamento',
  a94bc4d4df1d56c16e25493e22684412: 'Limite Disponibile',
  '92f97c843d499a1559828ba0a9134e24': 'Limite in uso',
  b5dd8e130d88698834ee00464f01cea4: 'Limite disponibile',
  '08f8f98f90b3eb19ef77ad80b322921f': 'Limite in uso',
  '4a739fa0b571805cc56fc4080ea82fcb': 'In caricamento...',
  '24cc5627dea65e22f9279be9522a06e2': 'Importo del prestito',
  bd24bcedd066a27749016a07cb729a32: 'Contrassegnato saldato',
  d0dcb336e1d5256ceb7d9a8b8c5a478e: 'Data di scadenza',
  '41dcb6cef39f12b7e13ec8e3e504b3b3': "Data d'inizio dell'adesione",
  d26429adfb846303cbd889879b23e1ba:
    'I soldi incassati dai pagamenti degli acquirenti, dalle rimesse dei venditori e/o dalle detrazioni prima e/o dopo il periodo di tolleranza.',
  '2e5b5d9440ab665bfd8ad63a16c6738b': 'Importo netto pagato',
  '3d18f259085bdd0acd2ffebd2cc95e4b': 'Importo netto pagato',
  eb094ee2b99cdffc1c6c4db9349d0cc2: 'Nuovo Importo',
  ff47fd34d6a57673fa57ad67ec26feb0: 'Prossimo giorno lavorativo',
  '325c48f099844279c0cf48584de42e64': 'Nessuna attività recente',
  '8ab2d59e3a0a751ccbf443e0b04fca48': 'Nessuno',
  '7457575d324c4b8dada2e13c0f0fe5d5': 'Non iscritto attivamente in Cash',
  '97d4ed503f1a63577fc5befd1c7a2663': 'Storico fatture non sufficiente',
  '38e96e9d0dcbbe1b0f22950fdde4d82f':
    'Non hai fatture sufficienti nei mesi precedenti',
  c9e2ce8da559465a8596317c35e8f3a4: 'Fatture pagate insufficienti',
  e6f890deafa579eb0c24603c3101c1c7:
    "Nota che i saldi sono in genere aggiornati il giorno lavorativo successivo. Apri un ticket d'assistenza se hai bisogno di conoscere la posizione attuale.",
  '83070a059f10db1515ac922bf3433158':
    'Numero di giorni dopo la data di fine del periodo di tolleranza.',
  '9d40ba58bf72a5bf003cd0d9a1fac469': 'Un report disponibile per il download',
  '2778717f6acbeb1edaac6a2656b478f3': 'Apri',
  '2c825aed78dedab44e18d2c0e60e7cf7': 'Altre commissioni',
  fad92e97e692758c0d0a9b5730663894: 'Interessi di debito in sospeso',
  '6c2e55b7a6b08252eb4124bd2f75b5df': 'Importo finanziato in sospeso',
  '9eab1baddf2537312408088fc54f21d5': 'Addebiti sugli arretrati in sospeso',
  bfba12a2c234532c0af24a4ad6831ab6: 'Importo totale in sospeso',
  '2e16b3dff2a0a8f233a1b8b62c1b38ec': 'Interessi di debito in sospeso',
  '3b77ef3a1af4e72eb5072092bae35ddd': 'Importo finanziato in sospeso',
  '2f9a3de6940f818fe0376e4aece59512': 'Addebiti sugli arretrati in sospeso',
  '6f79eea38bff17917740a69116270074': 'In arretrato',
  '1fdf67a5f18bc408f2ad55412310c39f': 'Saldi in arretrato',
  '848ac8c39b11a751d697a383303e6109': 'Debito in arretrato',
  '8d1e868665fc24a46f0cf55187f72218':
    'Il debito scaduto sarà notificato in conformità alla normativa RBI.',
  dc14337c43c60e68f4766cb5dd95bbc2: 'Panoramica',
  '5b885084a5067d7fb4372cef38d8a82d': 'Pagato',
  '830b95d305cdc61a66cdf75ebff1d737': "Pagato dall'acquirente",
  '6444796646c95dbc2cd1cb646e41293d':
    'Parte del valore nominale della fattura che è stata finanziata in base alla percentuale di pagamento anticipato approvata da HSBC.',
  '92cacee97a99fe8c26a5c1999ae51293': "Dettagli dell'acquirente partecipante",
  a39314e5897b8baaa51ca296610ec30c: 'Termini del partner',
  a6acd5f03fda9c8dc607e59d8be08320: "Ritardo d'approvazione del pagamento",
  e6249829dadf637c4092e4a900df529e:
    "L' esigibile è stato contestato dall'acquirente",
  '07ed468d28d8e8437d8ec64036b96c03': "L' esigibile è stato respinto",
  '042b8dfb88f7045ca9145bbcccf79200': "L' esigibile è nel futuro",
  f8768f5b4055c45a4d30591bea9cc7b1: 'Il pagamento è troppo vecchio',
  '8755bbc212b541efb6576f39f3f0f51f': 'Pagamento allocato',
  '8744ee10540166dfc39db2a6859a9ce1': 'Importo del pagamento',
  '56133aa371eb0132334e4867479be942': 'Dettagli di pagamento',
  '28d03c82bf3e37e2ff159922908c1ecb': 'Pagamento scaduto',
  '2dd7164df606f9b42beefaba031b06f7': 'Pagamento ricevuto',
  '4e62b46d2c536f88dcd8e6c6a70d67ce': 'Pagamento confermato dal venditore',
  '661711004f06b0d9601db365679be130': 'Importo del pagamento',
  c6e4c2b52f344cb3dc4f21eb1af772db: 'Data di pagamento',
  c28381c5e3ed0ea4f48a4dee4ebd6f40: 'Interessi di mora',
  '0fd036622716f275c3e14c5a14faa65a': 'In attesa',
  '5d6bc30e401a38c04005a5d1962b46bc': 'Diluizione in sospeso',
  '8cf3479829ba47a8a4123895d0088eeb': 'Diluizioni in sospeso',
  '270b56fd05f72407ca0607b6162d0b10': 'Connessione in sospeso',
  '4a78218416ca45d97b5633b37ae3f638':
    "Tasso percentuale al quale saranno calcolati gli oneri giornalieri per l'intera durata del finanziamento.",
  b586a5468c7070bf1717593ea84fa607:
    "Tasso percentuale che ti verrà addebitato giornalmente sull'importo finanziato in sospeso per le fatture in arretrato o finanziate e rifiutate.",
  f8c94740abd5c69fb813bd0f4827de0e:
    'Ti preghiamo di aprire un ticket di supporto se hai bisogno di conoscere i saldi correnti.',
  '34645fa620cae4187f27d9f96c172f26': "Ti preghiamo di versare l'importo",
  '4c54e70d0c88c94d16c03e32519d6be7':
    "Ti preghiamo d'inviare l'importo di seguito al seguente conto bancario:",
  '01734a7316427fdcdcd4f937cda3802b': 'Pagamento anticipato',
  '8181a4caaa4413f5585df4a5a5f979ba': 'Importo precedente',
  '500fc4731dab800a878b33906d9332fb': 'Conto di riscossione del programma',
  '7327113634d1f5f4f68632dceebfbc6f': 'Panoramica del programma',
  d95ebfb51a485293a134b1038d332a10: 'Programma attivato il',
  '6e5f8b0b9bafba4816b7a0430194289a':
    'Dettagli del programma non disponibili per la bacheca',
  '98feb2c86a81b8caeeaa30966ba34636': 'Informazioni sul programma',
  d202f03687187e744a1f4b11a1e5f8e4:
    "I pagamenti di fatture d'acquisto che non sono riscossi interamente entro la data di scadenza prevista generano un pagamento parziale. I pagamenti parziali maturano interessi pari ad 1,5 volte il tasso di sconto giornaliero. Sarà tua responsabilità rimborsare entrambi.",
  '521689b600b8da5b0a111b89dea72126': 'Ragione',
  fe7bd622a1efcec03eb86dabb9395936: 'Ricevuto',
  e84203a8833e4d0d92faf89f4107bf11: 'Rimborso pro-soluto',
  '7e7c742f83bcdbd977c9e32d053b3f52': 'Importo del rimborso',
  b0be744ae1f6312f16990bbd92a87aea: "Data d'emissione del rimborso",
  '39591f42bcdde6d7ce9409e167aa7e80': 'Durata del rimborso',
  '654d6dcc141079bc84500fd14ca5087d': 'Importo del rimborso',
  '50618267079bccdc7f15c8a8e8aa6b73': 'Dettagli del rimborso',
  '5b2508eaa0c48b46fe2034cdf1916867': "Data d'emissione del rimborso",
  c0a6a91e7ca80af9de47097b06dc7618: 'Durata del rimborso',
  '4a2875439ab289a068abb9ea3b567bcb': 'Rifiutata',
  '592b34cdfa2e51826b32a0783e0edce3': "Respinto dall'acquirente",
  '807d35f105b65ab2795c4f8e51795f3c': 'Respinto dal venditore',
  aea4dd208e871d7ca0051b0ec38c3068: 'Data del rigetto',
  d64ed4934fbf68a8f716467b0adf89ba: 'Data del rigetto',
  '5a77de3b287a92494be077c197f37581':
    'Importo residuo finanziato ancora da riscuotere o rimborsare.',
  e442b864bf745a75477ea0ee88bc15c4: 'Data di rimborso',
  b4f924c1a7c5d182fab9d3088cc22471:
    'Importo del rimborso alla data di scadenza del periodo di tolleranza. Lo stesso non cambia per riflettere eventuali rimborsi effettuati dopo tale data, né include i debiti arretrati.',
  '636a65df0d453f8e6f73e87e65276c5e': 'Rimborso dovuto',
  dc20cbfb76d220c108241a451bc3faf4: 'Informazioni sulle istruzioni di rimborso',
  c8c021e2a7c9064a0b97db4aa96f6623:
    "I rimborsi dovuti sono l'importo totale in sospeso che deve essere recuperato, a causa del fatto che l'importo pagato dall'acquirente è inferiore al valore nominale della fattura e/o che l'acquirente rifiuta la fattura dopo che è stata finanziata.",
  '45b60e22719c12291932bf09db0df5cd':
    "I rimborsi dovuti sono l'importo totale in sospeso che dev'essere recuperato, risultante da:",
  ba093b1421452b3afdebbebedaeae552: 'Richiedi finanziamento',
  '34f8f9968ec0d7f91e558e906a7de982': 'Richiesto',
  dc1650cfbadb05f8fb6357692e48ee71: 'Richiesta di finanziamento',
  '563dff6e09a85e6fb07b91c48e9d0fca': 'Trattenuto',
  '15a9cd2bd0e2cda9a8f399b4a55ffed2': 'Importo trattenuto',
  a476316a89541d1aa71d5b5366874e13: 'Importo trattenuto',
  '328f6585a0e78a96deefc0661474248f': 'Riprova',
  '626efcff97ad72e5eb9cc8a602aec8bd': 'MP',
  '70c8ea0dc7c6d6c7e9cffaa1c9e893fc': 'Vendite',
  '87d4fbccb70028d34d4260b9527e3fd3': 'Cerca per numero di fattura',
  '3215999493db188b35242dc4f4fd8fb5':
    'Vedi le informazioni sul programma per maggiori dettagli sui rimborsi.',
  '69f43575c3a51e94605eb75b75f7b563': 'Selezionato',
  '56a1c7c4a02e9985745969563a80799f': 'Conto di rimborso del venditore',
  dfc8eafdd7ec598ceb6d2f1bdf68fd50: 'Invio',
  '3e7dc5ef26363b6a0176875cb5d6fee0': 'Inviato',
  '95d54926719f1071b2fa640f1ba23d3e': 'Saldato',
  e243f36392a7f264d830f04f21aa8972: 'Fatture regolate',
  b548572d4123e3a569d27e4676664dad: 'Mancato pagamento',
  '6e09ada7e33833d259370b0cc900f082': 'Importo pagamento parziale',
  bbb44706c9f67b578a7aac0a5ed30e40: 'Deduzioni per pagamenti parziali',
  '8be3fe50247f2256bd4aa1eb8bc67fae': 'Pagamenti parziali',
  '46bbc145b1f85c082950c768ade012f6': 'Importo pagamento parziale',
  e66e6d9768e8f489c05951ee70ede350:
    'Qualcosa è andato storto, non tutte le funzioni della bacheca potrebbero essere disponibili. Ti preghiamo di riprovare o contattare il supporto clienti.',
  cd969f183dc619b5716067cfda39c055: 'Codice d’avviamento bancario',
  '17939071126e9e41921d79b837f63297': 'Nome fornitore',
  '750d4460b260343039f9bc71b66a231c':
    'Il fornitore è stato giudicato insolvente',
  '7f6562457845bcf84c0cfece3d1eaa51':
    'Questo fornitore non è stato ancora configurato nel programma',
  '8876cfd0d8d60c5defe5e40d9d563da3': 'Supporto',
  '9ef0225cd6b858ef31c2f3c1bd312baa': 'Termini accettati da',
  '4a8bbcc5abd16a59fa1cc5548cafe8ac': 'Termini del servizio',
  '1294cf929ec55581954a79948592dda9':
    'La data di finanziamento del programma Contanti è stata attivata.',
  f51144e4468390a486d41b4db66fc243: 'Impossibile scaricare il file.',
  '692eebfa29fc5d4a7090a22f5aa668fd':
    "Il numero massimo di giorni in cui si prevede il pagamento da parte dell'acquirente, come approvato da HSBC.",
  d438da584c455e727642a303917e690d:
    "L'importo residuo del credito che può essere utilizzato. È la differenza tra il limite di credito e il credito utilizzato.",
  '78c4e85a6398bb0b164ed2479f1faeca':
    "L'importo totale delle fatture attualmente finanziate per questo programma. Questo importo viene calcolato in base al limite di credito e all'importo attuale delle fatture finanziate.",
  '43fc53cbc779fd0ef3f42d350a2274b5':
    "L'importo totale delle fatture che possono essere finanziate per questo programma. Questo importo viene calcolato in base al limite di credito e all'importo attuale delle fatture finanziate.",
  '9c9fa02e1129e31e3423a0dfca81732d':
    "L'importo totale di denaro che è stato trattenuto prima del regolamento della fattura.",
  '13f271ac486faca89c1944226ba0efff':
    'Il valore nominale totale di tutte le fatture importate.',
  '4c1f04bf5bcde1f8e3532de0896377fe':
    'Il valore nominale totale delle fatture che sono passate allo stato di liquidazione perché sono state interamente incassate.',
  '8cbe218cd17eada461e5da93d4cbd541':
    'Il valore nominale totale delle fatture finanziate.',
  '565c255165bed2ff2640d0e42369c62c':
    'Il valore totale delle fatture non ammissibili al finanziamento.',
  '3463c9dcfdb9aa161c07a692207786bf':
    'Questo account non è iscritto attivamente al Cash.',
  '1fa3524cbb92859434dcb01b98f71c7d':
    "In genere viene aggiornato il giorno lavorativo successivo. Per conoscere i saldi attuali, ti preghiamo d'aprire un ticket di supporto.",
  '0678bf00ae9e72b3053f3d5f0f64e639':
    'Questo valore riflette il tuo saldo più recente di pagamenti parziali inclusi eventuali interessi.',
  '32460c75c8ea6599665b77eeb2df37a3': 'Insieme con',
  '603e4b3b3ac7b888a06548d14435bc25': 'Troppe diluizioni',
  d356429f9bd0a4a6036707d99bf5e2b2: 'Totale',
  '7cfb9dc5c11c2fb869c897552b563d14': 'Importo totale riscosso',
  f786c8a1095bdf155841d510bb53142a: 'Interessi totali sul debito in arretrato.',
  '4f25d1096076868bc590da9f8a26cf55': 'Limite totale',
  '230bd56af0acab75194f58b46a763535':
    "Importo totale riscosso dai pagamenti dell'acquirente.",
  '8f78461d739b4d6d46f961f352000cec': 'Importo totale riscosso da te.',
  b62dd565f60dc6172605d8db223510b9:
    "Importo totale riscosso attraverso avvisi di pagamento dell'acquirente, avvisi di pagamento del venditore e / o restituzioni, inclusi eventuali interessi sul debito in arretrato.",
  '8dfcacc58acbafda8eca3d58c4d8a447':
    'Importo totale finanziato a fronte delle vendite.',
  '1c27145f9530e0e06436fea1e3e645cf':
    'Importo totale non finanziato, come da percentuale di pre-pagamento approvata da HSBC.',
  d6c89215b3fd6857262efc2039d78dec:
    'Importo totale delle fatture finanziate riscosse integralmente.',
  a069138e83a8b4cb7e81145689155826:
    'Importo totale degli interessi sui debiti arretrati da pagare o in sospeso.',
  '45f2193bb0825a19bb1ede965692c3a4':
    'Importo totale degli addebiti sugli arretrati dovuti. Le penali si accumulano quotidianamente.',
  bc37640f0d55a99b3f0b8bce05a2f0d1:
    'Importo totale degli addebiti sugli arretrati pagati.',
  bec87347063c8e0b9fac78e381fa0bff:
    'Importo totale degli addebiti arretrati ancora da pagare o in sospeso.',
  d649fb9a0971deaa127067341f59be91:
    'Importo totale recuperato da altre detrazioni di fattura.',
  '28fcda8475061ec7eb4894565e49fc87':
    'Importo totale ancora da riscuotere o rimborsare.',
  d6b59ced2fa8fba56e7e9c3c451fd409: 'Importo totale della raccolta',
  '04296a7ba8cd0ba0cea8842ef2a2e5e3':
    'Valore nominale totale delle fatture non ammissibili al finanziamento.',
  '02bd64f3a39f4190918d46eb0e86a1c6':
    'Valore nominale totale delle fatture inviate agli acquirenti.',
  f0164c47e6c3d2497856505cb08e6e4f:
    "Importo totale finanziato riscosso attraverso la rimessa dell'acquirente, la rimessa del venditore e/o la restituzione.",
  d4867ee77e79ded226c07918b9393715:
    'Totale degli interessi pagati sul debito in arretrato.',
  de4c28160afe1ab48bcf83be49bbb196: 'Limite totale',
  '9c9f73042961fc50756dc8ae2bbe2a6b': 'Importo totale in sospeso',
  '8fc4a8a140b022c2b3e062321fa9aaba':
    'Importo totale finanziato in sospeso ed eventuali interessi di mora da rimborsare. Questo potrebbe essere dovuto al fatto che una o più fatture finanziate sono scadute o che il tuo acquirente ha respinto una o più fatture dopo che sono state finanziate.',
  fecdb72e34cebfadf037b45b596d90e5: 'F.A.Q. programma contanti Tradeshift',
  '528a7c5ab621e9a113ff3fa6e16323e5': 'Dettagli della transazione',
  e00ab25ba9a337b2f39d3d7dc06790c1:
    'Impossibile scaricare la fattura CSV. Ti preghiamo di contattare il supporto.',
  c8415d81378085ba3933015b8f5fc768:
    'Impossibile recuperare il contratto. Ti preghiamo di contattare il supporto.',
  '64580a198c861aa0226c91b28f8418aa': 'Non disponibile per la vendita',
  '5d7f86753376a21d28b5c1ad07c7d149':
    'Le fatture non pagate oltre questa data saranno considerate "In arretrato" e matureranno quotidianamente interessi di mora.',
  '9d632e72031eea3767881d6c3adcfe54': 'Limite di credito utilizzato',
  e871a6882109bd7ef07827882236c8db: 'Vedi accordo',
  '2ac548c28faaa41283f9d6b7606a4844': 'Annullato',
  '0bcb6eb0e07f04a47296d8c341dd94ad': 'Benvenuto in Contanti!',
  '78eb58937961a18929a623397b544a01':
    'Puoi saldare il saldo del rimborso dovuto effettuando un bonifico diretto sul conto di rimborso (indicato sopra).',
  f347abf54f65274a614a9ba7515dfa98:
    "Puoi monitorare tutti i pagamenti anticipati in un unico luogo grazie all'app Contanti",
  '0b28e75aa5587b8f49edaa7e620b5cf0': 'Il tuo programma contanti è attivo!',
  '7807cbebc0a0ffe93df0d4b4f626eed5': 'Il tuo programma contanti è inattivo',
  '6e6e06469dd953a124d718cd2307e32c':
    'La tua partecipazione al programma contanti (Cash) non è attiva dal {date}. Contatta <a href=',
  '626afbd7141286d15df45bccdb695a66':
    "una fattura finanziata che viene respinta dall'acquirente prima della fine del periodo di tolleranza (maturando interessi giornalieri dopo il giorno del rigetto)",
  '5b2ecfd8f9f07ffbb60c5f166959f789':
    'una fattura finanziata non pagata al termine del periodo di tolleranza (interessi maturati ogni giorno successivo); e/o',
  '33e67e73d7f54aaee31e6040f873be79': 'applicato alle deduzioni',
  a9798a3e063111de4d4768c363bdc267: 'applicato alle fatture',
  a5be13d3bfec5fc92521a841009db3c7: 'Numero di conto bancario',
  '736c1e36ac779a73ff4fddd19d82008c': "tasso d'interesse giornaliero",
  ab53acde68c117f8fbfff6a8901de083: "tasso d'interesse di mora giornaliero",
  '4c584eabd4260d69914ac993cc4db5d2': 'giorni',
  '047dbee224da5fb4ecfc21268ffd3115': 'data di scadenza',
  dd051262964c80130a2e9023ff933d89: 'pagamento anticipato',
  bc55e8a10310a96eeda133ddf159303d: 'data di scadenza prevista',
  '0c38a7cd8b860eb533f8f20ba93a6f0e': 'per pagamenti anticipati',
  '932d9bd46daba4d09f361717c0ec03ab': 'importo finanziato',
  '5e82566144af4903b40303fc3d1c799e': 'data del finanziamento',
  '97048ad7f0d40ee95f476fdd4c7bf81e': 'Periodo di tolleranza',
  '0a6949f0e73bc3c42b0e93fe10e63fff': 'pagamento a scadenza',
  '2db524546615d120af0adf844a6e1bd7': 'di',
  '99c2f4783bb6821d84ad17a22f556813': 'di Finanziato',
  '7555b72d3b4ffe69558b68b3cb86b954': 'su',
  c138be463a457afe89e5ecfefc6a3dc9: 'Note di credito in sospeso',
  '37f59e13afd5455cf130060ed55f4976': 'numero di routing',
  '1e5c6ba6010146f42f6fa180329cf2dc': 'durata',
  '763d4d3568ec0349aa3c3dbe3064fd59': 'al seguente conto bancario:',
  cf4edd5f38054a752994e3242a1f3807: 'Importo totale',
  '3c9f634310f5e3de42b79ee83d20923c': 'Totale commissione',
  '573b1b7930d8030d1669945f2c34992a': 'trasferito alla tua banca',
  ea0cb39e8c47c1bcafa2a9e737db4cdf: 'sarà detratto dai pagamenti imminenti',
};
